import {ElView} from "../../el-view";
// import {Swiper} from "swiper/swiper-react";
import {capitalizeFirstLetter} from "../../helper/string-helper";
import {Translator} from "../../translation/translator";

export const SwiperHomeComponent = ElView.extend({
    el: 'swiper-home-component',
    events: {
        // 'click .swiper-button-next': 'onNextSlide',
    },

    swiper: null,

    init() {
        this.swiper = new Swiper('.swiper-container', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },


        });

    },

    hola(){
        this.swiper.width(100);
    }




})