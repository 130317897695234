import {ElView} from "../../../shared/el-view";
import {formatNumber} from "../../../shared/helper/number-helper";

export const AdminChargeRakesPreviewTable = ElView.extend({
    events: {
        'click .j-open-affiliate-modal': 'onClickOpenAffiliateModal',
    },
    data: null,

    init(params) {
        $('#modal-affiliate-rewards').on('hide.bs.modal', (event) => {
            this.onHideAffiliatesModal(event);
        });

        this.data = params.data;

        $('.j-room-select-charge-rakes_preview-rakes').select2();

        this.previewTable();
    },

    previewTable() {
        const _this = this;

        this.datatable = this.$el.KTDatatable(
            {
                data: {
                    type: 'local',
                    source: _this.data,
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#rakes-search'),
                    key: 'generalSearch'
                },
                autoWidth: false,
                columns: [
                    // {
                    //     field: 'createdAt',
                    //     type: 'date',
                    //     format: 'DD-MM-YYYY',
                    //     title: capitalizeFirstLetter(Translator.trans('Creación')),
                    //     template: (row) => {
                    //         return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`
                    //     },
                    //
                    // },
                    {
                        field: 'fullname',
                        title: 'Nombre',
                        template: (row) => {
                            if (row.fullname === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" 
                                            style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">${row.fullname}
                                        </label>`;
                            const route = this.route('admin-user-details', {userId: row.userId})
                            return `<a href="${route}" class="font-weight-bolder">${row.fullname}</a>`
                        },
                    },
                    {
                        field: 'roomName',
                        title: 'Sala',
                        template: (row) => {
                            const route = this.route('admin-room-details', {roomId: row.roomId})
                            return `<a href="${route}" class="font-weight-bolder">${row.roomName}</a>`
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.nick}</span>`
                        }
                    },
                    {
                        field: 'currencyConversion',
                        title: 'Cambio moneda',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${formatNumber(row.currencyConversion, 4)}</span>`
                        }
                    },
                    {
                        field: 'userMultiplier',
                        title: 'Multiplicador',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${formatNumber(row.userMultiplier, 2)}</span>`
                        }
                    },
                    {
                        field: 'rake',
                        title: 'Rake',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(row.rake, 2)} ${row.currencySymbol}</span>`
                        }
                    },
                    {
                        field: 'userReward',
                        title: 'A recibir',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(row.userReward, 2)} €</span>`
                        }
                    },
                    {
                        field: 'affiliates',
                        title: 'Afiliados',
                        textAlign: 'center',
                        template: (row) => {
                            if (_.isEmpty(row.affiliatesData) || this.noAffiliateHasReward(row.affiliatesData))
                                return ``;
                            return `<button class="btn btn-icon btn-info font-weight-bolder font-size-sm j-open-affiliate-modal"
                                        data-toggle="modal" data-affiliate-rewards=${this.escapeHtml(JSON.stringify(row.affiliatesData))}
                                        data-target="#modal-affiliate-rewards"> <span class="fas fa-user-friends"></span>
                                </button>`;
                        }
                    }
                ],
            });

        $('#charge-rakes-search-fullname').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'fullname');
        });

        $('#charge-rakes-search-nick').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'nick');
        });

        $('.j-room-select-charge-rakes_preview-rakes').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'roomName');
        });
    },

    noAffiliateHasReward(affiliatesData) {
        for (const data of affiliatesData)
            if (parseFloat(data['affiliateReward']) > 0)
                return false;

        return true;
    },

    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            .replace(/ /g, "&nbps;");
    },

    unescapeHtml(unsafe) {
        return unsafe
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#039;/g, "'")
            .replace(/&nbps;/g, " ");
    },

    onClickOpenAffiliateModal(event) {
        const affiliatesData = $(event.currentTarget).attr('data-affiliate-rewards');

        setTimeout(() => {
            this.affiliateTable = $('#admin-charge-rakes-affiliates-datatable').KTDatatable(
                {
                    data: {
                        type: 'local',
                        source: JSON.parse(this.unescapeHtml(affiliatesData)),
                        pageSize: 10,
                    },
                    rows: {
                        autoHide: false,
                    },
                    layout: {
                        scroll: false,
                    },
                    sortable: false,
                    pagination: false,
                    columns: [
                        {
                            field: 'affiliateName',
                            title: 'Nombre',
                            template: (row) => {
                                const route = this.route('admin-affiliate-details', {affiliateId: row.affiliateId})
                                return `<a href="${route}" class="font-weight-bolder">${row.affiliateName}</a>`
                            },
                        },
                        {
                            field: 'affiliateReward',
                            title: 'Recompensa',
                            template: (row) => {
                                return `<span class="font-weight-bolder">${formatNumber(row.affiliateReward, 2)} €</span>`
                            }
                        },
                    ],
                });
        }, 500);

    },

    onHideAffiliatesModal() {
        this.affiliateTable.destroy();
    },

    destroy() {
        this.datatable.destroy();
    },
})