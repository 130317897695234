import {ElView} from "../shared/el-view";
import slugify from "@sindresorhus/slugify";

export const BlogSectionDetailsPage = ElView.extend({
    el: '.j-blog-section-details',
    events: {
        'keyup .j-create-section-form-title-input': 'onKeyPressed',
        'click .j-save-section': 'onSaveSection',
        'click .j-remove-section': 'onDeleteSection',
    },

    init() {

    },

    onKeyPressed() {
        let text = this.find('.j-create-section-form-title-input').val();

        this.find('.j-create-section-slug').val(slugify(text));

    },

    async onDeleteSection(event) {
        const sectionId = $(event.currentTarget).attr('data-section-id');
        const route = this.route('remove-section', {sectionId: sectionId});

        const result = await this.confirmation('¿Deseas borrar la sección?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Sección eliminada');

                const redirectRooute = this.route('admin-blog-sections');
                location.replace(redirectRooute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'La sección no ha sido eliminada');
            }
        }
    },

    onSaveSection() {
        this.find('.j-create-section-form-title-input').prop('disabled', false);
        this.find('.j-create-section-slug').prop('disabled', false);
        this.find('.j-section-form').submit();
    },
});