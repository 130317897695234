import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";

export const AdminAffiliatesPage = ElView.extend({
    el: '.j-admin-affiliates',
    events: {},
    datatable: null,
    init: function () {
        this.find('.j-select-2-affiliate-type').select2({
            minimumResultsForSearch: -1,
        })

        this.initAffiliatesTable();
    },

    initAffiliatesTable() {
        const _this = this;
        this.datatable = this.find('#admin-affiliates-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('affiliates-table-data'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#affiliates-search'),
                    key: 'generalSearch'
                },

                columns: [
                    {
                        field: 'name',
                        title: capitalizeFirstLetter(Translator.trans('name')),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.name}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        template: (row) => {
                            return `<a  href="${_this.route('admin-user-details', {'userId': row.userId})}" class="font-weight-bolder">${row.username}</a>`;
                        }
                    },
                    {
                        field: 'type',
                        title: capitalizeFirstLetter(Translator.trans('type')),
                        template: (row) => {
                            return `<span class="font-weight-bold">${capitalizeFirstLetter(Translator.trans(row.type))}</span>`;
                        }
                    },
                    {
                        field: 'referedsCount',
                        title: 'Credenciales referidas',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${capitalizeFirstLetter(Translator.trans(row.referedsCount))}</span>`;
                        }
                    },
                    {
                        field: 'parentAffiliate',
                        title: 'Sub afiliado',
                        textAlign: 'center',
                        template: (row) => {
                            if (_.isUndefined(row.parentAffiliate) || row.parentAffiliate === '' || row.parentAffiliate === 'undefined')
                                return ``;

                            return `<a  href="${_this.route('admin-affiliate-details', {'affiliateId': row.parentAffiliateId})}" class="font-weight-bolder">${row.parentAffiliate}</a>`;
                        }
                    },
                    {
                        field: 'roomsName',
                        title: capitalizeFirstLetter('Salas con credenciales'),
                        class: 'd-none',
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.roomsName)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.roomsName[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'nicksAndLogins',
                        title: capitalizeFirstLetter('nicksAndLogins'),
                        class: 'd-none',
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.nicksAndLogins)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.nicksAndLogins[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        textAlign: 'center',
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        template: function (row) {
                            const editRoute = Router.generate('admin-affiliate-details', {'affiliateId': row.id});

                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`;
                        },
                    },
                ],
            }
        );

        $('#admin-affiliates-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'name');
        });

        $('#affiliates-filter-by-type').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'type');
        });

        $('#affiliate-search-rooms').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'roomsName');
        });

        $('#user-search-nicks-logins').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'nicksAndLogins');
        });
    },
});