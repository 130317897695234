import {ElView} from "../el-view";

export const PostContentEditorComponent = ElView.extend({
    el: 'post-content-component',
    events: {
        'click .j-copy-form': 'onCopyForm',
        'click .j-paste-form': 'onPasteForm',
        'click .j-copy-es-content': 'onCopyEsContent',
        'click .j-copy-en-content': 'onCopyEnContent',
        'hide-es': 'onHideEs',
        'hide-intl': 'onHideIntl',
        'hide-gb': 'onHideGb',
        'show-es': 'onShowEs',
        'show-intl': 'onShowIntl',
        'show-gb': 'onShowGb',
        'error-es-es': 'onErrorEsEs',
        'error-es-gb': 'onErrorEsGb',
        'error-en-gb': 'onErrorEnGb',
        'error-es': 'onErrorEs',
        'error-en': 'onErrorEn',
        'clear-errors': 'onClearErrors',
    },

    copiedLanguage: null,

    copiedTitle: null,
    copiedMetaTitle: null,
    copiedSlug: null,
    copiedDescription: null,
    copiedMetaDescription: null,
    copiedContent: null,

    copiedLead: null,
    copiedClaim: null,

    countryToNavLinkMap: {
        'ES': ['j-esEsContent-nav-link'],
        'GB': ['j-ukEsContent-nav-link', 'j-ukEnContent-nav-link'],
        'intl': ['j-esContent-nav-link', 'j-enContent-nav-link'],
    },

    localeToNavLinkMap: {
        'es_ES': 'j-esEsContent-nav-link',
        'es_GB': 'j-ukEsContent-nav-link',
        'en_GB': 'j-ukEnContent-nav-link',
        'es': 'j-esContent-nav-link',
        'en': 'j-enContent-nav-link',
    },

    init() {
    },

    onCopyForm(event) {
        const $button = this.$(event.currentTarget);

        this.copyFromLang($button.closest('.tab-pane'));
    },

    onPasteForm(event) {
        const $button = this.$(event.currentTarget);

        this.pasteToLang($button.closest('.tab-pane'))
    },

    copyFromLang($container) {
        this.copiedLanguage = $container.closest('.tab-pane').attr('id').substr(0, 2);

        this.copiedTitle = $container.find('.j-create-post-form-title-input').val();
        this.copiedMetaTitle = $container.find('.j-create-category-form-metaTitle-input').val();
        this.copiedSlug = $container.find('.j-create-post-slug').val();
        this.copiedDescription = $container.find('.j-create-post-form-shortDescription-input').val();
        this.copiedMetaDescription = $container.find('.j-create-category-form-metaDescription-input').val();
        this.copiedContent = $container.find('.j-create-post-form-content-input').val();

        this.copiedLead = $container.find('input[class*="j-create-room-post-lead-"]').val();
        this.copiedClaim = $container.find('input[class*="j-create-room-post-claim-"]').val();
    },

    pasteToLang($container) {
        const isNotTheSameLanguage = this.copiedLanguage !== $container.attr('id').substr(0, 2);
        if (isNotTheSameLanguage) {
            this.showErrorWrongLanguageToCopyFrom();
            return;
        }

        const $parentContainer = $container;

        const $inputTitle = $parentContainer.find('.j-create-post-form-title-input');
        const $inputMetaTitle = $parentContainer.find('.j-create-category-form-metaTitle-input');
        const $inputSlug = $parentContainer.find('.j-create-post-slug');
        const $inputDescription = $parentContainer.find('.j-create-post-form-shortDescription-input');
        const $inputMetaDescription = $parentContainer.find('.j-create-category-form-metaDescription-input');
        const $inputContent = $parentContainer.find('.j-create-post-form-content-input');

        const $inputLead = $container.find('input[class*="j-create-room-post-lead-"]');
        const $inputClaim = $container.find('input[class*="j-create-room-post-claim-"]');

        this.pasteToInput($inputTitle, this.copiedTitle);
        this.pasteToInput($inputMetaTitle, this.copiedMetaTitle);
        this.pasteToInput($inputSlug, this.copiedSlug);
        this.pasteToInput($inputDescription, this.copiedDescription);
        this.pasteToInput($inputMetaDescription, this.copiedMetaDescription);
        this.pasteToInput($inputLead, this.copiedLead);
        this.pasteToInput($inputClaim, this.copiedClaim);
        if (!!this.copiedContent)
            $inputContent.summernote('code', this.copiedContent);
    },

    pasteToInput($input, value) {
        if (!!value)
            $input.val(value);
    },

    onHideEs(event) {
        this.hideTabsOfCountry('ES', $(event.currentTarget));
    },

    onHideGb(event) {
        this.hideTabsOfCountry('GB', $(event.currentTarget));
    },

    onHideIntl(event) {
        this.hideTabsOfCountry('intl', $(event.currentTarget));
    },

    onShowEs(event) {
        this.showTabsOfCountry('ES', $(event.currentTarget));
    },

    onShowGb(event) {
        this.showTabsOfCountry('GB', $(event.currentTarget));
    },

    onShowIntl(event) {
        this.showTabsOfCountry('intl', $(event.currentTarget));
    },

    hideTabsOfCountry(country, $component) {
        this.countryToNavLinkMap[country].forEach((tabNavLinkClass) => {
            $component.find('.' + tabNavLinkClass).addClass('disabled');
        });

        this.clickOnFirstEnabledTab($component);
    },

    showTabsOfCountry(country, $component) {
        this.countryToNavLinkMap[country].forEach((tabNavLinkClass) => {
            $component.find('.' + tabNavLinkClass).removeClass('disabled');
        });
    },

    clickOnFirstEnabledTab($component) {
        const $activeTab = $component.find('.j-post-content-nav-link.active');

        const tabIsDisabled = $activeTab.hasClass('disabled');
        if (tabIsDisabled)
            $component.find('.j-post-content-nav-link:not(.disabled)').first().click();
    },

    onErrorEsEs(event) {
        this.showErrorOnLocaleAndComponent('es_ES', $(event.currentTarget));
    },

    onErrorEsGb(event) {
        this.showErrorOnLocaleAndComponent('es_GB', $(event.currentTarget));
    },

    onErrorEnGb(event) {
        this.showErrorOnLocaleAndComponent('en_GB', $(event.currentTarget));
    },

    onErrorEs(event) {
        this.showErrorOnLocaleAndComponent('es', $(event.currentTarget));
    },

    onErrorEn(event) {
        this.showErrorOnLocaleAndComponent('en', $(event.currentTarget));
    },

    showErrorOnLocaleAndComponent(country, $component) {
        const tabNavLinkClass = this.localeToNavLinkMap[country];

        const navLinkIsDisabled = this.find('.' + tabNavLinkClass).hasClass('disabled');
        if (!navLinkIsDisabled) {
            $component.find('.' + tabNavLinkClass).addClass('j-content-editor-nav-error');
            $component.find('.' + tabNavLinkClass).addClass('content-editor-nav-error');
        }
    },

    onClearErrors(event) {
        this.find('.j-post-content-nav-link').removeClass('j-content-editor-nav-error');
        this.find('.j-post-content-nav-link').removeClass('content-editor-nav-error');
    },

    onCopyEsContent(event) {
        const $clickedTabContent = $(event.currentTarget).closest('.tab-pane');
        const $postContentComponent = $(event.currentTarget).closest('post-content-component');

        this.copyFromLang($postContentComponent.find('#esContent'));
        this.pasteToLang($clickedTabContent);
    },

    onCopyEnContent(event) {
        const $clickedTabContent = $(event.currentTarget).closest('.tab-pane');
        const $postContentComponent = $(event.currentTarget).closest('post-content-component');

        this.copyFromLang($postContentComponent.find('#enContent'));
        this.pasteToLang($clickedTabContent);
    },

    showErrorWrongLanguageToCopyFrom() {
        toastr.error('Está copiando el contenido de un idioma diferente');
    }
});