import {ElView} from "../shared/el-view";
import {Translator} from "../shared/translation/translator";

export const HabpokerPage = ElView.extend({
    el: '.j-habpoker',
    events: {},

    bannersCount: null,

    init() {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#edf4f1",
                    "text": "#000000"
                },
                "button": {
                    "background": "#01a35a",
                    "text": "#ffffff"
                }
            },
            "theme": "edgeless",
            "position": "bottom-right",
            "content": {
                "message": this.translate('cookie.message'),
                "dismiss": this.translate('cookie.dismiss'),
                "link": this.translate('cookie.link'),
                "href": this.route(`terms-conditions.${Translator.currentLanguageShortCode()}`),
            }
        });

        this.bannersCount = this.find('.j-banner-data').attr('data-banners-count');
        if (this.bannersCount > 1)
            this.initSwiper();

        this.initRoomsCarousel();
        this.initRoomsMobileCarousel();
    },

    initSwiper() {
        new Swiper('.swiper-container', {
            direction: 'horizontal',
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
        });
    },

    initRoomsMobileCarousel() {
        new Swiper('.rooms-center-cards-container', {
            direction: 'horizontal',
            loop: true,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 100,
                },
                600: {
                    slidesPerView: 2,
                    spaceBetween: 75,
                    // centeredSlides:true,
                    // centeredSlidesBounds:true,
                },
            },
        });
    },

    initRoomsCarousel: function () {
        $('.j-rewards-carousel').slick({
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                // {
                //     breakpoint: 480,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1
                //     }
                // }
            ]
        })
    },

});
