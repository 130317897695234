import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";

export const AdminLocationRequests = ElView.extend({
    el: 'admin-location-requests',
    events: {},

    async init() {
        const route = this.route('admin-location-requests-table-data');

        const tableData = await $.get(route)
        this.initRequestsTable(tableData);
    },

    initRequestsTable(tableData) {
        this.datatable = this.find('#admin-location-requests-datatable').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: tableData,
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#requests-table-search-by-username'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'createdAt',
                        title: 'Solicitado',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Nombre de usuario',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.username}</span>`;
                        }
                    },
                    {
                        field: 'country',
                        title: 'País requerido',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.country}</span>`;
                        }
                    },
                    {
                        field: 'email',
                        title: 'Email de notificación',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${row.email}</span>`;
                        }
                    },
                    {
                        field: 'isUsingVPN',
                        title: 'Usa una VPN',
                        textAlign: 'center',
                        template: (row) => {
                            let isUsingVPN = '';
                            if (row.isUsingVPN)
                                isUsingVPN = 'Sí';
                            else
                                isUsingVPN = 'No';

                            return `<span class="text-muted">${isUsingVPN}</span>`;
                        }
                    },
                    {
                        field: 'allegation',
                        title: 'Alegaciones',
                        textAlign: 'center',
                        template: (row) => {
                            let allegation = row.allegation;
                            if (allegation.length > 10)
                                allegation = row.allegation.substr(0, 10) + '...';

                            return `<span class="text-muted" data-toggle="tooltip" data-placement="top" title="${row.allegation}">${allegation}</span>`;
                        }
                    },
                    {
                        field: 'actions',
                        title: 'Acciones',
                        textAlign: 'center',
                        template: (row) => {
                            const userDetailsRoute = this.route('admin-user-details', {userId: row.id});
                            return `<a href="${userDetailsRoute}" type="button" class="btn btn-sm btn-icon btn-light-warning" data-toggle="tooltip" data-placement="top" title="Ir al Perfil">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`;
                        }
                    },

                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#requests-table-search-by-username').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });
    },
})
