import {ElView} from "../../../shared/el-view";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {Translator} from "../../../shared/translation/translator";

export const AdminRoomGroupsPage = ElView.extend({
    el: '.j-admin-room-groups',
    events: {
    },
    datatable: null,

    init: function () {
        this.initRoomsGroupsTable();
    },

    initRoomsGroupsTable() {
        this.datatable = this.find('#admin-rooms-groups-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('rooms-groups-table-data'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#roomsGroups-search'),
                    key: 'generalSearch'
                },

                columns: [
                    {
                        field: 'name',
                        width: 300,
                        title: capitalizeFirstLetter('name'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.name}</span>`;
                        }
                    },
                    {
                        field: 'handsFolder',
                        width: 300,
                        title: capitalizeFirstLetter('handsFolder'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.handsFolder}</span>`;
                        }
                    },
                    {
                        field: 'rooms',
                        width: 300,
                        title: capitalizeFirstLetter('rooms'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.rooms}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 300,
                        template: (row) => {
                            const detailsRoute = this.route('admin-room-group-details', {roomGroupId: row.id});

                            return `<a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`;
                        },
                    },
                ],
            }
        );
    },
});