import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";
import uuid4 from "uuid4/browser.mjs";

export const AdminUserDetailsPage = ElView.extend({
    el: '.j-admin-user-details-page',
    events: {
        'select2:select .j-select-2-room-link': 'onSelectRoomForRoomLinks',
        'click .j-delete-room-link': 'onClickDeleteRoomLink',
        'change .j-user-image-input': 'updateUserImage',
        'click .j-remove-user-img': 'removeUserImage',
        'click .j-remove-user': 'onRemoveUser',
        'click .j-save-user': 'saveUser',
        'select2:select .j-room-rakebacks-select': 'onSelectRoomRakebacksDropdown',
        'click .j-add-new-rakebackrange': 'onAddNewRakebackRange',
        'click .remove-RakebackRange': 'onRemoveRakebackRange',
        'click .j-update-type': 'onUpdateType',
        'click .j-remove-credentials': 'onRemoveCredencials',
        'click .j-add-credentials': 'onAddCredentials',
        'click .j-verify-credentials': 'onVerifyCredentials',
    },
    userId: null,
    rakeRangeCount: null,

    init() {
        this.initRoomLinkSelect();

        this.find('.j-location-form-input').select2({
            minimumResultsForSearch: -1,
        });
        this.find('.j-language-form-input').select2({
            minimumResultsForSearch: -1,
        });

        this.userId = this.find('j-data').attr('data-user-id');
        new AvatarImage('user-edit-image');
        this.find('.j-room-rakebacks-select').trigger('select2:select');

        setTimeout(() => {
            const firstRoomNameToShow = this.find('.j-rakes-form').find('.select2-selection__rendered').attr('title');
            const $firstRakeToShow = this.find('#' + firstRoomNameToShow);

            $firstRakeToShow.show();

        }, 100);
    },

    initRoomLinkSelect() {
        const $select = this.find('.j-select-2-room-link');
        const data = JSON.parse(this.find('.j-data').attr('data-rooms-for-user'));
        const $roomIds = this.find('.j-room-link-form-room-id-input');

        $select.select2({
                width: 300,
            }
        )

        data.forEach((value, index) => {
            let alreadyAdded = false;
            $roomIds.each((roomIdIndex, element) => {
                const roomId = $(element).val();
                if (roomId === value['id'])
                    alreadyAdded = true;
            })
            if (!alreadyAdded) {
                const newOption = new Option(value['roomName'], value['id'], false, false);
                $select.append(newOption).trigger('change');
            }
        })
    },

    updateUserImage() {
        const _this = this;
        const fd = new FormData();
        const files = this.find('.j-user-image-input')[0].files[0];
        fd.append('file', files);
        fd.append('userId', this.userId);
        $.ajax({
            url: Router.generate('core-api-update-user-image'),
            type: 'post',
            data: fd,
            contentType: false,
            processData: false,
            success: (data) => {
                _this.find('.j-user-image-id-input').val(data);
            }
        });
    },

    saveUser() {
        this.enableRakebackInputs();
        this.removeRakebackPrototype();
        this.reIndexRakebacksFormInputs();
        this.removeRoomLinkPrototype();

        if (!this.formIsValid()) {
            Swal.fire(Translator.trans("errors.detected"), Translator.trans("errors.invalid.ranges"), "error");
            return;
        }

        this.find('.j-credentials-template').remove();

        const userId = this.find('.j-data').attr('data-user-id');
        const route = this.route('admin-user-details', {userId});
        const $form = this.find('.j-user-details-form').serialize();
        this.find('.j-user-details-form').submit(
            //     $.post(route, $form)
            //         .then((response) => {
            // location.reload();
            // })
        );
    },

    removeUserImage() {
        const route = Router.generate('core-api-update-user-image');
        const data = {userId: this.userId};

        $.post(route, data);
    },

    async onRemoveUser(event) {
        const $deleteBtn = $(event.currentTarget);
        const userId = $deleteBtn.attr('data-user-id')
        const route = this.route('tool-api-remove-user');

        const firstConfirmation = await this.confirmation('Va a desactivar el usuario', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                await $.post(route, {userId})
                await this.confirmationOk('Desactivado!', 'El usuario ha sido desactivado');

                const redirectRoute = this.route('admin-users');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El usuario no ha sido desactivado');
            }
        }
    },

    onSelectRoomRakebacksDropdown(event) {
        const $select = $(event.currentTarget);

        let $options = this.find('.j-room-options');
        $options.each((rowIndex, option) => {
            $(option).hide();
        })

        const roomId = $select.find(':selected').attr('data-room-id');
        const $selection = this.find('#' + roomId);

        $selection.fadeIn();
        $selection.show('fast');
    },

    formIsValid() {
        const $roomRakebacks = this.find('.j-room-options');

        let isValid = true;

        $roomRakebacks.each((index, element) => {
            let prevTo = '0';
            let $rakebackRangeRows = $(element).find('.j-rakeback-form-row');

            $rakebackRangeRows.each((rowIndex, htmlElement) => {
                const $row = $(htmlElement);
                const from = $row.find('.j-form-value-from').val();
                const to = $row.find('.j-form-value-to').val();

                if (to !== '∞' && from !== '0')
                    if (parseInt(from) > parseInt(to) || parseInt(prevTo) + 1 !== parseInt(from)) {
                        isValid = false;
                    }

                prevTo = to;
            });
        });

        // throw new Error();
        return isValid;
    },

    onAddNewRakebackRange(event) {
        const $clicked = $(event.currentTarget);
        let $roomRakebacksContainer = $clicked.closest('.j-room-options');
        const $template = this.find('.j-rakeback-form-row-template');
        const $rakebackRows = $roomRakebacksContainer.find('.j-rakeback-form-row');

        const newRowIndex = $rakebackRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-form-row-template');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-rakeback-form-row');

        const $addButton = $roomRakebacksContainer.find('.j-add-new-rakebackrange');
        $newRowForm.insertBefore($addButton);

        if (newRowIndex === 0) {
            $newRowForm.find('.j-form-value-from').val(0);
            $newRowForm.find('.j-form-value-to').val('∞');
            $newRowForm.find('.j-form-value-multiplier').val(1);

        } else {
            const $lastRowForm = $newRowForm.prev();
            const $lastRowFormFrom = $lastRowForm.find('.j-form-value-from');
            const $lastRowFormTo = $lastRowForm.find('.j-form-value-to');
            $lastRowFormTo.val(parseInt($lastRowFormFrom.val()) + 1);

            $newRowForm.find('.j-form-value-from').val(parseInt($lastRowFormFrom.val()) + 2);
            $newRowForm.find('.j-form-value-to').val('∞');
            $newRowForm.find('.j-form-value-to').prop('disabled', true);
            $newRowForm.find('.j-form-value-multiplier').val(1);
        }

        const userId = this.find('.j-data').attr('data-user-id');
        const roomId = $roomRakebacksContainer.attr('data-room-id');

        $newRowForm.find('.j-form-value-userId').val(userId);
        $newRowForm.find('.j-form-value-roomId').val(roomId);

        $newRowForm.fadeIn();

        this.disableRakebackInputs();
    },

    onRemoveRakebackRange(event) {
        const $clicked = $(event.currentTarget);

        const $roomRakebacksContainer = $clicked.closest('.j-room-options');
        const $formRow = $clicked.closest('.j-rakeback-form-row');
        const $prevRow = $formRow.prev();
        const $nextRow = $formRow.next();

        if ($prevRow.length === 0 || $prevRow.find('.j-form-value-to').val() === '∞') {

            if ($nextRow.find('.j-form-value-to').val() !== '∞') {

                const fromValue = $nextRow.next().find('.j-form-value-from').val();
                $nextRow.find('.j-form-value-to').val(fromValue - 1);
            }

            $nextRow.find('.j-form-value-from').val(0);

        } else if ($nextRow.hasClass('j-add-new-rakebackrange')) {
            $prevRow.find('.j-form-value-to').val('∞');

        } else {

            const prevRowTo = parseInt($prevRow.find('.j-form-value-to').val()) + 1;

            $nextRow.find('.j-form-value-from').val(prevRowTo);
        }

        $formRow.fadeOut();
        $formRow.remove();

        this.disableRakebackInputs();

        if ($roomRakebacksContainer.find('.j-rakeback-form-row').length === 0)
            this.removeUserRakebacksRanges($roomRakebacksContainer.attr('data-room-id'));
    },

    disableRakebackInputs() {
        this.find('.j-rakes-form').find('.j-form-value-from').each((index, element) => {
            if ($(element).val() === '0') {
                $(element).prop('disabled', true);
            } else
                $(element).prop('disabled', false);
        })

        this.find('.j-rakes-form').find('.j-form-value-to').each((index, element) => {
            if ($(element).val() === '∞') {
                $(element).prop('disabled', true);
            } else
                $(element).prop('disabled', false);
        })
    },

    enableRakebackInputs() {

        this.find('.j-form-value-from').each((index, element) => {
            if ($(element).val() === '0')
                $(element).prop('disabled', false);
        })

        this.find('.j-form-value-to').each((index, element) => {
            if ($(element).val() === '∞')
                $(element).prop('disabled', false);
        })
    },

    removeRakebackPrototype() {
        this.find('.j-rakeback-form-row-template').remove();
    },

    reIndexRakebacksFormInputs() {
        let $rakebacks = this.find('.j-rakeback-form-row');

        $rakebacks.each(function (index, element) {
            const userId = $(element).find('.j-form-value-userId').val();
            const roomId = $(element).find('.j-form-value-roomId').val();
            const from = $(element).find('.j-form-value-from').val();
            const to = $(element).find('.j-form-value-to').val();
            const multiplier = $(element).find('.j-form-value-multiplier').val();

            let newHtml;
            newHtml = $(element).html().replaceAll(/[\[][0-9]+[\]]/g, '[' + index + ']');
            newHtml = newHtml.replaceAll(/[_][0-9]+[_]/g, '_' + index + '_');

            $(element).html(newHtml);

            $(element).find('.j-form-value-userId').val(userId);
            $(element).find('.j-form-value-roomId').val(roomId);
            $(element).find('.j-form-value-from').val(from);
            $(element).find('.j-form-value-to').val(to);
            $(element).find('.j-form-value-multiplier').val(multiplier);
        })
    },

    removeUserRakebacksRanges(roomId) {
        const route = Router.generate('core-api-remove-user-rakebackranges')
        const data = {
            roomId: roomId,
            userId: this.find('.j-data').attr('data-user-id'),
        }

        $.post(route, data);
    },

    async onUpdateType(event) {
        const type = $(event.currentTarget).attr('data-user-type');
        const userId = this.find('.j-data').attr('data-user-id');
        const route = this.route('core-api-update-user-type', {userId});
        const data = {type: type};

        try {
            await $.post(route, data);
            await this.confirmationOk('Tipo de usuario actualizado');
            location.reload();
        } catch (exception) {
            await this.confirmationError('Error', exception.statusText);
        }
    },

    onSelectRoomForRoomLinks(event) {
        const $select = $(event.currentTarget);
        const data = $select.select2('data');
        const roomId = data[0]['id'];
        const roomName = data[0]['text'];

        $select.empty();
        this.createNewRoomLink(roomId, roomName);
        this.initRoomLinkSelect();
    },


    createNewRoomLink(roomId, roomName) {
        let $newRow = this.find('.j-room-links-form-template').clone();
        $newRow.removeClass('j-room-links-form-template');
        $newRow.children().addClass('j-room-link-form-row');
        let lastIndex = this.find('.j-room-link-form-row').length;

        $newRow = $($newRow.html().replaceAll(/__name__/g, lastIndex));
        $newRow.find('.j-room-link-form-room-id-input').val(roomId);
        $newRow.find('.j-room-link-room-name-input').val(roomName);

        const $rowsContainer = this.find('.j-room-links-form-container');

        $rowsContainer.append($newRow);
        $newRow.fadeIn();

        $('.j-room-link-form-container').animate({scrollTop: $('.j-room-links-form-container').get(0).scrollHeight}, 'slow');
    },

    onClickDeleteRoomLink(event) {
        const $clicked = $(event.currentTarget);
        const $row = $clicked.closest('.j-room-link-form-row');
        $row.remove();
    },

    removeRoomLinkPrototype() {
        this.find('.j-room-links-form-template').remove();
    },

    onRemoveCredencials(event) {
        const $target = $(event.currentTarget);

        $target.closest('.j-credentials-row').remove();
    },

    onAddCredentials() {
        const $template = this.find('.j-credentials-template');
        const $credentialsContainer = this.find('.j-credentials-container');
        const $credentialsRows = this.find('.j-credentials-row');
        const userId = this.find('.j-data').attr('data-user-id');

        const newRowIndex = $credentialsRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-credentials-template');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-credentials-row');

        $newRowForm.find('.j-credentials-template-room-select-2').select2();
        $newRowForm.find('.j-user-room-credentials-form-user-id-input').val(userId);
        $newRowForm.find('.j-user-room-credentials-form-id-input').val(uuid4());
        $newRowForm.find('.j-user-room-credentials-form-is-verified-input').val(true);

        $credentialsContainer.append($newRowForm);
    },

    onVerifyCredentials(event) {
        const $clicked = $(event.currentTarget);
        const credentialsId = $clicked.closest('.j-credentials-row').find('.j-user-room-credentials-form-id-input').val();
        const route = this.route('core-api-verify-user-room-credentials', {credentialsId});

        $.post(route);

        $clicked.remove();

        this.confirmationOk('Credenciales Verificadas');
    }
});