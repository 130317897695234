import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";
import {Translator} from "../shared/translation/translator";

export const BlogCategoriesPage = ElView.extend({
    el: '.j-blog-categories',
    events: {},
    datatable: null,

    init: function () {
        this.initCategoriesTable();
    },

    initCategoriesTable() {
        this.datatable = this.find('#blog-categories-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('blog-categories-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#categories-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                columns: [
                    {
                        field: 'title',
                        width: 200,
                        title: capitalizeFirstLetter('titulo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },
                    {
                        field: 'section',
                        width: 200,
                        title: capitalizeFirstLetter('sección'),
                        textAlign: 'center',
                        template: (row) => {
                            const sectionToCssClassMap = {
                                'habpoker': 'info',
                                'habcasino': 'primary',
                                'habbets': 'dark'
                            };

                            return `<span class="label label-inline label-rounded font-weight-bold label-light-${sectionToCssClassMap[row.section]} m-1">${capitalizeFirstLetter(row.section)}</span>`;
                        }
                    },
                    {
                        field: 'geolocations',
                        width: 200,
                        title: capitalizeFirstLetter('geolocalizaciones'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let geoLocation of row.geoLocations){
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${geoLocation}</span>`;
                            }

                            return html;
                        }
                    },
                    {
                        field: 'type',
                        width: 200,
                        title: capitalizeFirstLetter('blog'),
                        textAlign: 'center',
                        template: (row) => {
                            const typeToCssClassMap = {
                                'post': 'info',
                                'promo': 'primary'
                            };

                            return `<span class="label label-inline label-rounded font-weight-bold label-light-${typeToCssClassMap[row.type]} m-1">${capitalizeFirstLetter(row.type)}</span>`;
                        }
                    },
                    {
                        field: 'isSub',
                        width: 200,
                        title: capitalizeFirstLetter('tipo'),
                        textAlign: 'center',
                        template: (row) => {
                            const typetoCssClassMap = {
                                'true': 'info',
                                'false': 'primary'
                            };

                            let $result;

                            if (row.isSub) {
                                $result = 'Subcategoría';
                            } else {
                                $result = 'Categoría';

                            }

                            return `<span class="label label-inline label-rounded font-weight-bold label-light-${typetoCssClassMap[row.isSub]}">${capitalizeFirstLetter($result)}</span>`;
                        }
                    },

                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            const detailsRoute = this.route('admin-blog-category-details', {categoryId: row.id});

                            return `<a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`
                        },
                    },
                ],
            }
        );
    },


});