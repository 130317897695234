import {ElView} from "../el-view";

export const PageSectionEditorComponent = ElView.extend({
    el: 'page-section-component',
    events: {
        'click .j-copy-form': 'onCopyForm',
        'click .j-paste-form': 'onPasteForm',
        'hide-es': 'onHideEs',
        'hide-intl': 'onHideIntl',
        'hide-gb': 'onHideGb',
        'show-es': 'onShowEs',
        'show-intl': 'onShowIntl',
        'show-gb': 'onShowGb',
        'error-es-es': 'onErrorEsEs',
        'error-es-gb': 'onErrorEsGb',
        'error-en-gb': 'onErrorEnGb',
        'error-es': 'onErrorEs',
        'error-en': 'onErrorEn',
        'clear-errors': 'onClearErrors',
        'reinit': 'onReinit',
    },

    copiedLanguage: null,

    copiedTitle: null,
    copiedShortTitle: null,
    copiedDescription: null,
    copiedShortDescription: null,
    copiedContent: null,

    tabContentIdToLocale: {
        esEsContent: 'es_ES',
        ukEsContent: 'es_GB',
        ukEnContent: 'en_GB',
        esContent: 'es',
        enContent: 'en',
    },

    countryToNavLinkMap: {
        'ES': ['j-esEsContent-nav-link'],
        'GB': ['j-ukEsContent-nav-link', 'j-ukEnContent-nav-link'],
        'intl': ['j-esContent-nav-link', 'j-enContent-nav-link'],
    },

    localeToNavLinkMap: {
        'es_ES': 'j-esEsContent-nav-link',
        'es_GB': 'j-ukEsContent-nav-link',
        'en_GB': 'j-ukEnContent-nav-link',
        'es': 'j-esContent-nav-link',
        'en': 'j-enContent-nav-link',
    },

    init() {
    },

    onCopyForm(event) {
        const $button = this.$(event.currentTarget);
        this.copyFromLang($button.closest('.tab-pane'));
    },

    onPasteForm(event) {
        const $button = this.$(event.currentTarget);
        this.pasteToLang($button.closest('.tab-pane'))
    },

    copyFromLang($container) {
        this.copiedLanguage = $container.closest('.tab-pane').attr('id').substr(0, 2);

        this.copiedLocale = this.tabContentIdToLocale[$container.closest('.tab-pane').attr('id')];

        this.copiedTitle = $container.find('.j-page-section-variation-form-title-input').val();
        this.copiedShortTitle = $container.find('.j-page-section-variation-form-short-title-input').val();
        this.copiedDescription = $container.find('.j-page-section-variation-form-description-input').val();
        this.copiedShortDescription = $container.find('.j-page-section-variation-form-short-description-input').val();
        this.copiedContent = $container.find('.j-page-section-variation-form-content-input').val();
    },

    pasteToLang($container) {
        const isNotTheSameLanguage = this.copiedLanguage !== $container.attr('id').substr(0, 2);
        if (isNotTheSameLanguage) {
            this.showErrorWrongLanguageToCopyFrom();
            return;
        }

        const $parentContainer = $container;

        const $titleInput = $parentContainer.find('.j-page-section-variation-form-title-input');
        const $shortTitleInput = $parentContainer.find('.j-page-section-variation-form-short-title-input');
        const $descriptionInput = $parentContainer.find('.j-page-section-variation-form-description-input');
        const $shortDescriptionInput = $parentContainer.find('.j-page-section-variation-form-short-description-input');
        const $contentInput = $parentContainer.find('.j-page-section-variation-form-content-input');

        this.pasteToInput($titleInput, this.copiedTitle);
        this.pasteToInput($shortTitleInput, this.copiedShortTitle);
        this.pasteToInput($descriptionInput, this.copiedDescription);
        this.pasteToInput($shortDescriptionInput, this.copiedShortDescription);
        if (!!this.copiedContent)
            $contentInput.summernote('code', this.copiedContent);
    },

    pasteToInput($input, value) {
        if (!!value)
            $input.val(value);
    },

    onHideEs(event) {
        this.hideTabsOfCountry('ES', $(event.currentTarget));
    },

    onHideGb(event) {
        this.hideTabsOfCountry('GB', $(event.currentTarget));
    },

    onHideIntl(event) {
        this.hideTabsOfCountry('intl', $(event.currentTarget));
    },

    onShowEs(event) {
        this.showTabsOfCountry('ES', $(event.currentTarget));
    },

    onShowGb(event) {
        this.showTabsOfCountry('GB', $(event.currentTarget));
    },

    onShowIntl(event) {
        this.showTabsOfCountry('intl', $(event.currentTarget));
    },

    hideTabsOfCountry(country, $component) {
        this.countryToNavLinkMap[country].forEach((tabNavLinkClass) => {
            $component.find('.' + tabNavLinkClass).addClass('disabled');
        });

        this.clickOnFirstEnabledTab($component);
    },

    showTabsOfCountry(country, $component) {
        this.countryToNavLinkMap[country].forEach((tabNavLinkClass) => {
            $component.find('.' + tabNavLinkClass).removeClass('disabled');
        });
    },

    clickOnFirstEnabledTab($component) {
        const $activeTab = $component.find('.j-page-section-nav-link.active');

        const tabIsDisabled = $activeTab.hasClass('disabled');
        if (tabIsDisabled)
            $component.find('.j-page-section-nav-link:not(.disabled)').first().click();
    },

    onErrorEsEs(event) {
        this.showErrorOnLocaleAndComponent('es_ES', $(event.currentTarget));
    },

    onErrorEsGb(event) {
        this.showErrorOnLocaleAndComponent('es_GB', $(event.currentTarget));
    },

    onErrorEnGb(event) {
        this.showErrorOnLocaleAndComponent('en_GB', $(event.currentTarget));
    },

    onErrorEs(event) {
        this.showErrorOnLocaleAndComponent('es', $(event.currentTarget));
    },

    onErrorEn(event) {
        this.showErrorOnLocaleAndComponent('en', $(event.currentTarget));
    },

    showErrorOnLocaleAndComponent(country, $component) {
        const tabNavLinkClass = this.localeToNavLinkMap[country];
        $component.find('.' + tabNavLinkClass).addClass('j-content-editor-nav-error');
        $component.find('.' + tabNavLinkClass).addClass('content-editor-nav-error');
    },

    onClearErrors(event) {
        this.find('.j-page-section-nav-link').removeClass('j-content-editor-nav-error');
        this.find('.j-page-section-nav-link').removeClass('content-editor-nav-error');
    },

    showErrorWrongLanguageToCopyFrom() {
        toastr.error('Está copiando el contenido de un idioma diferente');
    },
});