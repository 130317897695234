import {Translator} from "../translation/translator";

export const monthNumberToName = (monthNumber) => {
    const monthNumberToNameMap = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    return Translator.trans(monthNumberToNameMap[monthNumber]);
}

export const dateToYearMonthString = (date) => {
    date = new Date(date);

    const month = date.getMonth();
    const year = date.getFullYear();

    return monthNumberToName(month) + ' ' + year;
}

export const formatYearMonthString = (date) => {
    date = date.toString();
    const year = date.substring(0, 4);
    const monthNumber = parseInt(date.substring(4, 6));
    const monthName = monthNumberToName(monthNumber - 1);

    return monthName + ' ' + year;
}

export const dateFromString = (dateString, format) => {
    if (format === 'd/m/yyyy' || format === 'd/m/Y')
        return formatDateFromDMYYYY(dateString);

    return null;
}

const formatDateFromDMYYYY = (dateString) => {
    if (!dateString)
        return;

    const dateArray = dateString.split('/');

    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
}

export const convertDatesToYearMonthArray = (startDate, endDate) => {
    let datesArray = [];

    let dateIterator = startDate;
    while (dateIterator.getTime() < endDate.getTime()) {
        const yearMonth = dateToYearMonthString(dateIterator);
        datesArray.push(yearMonth);

        dateIterator = new Date(startDate.setMonth(startDate.getMonth() + 1));
    }

    return datesArray;
}

export const getCurrentDateDDMMYYYY = (separator) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return dd + separator + mm + separator + yyyy;
}

export const formatDateToEs = (dateFromBackend) => new Intl.DateTimeFormat('es').format(Date.parse(dateFromBackend));