import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";

export const AdminManualUploadRakesPage = ElView.extend({
    el: '.j-admin-rakes',
    events: {
        'changeDate .j-start-date': 'onChangeDate',
        'changeDate .j-end-date': 'onChangeDate',
        'click .j-generate-report': 'onClickGenerateReport',
        'click .j-button-remove-rake': 'onClickRemoveRake',
    },
    datatable: null,
    startDate: null,
    endDate: null,

    async init() {
        $('.j-start-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
            // startDate: ,
            // endDate: ,
        });

        $('.j-end-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.initRakesTable();
    },

    initRakesTable() {
        const _this = this;
        this.datatable = this.find('#admin-rakes-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('manual-upload-rakes-table-data', {
                        startDate: _this.startDate,
                        endDate: _this.endDate
                    }),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#rakes-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },
                columns: [
                    {
                        field: 'createdAt',
                        title: capitalizeFirstLetter(Translator.trans('Creación')),
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`
                        },
                    },
                    {
                        field: 'username',
                        title: capitalizeFirstLetter(Translator.trans('username')),
                        template: (row) => {
                            if (row.username === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" 
                                            style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">${row.username}
                                        </label>`;
                            return `<a href="${_this.route('admin-user-details', {'userId': row.userId})}" class="font-weight-bolder">${row.username}</a>`
                        },
                    },
                    {
                        field: 'roomId',
                        title: capitalizeFirstLetter(Translator.trans('game.room.room')),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.roomName}</span>`
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.nick}</span>`
                        }
                    },
                    {
                        field: 'affiliateName',
                        title: 'Afiliado',
                        template: (row) => {
                            return `<a href="${_this.route('admin-affiliate-details', {'affiliateId': row.affiliateId})}" class="font-weight-bolder">${row.affiliateName}</a>`
                        },
                    },
                    {
                        field: 'difference',
                        title: capitalizeFirstLetter(Translator.trans('rake')),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.difference.toFixed(2)} ${row.currencySymbol}</span>`
                        }
                    },
                    {
                        field: 'status',
                        title: 'Estado',
                        textAlign: 'center',
                        template: (row) => {
                            if (row.status === 'status_charged')
                                return `<label class="font-weight-bolder" style="background-color: #dcf4da; border-radius: 15px; padding: 0px 5px;">Cobrado</label>`

                            return `<label class="font-weight-bolder" style="background-color: #ee523557; border-radius: 15px; padding: 0px 5px;">Sin cobrar</label>`
                        }
                    },
                    {
                        field: 'action',
                        title: 'Acciones',
                        textAlign: 'center',
                        template: (row) => {
                            return `<button class="btn btn-icon btn-light-danger j-button-remove-rake" data-rake-id="${row.rakeId}" data-status="${row.status}">
                                    <span class="flaticon2-delete"></span>
                                    </button>`;
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#rakes-search-username').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });

        $('#rakes-search-nick').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'nick');
        });

        $('#rakes-search-login').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'login');
        });

        $('#rakes-search-room').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'roomId');
        });
    },

    onChangeDate(event) {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.datatable.destroy();

        this.initRakesTable();
    },

    async onClickGenerateReport(event) {
        window.location.href = this.route('admin-rakes-report', {
            username: this.find('#rakes-search-username').val().trim(),
            nick: this.find('#rakes-search-nick').val().trim(),
            login: this.find('#rakes-search-login').val().trim(),
            roomId: this.find('#rakes-search-room').val().trim(),
            startDate: this.find('.j-start-date').val(),
            endDate: this.find('.j-end-date').val(),
        });
    },

    async onClickRemoveRake(event) {
        const _this = this;
        const $clicked = $(event.currentTarget);
        const rakeId = $clicked.attr('data-rake-id');
        const status = $clicked.attr('data-status');

        try {
            const route = this.route('core-api-remove-rake', {rakeId: rakeId, status: status});

            const confirmation = await this.confirmation('¿Desea eliminar este rake?', 'No podrá deshacer esta acción', 'Eliminar', 'Cancelar');
            if (!confirmation.isDismissed) {
                await $.post(route);
                _this.datatable.reload();
            }

        } catch (exception) {
            this.confirmationError('Ha ocurrido algún error', 'Inténtelo de nuevo');
        }
    }
});