import {ElView} from "../../shared/el-view";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";

export const GameLogsPage = ElView.extend({
    el: '.j-game-logs',
    events: {},
    datatable: null,
    init: async function () {
        this.initGameLogsTable();
        this.datatable.sort('createdAt', 'desc');
    },

    initGameLogsTable() {
        this.datatable = this.find('#game-logs-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('game-logs-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#logs-search'),
                    delay: 300
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: this.translate('Displaying') + ' {{start}} - {{end}} ' +
                                    this.translate('of') + ' {{total}}'
                            },
                        },
                    },
                },

                columns: [
                    {
                        field: 'createdAt',
                        title: capitalizeFirstLetter('fecha'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.createdAt}`;
                        }
                    },
                    {
                        field: 'username',
                        title: capitalizeFirstLetter('usuario'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.username}</span>`;
                        }
                    },
                    {
                        field: 'ip',
                        title: capitalizeFirstLetter('ip'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.ip}`;
                        }
                    },
                    {
                        field: 'entityType',
                        title: capitalizeFirstLetter('Tipo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${capitalizeFirstLetter(this.translate(row.entityType))}`;
                        }
                    },
                ],
            }
        );
    },

});