import {ElView} from "../../shared/el-view";

export const AddReferedUserToAffiliateModalComponent = ElView.extend({
    el: 'add-refered-user-to-affiliate-modal',
    events: {'click .j-assign-credentials-modal-save': 'onClickAssignCredentialsModalSave'},

    datatable: null,

    init(params) {
        this.datatable = params.datatable;
    },

    async onClickAssignCredentialsModalSave() {
        const affiliateId = this.find('.j-assign-cred-affiliate-select').val();
        const userId = this.find('.j-assign-cred-user-select').val();

        const route = this.route('core-api-add-user-credentials-to-affiliate');
        const data = {
            affiliateId: affiliateId,
            userId: userId,
        }

        const confirmation = await this.confirmation('¿Seguro que desea asignar las credentiales?', '', 'Confimar', 'Cancelar');

        if (!confirmation.isDismissed) {
            try {
                await $.post(route, data);

                await this.confirmationOk('Credenciales asignadas');

                this.datatable.reload();
                $('#add-user-cred-to-affiliate').modal('hide');

            } catch (error) {
                this.confirmationError('Error', 'Ha ocurrido algún error');
            }
        }

    }
})