import {ElView} from "../../el-view";

export const DatepickerComponent = ElView.extend({
    el: '.j-datepicker',
    init() {
        this.$el.each((index, element) => {
            const $datepicker = $(element);

            $datepicker.datepicker({
                format: 'd/m/yyyy',
                orientation: 'bottom',
            });
        })
    }
});