import {ElView} from "../el-view";


export const Select2Component = ElView.extend({
    el: '.j-select-2',
    init() {
        this.$el.each((index, element) => {
            const $select = $(element);
            // const options = $select.attr('data-select-options');

            $(element).select2();
        })
    }
});