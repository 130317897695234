export const marketingAnalyzingAnimation = {
    "v": "5.1.13",
    "fr": 25,
    "ip": 0,
    "op": 100,
    "w": 1300,
    "h": 1000,
    "nm": "Data Analysis",
    "ddd": 0,
    "assets": [{
        "id": "comp_0", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 2",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-412, -97], [333, -98], [-413, -83], [-409, -53], [327, -53], [-410, -37],
                                [-407, -12], [326, -11], [-408, 6], [-409, 34], [325, 33], [-412, 49], [-411, 77],
                                [330, 76], [-412, 97], [-414, 117], [326, 117]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 25, "ix": 5},
                    "lc": 2,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {"a": 0, "k": 0, "ix": 1},
                "e": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.671]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p671_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [0.118]
                    }, {
                        "i": {"x": [0.833], "y": [0.781]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p781_0p167_0p112"],
                        "t": 1,
                        "s": [0.118],
                        "e": [0.467]
                    }, {
                        "i": {"x": [0.833], "y": [0.802]},
                        "o": {"x": [0.167], "y": [0.134]},
                        "n": ["0p833_0p802_0p167_0p134"],
                        "t": 2,
                        "s": [0.467],
                        "e": [1.037]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.144]},
                        "n": ["0p833_0p812_0p167_0p144"],
                        "t": 3,
                        "s": [1.037],
                        "e": [1.818]
                    }, {
                        "i": {"x": [0.833], "y": [0.817]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p817_0p167_0p15"],
                        "t": 4,
                        "s": [1.818],
                        "e": [2.8]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.153]},
                        "n": ["0p833_0p82_0p167_0p153"],
                        "t": 5,
                        "s": [2.8],
                        "e": [3.974]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 6,
                        "s": [3.974],
                        "e": [5.331]
                    }, {
                        "i": {"x": [0.833], "y": [0.824]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p824_0p167_0p157"],
                        "t": 7,
                        "s": [5.331],
                        "e": [6.861]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.159]},
                        "n": ["0p833_0p826_0p167_0p159"],
                        "t": 8,
                        "s": [6.861],
                        "e": [8.554]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p827_0p167_0p16"],
                        "t": 9,
                        "s": [8.554],
                        "e": [10.4]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 10,
                        "s": [10.4],
                        "e": [12.39]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 11,
                        "s": [12.39],
                        "e": [14.515]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.162]},
                        "n": ["0p833_0p829_0p167_0p162"],
                        "t": 12,
                        "s": [14.515],
                        "e": [16.765]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 13,
                        "s": [16.765],
                        "e": [19.13]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 14,
                        "s": [19.13],
                        "e": [21.6]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 15,
                        "s": [21.6],
                        "e": [24.166]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 16,
                        "s": [24.166],
                        "e": [26.819]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 17,
                        "s": [26.819],
                        "e": [29.549]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 18,
                        "s": [29.549],
                        "e": [32.346]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 19,
                        "s": [32.346],
                        "e": [35.2]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 20,
                        "s": [35.2],
                        "e": [38.102]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 21,
                        "s": [38.102],
                        "e": [41.043]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 22,
                        "s": [41.043],
                        "e": [44.013]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 23,
                        "s": [44.013],
                        "e": [47.002]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 24,
                        "s": [47.002],
                        "e": [50]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 25,
                        "s": [50],
                        "e": [52.998]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 26,
                        "s": [52.998],
                        "e": [55.987]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 27,
                        "s": [55.987],
                        "e": [58.957]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 28,
                        "s": [58.957],
                        "e": [61.898]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 29,
                        "s": [61.898],
                        "e": [64.8]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 30,
                        "s": [64.8],
                        "e": [67.654]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 31,
                        "s": [67.654],
                        "e": [70.451]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 32,
                        "s": [70.451],
                        "e": [73.181]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 33,
                        "s": [73.181],
                        "e": [75.834]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 34,
                        "s": [75.834],
                        "e": [78.4]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 35,
                        "s": [78.4],
                        "e": [80.87]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 36,
                        "s": [80.87],
                        "e": [83.235]
                    }, {
                        "i": {"x": [0.833], "y": [0.838]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p838_0p167_0p171"],
                        "t": 37,
                        "s": [83.235],
                        "e": [85.485]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 38,
                        "s": [85.485],
                        "e": [87.61]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 39,
                        "s": [87.61],
                        "e": [89.6]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p84_0p167_0p173"],
                        "t": 40,
                        "s": [89.6],
                        "e": [91.446]
                    }, {
                        "i": {"x": [0.833], "y": [0.841]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p841_0p167_0p174"],
                        "t": 41,
                        "s": [91.446],
                        "e": [93.139]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.176]},
                        "n": ["0p833_0p843_0p167_0p176"],
                        "t": 42,
                        "s": [93.139],
                        "e": [94.669]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 43,
                        "s": [94.669],
                        "e": [96.026]
                    }, {
                        "i": {"x": [0.833], "y": [0.847]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p847_0p167_0p18"],
                        "t": 44,
                        "s": [96.026],
                        "e": [97.2]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.183]},
                        "n": ["0p833_0p85_0p167_0p183"],
                        "t": 45,
                        "s": [97.2],
                        "e": [98.182]
                    }, {
                        "i": {"x": [0.833], "y": [0.856]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p856_0p167_0p188"],
                        "t": 46,
                        "s": [98.182],
                        "e": [98.963]
                    }, {
                        "i": {"x": [0.833], "y": [0.866]},
                        "o": {"x": [0.167], "y": [0.198]},
                        "n": ["0p833_0p866_0p167_0p198"],
                        "t": 47,
                        "s": [98.963],
                        "e": [99.533]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.219]},
                        "n": ["0p833_0p888_0p167_0p219"],
                        "t": 48,
                        "s": [99.533],
                        "e": [99.882]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.329]},
                        "n": ["0p833_0p917_0p167_0p329"],
                        "t": 49,
                        "s": [99.882],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.01]},
                        "n": ["0p833_1_0p167_0p01"],
                        "t": 50,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 51,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 52,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 53,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 54,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 55,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 56,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 57,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 58,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 59,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 60,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 61,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 62,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 63,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 64,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 65,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 66,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 67,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 68,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 69,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 70,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 71,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 72,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 73,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 74,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 75,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 76,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 77,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 78,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 79,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 80,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 81,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 82,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 83,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 84,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 85,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 86,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 87,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 88,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 89,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 90,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 91,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 92,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 93,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 94,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 95,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 96,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 97,
                        "s": [100],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 98,
                        "s": [100],
                        "e": [100]
                    }, {"t": 99}],
                    "ix": 2
                },
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 1",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [434, 323, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {"i": [[0, 0], [0, 0]], "o": [[0, 0], [0, 0]], "v": [[-346, 30], [318, 30]], "c": false},
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.682352941176, 0.756862745098, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 20, "ix": 5},
                    "lc": 2,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 2,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "rp",
                "c": {"a": 0, "k": 6, "ix": 1},
                "o": {"a": 0, "k": 0, "ix": 2},
                "m": 1,
                "ix": 2,
                "tr": {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 43], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 4},
                    "so": {"a": 0, "k": 100, "ix": 5},
                    "eo": {"a": 0, "k": 100, "ix": 6},
                    "nm": "Transform"
                },
                "nm": "Repeater 1",
                "mn": "ADBE Vector Filter - Repeater",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "graph callout Outlines",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -12.568], [0, 0], [0, 0], [0, 0], [-12.652, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, -12.568], [0, 0], [12.569, 0]],
                            "v": [[370.711, -10.897], [370.711, 33.668], [-370.711, 33.668], [-370.711, -10.897],
                                [-347.857, -33.668], [347.94, -33.668]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-419.021, 612.975], [480.979, 612.975], [480.979, -287.025],
                                    [-419.021, -287.025]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-419.021, -287.025], [480.979, -287.025], [480.979, 612.975],
                                    [-419.021, 612.975]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.681999954523, 0.757000014361, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [419.021, 287.025], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 7",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -12.568], [0, 0], [12.569, 0], [0, 0], [0, 12.57], [0, 0], [-12.652, 0], [0, 0]],
                            "o": [[0, 0], [0, 12.57], [0, 0], [-12.652, 0], [0, 0], [0, -12.568], [0, 0], [12.569, 0]],
                            "v": [[370.711, -154.75], [370.711, 154.748], [347.94, 177.521], [-347.857, 177.521],
                                [-370.711, 154.748], [-370.711, -154.75], [-347.857, -177.521], [347.94, -177.521]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [419.021, 430.878], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 8",
                "np": 2,
                "cix": 2,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.543, 0], [0, 0], [0, 1.543], [0, 0], [-1.542, 0], [0, 0], [0, -1.543], [0, 0]],
                            "o": [[0, 0], [-1.542, 0], [0, 0], [0, -1.543], [0, 0], [1.543, 0], [0, 0], [0, 1.543]],
                            "v": [[367.905, 177.52], [-367.906, 177.52], [-370.699, 174.727], [-370.699, -174.727],
                                [-367.906, -177.521], [367.905, -177.521], [370.699, -174.727], [370.699, 174.727]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.372999991623, 0.689999988032, 0.905999995213, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [435.357, 447.201], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 9",
                "np": 2,
                "cix": 2,
                "ix": 3,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_1", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "pot Outlines",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [960, 540, 0], "ix": 2},
                "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[15.157, -80.19], [-15.156, 80.19]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0, 0.75099995931, 0.847000002394, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 0.531, "ix": 5},
                    "lc": 2,
                    "lj": 1,
                    "ml": 10,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [488.219, 657.452], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 1",
                "np": 2,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-2.828, -10.702], [0, 0], [-3.933, 0], [0, 0], [-1.004, 3.802], [0, 0], [11.069, 0],
                                [0, 0]],
                            "o": [[0, 0], [1.006, 3.802], [0, 0], [3.934, 0], [0, 0], [2.828, -10.702], [0, 0],
                                [-11.069, 0]],
                            "v": [[-108.151, -78.145], [-62.955, 92.876], [-54.576, 99.328], [54.576, 99.328],
                                [62.954, 92.876], [108.151, -78.145], [91.84, -99.328], [-91.839, -99.328]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0, 0.75099995931, 0.847000002394, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 0.531, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 10,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [439.486, 655.782], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 2",
                "np": 2,
                "cix": 2,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-450, 450], [450, 450], [450, -450], [-450, -450]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 1,
                    "ty": "sh",
                    "ix": 2,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-450, -450], [450, -450], [450, 450], [-450, 450]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 2",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 2,
                    "ty": "sh",
                    "ix": 3,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[9.243, 106.954], [315.777, 106.954], [315.777, -153.729], [9.243, -153.729]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 3",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 3,
                    "ty": "sh",
                    "ix": 4,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-63.224, 15.205], [-93.636, -33.39], [-54.422, -2.401], [0, 0], [0, -41.616],
                                [20.92, -225.187]],
                            "o": [[0, 0], [0, 0], [0, 0], [54.421, 2.401], [0, 0], [0, 0], [0, 0]],
                            "v": [[16.644, 106.954], [49.273, -6.39], [146.111, -104.657], [214.138, -153.647],
                                [266.05, -99.021], [315.776, -49.607], [51.362, 106.954]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 4",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [450, 450], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 3",
                "np": 4,
                "cix": 2,
                "ix": 3,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-63.224, 15.205], [-93.637, -33.39], [-54.422, -2.401], [0, 0], [0, -41.616],
                                [20.92, -225.187]],
                            "o": [[0, 0], [0, 0], [0, 0], [54.421, 2.401], [0, 0], [0, 0], [0, 0]],
                            "v": [[-134.27, 131.501], [-101.64, 18.157], [-4.802, -80.11], [63.224, -129.1],
                                [115.136, -74.474], [164.863, -25.06], [-99.551, 131.501]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.380000005984, 0.773000021542, 0.948999980852, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [600.913, 425.453], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 4",
                "np": 2,
                "cix": 2,
                "ix": 4,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [17.382, 1.388], [0.717, -8.985], [-8.983, -0.717]],
                            "o": [[0, 0], [-8.985, -0.717], [-0.717, 8.984], [18.096, 1.445]],
                            "v": [[25.609, 2.369], [-7.325, -16.631], [-24.892, -1.662], [-9.924, 15.903]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-420.899, 391.079], [479.101, 391.079], [479.101, -508.921],
                                    [-420.899, -508.921]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-420.899, -508.921], [479.101, -508.921], [479.101, 391.079],
                                    [-420.899, 391.079]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [420.899, 508.921], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 5",
                "np": 4,
                "cix": 2,
                "ix": 5,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-17.24, -2.618], [-1.353, 8.912], [8.911, 1.353]],
                            "o": [[0, 0], [8.912, 1.354], [1.354, -8.911], [-17.945, -2.726]],
                            "v": [[-25.72, -4.471], [5.781, 16.82], [24.366, 3.136], [10.683, -15.448]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-471.231, 384.233], [428.769, 384.233], [428.769, -515.767],
                                    [-471.231, -515.767]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-471.231, -515.767], [428.769, -515.767], [428.769, 384.233],
                                    [-471.231, 384.233]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [471.231, 515.767], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 6",
                "np": 4,
                "cix": 2,
                "ix": 6,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [17.384, 1.388], [0.717, -8.984], [-8.984, -0.717]],
                            "o": [[0, 0], [-8.983, -0.716], [-0.716, 8.984], [18.095, 1.445]],
                            "v": [[25.609, 2.369], [-7.327, -16.632], [-24.892, -1.663], [-9.923, 15.903]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-423.221, 456.054], [476.779, 456.054], [476.779, -443.946],
                                    [-423.221, -443.946]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-423.221, -443.946], [476.779, -443.946], [476.779, 456.054],
                                    [-423.221, 456.054]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [423.221, 443.946], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 7",
                "np": 4,
                "cix": 2,
                "ix": 7,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-17.24, -2.62], [-1.354, 8.911], [8.91, 1.353]],
                            "o": [[0, 0], [8.913, 1.353], [1.353, -8.91], [-17.947, -2.727]],
                            "v": [[-25.72, -4.471], [5.781, 16.821], [24.367, 3.137], [10.685, -15.447]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-473.552, 449.209], [426.448, 449.209], [426.448, -450.791],
                                    [-473.552, -450.791]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-473.552, -450.791], [426.448, -450.791], [426.448, 449.209],
                                    [-473.552, 449.209]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [473.552, 450.791], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 8",
                "np": 4,
                "cix": 2,
                "ix": 8,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [17.174, 3.026], [1.563, -8.876], [-8.876, -1.565]],
                            "o": [[0, 0], [-8.876, -1.563], [-1.565, 8.877], [17.877, 3.149]],
                            "v": [[25.728, 5.166], [-5.262, -16.865], [-24.164, -3.625], [-10.925, 15.279]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-430.315, 512.519], [469.685, 512.519], [469.685, -387.481],
                                    [-430.315, -387.481]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-430.315, -387.481], [469.685, -387.481], [469.685, 512.519],
                                    [-430.315, 512.519]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [430.315, 387.481], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 9",
                "np": 4,
                "cix": 2,
                "ix": 9,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-16.916, -4.238], [-2.191, 8.743], [8.743, 2.19]],
                            "o": [[0, 0], [8.742, 2.191], [2.19, -8.743], [-17.607, -4.412]],
                            "v": [[-25.667, -7.235], [3.681, 16.94], [23.477, 5.076], [11.612, -14.719]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-480.716, 500.207], [419.284, 500.207], [419.284, -399.793],
                                    [-480.716, -399.793]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-480.716, -399.793], [419.284, -399.793], [419.284, 500.207],
                                    [-480.716, 500.207]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [480.716, 399.793], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 10",
                "np": 4,
                "cix": 2,
                "ix": 10,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [16.428, 5.847], [3.022, -8.49], [-8.49, -3.023]],
                            "o": [[0, 0], [-8.491, -3.023], [-3.023, 8.493], [17.102, 6.088]],
                            "v": [[25.375, 9.984], [-1.506, -16.907], [-22.353, -7.007], [-12.453, 13.842]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-445.264, 569.147], [454.736, 569.147], [454.736, -330.853],
                                    [-445.264, -330.853]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-445.264, -330.853], [454.736, -330.853], [454.736, 569.147],
                                    [-445.264, 569.147]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [445.264, 330.853], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 11",
                "np": 4,
                "cix": 2,
                "ix": 11,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-15.971, -7], [-3.618, 8.254], [8.255, 3.617]],
                            "o": [[0, 0], [8.256, 3.618], [3.618, -8.254], [-16.627, -7.286]],
                            "v": [[-25.009, -11.952], [-0.106, 16.781], [21.391, 8.385], [12.996, -13.112]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-494.684, 547.465], [405.316, 547.465], [405.316, -352.535],
                                    [-494.684, -352.535]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-494.684, -352.535], [405.316, -352.535], [405.316, 547.465],
                                    [-494.684, 547.465]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [494.684, 352.535], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 12",
                "np": 4,
                "cix": 2,
                "ix": 12,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [15.835, 7.304], [3.774, -8.184], [-8.184, -3.775]],
                            "o": [[0, 0], [-8.183, -3.774], [-3.773, 8.185], [16.486, 7.601]],
                            "v": [[24.889, 12.468], [0.536, -16.733], [-21.117, -8.747], [-13.132, 12.907]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-466.876, 625.041], [433.124, 625.041], [433.124, -274.959],
                                    [-466.876, -274.959]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-466.876, -274.959], [433.124, -274.959], [433.124, 625.041],
                                    [-466.876, 625.041]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [466.876, 274.959], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 13",
                "np": 4,
                "cix": 2,
                "ix": 13,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-15.278, -8.408], [-4.346, 7.896], [7.897, 4.346]],
                            "o": [[0, 0], [7.895, 4.347], [4.346, -7.895], [-15.902, -8.752]],
                            "v": [[-24.364, -14.357], [-2.146, 16.499], [20.019, 10.072], [13.591, -12.094]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-515.192, 598.556], [384.808, 598.556], [384.808, -301.444],
                                    [-515.192, -301.444]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-515.192, -301.444], [384.808, -301.444], [384.808, 598.556],
                                    [-515.192, 598.556]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [515.192, 301.444], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 14",
                "np": 4,
                "cix": 2,
                "ix": 14,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [14.871, 9.107], [4.707, -7.687], [-7.685, -4.707]],
                            "o": [[0, 0], [-7.685, -4.707], [-4.707, 7.685], [15.481, 9.479]],
                            "v": [[23.955, 15.55], [3.191, -16.303], [-19.247, -10.908], [-13.855, 11.531]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-496.885, 675.82], [403.115, 675.82], [403.115, -224.18], [-496.885, -224.18]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-496.885, -224.18], [403.115, -224.18], [403.115, 675.82], [-496.885, 675.82]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [496.885, 224.18], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 15",
                "np": 4,
                "cix": 2,
                "ix": 15,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-14.186, -10.142], [-5.241, 7.332], [7.333, 5.241]],
                            "o": [[0, 0], [7.333, 5.24], [5.24, -7.332], [-14.769, -10.556]],
                            "v": [[-23.228, -17.314], [-4.779, 15.935], [17.988, 12.146], [14.201, -10.619]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-543.176, 643.405], [356.824, 643.405], [356.824, -256.595],
                                    [-543.176, -256.595]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-543.176, -256.595], [356.824, -256.595], [356.824, 643.405],
                                    [-543.176, 643.405]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [543.176, 256.595], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 16",
                "np": 4,
                "cix": 2,
                "ix": 16,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-12.647, 12.007], [6.206, 6.536], [6.537, -6.206]],
                            "o": [[0, 0], [6.537, -6.206], [-6.206, -6.536], [-13.163, 12.499]],
                            "v": [[-21.472, 20.746], [14.669, 8.934], [15.266, -14.137], [-7.805, -14.735]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-549.642, 691.23], [350.358, 691.23], [350.358, -208.77], [-549.642, -208.77]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-549.642, -208.77], [350.358, -208.77], [350.358, 691.23], [-549.642, 691.23]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [549.642, 208.77], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 17",
                "np": 4,
                "cix": 2,
                "ix": 17,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-1.03, 1.075], [0, 0], [-19.406, -222.644]],
                            "o": [[-19.204, -220.167], [0, 0], [-1.043, 1.074], [0, 0]],
                            "v": [[-29.428, 166.229], [53.938, -163.123], [50.249, -166.672], [-34.532, 166.672]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-480.323, 510.213], [419.677, 510.213], [419.677, -389.787],
                                    [-480.323, -389.787]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-480.323, -389.787], [419.677, -389.787], [419.677, 510.213],
                                    [-480.323, 510.213]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.144999994016, 0.545000023935, 0.685999971278, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [480.323, 389.787], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 18",
                "np": 4,
                "cix": 2,
                "ix": 18,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-11.337, 6.549], [-3.384, -5.859], [5.859, -3.383]],
                            "o": [[0, 0], [5.86, -3.384], [3.384, 5.859], [-11.802, 6.817]],
                            "v": [[-18.16, 11.18], [-1.962, -12.326], [14.776, -7.844], [10.296, 8.893]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-392.861, 552.719], [507.139, 552.719], [507.139, -347.281],
                                    [-392.861, -347.281]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-392.861, -347.281], [507.139, -347.281], [507.139, 552.719],
                                    [-392.861, 552.719]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [392.861, 347.281], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 19",
                "np": 4,
                "cix": 2,
                "ix": 19,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [10.844, -7.336], [3.792, 5.605], [-5.604, 3.791]],
                            "o": [[0, 0], [-5.605, 3.793], [-3.791, -5.604], [11.288, -7.638]],
                            "v": [[17.647, -12.526], [3.158, 12.07], [-13.856, 8.788], [-10.574, -8.225]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-357.731, 529.33], [542.269, 529.33], [542.269, -370.67], [-357.731, -370.67]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-357.731, -370.67], [542.269, -370.67], [542.269, 529.33], [-357.731, 529.33]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [357.731, 370.67], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 20",
                "np": 4,
                "cix": 2,
                "ix": 20,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-10.492, 7.831], [-4.048, -5.423], [5.423, -4.048]],
                            "o": [[0, 0], [5.423, -4.048], [4.047, 5.422], [-10.922, 8.151]],
                            "v": [[-17.264, 13.371], [-3.931, -11.87], [13.217, -9.38], [10.727, 7.767]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-366.949, 588.572], [533.051, 588.572], [533.051, -311.428],
                                    [-366.949, -311.428]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-366.949, -311.428], [533.051, -311.428], [533.051, 588.572],
                                    [-366.949, 588.572]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [366.949, 311.428], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 21",
                "np": 4,
                "cix": 2,
                "ix": 21,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [9.91, -8.557], [4.423, 5.122], [-5.122, 4.421]],
                            "o": [[0, 0], [-5.122, 4.422], [-4.421, -5.121], [10.315, -8.909]],
                            "v": [[16.605, -14.609], [5.096, 11.516], [-12.185, 10.25], [-10.918, -7.03]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-333.715, 560.988], [566.285, 560.988], [566.285, -339.012],
                                    [-333.715, -339.012]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-333.715, -339.012], [566.285, -339.012], [566.285, 560.988],
                                    [-333.715, 560.988]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [333.715, 339.012], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 22",
                "np": 4,
                "cix": 2,
                "ix": 22,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [10.28, 8.108], [-4.19, 5.313], [-5.312, -4.19]],
                            "o": [[0, 0], [-5.313, -4.19], [4.19, -5.313], [10.701, 8.44]],
                            "v": [[17.028, 14.009], [-10.805, 7.662], [-12.838, -9.546], [4.369, -11.579]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-326.474, 596.342], [573.526, 596.342], [573.526, -303.658],
                                    [-326.474, -303.658]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-326.474, -303.658], [573.526, -303.658], [573.526, 596.342],
                                    [-326.474, 596.342]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [326.474, 303.658], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 23",
                "np": 4,
                "cix": 2,
                "ix": 23,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0.845, 0.731], [0, 0], [-0.797, -167.792]],
                            "o": [[-0.778, -165.927], [0, 0], [0.854, 0.733], [0, 0]],
                            "v": [[40.564, 121.707], [-44.408, -118.798], [-41.895, -121.707], [44.408, 121.688]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "gr",
                    "it": [{
                        "ind": 0,
                        "ty": "sh",
                        "ix": 1,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-383.159, 465.247], [516.841, 465.247], [516.841, -434.753],
                                    [-383.159, -434.753]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 1",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ind": 1,
                        "ty": "sh",
                        "ix": 2,
                        "ks": {
                            "a": 0,
                            "k": {
                                "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                                "v": [[-383.159, -434.753], [516.841, -434.753], [516.841, 465.247],
                                    [-383.159, 465.247]],
                                "c": true
                            },
                            "ix": 2
                        },
                        "nm": "Path 2",
                        "mn": "ADBE Vector Shape - Group",
                        "hd": false
                    }, {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 0], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 6},
                        "o": {"a": 0, "k": 100, "ix": 7},
                        "sk": {"a": 0, "k": 0, "ix": 4},
                        "sa": {"a": 0, "k": 0, "ix": 5},
                        "nm": "Transform"
                    }],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }, {"ty": "mm", "mm": 4, "nm": "Merge Paths 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.224000010771, 0.654999976065, 0.847000002394, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [383.159, 434.753], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 24",
                "np": 4,
                "cix": 2,
                "ix": 24,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-450, 450], [450, 450], [450, -450], [-450, -450]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 1,
                    "ty": "sh",
                    "ix": 2,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-450, -450], [450, -450], [450, 450], [-450, 450]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 2",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 2,
                    "ty": "sh",
                    "ix": 3,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [69.816, 26.071], [0, 0], [-55.216, -2.907], [0, 0], [-26.233, -23.104],
                                [0, 0], [2.994, -32.905], [0.824, -3.454]],
                            "o": [[0, 0], [-69.818, -26.073], [0, 0], [55.214, 2.906], [0, 0], [21.773, 19.178], [0, 0],
                                [-0.375, 4.112], [0, 0]],
                            "v": [[-65.125, 106.954], [-140.036, 27.746], [-259.437, 32.637], [-197.377, -28.496],
                                [-130.976, 10.016], [-74.81, 1.949], [-56.524, 59.219], [-38.855, 94.672],
                                [-40.687, 106.012]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 4",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [450, 450], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 25",
                "np": 3,
                "cix": 2,
                "ix": 25,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [69.817, 26.071], [0, 0], [-55.215, -2.907], [0, 0], [-26.234, -23.104],
                                [0, 0], [2.993, -32.906], [0.824, -3.454]],
                            "o": [[0, 0], [-69.817, -26.073], [0, 0], [55.214, 2.906], [0, 0], [21.773, 19.178], [0, 0],
                                [-0.375, 4.112], [0, 0]],
                            "v": [[82.525, 69.178], [7.613, -10.029], [-111.787, -5.138], [-49.729, -66.271],
                                [16.673, -27.759], [72.839, -35.826], [91.124, 21.444], [108.794, 56.897],
                                [106.961, 68.236]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.380000005984, 0.773000021542, 0.948999980852, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [302.351, 487.776], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 26",
                "np": 2,
                "cix": 2,
                "ix": 26,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -6.724], [82.016, 0], [0, 9.219], [-46.673, 2.786], [0, 0], [-3.937, 0], [0, 0],
                                [-1.057, 3.744], [0, 0]],
                            "o": [[0, 9.219], [-82.016, 0], [0, -6.339], [0, 0], [0.96, 3.744], [0, 0], [3.938, 0],
                                [0, 0], [51.765, 2.593]],
                            "v": [[148.474, -0.719], [0, 15.894], [-148.473, -0.719], [-70.107, -15.318],
                                [-67.706, -6.289], [-59.352, 0.145], [49.747, 0.145], [58.199, -6.289],
                                [60.695, -15.894]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.642999985639, 0.838999968884, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [444.274, 754.979], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 27",
                "np": 2,
                "cix": 2,
                "ix": 27,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_2", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "pie Outlines",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.671]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p671_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [0.017]
                    }, {
                        "i": {"x": [0.833], "y": [0.781]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p781_0p167_0p112"],
                        "t": 1,
                        "s": [0.017],
                        "e": [0.065]
                    }, {
                        "i": {"x": [0.833], "y": [0.802]},
                        "o": {"x": [0.167], "y": [0.134]},
                        "n": ["0p833_0p802_0p167_0p134"],
                        "t": 2,
                        "s": [0.065],
                        "e": [0.145]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.144]},
                        "n": ["0p833_0p812_0p167_0p144"],
                        "t": 3,
                        "s": [0.145],
                        "e": [0.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.817]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p817_0p167_0p15"],
                        "t": 4,
                        "s": [0.254],
                        "e": [0.392]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.153]},
                        "n": ["0p833_0p82_0p167_0p153"],
                        "t": 5,
                        "s": [0.392],
                        "e": [0.556]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 6,
                        "s": [0.556],
                        "e": [0.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.824]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p824_0p167_0p157"],
                        "t": 7,
                        "s": [0.746],
                        "e": [0.961]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.159]},
                        "n": ["0p833_0p826_0p167_0p159"],
                        "t": 8,
                        "s": [0.961],
                        "e": [1.198]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p827_0p167_0p16"],
                        "t": 9,
                        "s": [1.198],
                        "e": [1.456]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 10,
                        "s": [1.456],
                        "e": [1.735]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 11,
                        "s": [1.735],
                        "e": [2.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.162]},
                        "n": ["0p833_0p829_0p167_0p162"],
                        "t": 12,
                        "s": [2.032],
                        "e": [2.347]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 13,
                        "s": [2.347],
                        "e": [2.678]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 14,
                        "s": [2.678],
                        "e": [3.024]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 15,
                        "s": [3.024],
                        "e": [3.383]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 16,
                        "s": [3.383],
                        "e": [3.755]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 17,
                        "s": [3.755],
                        "e": [4.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 18,
                        "s": [4.137],
                        "e": [4.528]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 19,
                        "s": [4.528],
                        "e": [4.928]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 20,
                        "s": [4.928],
                        "e": [5.334]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 21,
                        "s": [5.334],
                        "e": [5.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 22,
                        "s": [5.746],
                        "e": [6.162]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 23,
                        "s": [6.162],
                        "e": [6.58]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 24,
                        "s": [6.58],
                        "e": [7]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 25,
                        "s": [7],
                        "e": [7.42]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 26,
                        "s": [7.42],
                        "e": [7.838]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 27,
                        "s": [7.838],
                        "e": [8.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 28,
                        "s": [8.254],
                        "e": [8.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 29,
                        "s": [8.666],
                        "e": [9.072]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 30,
                        "s": [9.072],
                        "e": [9.472]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 31,
                        "s": [9.472],
                        "e": [9.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 32,
                        "s": [9.863],
                        "e": [10.245]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 33,
                        "s": [10.245],
                        "e": [10.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 34,
                        "s": [10.617],
                        "e": [10.976]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 35,
                        "s": [10.976],
                        "e": [11.322]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 36,
                        "s": [11.322],
                        "e": [11.653]
                    }, {
                        "i": {"x": [0.833], "y": [0.838]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p838_0p167_0p171"],
                        "t": 37,
                        "s": [11.653],
                        "e": [11.968]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 38,
                        "s": [11.968],
                        "e": [12.265]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 39,
                        "s": [12.265],
                        "e": [12.544]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p84_0p167_0p173"],
                        "t": 40,
                        "s": [12.544],
                        "e": [12.802]
                    }, {
                        "i": {"x": [0.833], "y": [0.841]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p841_0p167_0p174"],
                        "t": 41,
                        "s": [12.802],
                        "e": [13.039]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.176]},
                        "n": ["0p833_0p843_0p167_0p176"],
                        "t": 42,
                        "s": [13.039],
                        "e": [13.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 43,
                        "s": [13.254],
                        "e": [13.444]
                    }, {
                        "i": {"x": [0.833], "y": [0.847]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p847_0p167_0p18"],
                        "t": 44,
                        "s": [13.444],
                        "e": [13.608]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.183]},
                        "n": ["0p833_0p85_0p167_0p183"],
                        "t": 45,
                        "s": [13.608],
                        "e": [13.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.856]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p856_0p167_0p188"],
                        "t": 46,
                        "s": [13.746],
                        "e": [13.855]
                    }, {
                        "i": {"x": [0.833], "y": [0.866]},
                        "o": {"x": [0.167], "y": [0.198]},
                        "n": ["0p833_0p866_0p167_0p198"],
                        "t": 47,
                        "s": [13.855],
                        "e": [13.935]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.219]},
                        "n": ["0p833_0p888_0p167_0p219"],
                        "t": 48,
                        "s": [13.935],
                        "e": [13.983]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.329]},
                        "n": ["0p833_1_0p167_0p329"],
                        "t": 49,
                        "s": [13.983],
                        "e": [14]
                    }, {
                        "i": {"x": [0.833], "y": [0.671]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p671_0p167_0"],
                        "t": 50,
                        "s": [14],
                        "e": [13.983]
                    }, {
                        "i": {"x": [0.833], "y": [0.781]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p781_0p167_0p112"],
                        "t": 51,
                        "s": [13.983],
                        "e": [13.935]
                    }, {
                        "i": {"x": [0.833], "y": [0.802]},
                        "o": {"x": [0.167], "y": [0.134]},
                        "n": ["0p833_0p802_0p167_0p134"],
                        "t": 52,
                        "s": [13.935],
                        "e": [13.855]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.144]},
                        "n": ["0p833_0p812_0p167_0p144"],
                        "t": 53,
                        "s": [13.855],
                        "e": [13.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.817]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p817_0p167_0p15"],
                        "t": 54,
                        "s": [13.746],
                        "e": [13.608]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.153]},
                        "n": ["0p833_0p82_0p167_0p153"],
                        "t": 55,
                        "s": [13.608],
                        "e": [13.444]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 56,
                        "s": [13.444],
                        "e": [13.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.824]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p824_0p167_0p157"],
                        "t": 57,
                        "s": [13.254],
                        "e": [13.039]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.159]},
                        "n": ["0p833_0p826_0p167_0p159"],
                        "t": 58,
                        "s": [13.039],
                        "e": [12.802]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p827_0p167_0p16"],
                        "t": 59,
                        "s": [12.802],
                        "e": [12.544]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 60,
                        "s": [12.544],
                        "e": [12.265]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p828_0p167_0p161"],
                        "t": 61,
                        "s": [12.265],
                        "e": [11.968]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.162]},
                        "n": ["0p833_0p829_0p167_0p162"],
                        "t": 62,
                        "s": [11.968],
                        "e": [11.653]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 63,
                        "s": [11.653],
                        "e": [11.322]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 64,
                        "s": [11.322],
                        "e": [10.976]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 65,
                        "s": [10.976],
                        "e": [10.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 66,
                        "s": [10.617],
                        "e": [10.245]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 67,
                        "s": [10.245],
                        "e": [9.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 68,
                        "s": [9.863],
                        "e": [9.472]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 69,
                        "s": [9.472],
                        "e": [9.072]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 70,
                        "s": [9.072],
                        "e": [8.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 71,
                        "s": [8.666],
                        "e": [8.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 72,
                        "s": [8.254],
                        "e": [7.838]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 73,
                        "s": [7.838],
                        "e": [7.42]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 74,
                        "s": [7.42],
                        "e": [7]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 75,
                        "s": [7],
                        "e": [6.58]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 76,
                        "s": [6.58],
                        "e": [6.162]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 77,
                        "s": [6.162],
                        "e": [5.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 78,
                        "s": [5.746],
                        "e": [5.334]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 79,
                        "s": [5.334],
                        "e": [4.928]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 80,
                        "s": [4.928],
                        "e": [4.528]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 81,
                        "s": [4.528],
                        "e": [4.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 82,
                        "s": [4.137],
                        "e": [3.755]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 83,
                        "s": [3.755],
                        "e": [3.383]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 84,
                        "s": [3.383],
                        "e": [3.024]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 85,
                        "s": [3.024],
                        "e": [2.678]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 86,
                        "s": [2.678],
                        "e": [2.347]
                    }, {
                        "i": {"x": [0.833], "y": [0.838]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p838_0p167_0p171"],
                        "t": 87,
                        "s": [2.347],
                        "e": [2.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 88,
                        "s": [2.032],
                        "e": [1.735]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p839_0p167_0p172"],
                        "t": 89,
                        "s": [1.735],
                        "e": [1.456]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p84_0p167_0p173"],
                        "t": 90,
                        "s": [1.456],
                        "e": [1.198]
                    }, {
                        "i": {"x": [0.833], "y": [0.841]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p841_0p167_0p174"],
                        "t": 91,
                        "s": [1.198],
                        "e": [0.961]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.176]},
                        "n": ["0p833_0p843_0p167_0p176"],
                        "t": 92,
                        "s": [0.961],
                        "e": [0.746]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 93,
                        "s": [0.746],
                        "e": [0.556]
                    }, {
                        "i": {"x": [0.833], "y": [0.847]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p847_0p167_0p18"],
                        "t": 94,
                        "s": [0.556],
                        "e": [0.392]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.183]},
                        "n": ["0p833_0p85_0p167_0p183"],
                        "t": 95,
                        "s": [0.392],
                        "e": [0.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.856]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p856_0p167_0p188"],
                        "t": 96,
                        "s": [0.254],
                        "e": [0.145]
                    }, {
                        "i": {"x": [0.833], "y": [0.866]},
                        "o": {"x": [0.167], "y": [0.198]},
                        "n": ["0p833_0p866_0p167_0p198"],
                        "t": 97,
                        "s": [0.145],
                        "e": [0.065]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.219]},
                        "n": ["0p833_0p833_0p167_0p219"],
                        "t": 98,
                        "s": [0.065],
                        "e": [0.017]
                    }, {"t": 99}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [929.279, 535.847, 0], "ix": 2},
                "a": {"a": 0, "k": [419.279, 445.847, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-112.441, -21.06], [0, 0]],
                            "o": [[36.388, 106.122], [0, 0], [0, 0]],
                            "v": [[-149.531, -52.65], [90.795, 155.264], [149.531, -155.264]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "tm",
                    "s": {"a": 0, "k": 0, "ix": 1},
                    "e": {"a": 0, "k": 100, "ix": 2},
                    "o": {"a": 0, "k": 0, "ix": 3},
                    "m": 1,
                    "ix": 2,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.808000028133, 0.082000002265, 0.765294075012, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [417.748, 449.11], "ix": 2},
                    "a": {"a": 0, "k": [148, -152], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-20.125, 0], [-57.8, 62.714], [0, 0]],
                            "o": [[19.072, 3.628], [91.965, 0], [0, 0], [0, 0]],
                            "v": [[-145.611, 152.515], [-86.875, 158.013], [145.611, 56.104], [-86.875, -158.014]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.722000002394, 0.925, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [506.154, 603.859], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 2",
                "np": 2,
                "cix": 2,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [56.981, -82.021], [0, 0]],
                            "o": [[-107.526, 0], [0, 0], [0, 0]],
                            "v": [[129.758, -158.073], [-129.758, -22.346], [129.758, 158.072]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976000019148, 0.698000021542, 0.325, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [289.522, 287.774], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 3",
                "np": 2,
                "cix": 2,
                "ix": 3,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[174.57, 0], [0, 0], [0, 0], [0, 82.605]],
                            "o": [[0, 0], [0, 0], [51.832, -56.278], [0, -174.569]],
                            "v": [[-158.072, -265.13], [-158.072, 51.014], [74.414, 265.13], [158.073, 51.014]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.093999997307, 0.741000007181, 0.969000004787, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [458.632, 413.581], "ix": 2},
                    "a": {"a": 0, "k": [-164, 48], "ix": 1},
                    "s": {
                        "a": 1,
                        "k": [{
                            "i": {"x": [0.833, 0.833], "y": [0.719, 0.719]},
                            "o": {"x": [0.167, 0.167], "y": [0.167, 0.167]},
                            "n": ["0p833_0p719_0p167_0p167", "0p833_0p719_0p167_0p167"],
                            "t": 0,
                            "s": [100, 100],
                            "e": [100.536, 100.536]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.805, 0.805]},
                            "o": {"x": [0.167, 0.167], "y": [0.119, 0.119]},
                            "n": ["0p833_0p805_0p167_0p119", "0p833_0p805_0p167_0p119"],
                            "t": 1,
                            "s": [100.536, 100.536],
                            "e": [101.804, 101.804]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.821, 0.821]},
                            "o": {"x": [0.167, 0.167], "y": [0.146, 0.146]},
                            "n": ["0p833_0p821_0p167_0p146", "0p833_0p821_0p167_0p146"],
                            "t": 2,
                            "s": [101.804, 101.804],
                            "e": [103.501, 103.501]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.83, 0.83]},
                            "o": {"x": [0.167, 0.167], "y": [0.156, 0.156]},
                            "n": ["0p833_0p83_0p167_0p156", "0p833_0p83_0p167_0p156"],
                            "t": 3,
                            "s": [103.501, 103.501],
                            "e": [105.439, 105.439]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.164, 0.164]},
                            "n": ["0p833_0p841_0p167_0p164", "0p833_0p841_0p167_0p164"],
                            "t": 4,
                            "s": [105.439, 105.439],
                            "e": [107.452, 107.452]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.889, 0.889]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p889_0p167_0p175", "0p833_0p889_0p167_0p175"],
                            "t": 5,
                            "s": [107.452, 107.452],
                            "e": [109.277, 109.277]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1.077, 1.077]},
                            "o": {"x": [0.167, 0.167], "y": [0.336, 0.336]},
                            "n": ["0p833_1p077_0p167_0p336", "0p833_1p077_0p167_0p336"],
                            "t": 6,
                            "s": [109.277, 109.277],
                            "e": [109.879, 109.879]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.808, 0.808]},
                            "o": {"x": [0.167, 0.167], "y": [0.04, 0.04]},
                            "n": ["0p833_0p808_0p167_0p04", "0p833_0p808_0p167_0p04"],
                            "t": 7,
                            "s": [109.879, 109.879],
                            "e": [108.721, 108.721]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.827, 0.827]},
                            "o": {"x": [0.167, 0.167], "y": [0.147, 0.147]},
                            "n": ["0p833_0p827_0p167_0p147", "0p833_0p827_0p167_0p147"],
                            "t": 8,
                            "s": [108.721, 108.721],
                            "e": [107.213, 107.213]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.832, 0.832]},
                            "o": {"x": [0.167, 0.167], "y": [0.161, 0.161]},
                            "n": ["0p833_0p832_0p167_0p161", "0p833_0p832_0p167_0p161"],
                            "t": 9,
                            "s": [107.213, 107.213],
                            "e": [105.593, 105.593]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.836, 0.836]},
                            "o": {"x": [0.167, 0.167], "y": [0.165, 0.165]},
                            "n": ["0p833_0p836_0p167_0p165", "0p833_0p836_0p167_0p165"],
                            "t": 10,
                            "s": [105.593, 105.593],
                            "e": [103.948, 103.948]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.169, 0.169]},
                            "n": ["0p833_0p841_0p167_0p169", "0p833_0p841_0p167_0p169"],
                            "t": 11,
                            "s": [103.948, 103.948],
                            "e": [102.347, 102.347]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.865, 0.865]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p865_0p167_0p175", "0p833_0p865_0p167_0p175"],
                            "t": 12,
                            "s": [102.347, 102.347],
                            "e": [100.899, 100.899]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.917, 0.917]},
                            "o": {"x": [0.167, 0.167], "y": [0.218, 0.218]},
                            "n": ["0p833_0p917_0p167_0p218", "0p833_0p917_0p167_0p218"],
                            "t": 13,
                            "s": [100.899, 100.899],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [-0.075, -0.075]},
                            "n": ["0p833_1_0p167_-0p075", "0p833_1_0p167_-0p075"],
                            "t": 14,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 15,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 16,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 17,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 18,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 19,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 20,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 21,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 22,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 23,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.955, 0.955]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_0p955_0p167_0", "0p833_0p955_0p167_0"],
                            "t": 24,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.719, 0.719]},
                            "o": {"x": [0.167, 0.167], "y": [0.083, 0.083]},
                            "n": ["0p833_0p719_0p167_0p083", "0p833_0p719_0p167_0p083"],
                            "t": 25,
                            "s": [100, 100],
                            "e": [100.536, 100.536]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.805, 0.805]},
                            "o": {"x": [0.167, 0.167], "y": [0.119, 0.119]},
                            "n": ["0p833_0p805_0p167_0p119", "0p833_0p805_0p167_0p119"],
                            "t": 26,
                            "s": [100.536, 100.536],
                            "e": [101.804, 101.804]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.821, 0.821]},
                            "o": {"x": [0.167, 0.167], "y": [0.146, 0.146]},
                            "n": ["0p833_0p821_0p167_0p146", "0p833_0p821_0p167_0p146"],
                            "t": 27,
                            "s": [101.804, 101.804],
                            "e": [103.501, 103.501]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.83, 0.83]},
                            "o": {"x": [0.167, 0.167], "y": [0.156, 0.156]},
                            "n": ["0p833_0p83_0p167_0p156", "0p833_0p83_0p167_0p156"],
                            "t": 28,
                            "s": [103.501, 103.501],
                            "e": [105.439, 105.439]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.164, 0.164]},
                            "n": ["0p833_0p841_0p167_0p164", "0p833_0p841_0p167_0p164"],
                            "t": 29,
                            "s": [105.439, 105.439],
                            "e": [107.452, 107.452]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.889, 0.889]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p889_0p167_0p175", "0p833_0p889_0p167_0p175"],
                            "t": 30,
                            "s": [107.452, 107.452],
                            "e": [109.277, 109.277]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1.077, 1.077]},
                            "o": {"x": [0.167, 0.167], "y": [0.336, 0.336]},
                            "n": ["0p833_1p077_0p167_0p336", "0p833_1p077_0p167_0p336"],
                            "t": 31,
                            "s": [109.277, 109.277],
                            "e": [109.879, 109.879]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.808, 0.808]},
                            "o": {"x": [0.167, 0.167], "y": [0.04, 0.04]},
                            "n": ["0p833_0p808_0p167_0p04", "0p833_0p808_0p167_0p04"],
                            "t": 32,
                            "s": [109.879, 109.879],
                            "e": [108.721, 108.721]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.827, 0.827]},
                            "o": {"x": [0.167, 0.167], "y": [0.147, 0.147]},
                            "n": ["0p833_0p827_0p167_0p147", "0p833_0p827_0p167_0p147"],
                            "t": 33,
                            "s": [108.721, 108.721],
                            "e": [107.213, 107.213]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.832, 0.832]},
                            "o": {"x": [0.167, 0.167], "y": [0.161, 0.161]},
                            "n": ["0p833_0p832_0p167_0p161", "0p833_0p832_0p167_0p161"],
                            "t": 34,
                            "s": [107.213, 107.213],
                            "e": [105.593, 105.593]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.836, 0.836]},
                            "o": {"x": [0.167, 0.167], "y": [0.165, 0.165]},
                            "n": ["0p833_0p836_0p167_0p165", "0p833_0p836_0p167_0p165"],
                            "t": 35,
                            "s": [105.593, 105.593],
                            "e": [103.948, 103.948]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.169, 0.169]},
                            "n": ["0p833_0p841_0p167_0p169", "0p833_0p841_0p167_0p169"],
                            "t": 36,
                            "s": [103.948, 103.948],
                            "e": [102.347, 102.347]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.865, 0.865]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p865_0p167_0p175", "0p833_0p865_0p167_0p175"],
                            "t": 37,
                            "s": [102.347, 102.347],
                            "e": [100.899, 100.899]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.917, 0.917]},
                            "o": {"x": [0.167, 0.167], "y": [0.218, 0.218]},
                            "n": ["0p833_0p917_0p167_0p218", "0p833_0p917_0p167_0p218"],
                            "t": 38,
                            "s": [100.899, 100.899],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [-0.075, -0.075]},
                            "n": ["0p833_1_0p167_-0p075", "0p833_1_0p167_-0p075"],
                            "t": 39,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 40,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 41,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 42,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 43,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 44,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 45,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 46,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 47,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 48,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.955, 0.955]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_0p955_0p167_0", "0p833_0p955_0p167_0"],
                            "t": 49,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.719, 0.719]},
                            "o": {"x": [0.167, 0.167], "y": [0.083, 0.083]},
                            "n": ["0p833_0p719_0p167_0p083", "0p833_0p719_0p167_0p083"],
                            "t": 50,
                            "s": [100, 100],
                            "e": [100.536, 100.536]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.805, 0.805]},
                            "o": {"x": [0.167, 0.167], "y": [0.119, 0.119]},
                            "n": ["0p833_0p805_0p167_0p119", "0p833_0p805_0p167_0p119"],
                            "t": 51,
                            "s": [100.536, 100.536],
                            "e": [101.804, 101.804]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.821, 0.821]},
                            "o": {"x": [0.167, 0.167], "y": [0.146, 0.146]},
                            "n": ["0p833_0p821_0p167_0p146", "0p833_0p821_0p167_0p146"],
                            "t": 52,
                            "s": [101.804, 101.804],
                            "e": [103.501, 103.501]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.83, 0.83]},
                            "o": {"x": [0.167, 0.167], "y": [0.156, 0.156]},
                            "n": ["0p833_0p83_0p167_0p156", "0p833_0p83_0p167_0p156"],
                            "t": 53,
                            "s": [103.501, 103.501],
                            "e": [105.439, 105.439]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.164, 0.164]},
                            "n": ["0p833_0p841_0p167_0p164", "0p833_0p841_0p167_0p164"],
                            "t": 54,
                            "s": [105.439, 105.439],
                            "e": [107.452, 107.452]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.889, 0.889]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p889_0p167_0p175", "0p833_0p889_0p167_0p175"],
                            "t": 55,
                            "s": [107.452, 107.452],
                            "e": [109.277, 109.277]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1.077, 1.077]},
                            "o": {"x": [0.167, 0.167], "y": [0.336, 0.336]},
                            "n": ["0p833_1p077_0p167_0p336", "0p833_1p077_0p167_0p336"],
                            "t": 56,
                            "s": [109.277, 109.277],
                            "e": [109.879, 109.879]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.808, 0.808]},
                            "o": {"x": [0.167, 0.167], "y": [0.04, 0.04]},
                            "n": ["0p833_0p808_0p167_0p04", "0p833_0p808_0p167_0p04"],
                            "t": 57,
                            "s": [109.879, 109.879],
                            "e": [108.721, 108.721]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.827, 0.827]},
                            "o": {"x": [0.167, 0.167], "y": [0.147, 0.147]},
                            "n": ["0p833_0p827_0p167_0p147", "0p833_0p827_0p167_0p147"],
                            "t": 58,
                            "s": [108.721, 108.721],
                            "e": [107.213, 107.213]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.832, 0.832]},
                            "o": {"x": [0.167, 0.167], "y": [0.161, 0.161]},
                            "n": ["0p833_0p832_0p167_0p161", "0p833_0p832_0p167_0p161"],
                            "t": 59,
                            "s": [107.213, 107.213],
                            "e": [105.593, 105.593]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.836, 0.836]},
                            "o": {"x": [0.167, 0.167], "y": [0.165, 0.165]},
                            "n": ["0p833_0p836_0p167_0p165", "0p833_0p836_0p167_0p165"],
                            "t": 60,
                            "s": [105.593, 105.593],
                            "e": [103.948, 103.948]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.169, 0.169]},
                            "n": ["0p833_0p841_0p167_0p169", "0p833_0p841_0p167_0p169"],
                            "t": 61,
                            "s": [103.948, 103.948],
                            "e": [102.347, 102.347]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.865, 0.865]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p865_0p167_0p175", "0p833_0p865_0p167_0p175"],
                            "t": 62,
                            "s": [102.347, 102.347],
                            "e": [100.899, 100.899]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.917, 0.917]},
                            "o": {"x": [0.167, 0.167], "y": [0.218, 0.218]},
                            "n": ["0p833_0p917_0p167_0p218", "0p833_0p917_0p167_0p218"],
                            "t": 63,
                            "s": [100.899, 100.899],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [-0.075, -0.075]},
                            "n": ["0p833_1_0p167_-0p075", "0p833_1_0p167_-0p075"],
                            "t": 64,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 65,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 66,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 67,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 68,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 69,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 70,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 71,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 72,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 73,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.955, 0.955]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_0p955_0p167_0", "0p833_0p955_0p167_0"],
                            "t": 74,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.719, 0.719]},
                            "o": {"x": [0.167, 0.167], "y": [0.083, 0.083]},
                            "n": ["0p833_0p719_0p167_0p083", "0p833_0p719_0p167_0p083"],
                            "t": 75,
                            "s": [100, 100],
                            "e": [100.536, 100.536]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.805, 0.805]},
                            "o": {"x": [0.167, 0.167], "y": [0.119, 0.119]},
                            "n": ["0p833_0p805_0p167_0p119", "0p833_0p805_0p167_0p119"],
                            "t": 76,
                            "s": [100.536, 100.536],
                            "e": [101.804, 101.804]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.821, 0.821]},
                            "o": {"x": [0.167, 0.167], "y": [0.146, 0.146]},
                            "n": ["0p833_0p821_0p167_0p146", "0p833_0p821_0p167_0p146"],
                            "t": 77,
                            "s": [101.804, 101.804],
                            "e": [103.501, 103.501]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.83, 0.83]},
                            "o": {"x": [0.167, 0.167], "y": [0.156, 0.156]},
                            "n": ["0p833_0p83_0p167_0p156", "0p833_0p83_0p167_0p156"],
                            "t": 78,
                            "s": [103.501, 103.501],
                            "e": [105.439, 105.439]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.164, 0.164]},
                            "n": ["0p833_0p841_0p167_0p164", "0p833_0p841_0p167_0p164"],
                            "t": 79,
                            "s": [105.439, 105.439],
                            "e": [107.452, 107.452]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.889, 0.889]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p889_0p167_0p175", "0p833_0p889_0p167_0p175"],
                            "t": 80,
                            "s": [107.452, 107.452],
                            "e": [109.277, 109.277]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1.077, 1.077]},
                            "o": {"x": [0.167, 0.167], "y": [0.336, 0.336]},
                            "n": ["0p833_1p077_0p167_0p336", "0p833_1p077_0p167_0p336"],
                            "t": 81,
                            "s": [109.277, 109.277],
                            "e": [109.879, 109.879]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.808, 0.808]},
                            "o": {"x": [0.167, 0.167], "y": [0.04, 0.04]},
                            "n": ["0p833_0p808_0p167_0p04", "0p833_0p808_0p167_0p04"],
                            "t": 82,
                            "s": [109.879, 109.879],
                            "e": [108.721, 108.721]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.827, 0.827]},
                            "o": {"x": [0.167, 0.167], "y": [0.147, 0.147]},
                            "n": ["0p833_0p827_0p167_0p147", "0p833_0p827_0p167_0p147"],
                            "t": 83,
                            "s": [108.721, 108.721],
                            "e": [107.213, 107.213]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.832, 0.832]},
                            "o": {"x": [0.167, 0.167], "y": [0.161, 0.161]},
                            "n": ["0p833_0p832_0p167_0p161", "0p833_0p832_0p167_0p161"],
                            "t": 84,
                            "s": [107.213, 107.213],
                            "e": [105.593, 105.593]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.836, 0.836]},
                            "o": {"x": [0.167, 0.167], "y": [0.165, 0.165]},
                            "n": ["0p833_0p836_0p167_0p165", "0p833_0p836_0p167_0p165"],
                            "t": 85,
                            "s": [105.593, 105.593],
                            "e": [103.948, 103.948]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.841, 0.841]},
                            "o": {"x": [0.167, 0.167], "y": [0.169, 0.169]},
                            "n": ["0p833_0p841_0p167_0p169", "0p833_0p841_0p167_0p169"],
                            "t": 86,
                            "s": [103.948, 103.948],
                            "e": [102.347, 102.347]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.865, 0.865]},
                            "o": {"x": [0.167, 0.167], "y": [0.175, 0.175]},
                            "n": ["0p833_0p865_0p167_0p175", "0p833_0p865_0p167_0p175"],
                            "t": 87,
                            "s": [102.347, 102.347],
                            "e": [100.899, 100.899]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [0.917, 0.917]},
                            "o": {"x": [0.167, 0.167], "y": [0.218, 0.218]},
                            "n": ["0p833_0p917_0p167_0p218", "0p833_0p917_0p167_0p218"],
                            "t": 88,
                            "s": [100.899, 100.899],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [-0.075, -0.075]},
                            "n": ["0p833_1_0p167_-0p075", "0p833_1_0p167_-0p075"],
                            "t": 89,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 90,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 91,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 92,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 93,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 94,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 95,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 96,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 97,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {
                            "i": {"x": [0.833, 0.833], "y": [1, 1]},
                            "o": {"x": [0.167, 0.167], "y": [0, 0]},
                            "n": ["0p833_1_0p167_0", "0p833_1_0p167_0"],
                            "t": 98,
                            "s": [100, 100],
                            "e": [100, 100]
                        }, {"t": 99}],
                        "ix": 3
                    },
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 4",
                "np": 2,
                "cix": 2,
                "ix": 4,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, -67.042], [-11.116, -32.177], [0, 0]],
                            "o": [[-35.686, 51.248], [0, 35.92], [0, 0], [0, 0]],
                            "v": [[-101.442, -141.517], [-158.072, 38.902], [-140.99, 141.517], [158.073, 38.902]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.093999997307, 0.741000007181, 0.969000004787, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [261.207, 406.944], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 5",
                "np": 2,
                "cix": 2,
                "ix": 5,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_3", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 6",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [960, 540, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-326, -130], [323, -128], [-329, -119], [-331, -91], [321, -92], [-332, -78],
                                [-329, -53], [324, -51], [-329, -42], [-327, -13], [321, -15], [-330, 0], [-329, 25],
                                [321, 24], [-328, 36], [-329, 61], [329, 60]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "tm",
                    "s": {"a": 0, "k": 0, "ix": 1},
                    "e": {
                        "a": 1,
                        "k": [{
                            "i": {"x": [0.833], "y": [0.676]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p676_0p167_0p167"],
                            "t": 0,
                            "s": [0],
                            "e": [0.467]
                        }, {
                            "i": {"x": [0.833], "y": [0.784]},
                            "o": {"x": [0.167], "y": [0.112]},
                            "n": ["0p833_0p784_0p167_0p112"],
                            "t": 1,
                            "s": [0.467],
                            "e": [1.818]
                        }, {
                            "i": {"x": [0.833], "y": [0.805]},
                            "o": {"x": [0.167], "y": [0.136]},
                            "n": ["0p833_0p805_0p167_0p136"],
                            "t": 2,
                            "s": [1.818],
                            "e": [3.974]
                        }, {
                            "i": {"x": [0.833], "y": [0.814]},
                            "o": {"x": [0.167], "y": [0.146]},
                            "n": ["0p833_0p814_0p167_0p146"],
                            "t": 3,
                            "s": [3.974],
                            "e": [6.861]
                        }, {
                            "i": {"x": [0.833], "y": [0.82]},
                            "o": {"x": [0.167], "y": [0.151]},
                            "n": ["0p833_0p82_0p167_0p151"],
                            "t": 4,
                            "s": [6.861],
                            "e": [10.4]
                        }, {
                            "i": {"x": [0.833], "y": [0.823]},
                            "o": {"x": [0.167], "y": [0.155]},
                            "n": ["0p833_0p823_0p167_0p155"],
                            "t": 5,
                            "s": [10.4],
                            "e": [14.515]
                        }, {
                            "i": {"x": [0.833], "y": [0.826]},
                            "o": {"x": [0.167], "y": [0.158]},
                            "n": ["0p833_0p826_0p167_0p158"],
                            "t": 6,
                            "s": [14.515],
                            "e": [19.13]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.16]},
                            "n": ["0p833_0p828_0p167_0p16"],
                            "t": 7,
                            "s": [19.13],
                            "e": [24.166]
                        }, {
                            "i": {"x": [0.833], "y": [0.829]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p829_0p167_0p161"],
                            "t": 8,
                            "s": [24.166],
                            "e": [29.549]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p831_0p167_0p163"],
                            "t": 9,
                            "s": [29.549],
                            "e": [35.2]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p832_0p167_0p164"],
                            "t": 10,
                            "s": [35.2],
                            "e": [41.043]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p833_0p167_0p165"],
                            "t": 11,
                            "s": [41.043],
                            "e": [47.002]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p834_0p167_0p166"],
                            "t": 12,
                            "s": [47.002],
                            "e": [52.998]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p835_0p167_0p167"],
                            "t": 13,
                            "s": [52.998],
                            "e": [58.957]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p836_0p167_0p168"],
                            "t": 14,
                            "s": [58.957],
                            "e": [64.8]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p837_0p167_0p169"],
                            "t": 15,
                            "s": [64.8],
                            "e": [70.451]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.171]},
                            "n": ["0p833_0p839_0p167_0p171"],
                            "t": 16,
                            "s": [70.451],
                            "e": [75.834]
                        }, {
                            "i": {"x": [0.833], "y": [0.84]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p84_0p167_0p172"],
                            "t": 17,
                            "s": [75.834],
                            "e": [80.87]
                        }, {
                            "i": {"x": [0.833], "y": [0.842]},
                            "o": {"x": [0.167], "y": [0.174]},
                            "n": ["0p833_0p842_0p167_0p174"],
                            "t": 18,
                            "s": [80.87],
                            "e": [85.485]
                        }, {
                            "i": {"x": [0.833], "y": [0.845]},
                            "o": {"x": [0.167], "y": [0.177]},
                            "n": ["0p833_0p845_0p167_0p177"],
                            "t": 19,
                            "s": [85.485],
                            "e": [89.6]
                        }, {
                            "i": {"x": [0.833], "y": [0.849]},
                            "o": {"x": [0.167], "y": [0.18]},
                            "n": ["0p833_0p849_0p167_0p18"],
                            "t": 20,
                            "s": [89.6],
                            "e": [93.139]
                        }, {
                            "i": {"x": [0.833], "y": [0.854]},
                            "o": {"x": [0.167], "y": [0.186]},
                            "n": ["0p833_0p854_0p167_0p186"],
                            "t": 21,
                            "s": [93.139],
                            "e": [96.026]
                        }, {
                            "i": {"x": [0.833], "y": [0.864]},
                            "o": {"x": [0.167], "y": [0.195]},
                            "n": ["0p833_0p864_0p167_0p195"],
                            "t": 22,
                            "s": [96.026],
                            "e": [98.182]
                        }, {
                            "i": {"x": [0.833], "y": [0.888]},
                            "o": {"x": [0.167], "y": [0.216]},
                            "n": ["0p833_0p888_0p167_0p216"],
                            "t": 23,
                            "s": [98.182],
                            "e": [99.533]
                        }, {
                            "i": {"x": [0.833], "y": [0.917]},
                            "o": {"x": [0.167], "y": [0.324]},
                            "n": ["0p833_0p917_0p167_0p324"],
                            "t": 24,
                            "s": [99.533],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0.039]},
                            "n": ["0p833_1_0p167_0p039"],
                            "t": 25,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 26,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 27,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 28,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 29,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 30,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 31,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 32,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 33,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 34,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 35,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 36,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 37,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 38,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 39,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 40,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 41,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 42,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 43,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 44,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 45,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 46,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 47,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 48,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [9.294]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_9p294_0p167_0"],
                            "t": 49,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [0.918]},
                            "o": {"x": [0.167], "y": [0.083]},
                            "n": ["0p833_0p918_0p167_0p083"],
                            "t": 50,
                            "s": [100],
                            "e": [0.467]
                        }, {
                            "i": {"x": [0.833], "y": [0.784]},
                            "o": {"x": [0.167], "y": [-6.059]},
                            "n": ["0p833_0p784_0p167_-6p059"],
                            "t": 51,
                            "s": [0.467],
                            "e": [1.818]
                        }, {
                            "i": {"x": [0.833], "y": [0.805]},
                            "o": {"x": [0.167], "y": [0.136]},
                            "n": ["0p833_0p805_0p167_0p136"],
                            "t": 52,
                            "s": [1.818],
                            "e": [3.974]
                        }, {
                            "i": {"x": [0.833], "y": [0.814]},
                            "o": {"x": [0.167], "y": [0.146]},
                            "n": ["0p833_0p814_0p167_0p146"],
                            "t": 53,
                            "s": [3.974],
                            "e": [6.861]
                        }, {
                            "i": {"x": [0.833], "y": [0.82]},
                            "o": {"x": [0.167], "y": [0.151]},
                            "n": ["0p833_0p82_0p167_0p151"],
                            "t": 54,
                            "s": [6.861],
                            "e": [10.4]
                        }, {
                            "i": {"x": [0.833], "y": [0.823]},
                            "o": {"x": [0.167], "y": [0.155]},
                            "n": ["0p833_0p823_0p167_0p155"],
                            "t": 55,
                            "s": [10.4],
                            "e": [14.515]
                        }, {
                            "i": {"x": [0.833], "y": [0.826]},
                            "o": {"x": [0.167], "y": [0.158]},
                            "n": ["0p833_0p826_0p167_0p158"],
                            "t": 56,
                            "s": [14.515],
                            "e": [19.13]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.16]},
                            "n": ["0p833_0p828_0p167_0p16"],
                            "t": 57,
                            "s": [19.13],
                            "e": [24.166]
                        }, {
                            "i": {"x": [0.833], "y": [0.829]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p829_0p167_0p161"],
                            "t": 58,
                            "s": [24.166],
                            "e": [29.549]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p831_0p167_0p163"],
                            "t": 59,
                            "s": [29.549],
                            "e": [35.2]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p832_0p167_0p164"],
                            "t": 60,
                            "s": [35.2],
                            "e": [41.043]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p833_0p167_0p165"],
                            "t": 61,
                            "s": [41.043],
                            "e": [47.002]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p834_0p167_0p166"],
                            "t": 62,
                            "s": [47.002],
                            "e": [52.998]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p835_0p167_0p167"],
                            "t": 63,
                            "s": [52.998],
                            "e": [58.957]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p836_0p167_0p168"],
                            "t": 64,
                            "s": [58.957],
                            "e": [64.8]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p837_0p167_0p169"],
                            "t": 65,
                            "s": [64.8],
                            "e": [70.451]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.171]},
                            "n": ["0p833_0p839_0p167_0p171"],
                            "t": 66,
                            "s": [70.451],
                            "e": [75.834]
                        }, {
                            "i": {"x": [0.833], "y": [0.84]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p84_0p167_0p172"],
                            "t": 67,
                            "s": [75.834],
                            "e": [80.87]
                        }, {
                            "i": {"x": [0.833], "y": [0.842]},
                            "o": {"x": [0.167], "y": [0.174]},
                            "n": ["0p833_0p842_0p167_0p174"],
                            "t": 68,
                            "s": [80.87],
                            "e": [85.485]
                        }, {
                            "i": {"x": [0.833], "y": [0.845]},
                            "o": {"x": [0.167], "y": [0.177]},
                            "n": ["0p833_0p845_0p167_0p177"],
                            "t": 69,
                            "s": [85.485],
                            "e": [89.6]
                        }, {
                            "i": {"x": [0.833], "y": [0.849]},
                            "o": {"x": [0.167], "y": [0.18]},
                            "n": ["0p833_0p849_0p167_0p18"],
                            "t": 70,
                            "s": [89.6],
                            "e": [93.139]
                        }, {
                            "i": {"x": [0.833], "y": [0.854]},
                            "o": {"x": [0.167], "y": [0.186]},
                            "n": ["0p833_0p854_0p167_0p186"],
                            "t": 71,
                            "s": [93.139],
                            "e": [96.026]
                        }, {
                            "i": {"x": [0.833], "y": [0.864]},
                            "o": {"x": [0.167], "y": [0.195]},
                            "n": ["0p833_0p864_0p167_0p195"],
                            "t": 72,
                            "s": [96.026],
                            "e": [98.182]
                        }, {
                            "i": {"x": [0.833], "y": [0.888]},
                            "o": {"x": [0.167], "y": [0.216]},
                            "n": ["0p833_0p888_0p167_0p216"],
                            "t": 73,
                            "s": [98.182],
                            "e": [99.533]
                        }, {
                            "i": {"x": [0.833], "y": [0.917]},
                            "o": {"x": [0.167], "y": [0.324]},
                            "n": ["0p833_0p917_0p167_0p324"],
                            "t": 74,
                            "s": [99.533],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0.039]},
                            "n": ["0p833_1_0p167_0p039"],
                            "t": 75,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 76,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 77,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 78,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 79,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 80,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 81,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 82,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 83,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 84,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 85,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 86,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 87,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 88,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 89,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 90,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 91,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 92,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 93,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 94,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 95,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 96,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 97,
                            "s": [100],
                            "e": [100]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_1_0p167_0"],
                            "t": 98,
                            "s": [100],
                            "e": [100]
                        }, {"t": 99}],
                        "ix": 2
                    },
                    "o": {"a": 0, "k": 0, "ix": 3},
                    "m": 1,
                    "ix": 2,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 20, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 5",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [960, 534, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-306, -122], [308, -122]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.698039215686, 0.921568627451, 0.976470588235, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 16, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 2",
                "np": 2,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.921568627451, 0.980392156863, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 2,
                "cix": 2,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "rp",
                "c": {"a": 0, "k": 6, "ix": 1},
                "o": {"a": 0, "k": 0, "ix": 2},
                "m": 1,
                "ix": 3,
                "tr": {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 38], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 4},
                    "so": {"a": 0, "k": 100, "ix": 5},
                    "eo": {"a": 0, "k": 100, "ix": 6},
                    "nm": "Transform"
                },
                "nm": "Repeater 1",
                "mn": "ADBE Vector Filter - Repeater",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [1204.8, 670.9, 0], "ix": 2},
                "a": {"a": 0, "k": [16.8, -53.1, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[17.138, 0], [0, 0], [0, -17.136], [0, 0], [-17.136, 0], [0, 0], [-75.129, 0],
                                [6.451, 56.246], [0, 0], [0, 17.136], [0, 0]],
                            "o": [[0, 0], [-17.136, 0], [0, 0], [0, 17.136], [0, 0], [2.593, 20.809], [0, 0], [0, 0],
                                [17.138, 0], [0, 0], [0, -17.136]],
                            "v": [[311.792, -160.427], [-311.792, -160.427], [-342.82, -129.399], [-342.82, 67.495],
                                [-311.792, 98.523], [205.24, 98.523], [297.581, 160.427], [260.216, 98.523],
                                [311.792, 98.523], [342.82, 67.495], [342.82, -129.399]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.921568627451, 0.980392156863, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [60, -30], "ix": 2},
                    "a": {"a": 0, "k": [288, 154], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "nm": "Shape Layer 7",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [976, 550, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[2.833, 0], [0, 0], [0, -2.834], [0, 0], [-2.833, 0], [0, 0], [-12.422, 0],
                                [1.067, 9.3], [0, 0], [0, 2.833], [0, 0]],
                            "o": [[0, 0], [-2.833, 0], [0, 0], [0, 2.833], [0, 0], [0.429, 3.44], [0, 0], [0, 0],
                                [2.833, 0], [0, 0], [0, -2.834]],
                            "v": [[51.554, -26.526], [-51.553, -26.526], [-56.683, -21.395], [-56.683, 11.161],
                                [-51.553, 16.291], [33.935, 16.291], [49.203, 26.526], [43.026, 16.291],
                                [51.554, 16.291], [56.683, 11.161], [56.683, -21.395]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.372549019608, 0.690196078431, 0.905882352941, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [596, 596], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_4", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 5",
            "parent": 5,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-48.514, -133.782, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-3.322, -30.308], [-79.366, 19.32], [-10.801, -5.83], [-5.743, 2.009],
                                [-24.819, -4.578], [0, 0], [-0.668, 10.716], [15.714, 21.709], [0, 0],
                                [46.799, -13.694], [-0.121, -1.348]],
                            "o": [[0, 0], [21.779, -5.303], [16.224, 51.039], [7.19, -2.515], [18.989, 3.502], [0, 0],
                                [0.398, -6.403], [-15.712, -21.708], [0, 0], [-46.796, 13.693], [0.119, 1.35]],
                            "v": [[-94.48, -27.201], [-13.064, 1.785], [38.737, -2.643], [61.23, 70.874],
                                [73.766, 30.541], [72.94, 80.831], [96.69, 73.144], [82.088, -46.398], [47.62, -67.122],
                                [-19.039, -70.166], [-90.363, -72.403]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.172549019608, 0.117647058824, 0.337254901961, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 4",
            "parent": 5,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-10, 66, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-5.227, -0.909], [0.691, -6.078], [0.211, -0.889], [37.034, -3.309], [2.602, -0.087],
                                [11.079, 29.889], [0.377, 0.982], [-6.948, -0.684], [-18.87, 7.367], [-8.502, -1.053],
                                [-4.992, -0.715], [-0.693, 0.352], [-2.343, 14.345]],
                            "o": [[0.907, 9.342], [-0.073, 0.804], [-4.338, 26.917], [-2.863, 0.402], [-40.084, 1.624],
                                [-0.384, -1.053], [0, 0], [6.947, 0.685], [12.037, -4.699], [7.848, 1.038],
                                [1.242, 0.106], [6.653, -3.421], [0.951, -5.592]],
                            "v": [[74.566, -49.297], [73.75, -25.957], [73.329, -23.382], [-0.969, 48.673],
                                [-9.238, 49.411], [-74.334, -9.067], [-75.474, -12.083], [-54.58, 10.841],
                                [-37.389, -4.552], [1.358, -7.766], [28.283, -1.996], [31.16, -2.255],
                                [56.361, -45.443]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.172549019608, 0.117647058824, 0.337254901961, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-16.5, -92], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "head",
            "parent": 5,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-38.802, -63.085, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-3.187, -31.608], [0.69, -6.079], [0.21, -0.889], [0.283, -0.894], [23.936, -3.079],
                                [2.986, 0.169], [11.296, 29.071], [0.377, 0.982], [0.866, 9.709], [-4.848, 7.345],
                                [-1.366, 0.123], [0, 0]],
                            "o": [[0.905, 9.341], [-0.073, 0.804], [-0.145, 0.809], [-6.801, 26.921], [-3.163, 0.283],
                                [-33.333, -1.517], [-0.384, -1.053], [-9.952, -27.018], [-0.205, -2.299],
                                [5.329, -8.072], [1.367, -0.122], [0, 0]],
                            "v": [[77.17, -9.306], [77.701, 14.495], [77.279, 17.069], [76.712, 19.656],
                                [11.894, 87.638], [2.633, 87.813], [-61.471, 29.898], [-62.611, 26.882],
                                [-76.635, -37.609], [-73.543, -72.021], [-0.955, -87.86], [63.736, -83.417]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "nm": "Shape Layer 6",
            "parent": 5,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-116, -130.945, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-28.118, 0.906], [0, 0]],
                            "o": [[0, 0], [28.119, -0.907], [0, 0]],
                            "v": [[-26.043, -27.738], [0, 34.206], [0, -35.113]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.172549019608, 0.117647058824, 0.337254901961, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 5,
            "ty": 4,
            "nm": "neck\\",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [0.009]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [0.009],
                        "e": [0.036]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [0.036],
                        "e": [0.079]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [0.079],
                        "e": [0.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [0.137],
                        "e": [0.208]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [0.208],
                        "e": [0.29]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [0.29],
                        "e": [0.383]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [0.383],
                        "e": [0.483]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [0.483],
                        "e": [0.591]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [0.591],
                        "e": [0.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [0.704],
                        "e": [0.821]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [0.821],
                        "e": [0.94]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [0.94],
                        "e": [1.06]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [1.06],
                        "e": [1.179]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [1.179],
                        "e": [1.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [1.296],
                        "e": [1.409]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [1.409],
                        "e": [1.517]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [1.517],
                        "e": [1.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [1.617],
                        "e": [1.71]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [1.71],
                        "e": [1.792]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [1.792],
                        "e": [1.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [1.863],
                        "e": [1.921]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [1.921],
                        "e": [1.964]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [1.964],
                        "e": [1.991]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p917_0p167_0p324"],
                        "t": 24,
                        "s": [1.991],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.001]},
                        "n": ["0p833_1_0p167_0p001"],
                        "t": 25,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 26,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 27,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 28,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 29,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 30,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 31,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 32,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 33,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 34,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 35,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 36,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 37,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 38,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 39,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 40,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 41,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 42,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 43,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 44,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 45,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 46,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 47,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 48,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 49,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 50,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 51,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 52,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 53,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 54,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 55,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 56,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 57,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 58,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 59,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 60,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 61,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 62,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 63,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 64,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 65,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 66,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 67,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 68,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 69,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 70,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 71,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 72,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 73,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [1.001]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p001_0p167_0"],
                        "t": 74,
                        "s": [2],
                        "e": [2]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p676_0p167_0p083"],
                        "t": 75,
                        "s": [2],
                        "e": [1.991]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [1.991],
                        "e": [1.964]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [1.964],
                        "e": [1.921]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [1.921],
                        "e": [1.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [1.863],
                        "e": [1.792]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [1.792],
                        "e": [1.71]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [1.71],
                        "e": [1.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [1.617],
                        "e": [1.517]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [1.517],
                        "e": [1.409]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [1.409],
                        "e": [1.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [1.296],
                        "e": [1.179]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [1.179],
                        "e": [1.06]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [1.06],
                        "e": [0.94]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [0.94],
                        "e": [0.821]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [0.821],
                        "e": [0.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [0.704],
                        "e": [0.591]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [0.591],
                        "e": [0.483]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [0.483],
                        "e": [0.383]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [0.383],
                        "e": [0.29]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [0.29],
                        "e": [0.208]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [0.208],
                        "e": [0.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [0.137],
                        "e": [0.079]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [0.079],
                        "e": [0.036]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [0.036],
                        "e": [0.009]
                    }, {"t": 99}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [458.359, 384.097, 0], "ix": 2},
                "a": {"a": 0, "k": [-1.641, 48.097, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-1.559, -6.639], [-9.203, 0.518], [0, 0], [0, 0]],
                            "o": [[3.876, 5.26], [2.107, 8.973], [50.082, -2.814], [-10.321, -15.786], [0, 0]],
                            "v": [[-38.802, 13.967], [-30.879, 33.4], [-11.281, 48.067], [35.473, 13.967],
                                [18.878, -48.585]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "nm": "Shape Layer 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [414, 404, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr", "it": [{
                    "ind": 0, "ty": "sh", "ix": 1, "ks": {
                        "a": 0, "k": {
                            "i": [[2.014, 0.112], [0.448, 0.112], [-0.336, -0.447], [0, 0], [2.126, 0.111],
                                [1.454, 1.119], [2.013, 0.112], [3.245, 3.244], [0, 0], [2.125, 0.112], [3.467, 3.692],
                                [1.679, 2.013], [1.23, 0.783], [1.006, 0], [0, 0], [0, 0], [19.912, 0],
                                [-28.077, -15.884], [-3.467, 0], [2.573, -1.342], [2.349, -0.336], [-0.224, -0.224],
                                [-1.006, -1.23], [-0.895, -0.894], [0, 0]],
                            "o": [[-0.448, -0.111], [1.343, 1.454], [1.231, 1.342], [-1.677, 1.789], [-1.119, -0.112],
                                [-1.566, 1.343], [-2.014, -0.224], [0.448, 0.894], [-1.789, 1.678], [-2.014, -0.224],
                                [-1.119, -1.119], [-1.566, -1.119], [-2.685, -1.901], [-0.224, 0], [0, 0],
                                [-16.22, -0.336], [0, 0], [2.909, 0.448], [8.166, 0.111], [-0.895, 0.335],
                                [0.111, 0.112], [1.23, 1.342], [3.579, 4.363], [1.23, 1.454], [-1.678, 1.678]],
                            "v": [[43.235, 18.065], [42.004, 17.73], [44.577, 20.751], [45.024, 22.988],
                                [39.766, 24.442], [35.851, 22.54], [30.93, 23.658], [23.211, 18.178], [23.211, 19.743],
                                [17.842, 21.198], [9.564, 15.158], [5.201, 10.124], [0.951, 7.215], [-5.537, 5.09],
                                [-5.873, 5.09], [-5.873, 4.979], [-49.275, -2.405], [25.337, -8.893], [37.193, -7.662],
                                [40.438, -2.069], [34.957, -1.063], [35.404, -0.615], [38.76, 3.3], [48.045, 14.262],
                                [48.492, 16.612]],
                            "c": true
                        }, "ix": 2
                    }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-123, 54.5], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }], "nm": "Shape 1", "np": 3, "cix": 2, "ix": 1, "mn": "ADBE Vector Group", "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [392, 447, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [-3.132, 7.271], [-8.166, -2.125], [0, 0], [-28.972, 0]],
                            "o": [[0, 0], [0, 0], [8.39, -2.685], [8.39, 2.236], [0, 0], [29.084, 0]],
                            "v": [[114.601, 7.327], [-114.601, 7.327], [-111.245, -4.642], [-84.063, -4.865],
                                [-70.08, -1.175], [79.03, -1.175]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.949019607843, 0.56862745098, 0.152941176471, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 8,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 402, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[2.909, -104.477], [0, 0], [-3.132, 7.271], [-114.434, 18.569], [0, 0]],
                            "o": [[0, 0], [0, 0], [8.724, -20.582], [0, 0], [0, 0]],
                            "v": [[169.973, 52.239], [-172.881, 52.239], [-169.524, 40.27], [-14.598, -40.494],
                                [41.892, -52.127]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0, "ind": 9, "ty": 4, "nm": "arm", "sr": 1, "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [0.019]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [0.019],
                        "e": [0.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [0.073],
                        "e": [0.159]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [0.159],
                        "e": [0.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [0.274],
                        "e": [0.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [0.416],
                        "e": [0.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [0.581],
                        "e": [0.765]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [0.765],
                        "e": [0.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [0.967],
                        "e": [1.182]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [1.182],
                        "e": [1.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [1.408],
                        "e": [1.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [1.642],
                        "e": [1.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [1.88],
                        "e": [2.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [2.12],
                        "e": [2.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [2.358],
                        "e": [2.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [2.592],
                        "e": [2.818]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [2.818],
                        "e": [3.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [3.033],
                        "e": [3.235]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [3.235],
                        "e": [3.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [3.419],
                        "e": [3.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [3.584],
                        "e": [3.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [3.726],
                        "e": [3.841]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [3.841],
                        "e": [3.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [3.927],
                        "e": [3.981]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1_0p167_0p324"],
                        "t": 24,
                        "s": [3.981],
                        "e": [4]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p676_0p167_0"],
                        "t": 25,
                        "s": [4],
                        "e": [3.981]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 26,
                        "s": [3.981],
                        "e": [3.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 27,
                        "s": [3.927],
                        "e": [3.841]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 28,
                        "s": [3.841],
                        "e": [3.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 29,
                        "s": [3.726],
                        "e": [3.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 30,
                        "s": [3.584],
                        "e": [3.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 31,
                        "s": [3.419],
                        "e": [3.235]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 32,
                        "s": [3.235],
                        "e": [3.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 33,
                        "s": [3.033],
                        "e": [2.818]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 34,
                        "s": [2.818],
                        "e": [2.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 35,
                        "s": [2.592],
                        "e": [2.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 36,
                        "s": [2.358],
                        "e": [2.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 37,
                        "s": [2.12],
                        "e": [1.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 38,
                        "s": [1.88],
                        "e": [1.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 39,
                        "s": [1.642],
                        "e": [1.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 40,
                        "s": [1.408],
                        "e": [1.182]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 41,
                        "s": [1.182],
                        "e": [0.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 42,
                        "s": [0.967],
                        "e": [0.765]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 43,
                        "s": [0.765],
                        "e": [0.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 44,
                        "s": [0.581],
                        "e": [0.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 45,
                        "s": [0.416],
                        "e": [0.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 46,
                        "s": [0.274],
                        "e": [0.159]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 47,
                        "s": [0.159],
                        "e": [0.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 48,
                        "s": [0.073],
                        "e": [0.019]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1_0p167_0p324"],
                        "t": 49,
                        "s": [0.019],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p676_0p167_0"],
                        "t": 50,
                        "s": [0],
                        "e": [0.019]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 51,
                        "s": [0.019],
                        "e": [0.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 52,
                        "s": [0.073],
                        "e": [0.159]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 53,
                        "s": [0.159],
                        "e": [0.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 54,
                        "s": [0.274],
                        "e": [0.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 55,
                        "s": [0.416],
                        "e": [0.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 56,
                        "s": [0.581],
                        "e": [0.765]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 57,
                        "s": [0.765],
                        "e": [0.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 58,
                        "s": [0.967],
                        "e": [1.182]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 59,
                        "s": [1.182],
                        "e": [1.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 60,
                        "s": [1.408],
                        "e": [1.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 61,
                        "s": [1.642],
                        "e": [1.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 62,
                        "s": [1.88],
                        "e": [2.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 63,
                        "s": [2.12],
                        "e": [2.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 64,
                        "s": [2.358],
                        "e": [2.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 65,
                        "s": [2.592],
                        "e": [2.818]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 66,
                        "s": [2.818],
                        "e": [3.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 67,
                        "s": [3.033],
                        "e": [3.235]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 68,
                        "s": [3.235],
                        "e": [3.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 69,
                        "s": [3.419],
                        "e": [3.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 70,
                        "s": [3.584],
                        "e": [3.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 71,
                        "s": [3.726],
                        "e": [3.841]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 72,
                        "s": [3.841],
                        "e": [3.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 73,
                        "s": [3.927],
                        "e": [3.981]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1_0p167_0p324"],
                        "t": 74,
                        "s": [3.981],
                        "e": [4]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p676_0p167_0"],
                        "t": 75,
                        "s": [4],
                        "e": [3.981]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [3.981],
                        "e": [3.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [3.927],
                        "e": [3.841]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [3.841],
                        "e": [3.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [3.726],
                        "e": [3.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [3.584],
                        "e": [3.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [3.419],
                        "e": [3.235]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [3.235],
                        "e": [3.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [3.033],
                        "e": [2.818]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [2.818],
                        "e": [2.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [2.592],
                        "e": [2.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [2.358],
                        "e": [2.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [2.12],
                        "e": [1.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [1.88],
                        "e": [1.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [1.642],
                        "e": [1.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [1.408],
                        "e": [1.182]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [1.182],
                        "e": [0.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [0.967],
                        "e": [0.765]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [0.765],
                        "e": [0.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [0.581],
                        "e": [0.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [0.416],
                        "e": [0.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [0.274],
                        "e": [0.159]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [0.159],
                        "e": [0.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [0.073],
                        "e": [0.019]
                    }, {"t": 99}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [548, 408.056, 0], "ix": 2},
                "a": {"a": 0, "k": [60, -197.944, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            }, "ao": 0, "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-3.665, -6.868],
                                [30.476, -61.534], [1.22, -1.758], [0, 0], [0, 0], [-40.083, 36.638], [-10.183, 15.261],
                                [-0.288, 0.436]],
                            "o": [[0, 0], [33.959, -52.567], [0, 0], [0, 0], [-13.908, -6.085], [0, 0], [0, 0],
                                [0.222, 19.112], [-1.216, 1.651], [-53.852, 77.553], [0, 0], [0, 0], [36.372, -33.247],
                                [0.291, -0.432], [0.555, -0.835]],
                            "v": [[88.2, -21.905], [88.2, -21.967], [133.679, -157.141], [90.333, -186.672],
                                [68.162, -176.031], [30.441, -191.105], [20.017, -157.141], [29.207, -148.299],
                                [-4.117, -18.858], [-7.759, -13.992], [-133.679, 164.002], [-102.854, 191.105],
                                [-16.634, 112.966], [86.441, -19.262], [87.31, -20.562]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }], "ip": 0, "op": 100, "st": 0, "bm": 0
        }, {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "hands",
            "parent": 9,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-40, -198, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [7.69, -5.943], [10.837, -2.797], [3.147, -3.845], [-6.642, 1.399],
                                [-2.797, 0], [2.797, -6.163], [4.544, -3.495], [1.399, -4.194], [-6.642, 2.098],
                                [-8.04, 4.194], [-2.119, 1.775], [-6.565, 0.995], [-2.33, 1.667], [-0.817, 0.332],
                                [-3.147, 5.593], [0, 0]],
                            "o": [[0, 0], [-7.691, 5.942], [-10.836, 2.797], [-3.146, 3.845], [6.642, -1.398],
                                [2.797, 0], [-2.797, 6.164], [-4.544, 3.496], [-1.398, 4.195], [6.641, -2.098],
                                [4.323, -2.255], [2.619, 0.674], [3.388, -0.513], [0.924, -0.201], [5.943, -2.414],
                                [3.147, -5.593], [0, 0]],
                            "v": [[21.061, -50.163], [1.835, -24.295], [-19.838, -15.556], [-43.084, 2.269],
                                [-36.442, 6.464], [-18.439, -0.175], [-18.439, 9.391], [-33.471, 29.188],
                                [-51.998, 43.87], [-45.705, 48.065], [-23.334, 36.18], [-14.886, 29.619],
                                [3.674, 31.519], [12.382, 27.704], [15.118, 27.057], [27.353, -1.573],
                                [53.396, -27.966]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-98, 396], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_5", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "outer arm",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [506, 251.979, 0], "ix": 2},
                "a": {"a": 0, "k": [66, -58.021, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [11.15, -34.805], [2.464, -9.285], [6.884, -0.181], [0, 0], [0, 0],
                                [-22.048, -3.144], [-11.747, 1.439], [1.048, 9.76]],
                            "o": [[0, 0], [-6.231, 19.452], [-1.767, 6.656], [0, 0], [0, 0], [0, 0], [22.048, 3.144],
                                [21.762, -2.666], [-1.637, -15.245]],
                            "v": [[56.011, -57.185], [33.259, -21.023], [20.395, 22.528], [5.819, 34.048],
                                [-75.638, 36.188], [-76.169, 52.415], [-22.323, 58.996], [33.063, 60.893],
                                [75.121, -47.087]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "arm shadow",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [488.5, 321, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[21.752, -2.653], [12.985, 0.758], [-1.327, 9.289], [0, 0], [-1.754, 6.682],
                                [-6.255, 19.477], [0, 0], [-0.711, -18.482]],
                            "o": [[-7.44, 0.901], [0.853, -8.767], [0, 0], [6.872, -0.19], [2.464, -9.289],
                                [11.137, -34.784], [0, 0], [0.664, 18.198]],
                            "v": [[-4.763, 62.839], [-36.562, 63.218], [-33.244, 36.016], [-32.012, 35.969],
                                [-17.463, 24.453], [-4.573, -19.098], [18.174, -55.257], [35.898, -45.495]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.949019607843, 0.56862745098, 0.152941176471, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "hands",
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-86.282, 41.117, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[3.6, 1.204], [0, 0], [3.933, 0.475], [5.687, 1.09], [-0.9, -1.374], [0, 0],
                                [3.507, 0], [0, 0], [0.142, 0.091], [0.736, 0.78], [-0.395, -2.692], [0, 0],
                                [-5.08, -2.633], [-3.641, -0.743], [-1.638, 0.395], [-4.824, 0.062], [0, 0]],
                            "o": [[0, 0], [0, 0], [-3.934, -0.474], [-5.734, -1.09], [1.09, 1.611], [0, 0], [0, 0],
                                [-1.7, -0.275], [-0.634, -0.629], [-3.752, -3.977], [0.395, 2.692], [2.37, 2.737],
                                [2.626, 1.361], [1.651, 0.336], [2.219, -0.535], [3.164, 0.139], [7.06, 0.569]],
                            "v": [[18.773, 5.937], [17.632, -3.299], [5.397, -2.701], [-8.914, -6.35], [-14.98, -3.743],
                                [-7.445, -1.326], [-10.052, -0.521], [-13.677, -0.521], [-17.967, -2.447],
                                [-19.984, -4.498], [-25.71, -7.038], [-22.354, -0.521], [-14.455, 5.869],
                                [-1.839, 9.394], [3.132, 9.268], [13.914, 7.988], [19.046, 8.14]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 1,
            "nm": "laptop",
            "parent": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-70.169, -5.634, 0], "ix": 2},
                "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "hasMask": true,
            "masksProperties": [{
                "inv": false,
                "mode": "f",
                "pt": {
                    "a": 0,
                    "k": {
                        "i": [[0.328, -0.118], [0, 0], [0.118, 0.328], [0, 0], [-0.328, 0.118], [0, 0],
                            [-0.118, -0.328], [0, 0]],
                        "o": [[0, 0], [-0.328, 0.118], [0, 0], [-0.118, -0.328], [0, 0], [0.328, -0.118], [0, 0],
                            [0.118, 0.329]],
                        "v": [[417.087, 494.863], [411.341, 496.929], [410.532, 496.549], [377.073, 403.723],
                            [377.453, 402.915], [383.2, 400.848], [384.009, 401.229], [417.468, 494.054]],
                        "c": true
                    },
                    "ix": 1
                },
                "o": {"a": 0, "k": 100, "ix": 3},
                "x": {"a": 0, "k": 0, "ix": 4},
                "nm": "Mask 1"
            }, {
                "inv": false,
                "mode": "f",
                "pt": {
                    "a": 0,
                    "k": {
                        "i": [[0.405, 0], [0, 0], [0, 0.405], [0, 0], [-0.405, 0], [0, 0], [0, -0.405], [0, 0]],
                        "o": [[0, 0], [-0.405, 0], [0, 0], [0, -0.405], [0, 0], [0.405, 0], [0, 0], [0, 0.405]],
                        "v": [[519.562, 499.27], [413.651, 499.27], [412.917, 498.536], [412.917, 492.395],
                            [413.651, 491.661], [519.562, 491.661], [520.296, 492.395], [520.296, 498.536]],
                        "c": true
                    },
                    "ix": 1
                },
                "o": {"a": 0, "k": 100, "ix": 3},
                "x": {"a": 0, "k": 0, "ix": 4},
                "nm": "Mask 2"
            }],
            "sw": 900,
            "sh": 900,
            "sc": "#1c44b5",
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 5,
            "ty": 4,
            "nm": "body",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [506.158, 314.291, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [37.012, -9.051], [0, 0], [-4.123, 40.803], [-1.327, 9.289],
                                [-18.577, 27.676], [0, 0], [0.047, -31.325], [-1.185, -18.055]],
                            "o": [[-13.127, -5.355], [-37.059, 9.098], [1.327, -2.796], [0.853, -8.767],
                                [5.545, -37.77], [9.193, -13.695], [14.264, 17.44], [-0.048, 31.325], [1.28, 18.103]],
                            "v": [[57.365, 100.325], [-12.44, 109.945], [-57.365, 110.703], [-52.248, 34.121],
                                [-48.93, 6.919], [-14.857, -98.666], [20.449, -119.043], [48.741, -49.522],
                                [41.632, 30.519]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "nm": "neck",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [506.158, 197, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-5.561, -4.698], [0, 0], [9.836, 18.297]],
                            "o": [[0, 0], [-8.555, 10.333], [0, 0], [-9.836, -18.298]],
                            "v": [[17.301, -9.77], [21.545, 9.938], [-6.252, 21.82], [-11.709, -3.523]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882353001015, 0.741175991881, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "hair",
            "parent": 8,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-72.5, 150.5, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-6.871, -10.548], [0.374, 11.594], [7.095, -6.481], [4.112, 53.595], [-12.61, 6.059],
                                [-18.718, -22.855], [-3.427, -20.842], [7.412, -3.115], [1.774, 9.878],
                                [14.095, 12.885]],
                            "o": [[0, 0], [-0.374, -11.594], [-7.095, 6.48], [5.316, 1.685], [6.553, -3.149],
                                [9.201, -1.628], [3.427, 20.842], [-4.768, 2.004], [-0.754, -5.144], [-5.825, -4.052]],
                            "v": [[20.454, 23.57], [11.165, 10.005], [2.198, 4.507], [-56.875, -17.323],
                                [-25.478, -24.431], [28.082, -29.722], [53.448, -2.284], [41.884, 50.573],
                                [33.077, 37.833], [31.648, 9.839]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.172549019608, 0.117647058824, 0.337254901961, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [64.75, -188.25], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0, "ind": 8, "ty": 4, "nm": "face", "sr": 1, "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.685]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p685_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [-0.067]
                    }, {
                        "i": {"x": [0.833], "y": [0.79]},
                        "o": {"x": [0.167], "y": [0.113]},
                        "n": ["0p833_0p79_0p167_0p113"],
                        "t": 1,
                        "s": [-0.067],
                        "e": [-0.255]
                    }, {
                        "i": {"x": [0.833], "y": [0.811]},
                        "o": {"x": [0.167], "y": [0.138]},
                        "n": ["0p833_0p811_0p167_0p138"],
                        "t": 2,
                        "s": [-0.255],
                        "e": [-0.541]
                    }, {
                        "i": {"x": [0.833], "y": [0.821]},
                        "o": {"x": [0.167], "y": [0.149]},
                        "n": ["0p833_0p821_0p167_0p149"],
                        "t": 3,
                        "s": [-0.541],
                        "e": [-0.903]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.156]},
                        "n": ["0p833_0p827_0p167_0p156"],
                        "t": 4,
                        "s": [-0.903],
                        "e": [-1.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p831_0p167_0p161"],
                        "t": 5,
                        "s": [-1.32],
                        "e": [-1.77]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p835_0p167_0p165"],
                        "t": 6,
                        "s": [-1.77],
                        "e": [-2.23]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p839_0p167_0p169"],
                        "t": 7,
                        "s": [-2.23],
                        "e": [-2.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.844]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p844_0p167_0p173"],
                        "t": 8,
                        "s": [-2.68],
                        "e": [-3.097]
                    }, {
                        "i": {"x": [0.833], "y": [0.851]},
                        "o": {"x": [0.167], "y": [0.179]},
                        "n": ["0p833_0p851_0p167_0p179"],
                        "t": 9,
                        "s": [-3.097],
                        "e": [-3.459]
                    }, {
                        "i": {"x": [0.833], "y": [0.862]},
                        "o": {"x": [0.167], "y": [0.189]},
                        "n": ["0p833_0p862_0p167_0p189"],
                        "t": 10,
                        "s": [-3.459],
                        "e": [-3.745]
                    }, {
                        "i": {"x": [0.833], "y": [0.887]},
                        "o": {"x": [0.167], "y": [0.21]},
                        "n": ["0p833_0p887_0p167_0p21"],
                        "t": 11,
                        "s": [-3.745],
                        "e": [-3.933]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.315]},
                        "n": ["0p833_0p917_0p167_0p315"],
                        "t": 12,
                        "s": [-3.933],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [-0.006]},
                        "n": ["0p833_1_0p167_-0p006"],
                        "t": 13,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 14,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 15,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 16,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 17,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 18,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 19,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 20,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 21,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 22,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 23,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 24,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 25,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 26,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 27,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 28,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 29,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 30,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 31,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 32,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 33,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 34,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 35,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 36,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 37,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 38,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 39,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 40,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 41,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 42,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 43,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 44,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 45,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 46,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 47,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 48,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 49,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 50,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 51,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 52,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 53,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 54,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 55,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 56,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 57,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 58,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 59,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 60,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 61,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 62,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 63,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 64,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 65,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 66,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 67,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 68,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 69,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 70,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 71,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 72,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 73,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 74,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 75,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 76,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 77,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 78,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 79,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 80,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 81,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 82,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 83,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 84,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 85,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [0.994]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p994_0p167_0"],
                        "t": 86,
                        "s": [-4],
                        "e": [-4]
                    }, {
                        "i": {"x": [0.833], "y": [0.685]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p685_0p167_0p083"],
                        "t": 87,
                        "s": [-4],
                        "e": [-3.933]
                    }, {
                        "i": {"x": [0.833], "y": [0.79]},
                        "o": {"x": [0.167], "y": [0.113]},
                        "n": ["0p833_0p79_0p167_0p113"],
                        "t": 88,
                        "s": [-3.933],
                        "e": [-3.745]
                    }, {
                        "i": {"x": [0.833], "y": [0.811]},
                        "o": {"x": [0.167], "y": [0.138]},
                        "n": ["0p833_0p811_0p167_0p138"],
                        "t": 89,
                        "s": [-3.745],
                        "e": [-3.459]
                    }, {
                        "i": {"x": [0.833], "y": [0.821]},
                        "o": {"x": [0.167], "y": [0.149]},
                        "n": ["0p833_0p821_0p167_0p149"],
                        "t": 90,
                        "s": [-3.459],
                        "e": [-3.097]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.156]},
                        "n": ["0p833_0p827_0p167_0p156"],
                        "t": 91,
                        "s": [-3.097],
                        "e": [-2.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p831_0p167_0p161"],
                        "t": 92,
                        "s": [-2.68],
                        "e": [-2.23]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p835_0p167_0p165"],
                        "t": 93,
                        "s": [-2.23],
                        "e": [-1.77]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p839_0p167_0p169"],
                        "t": 94,
                        "s": [-1.77],
                        "e": [-1.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.844]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p844_0p167_0p173"],
                        "t": 95,
                        "s": [-1.32],
                        "e": [-0.903]
                    }, {
                        "i": {"x": [0.833], "y": [0.851]},
                        "o": {"x": [0.167], "y": [0.179]},
                        "n": ["0p833_0p851_0p167_0p179"],
                        "t": 96,
                        "s": [-0.903],
                        "e": [-0.541]
                    }, {
                        "i": {"x": [0.833], "y": [0.862]},
                        "o": {"x": [0.167], "y": [0.189]},
                        "n": ["0p833_0p862_0p167_0p189"],
                        "t": 97,
                        "s": [-0.541],
                        "e": [-0.255]
                    }, {
                        "i": {"x": [0.833], "y": [0.887]},
                        "o": {"x": [0.167], "y": [0.21]},
                        "n": ["0p833_0p887_0p167_0p21"],
                        "t": 98,
                        "s": [-0.255],
                        "e": [-0.067]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.315]},
                        "n": ["0p833_1_0p167_0p315"],
                        "t": 99,
                        "s": [-0.067],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.685]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p685_0p167_0"],
                        "t": 100,
                        "s": [0],
                        "e": [-0.067]
                    }, {
                        "i": {"x": [0.833], "y": [0.79]},
                        "o": {"x": [0.167], "y": [0.113]},
                        "n": ["0p833_0p79_0p167_0p113"],
                        "t": 101,
                        "s": [-0.067],
                        "e": [-0.255]
                    }, {
                        "i": {"x": [0.833], "y": [0.811]},
                        "o": {"x": [0.167], "y": [0.138]},
                        "n": ["0p833_0p811_0p167_0p138"],
                        "t": 102,
                        "s": [-0.255],
                        "e": [-0.541]
                    }, {
                        "i": {"x": [0.833], "y": [0.821]},
                        "o": {"x": [0.167], "y": [0.149]},
                        "n": ["0p833_0p821_0p167_0p149"],
                        "t": 103,
                        "s": [-0.541],
                        "e": [-0.903]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.156]},
                        "n": ["0p833_0p827_0p167_0p156"],
                        "t": 104,
                        "s": [-0.903],
                        "e": [-1.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p831_0p167_0p161"],
                        "t": 105,
                        "s": [-1.32],
                        "e": [-1.77]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 106,
                        "s": [-1.77],
                        "e": [-2.23]
                    }, {"t": 107}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [523.46, 187.23, 0], "ix": 2},
                "a": {"a": 0, "k": [24.96, 27.73, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            }, "ao": 0, "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-4.624, -7.201], [-0.212, -0.819], [0.137, -2.51], [-7.675, -8.09],
                                [-13.005, 6.859], [2.22, 21.809], [1.16, 0.458], [1.353, -0.291]],
                            "o": [[0, 0], [0.433, 0.675], [0.781, 3.01], [3.55, 12.423], [7.882, 3.482],
                                [2.777, -0.244], [-2.22, -21.809], [-1.16, -0.458], [-1.353, 0.291]],
                            "v": [[-36.814, -15.601], [-34.277, -4.257], [-31.837, -1.815], [-31.923, 6.81],
                                [-15.502, 38.862], [20.768, 30.017], [36.68, 0.412], [16.252, -41.886],
                                [-19.95, -35.86]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882353001015, 0.741175991881, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }], "ip": 0, "op": 108, "st": 0, "bm": 0
        }, {
            "ddd": 0,
            "ind": 9,
            "ty": 4,
            "nm": "back arm",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [-2.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 1,
                        "s": [-2.5],
                        "e": [-5]
                    }, {
                        "i": {"x": [0.833], "y": [0.942]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p942_0p167_0p167"],
                        "t": 2,
                        "s": [-5],
                        "e": [-7.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.195]},
                        "n": ["0p833_0p743_0p167_-0p195"],
                        "t": 3,
                        "s": [-7.5],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 4,
                        "s": [-6.75],
                        "e": [-5.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 5,
                        "s": [-5.188],
                        "e": [-3.626]
                    }, {
                        "i": {"x": [0.833], "y": [91.944]},
                        "o": {"x": [0.167], "y": [136.611]},
                        "n": ["0p833_91p944_0p167_136p611"],
                        "t": 6,
                        "s": [-3.626],
                        "e": [-3.625]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 7,
                        "s": [-3.625],
                        "e": [-4.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 8,
                        "s": [-4.666],
                        "e": [-5.708]
                    }, {
                        "i": {"x": [0.833], "y": [0.859]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p859_0p167_0p167"],
                        "t": 9,
                        "s": [-5.708],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.203]},
                        "n": ["0p833_0p845_0p167_0p203"],
                        "t": 10,
                        "s": [-6.75],
                        "e": [-7.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p833_0p167_0p18"],
                        "t": 11,
                        "s": [-7.475],
                        "e": [-8.1]
                    }, {
                        "i": {"x": [0.833], "y": [1.142]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_1p142_0p167_0p167"],
                        "t": 12,
                        "s": [-8.1],
                        "e": [-8.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.744]},
                        "o": {"x": [0.167], "y": [0.053]},
                        "n": ["0p833_0p744_0p167_0p053"],
                        "t": 13,
                        "s": [-8.725],
                        "e": [-7.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 14,
                        "s": [-7.032],
                        "e": [-3.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 15,
                        "s": [-3.516],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.293]},
                        "n": ["0p833_1_0p167_0p293"],
                        "t": 16,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 17,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 18,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 19,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 20,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 21,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 22,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 23,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1.208]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p208_0p167_0"],
                        "t": 24,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 25,
                        "s": [0],
                        "e": [-2.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 26,
                        "s": [-2.5],
                        "e": [-5]
                    }, {
                        "i": {"x": [0.833], "y": [0.942]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p942_0p167_0p167"],
                        "t": 27,
                        "s": [-5],
                        "e": [-7.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.195]},
                        "n": ["0p833_0p743_0p167_-0p195"],
                        "t": 28,
                        "s": [-7.5],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 29,
                        "s": [-6.75],
                        "e": [-5.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 30,
                        "s": [-5.188],
                        "e": [-3.626]
                    }, {
                        "i": {"x": [0.833], "y": [91.944]},
                        "o": {"x": [0.167], "y": [136.611]},
                        "n": ["0p833_91p944_0p167_136p611"],
                        "t": 31,
                        "s": [-3.626],
                        "e": [-3.625]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 32,
                        "s": [-3.625],
                        "e": [-4.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 33,
                        "s": [-4.666],
                        "e": [-5.708]
                    }, {
                        "i": {"x": [0.833], "y": [0.859]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p859_0p167_0p167"],
                        "t": 34,
                        "s": [-5.708],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.203]},
                        "n": ["0p833_0p845_0p167_0p203"],
                        "t": 35,
                        "s": [-6.75],
                        "e": [-7.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p833_0p167_0p18"],
                        "t": 36,
                        "s": [-7.475],
                        "e": [-8.1]
                    }, {
                        "i": {"x": [0.833], "y": [1.142]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_1p142_0p167_0p167"],
                        "t": 37,
                        "s": [-8.1],
                        "e": [-8.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.744]},
                        "o": {"x": [0.167], "y": [0.053]},
                        "n": ["0p833_0p744_0p167_0p053"],
                        "t": 38,
                        "s": [-8.725],
                        "e": [-7.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 39,
                        "s": [-7.032],
                        "e": [-3.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 40,
                        "s": [-3.516],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.293]},
                        "n": ["0p833_1_0p167_0p293"],
                        "t": 41,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 42,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 43,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 44,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 45,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 46,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 47,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 48,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1.208]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p208_0p167_0"],
                        "t": 49,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 50,
                        "s": [0],
                        "e": [-2.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 51,
                        "s": [-2.5],
                        "e": [-5]
                    }, {
                        "i": {"x": [0.833], "y": [0.942]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p942_0p167_0p167"],
                        "t": 52,
                        "s": [-5],
                        "e": [-7.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.195]},
                        "n": ["0p833_0p743_0p167_-0p195"],
                        "t": 53,
                        "s": [-7.5],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 54,
                        "s": [-6.75],
                        "e": [-5.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 55,
                        "s": [-5.188],
                        "e": [-3.626]
                    }, {
                        "i": {"x": [0.833], "y": [91.944]},
                        "o": {"x": [0.167], "y": [136.611]},
                        "n": ["0p833_91p944_0p167_136p611"],
                        "t": 56,
                        "s": [-3.626],
                        "e": [-3.625]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 57,
                        "s": [-3.625],
                        "e": [-4.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 58,
                        "s": [-4.666],
                        "e": [-5.708]
                    }, {
                        "i": {"x": [0.833], "y": [0.859]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p859_0p167_0p167"],
                        "t": 59,
                        "s": [-5.708],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.203]},
                        "n": ["0p833_0p845_0p167_0p203"],
                        "t": 60,
                        "s": [-6.75],
                        "e": [-7.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p833_0p167_0p18"],
                        "t": 61,
                        "s": [-7.475],
                        "e": [-8.1]
                    }, {
                        "i": {"x": [0.833], "y": [1.142]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_1p142_0p167_0p167"],
                        "t": 62,
                        "s": [-8.1],
                        "e": [-8.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.744]},
                        "o": {"x": [0.167], "y": [0.053]},
                        "n": ["0p833_0p744_0p167_0p053"],
                        "t": 63,
                        "s": [-8.725],
                        "e": [-7.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 64,
                        "s": [-7.032],
                        "e": [-3.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 65,
                        "s": [-3.516],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.293]},
                        "n": ["0p833_1_0p167_0p293"],
                        "t": 66,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 67,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 68,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 69,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 70,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 71,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 72,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 73,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1.208]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p208_0p167_0"],
                        "t": 74,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 75,
                        "s": [0],
                        "e": [-2.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 76,
                        "s": [-2.5],
                        "e": [-5]
                    }, {
                        "i": {"x": [0.833], "y": [0.942]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p942_0p167_0p167"],
                        "t": 77,
                        "s": [-5],
                        "e": [-7.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.195]},
                        "n": ["0p833_0p743_0p167_-0p195"],
                        "t": 78,
                        "s": [-7.5],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 79,
                        "s": [-6.75],
                        "e": [-5.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 80,
                        "s": [-5.188],
                        "e": [-3.626]
                    }, {
                        "i": {"x": [0.833], "y": [91.944]},
                        "o": {"x": [0.167], "y": [136.611]},
                        "n": ["0p833_91p944_0p167_136p611"],
                        "t": 81,
                        "s": [-3.626],
                        "e": [-3.625]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 82,
                        "s": [-3.625],
                        "e": [-4.666]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 83,
                        "s": [-4.666],
                        "e": [-5.708]
                    }, {
                        "i": {"x": [0.833], "y": [0.859]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p859_0p167_0p167"],
                        "t": 84,
                        "s": [-5.708],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.203]},
                        "n": ["0p833_0p845_0p167_0p203"],
                        "t": 85,
                        "s": [-6.75],
                        "e": [-7.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p833_0p167_0p18"],
                        "t": 86,
                        "s": [-7.475],
                        "e": [-8.1]
                    }, {
                        "i": {"x": [0.833], "y": [1.142]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_1p142_0p167_0p167"],
                        "t": 87,
                        "s": [-8.1],
                        "e": [-8.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.744]},
                        "o": {"x": [0.167], "y": [0.053]},
                        "n": ["0p833_0p744_0p167_0p053"],
                        "t": 88,
                        "s": [-8.725],
                        "e": [-7.032]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 89,
                        "s": [-7.032],
                        "e": [-3.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 90,
                        "s": [-3.516],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.293]},
                        "n": ["0p833_1_0p167_0p293"],
                        "t": 91,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 92,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 93,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 94,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 95,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 96,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 97,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 98,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1.208]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p208_0p167_0"],
                        "t": 99,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p833_0p167_0p083"],
                        "t": 100,
                        "s": [0],
                        "e": [-2.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 101,
                        "s": [-2.5],
                        "e": [-5]
                    }, {
                        "i": {"x": [0.833], "y": [0.942]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p942_0p167_0p167"],
                        "t": 102,
                        "s": [-5],
                        "e": [-7.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.195]},
                        "n": ["0p833_0p743_0p167_-0p195"],
                        "t": 103,
                        "s": [-7.5],
                        "e": [-6.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p833_0p167_0p123"],
                        "t": 104,
                        "s": [-6.75],
                        "e": [-5.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p917_0p167_0p167"],
                        "t": 105,
                        "s": [-5.188],
                        "e": [-3.626]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [136.611]},
                        "n": ["0p833_0p833_0p167_136p611"],
                        "t": 106,
                        "s": [-3.626],
                        "e": [-3.625]
                    }, {"t": 107}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [466.948, 309.367, 0], "ix": 2},
                "a": {"a": 0, "k": [37.948, -0.633, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0.854, -0.048], [4.028, -0.142], [0, 0], [0, 0],
                                [-14.644, 2.606]],
                            "o": [[0, 0], [0, 0], [-0.853, 0.047], [-3.886, 0.142], [-28.955, 0.664], [0, 0],
                                [27.391, -8.009], [6.587, -1.137]],
                            "v": [[39.698, -14.809], [36.618, 11.065], [36.286, 13.672], [33.774, 13.767],
                                [21.832, 14.146], [-37.926, 14.525], [-39.698, 3.024], [29.225, -13.056]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "back hands",
            "parent": 9,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [-45.198, 11.524, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [3.042, -0.563], [4.506, -0.451], [4.547, -3.659], [-0.991, -0.615],
                                [-4.227, 3.033], [-1.464, -1.014], [1.239, -0.788], [0.225, -0.788], [-4.055, 0.765],
                                [-1.352, 1.014], [-3.042, 0.338], [0, 0]],
                            "o": [[0, 0], [-3.042, 0.563], [-2.317, 0.232], [-3.645, 4.144], [2.041, 1.266],
                                [3.204, -2.298], [1.464, 1.014], [-1.239, 0.789], [-0.225, 0.789], [4.056, -0.764],
                                [1.352, -1.014], [3.042, -0.338], [0, 0]],
                            "v": [[21.727, -10.918], [8.885, -7.2], [-7.112, -7.2], [-18.743, 1.139], [-24.679, 9.651],
                                [-13.252, 1.392], [-5.901, 0.209], [-6.323, 3.502], [-10.604, 5.417], [-6.549, 6.97],
                                [1.9, 3.502], [7.646, 1.7], [25.67, -1.291]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 11,
            "ty": 4,
            "nm": "Shape Layer 6",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [451, 455, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-3.034, -5.971], [4.881, 8.056], [0.048, 0.047], [0.047, 0.047], [11.942, 19.145],
                                [0, 0.095], [0.569, 1.422], [0.095, 0.237], [0.095, 0.616], [0.094, 0.522], [0, 0.095],
                                [5.734, 20.188], [0, 0], [0, 0], [5.639, -18.813], [0.494, -10.766], [-8.293, -14.612]],
                            "o": [[-4.218, -7.393], [0, 0], [0, -0.047], [-26.349, -43.172], [-0.047, -0.095],
                                [-0.996, -1.611], [-0.095, -0.284], [-0.38, -0.948], [-0.095, -0.474], [0, -0.047],
                                [-1.99, -10.094], [0, 0], [0, 0], [0, 0], [-5.64, 18.767], [-1.086, 23.646],
                                [8.921, 15.719]],
                            "v": [[51.063, 141.056], [37.319, 117.788], [37.272, 117.74], [37.177, 117.551],
                                [-34.713, 6.611], [-34.855, 6.374], [-37.177, 1.825], [-37.509, 1.019],
                                [-38.267, -1.398], [-38.552, -2.867], [-38.599, -3.104], [-51.063, -50.683],
                                [-17.368, -141.056], [-5.095, -141.056], [-24.571, -80.492], [-32.261, -21.568],
                                [3.973, 56.773]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.298039215686, 0.250980392157, 0.317647058824, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [102, 129], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 12,
            "ty": 4,
            "nm": "Shape Layer 4",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [430, 280, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -0.569], [8.199, 2.037], [1.422, 0.663], [2.986, 4.597], [-0.048, 0.095],
                                [0.569, 1.232], [0.095, 0.237], [0.095, 0.616], [0.094, 0.522], [0, 0.095],
                                [5.734, 20.188], [3.555, 12.416], [-0.284, 10.71], [-21.42, -15.07], [-0.142, -10.142],
                                [-0.379, -26.112], [0, -0.569]],
                            "o": [[0.427, 20.235], [-1.374, -0.332], [-5.497, -2.654], [-0.047, -0.095],
                                [-0.616, -0.901], [-0.095, -0.284], [-0.38, -0.948], [-0.095, -0.474], [0, -0.047],
                                [-1.99, -10.094], [-3.269, -11.421], [-10.805, -37.296], [1.043, -38.48],
                                [9.004, 6.398], [0.189, 9.525], [0.047, 0.664], [0.047, 0.711]],
                            "v": [[39.997, 90.752], [24.785, 113.594], [20.567, 112.03], [6.776, 99.756],
                                [6.682, 99.519], [4.834, 96.107], [4.502, 95.301], [3.744, 92.884], [3.46, 91.415],
                                [3.412, 91.178], [-9.052, 43.599], [-19.43, 7.44], [-40.139, -77.151],
                                [27.012, -69.947], [38.48, -42.414], [39.902, 87.008], [39.949, 88.856]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [86, 204], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 13,
            "ty": 4,
            "nm": "Shape Layer 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [496.955, 488.443, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[7.247, -34.558], [7.826, -21.592], [0, 0], [4.702, -1.127], [0.841, 21.421],
                                [-0.284, 2.781], [-1.001, 9.238], [-4.928, 1.593]],
                            "o": [[-0.099, 0.555], [0, 0], [-1.147, 3.016], [-10.787, 2.649], [-0.112, -2.362],
                                [1.845, -17.323], [0.959, -9.239], [24.597, -7.838]],
                            "v": [[38.97, -36.886], [-9.031, 84.701], [-9.032, 84.743], [-18.588, 91.368],
                                [-46.105, 68.214], [-45.827, 60.5], [-36.788, -61.887], [-28.894, -86.178]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 14,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [438, 352, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[3.85, -35.134], [0.558, 0.003], [0, 0], [0, 0], [1.121, 28.175], [0.035, 1.643],
                                [-8.849, -19.002], [-1.256, -5.281], [-1.58, -7.188]],
                            "o": [[-0.601, 0.039], [-12.348, 0.057], [0, 0], [1.005, -54.529], [-0.074, -1.972],
                                [-0.524, -24.269], [2.075, 4.428], [1.549, 6.355], [9.345, 42.504]],
                            "v": [[42.518, 386.783], [40.76, 386.815], [21.39, 385.373], [21.41, 381.815],
                                [15.212, 209.941], [15.205, 189.398], [47.819, 205.759], [52.794, 220.364],
                                [55.974, 240.963]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 15,
            "ty": 4,
            "nm": "Shape Layer 5",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [440.555, 425.683, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr", "it": [{
                    "ind": 0, "ty": "sh", "ix": 1, "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-13.648, -31.514], [0, 0], [0, 0], [1.327, 2.369], [0, 0], [0.094, 0.19],
                                [4.881, 8.056], [0.048, 0.047], [0.047, 0.047], [11.942, 19.145], [0, 0.095],
                                [0.569, 1.422], [0.095, 0.237], [0.095, 0.616], [0.094, 0.522], [0, 0.095],
                                [-1.943, 2.085], [-0.569, 0.426], [-10.663, -7.535], [-0.569, -0.426], [-0.284, -0.189],
                                [-7.156, -10.852]],
                            "o": [[-11.563, 6.872], [0, 0], [-1.232, -2.275], [0, 0], [0, -0.047], [-4.218, -7.393],
                                [0, 0], [0, -0.047], [-26.349, -43.172], [-0.047, -0.095], [-0.996, -1.611],
                                [-0.095, -0.284], [-0.38, -0.948], [-0.095, -0.474], [0, -0.047], [-0.474, -3.649],
                                [0.427, -0.521], [6.445, -4.644], [0.521, 0.379], [0.284, 0.19], [8.862, 6.493],
                                [24.359, 36.774]],
                            "v": [[57.508, 75.373], [38.457, 84.615], [36.704, 81.534], [32.865, 74.568],
                                [32.817, 74.521], [32.628, 74.141], [18.885, 50.873], [18.837, 50.826],
                                [18.743, 50.636], [-53.147, -60.304], [-53.289, -60.541], [-55.612, -65.09],
                                [-55.943, -65.896], [-56.702, -68.313], [-56.986, -69.782], [-57.033, -70.018],
                                [-54.617, -78.596], [-53.1, -79.97], [-23.055, -76.037], [-21.396, -74.805],
                                [-20.544, -74.189], [5.047, -48.504]],
                            "c": true
                        },
                        "ix": 2
                    }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [130, 222], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }], "nm": "Shape 1", "np": 3, "cix": 2, "ix": 1, "mn": "ADBE Vector Group", "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 16,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [430, 312, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-0.938, 4.837], [25.121, 24.29], [23.709, -1.6], [0, 0], [-7.532, -63.17]],
                            "o": [[0, 0], [2.977, -15.348], [0, 0], [-18.67, 1.259], [0, 0], [0, 0]],
                            "v": [[41.582, 31.103], [43.672, 18.844], [26.184, -51.605], [-27.134, -36.751],
                                [-50.09, -36.765], [-43.773, 51.605]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [83, 133], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 17,
            "ty": 4,
            "nm": "Shape Layer 8",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [504.948, 478.451, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -4.159], [0, 0], [0, 0], [0, 0], [3.729, 0], [0, 0], [0.229, 1.592], [-2.814, 0],
                                [-1.906, 4.102], [-1.672, -0.557], [-1.004, -1.842], [-3.171, 0.558], [-1.315, 1.375]],
                            "o": [[0, 4.159], [0, 0], [0, 0], [0, 0], [0, 0], [-9.046, 0], [-0.955, -6.653], [8.08, 0],
                                [1.384, -2.976], [0, 0], [1.541, 2.828], [1.813, -0.319], [0, 0]],
                            "v": [[26.359, -0.579], [25.202, 10.354], [10.562, 10.354], [9.841, 7.91], [1.365, 10.354],
                                [-10.993, 10.354], [-25.404, 7.61], [-12.739, 1.259], [5.995, -6.624], [8.439, -9.797],
                                [10.948, -7.631], [18.582, -3.466], [23.53, -5.852]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.227450980392, 0.227450980392, 0.227450980392, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-51, 276], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 18,
            "ty": 4,
            "nm": "Shape Layer 7",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [448.25, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-1.127, -4.951], [-1.477, 2.068], [0, 0]],
                            "o": [[0, 0], [0.888, 3.9], [1.477, -2.068], [0, 0]],
                            "v": [[-8.644, -15.428], [-8.354, 11.144], [6.124, 13.36], [9.481, -13.799]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [22, 293], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 108,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 19,
            "ty": 4,
            "nm": "Shape Layer 10",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [441.5, 459, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-1.54, -3.863], [0, 0], [0, 0], [0, 0], [3.391, -2.128], [2.898, -0.661],
                                [0.802, 1.393], [-2.614, 1.042], [-0.252, 4.516], [-1.76, 0.102], [-2.031, -5.103],
                                [-2.027, 5.019]],
                            "o": [[1.54, 3.863], [0, 0], [0, 0], [0, 0], [-2.519, 1.581], [-10.139, 2.315],
                                [-3.352, -5.826], [7.505, -2.993], [0.183, -3.277], [0, 0], [0.133, -0.032], [0, 0]],
                            "v": [[22.289, -12.715], [26.112, -1.365], [15.015, 5.252], [12.837, 3.609], [6.998, 9.71],
                                [-3.988, 14.263], [-22.76, 14.064], [-10.062, 3.994], [1.134, -10.788], [2.229, -14.64],
                                [7.679, -10.114], [17.708, -16.565]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.227450980392, 0.227450980392, 0.227450980392, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [170, 288], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 109,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 20,
            "ty": 4,
            "nm": "Shape Layer 9",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [442.052, 453.168, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-1.752, -3.595], [-0.606, 2.469], [0, 0]],
                            "o": [[0, 0], [1.751, 3.596], [0.607, -2.468], [0, 0]],
                            "v": [[-10.315, -4.02], [-4.561, 10.347], [9.707, 7.043], [0.645, -13.943]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [179, 276], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 109,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 21,
            "ty": 4,
            "nm": "Shape Layer 11",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 41, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [539.732, 762, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[84.404, 0], [0, -4.532], [-84.414, 0], [0, 4.53]],
                            "o": [[-84.414, 0], [0, 4.53], [84.404, 0], [0, -4.532]],
                            "v": [[0.006, -8.206], [-152.835, 0.001], [0.006, 8.206], [152.835, 0.001]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.643137254902, 0.839215686275, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 109,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_6", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [485, 786.303, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-1.371, 3.314], [1.097, 0.481], [3.047, 0.777], [-1.72, -0.556], [2.694, -0.294],
                                [5.446, 0.371], [0.547, 1.336], [0.343, -2.286], [-0.893, -1.948], [-0.004, -3.063],
                                [-4.077, -0.115], [0, 0], [0, 0], [-2.706, -0.076], [-1.644, -1.213], [-1.514, -0.365],
                                [-4.736, 0.531]],
                            "o": [[0.803, -1.94], [-1.521, -0.667], [-3.046, -0.777], [1.721, 0.557], [-9.305, 1.016],
                                [-7.86, -0.536], [-1.3, 1.115], [-0.31, 2.066], [0.62, 1.344], [0, 0], [2.581, 0.073],
                                [0, 0], [0, 0], [2.705, 0.076], [1.288, 0.951], [4.368, 1.051], [9.972, -1.118]],
                            "v": [[29.586, 5.158], [29.156, 0.031], [19.188, -3.705], [13.167, -4.271], [9.732, 0.16],
                                [-12.719, -3.92], [-24.089, -13.847], [-29.535, -2.963], [-29.495, 4.824],
                                [-27.543, 11.924], [-21.804, 12.887], [-15.923, 11.978], [-15.971, 7.305],
                                [-11.214, 6.954], [-3.51, 9.592], [0.656, 12.515], [12.894, 13.316]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "shoes left",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [418.59, 781.047, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-1.706, 3.086], [0.984, 0.635], [2.799, 1.208], [-1.568, -0.799], [2.595, 0.097],
                                [5.128, 1.153], [0.357, 1.402], [0.603, -2.215], [-0.613, -2.059], [0.368, -3.035],
                                [-3.858, -0.702], [0, 0], [0, 0], [-2.561, -0.465], [-0.254, -0.289], [-1.368, -0.649],
                                [-4.563, -0.156]],
                            "o": [[0.998, -1.806], [-1.365, -0.881], [-2.799, -1.209], [1.567, 0.799], [-8.963, -0.335],
                                [-7.401, -1.663], [-1.37, 0.917], [-0.546, 2.002], [0.425, 1.421], [0, 0],
                                [2.443, 0.444], [0, 0], [0, 0], [2.56, 0.465], [1.05, 1.195], [3.62, 1.717],
                                [9.609, 0.33]],
                            "v": [[27.749, 10.381], [27.964, 5.24], [18.948, 0.102], [13.297, -1.326], [9.496, 2.568],
                                [-11.337, -4.71], [-22.384, -16.387], [-27.427, -6.184], [-28.335, 1.535],
                                [-27.343, 8.85], [-22.009, 10.631], [-16.312, 10.578], [-15.789, 5.941],
                                [-11.228, 6.28], [-4.196, 9.361], [-0.907, 13.409], [10.901, 16.056]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "hair",
            "parent": 9,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [16.559, 106.118, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr", "it": [{
                    "ind": 0, "ty": "sh", "ix": 1, "ks": {
                        "a": 0, "k": {
                            "i": [[0.102, 0.12], [2.309, 3.329], [-0.509, 3.828], [-1.053, 2.123], [6.689, 7.634],
                                [1.117, 3.128], [-2.746, 1.718], [-1.52, -1.37], [-0.541, 6.186], [5.196, 10.632],
                                [-0.427, 1.131], [-2.732, -4.265], [26.87, -0.669], [6.04, -3.064], [-0.404, -5.424],
                                [0.74, -3.614], [-0.683, -5.238], [-1.526, -7.644], [2.459, -5.927], [2.864, -5.551],
                                [-3.723, -7.055], [1.214, -8.636], [-11.995, -10.552], [-6.387, 0.989], [-5.672, 3.079],
                                [-6.49, 14.483], [-3.275, 8.405], [-0.503, 5.228], [2.643, 3.405]],
                            "o": [[-2.611, -3.094], [-2.18, -3.141], [0.314, -2.362], [4.685, -9.443], [-2.186, -2.496],
                                [-1.011, -2.831], [1.911, -1.195], [1.063, 0.958], [0.517, -5.927], [-0.616, -1.259],
                                [6.679, 1.272], [2.99, -3.346], [-6.788, 0.169], [-6.04, 3.063], [-9.516, 1.833],
                                [-1.057, 5.151], [1.008, 7.723], [1.242, 6.22], [-2.362, 5.693], [-3.638, 7.051],
                                [3.709, 7.029], [-0.395, 2.809], [4.907, 4.316], [6.387, -0.99], [10.037, -5.45],
                                [3.674, -8.201], [1.836, -4.711], [0.416, -4.321], [-0.097, -0.125]],
                            "v": [[41.11, 16.749], [32.186, 8.783], [29.111, -2.876], [31.687, -9.474],
                                [29.981, -32.656], [24.514, -40.908], [25.463, -48.541], [30.427, -48.492],
                                [35.102, -44.704], [32.67, -62.757], [29.604, -70.104], [41.298, -62.807],
                                [8.693, -89.956], [-11.149, -86.277], [-23.509, -72.379], [-35.103, -57.227],
                                [-35.668, -41.497], [-30.263, -18.76], [-31.137, 0.271], [-42.006, 14.613],
                                [-41.686, 37.446], [-34.546, 57.73], [-25.452, 84.074], [-4.918, 89.636],
                                [11.918, 84.322], [37.395, 60.87], [38.965, 43.121], [45.228, 29.276],
                                [41.407, 17.117]],
                            "c": true
                        }, "ix": 2
                    }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.129411764706, 0.356862745098, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-26, -69], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }], "nm": "Shape 1", "np": 3, "cix": 2, "ix": 1, "mn": "ADBE Vector Group", "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "nm": "sleeve",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.686]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p686_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [-0.095]
                    }, {
                        "i": {"x": [0.833], "y": [0.79]},
                        "o": {"x": [0.167], "y": [0.113]},
                        "n": ["0p833_0p79_0p167_0p113"],
                        "t": 1,
                        "s": [-0.095],
                        "e": [-0.357]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.138]},
                        "n": ["0p833_0p812_0p167_0p138"],
                        "t": 2,
                        "s": [-0.357],
                        "e": [-0.754]
                    }, {
                        "i": {"x": [0.833], "y": [0.822]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p822_0p167_0p15"],
                        "t": 3,
                        "s": [-0.754],
                        "e": [-1.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p828_0p167_0p157"],
                        "t": 4,
                        "s": [-1.254],
                        "e": [-1.823]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.162]},
                        "n": ["0p833_0p833_0p167_0p162"],
                        "t": 5,
                        "s": [-1.823],
                        "e": [-2.428]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p837_0p167_0p166"],
                        "t": 6,
                        "s": [-2.428],
                        "e": [-3.037]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p842_0p167_0p171"],
                        "t": 7,
                        "s": [-3.037],
                        "e": [-3.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p849_0p167_0p177"],
                        "t": 8,
                        "s": [-3.617],
                        "e": [-4.136]
                    }, {
                        "i": {"x": [0.833], "y": [0.858]},
                        "o": {"x": [0.167], "y": [0.185]},
                        "n": ["0p833_0p858_0p167_0p185"],
                        "t": 9,
                        "s": [-4.136],
                        "e": [-4.56]
                    }, {
                        "i": {"x": [0.833], "y": [0.878]},
                        "o": {"x": [0.167], "y": [0.202]},
                        "n": ["0p833_0p878_0p167_0p202"],
                        "t": 10,
                        "s": [-4.56],
                        "e": [-4.857]
                    }, {
                        "i": {"x": [0.833], "y": [0.94]},
                        "o": {"x": [0.167], "y": [0.264]},
                        "n": ["0p833_0p94_0p167_0p264"],
                        "t": 11,
                        "s": [-4.857],
                        "e": [-4.995]
                    }, {
                        "i": {"x": [0.833], "y": [0.531]},
                        "o": {"x": [0.167], "y": [-0.209]},
                        "n": ["0p833_0p531_0p167_-0p209"],
                        "t": 12,
                        "s": [-4.995],
                        "e": [-4.955]
                    }, {
                        "i": {"x": [0.833], "y": [0.779]},
                        "o": {"x": [0.167], "y": [0.101]},
                        "n": ["0p833_0p779_0p167_0p101"],
                        "t": 13,
                        "s": [-4.955],
                        "e": [-4.775]
                    }, {
                        "i": {"x": [0.833], "y": [0.807]},
                        "o": {"x": [0.167], "y": [0.134]},
                        "n": ["0p833_0p807_0p167_0p134"],
                        "t": 14,
                        "s": [-4.775],
                        "e": [-4.476]
                    }, {
                        "i": {"x": [0.833], "y": [0.818]},
                        "o": {"x": [0.167], "y": [0.147]},
                        "n": ["0p833_0p818_0p167_0p147"],
                        "t": 15,
                        "s": [-4.476],
                        "e": [-4.083]
                    }, {
                        "i": {"x": [0.833], "y": [0.825]},
                        "o": {"x": [0.167], "y": [0.154]},
                        "n": ["0p833_0p825_0p167_0p154"],
                        "t": 16,
                        "s": [-4.083],
                        "e": [-3.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.159]},
                        "n": ["0p833_0p829_0p167_0p159"],
                        "t": 17,
                        "s": [-3.617],
                        "e": [-3.104]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p833_0p167_0p163"],
                        "t": 18,
                        "s": [-3.104],
                        "e": [-2.564]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p836_0p167_0p166"],
                        "t": 19,
                        "s": [-2.564],
                        "e": [-2.022]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p84_0p167_0p17"],
                        "t": 20,
                        "s": [-2.022],
                        "e": [-1.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p845_0p167_0p174"],
                        "t": 21,
                        "s": [-1.5],
                        "e": [-1.021]
                    }, {
                        "i": {"x": [0.833], "y": [0.851]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p851_0p167_0p18"],
                        "t": 22,
                        "s": [-1.021],
                        "e": [-0.609]
                    }, {
                        "i": {"x": [0.833], "y": [0.862]},
                        "o": {"x": [0.167], "y": [0.19]},
                        "n": ["0p833_0p862_0p167_0p19"],
                        "t": 23,
                        "s": [-0.609],
                        "e": [-0.286]
                    }, {
                        "i": {"x": [0.833], "y": [0.887]},
                        "o": {"x": [0.167], "y": [0.211]},
                        "n": ["0p833_0p887_0p167_0p211"],
                        "t": 24,
                        "s": [-0.286],
                        "e": [-0.075]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.316]},
                        "n": ["0p833_0p917_0p167_0p316"],
                        "t": 25,
                        "s": [-0.075],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.006]},
                        "n": ["0p833_1_0p167_0p006"],
                        "t": 26,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 27,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 28,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 29,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 30,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 31,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 32,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 33,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 34,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 35,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 36,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 37,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 38,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 39,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 40,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 41,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 42,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 43,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 44,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 45,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 46,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 47,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 48,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1.008]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1p008_0p167_0"],
                        "t": 49,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.686]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p686_0p167_0p083"],
                        "t": 50,
                        "s": [0],
                        "e": [-0.095]
                    }, {
                        "i": {"x": [0.833], "y": [0.79]},
                        "o": {"x": [0.167], "y": [0.113]},
                        "n": ["0p833_0p79_0p167_0p113"],
                        "t": 51,
                        "s": [-0.095],
                        "e": [-0.357]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.138]},
                        "n": ["0p833_0p812_0p167_0p138"],
                        "t": 52,
                        "s": [-0.357],
                        "e": [-0.754]
                    }, {
                        "i": {"x": [0.833], "y": [0.822]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p822_0p167_0p15"],
                        "t": 53,
                        "s": [-0.754],
                        "e": [-1.254]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p828_0p167_0p157"],
                        "t": 54,
                        "s": [-1.254],
                        "e": [-1.823]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.162]},
                        "n": ["0p833_0p833_0p167_0p162"],
                        "t": 55,
                        "s": [-1.823],
                        "e": [-2.428]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p837_0p167_0p166"],
                        "t": 56,
                        "s": [-2.428],
                        "e": [-3.037]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p842_0p167_0p171"],
                        "t": 57,
                        "s": [-3.037],
                        "e": [-3.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p849_0p167_0p177"],
                        "t": 58,
                        "s": [-3.617],
                        "e": [-4.136]
                    }, {
                        "i": {"x": [0.833], "y": [0.858]},
                        "o": {"x": [0.167], "y": [0.185]},
                        "n": ["0p833_0p858_0p167_0p185"],
                        "t": 59,
                        "s": [-4.136],
                        "e": [-4.56]
                    }, {
                        "i": {"x": [0.833], "y": [0.878]},
                        "o": {"x": [0.167], "y": [0.202]},
                        "n": ["0p833_0p878_0p167_0p202"],
                        "t": 60,
                        "s": [-4.56],
                        "e": [-4.857]
                    }, {
                        "i": {"x": [0.833], "y": [0.94]},
                        "o": {"x": [0.167], "y": [0.264]},
                        "n": ["0p833_0p94_0p167_0p264"],
                        "t": 61,
                        "s": [-4.857],
                        "e": [-4.995]
                    }, {
                        "i": {"x": [0.833], "y": [0.531]},
                        "o": {"x": [0.167], "y": [-0.209]},
                        "n": ["0p833_0p531_0p167_-0p209"],
                        "t": 62,
                        "s": [-4.995],
                        "e": [-4.955]
                    }, {
                        "i": {"x": [0.833], "y": [0.779]},
                        "o": {"x": [0.167], "y": [0.101]},
                        "n": ["0p833_0p779_0p167_0p101"],
                        "t": 63,
                        "s": [-4.955],
                        "e": [-4.775]
                    }, {
                        "i": {"x": [0.833], "y": [0.807]},
                        "o": {"x": [0.167], "y": [0.134]},
                        "n": ["0p833_0p807_0p167_0p134"],
                        "t": 64,
                        "s": [-4.775],
                        "e": [-4.476]
                    }, {
                        "i": {"x": [0.833], "y": [0.818]},
                        "o": {"x": [0.167], "y": [0.147]},
                        "n": ["0p833_0p818_0p167_0p147"],
                        "t": 65,
                        "s": [-4.476],
                        "e": [-4.083]
                    }, {
                        "i": {"x": [0.833], "y": [0.825]},
                        "o": {"x": [0.167], "y": [0.154]},
                        "n": ["0p833_0p825_0p167_0p154"],
                        "t": 66,
                        "s": [-4.083],
                        "e": [-3.617]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.159]},
                        "n": ["0p833_0p829_0p167_0p159"],
                        "t": 67,
                        "s": [-3.617],
                        "e": [-3.104]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p833_0p167_0p163"],
                        "t": 68,
                        "s": [-3.104],
                        "e": [-2.564]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p836_0p167_0p166"],
                        "t": 69,
                        "s": [-2.564],
                        "e": [-2.022]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p84_0p167_0p17"],
                        "t": 70,
                        "s": [-2.022],
                        "e": [-1.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p845_0p167_0p174"],
                        "t": 71,
                        "s": [-1.5],
                        "e": [-1.021]
                    }, {
                        "i": {"x": [0.833], "y": [0.851]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p851_0p167_0p18"],
                        "t": 72,
                        "s": [-1.021],
                        "e": [-0.609]
                    }, {
                        "i": {"x": [0.833], "y": [0.862]},
                        "o": {"x": [0.167], "y": [0.19]},
                        "n": ["0p833_0p862_0p167_0p19"],
                        "t": 73,
                        "s": [-0.609],
                        "e": [-0.286]
                    }, {
                        "i": {"x": [0.833], "y": [0.887]},
                        "o": {"x": [0.167], "y": [0.211]},
                        "n": ["0p833_0p887_0p167_0p211"],
                        "t": 74,
                        "s": [-0.286],
                        "e": [-0.075]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.316]},
                        "n": ["0p833_0p917_0p167_0p316"],
                        "t": 75,
                        "s": [-0.075],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.006]},
                        "n": ["0p833_1_0p167_0p006"],
                        "t": 76,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 77,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 78,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 79,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 80,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 81,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 82,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 83,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 84,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 85,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 86,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 87,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 88,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 89,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 90,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 91,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 92,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 93,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 94,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 95,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 96,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 97,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 98,
                        "s": [0],
                        "e": [0]
                    }, {"t": 99}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [434, 300, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-7.548, -4.457], [0, 0], [2.596, -9.227], [20.73, -2.25], [0, 0]],
                            "o": [[0, 0], [10.083, 5.955], [0, 0], [0, 0], [-21.429, 2.325], [0, 0]],
                            "v": [[20.017, -62.614], [35.428, -59.972], [66.557, -50.513], [65.578, -17.242],
                                [37.861, -16.092], [-12.174, 0.429]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 5,
            "ty": 4,
            "nm": "blouse",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [434, 300, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.289, -12.324], [-1.134, -13.923], [0.206, -2.372], [1.083, -0.98], [5.259, -0.928],
                                [7.632, -1.495], [0, 4.383], [-0.258, 5.982], [2.424, 13.304], [-3.506, 31.198], [0, 0],
                                [-4.022, -0.67], [-4.692, -18.564], [-1.186, -3.352], [2.114, -5.672]],
                            "o": [[-0.877, 8.147], [0.206, 2.372], [-0.103, 1.393], [-2.475, 2.424], [-17.119, 3.094],
                                [-7.168, 1.444], [0, -3.042], [0.567, -13.097], [-5.053, -27.897], [0, 0],
                                [4.022, 0.67], [2.939, 0.465], [2.836, 4.795], [2.32, 6.24], [-4.022, 10.984]],
                            "v": [[36.792, 26.015], [37.926, 60.615], [39.061, 69.742], [37.256, 73.3],
                                [24.726, 78.147], [-27.098, 80.674], [-41.639, 75.311], [-38.597, 64.585],
                                [-40.866, 8.379], [-43.805, -67.268], [8.483, -82.118], [14.773, -68.505],
                                [38.957, -33.75], [44.991, -21.684], [44.939, -5.183]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "nm": "dress",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [432, 301, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-3.466, -26.312], [-1.086, -6.961], [0.297, -18.062], [-21.243, 12.512],
                                [1.672, 30.256], [0.41, 3.801]],
                            "o": [[-17.222, 14.863], [0.918, 6.965], [2.468, 15.837], [23.112, 16.761],
                                [2.361, -21.826], [-2.524, -45.641], [0, 0]],
                            "v": [[-32.866, -85.159], [-53.231, -19.328], [-44.195, 13.736], [-36.953, 70.945],
                                [54.336, 73.728], [54.584, 4.293], [31.29, -87.706]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.349019607843, 0.290196078431, 0.372549019608, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [4, 154], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "arm",
            "parent": 4,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [0, 1, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-5.72, 1.122], [0, 0], [8.779, 2.55], [6.964, -4.236], [0, 0]],
                            "o": [[23.239, -0.268], [22.538, -4.422], [0, 0], [-14.605, 11.85], [-4.514, 2.746],
                                [0, 0]],
                            "v": [[-71.664, 30.517], [-1.087, 25.147], [79.035, -18.751], [71.351, -30.517],
                                [-2.508, 0.586], [-80.13, 0.339]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [102, -50], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 8,
            "ty": 4,
            "nm": "hand",
            "parent": 4,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [188.333, -84.854, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-2.613, 4.179], [0.182, 1.581], [4.57, -1.323], [-1.276, 5.358],
                                [2.174, -3.561], [5.944, -1.329], [-0.982, 2.087], [1.036, 0.384], [0.605, -0.514],
                                [2.302, -1.955], [1.154, -3.134], [-2.275, 0.741]],
                            "o": [[3.094, 0.898], [0.839, -1.342], [1.526, -1.796], [4.708, -3.151], [0.427, -1.795],
                                [-3.021, 4.951], [-0.382, -0.984], [1.089, -2.313], [-0.782, -0.29], [-2.302, 1.955],
                                [-2.532, 2.15], [-0.193, 0.522], [0, 0]],
                            "v": [[-7.645, 16.422], [8.19, 9.63], [9.374, 5.136], [8.96, -1.689], [16.138, -14.984],
                                [12.31, -13.759], [-1.344, -4.644], [0.573, -8.032], [-0.086, -12.34], [-3.25, -9.476],
                                [-10.157, -3.61], [-15.166, 3.21], [-14.29, 8.297]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 9,
            "ty": 4,
            "nm": "head",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [421.826, 237.02, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 47.138, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.116, 2.137], [19.431, -1.923], [-0.236, -14.56], [-3.799, -10.073], [0, 0], [0, 0],
                                [-2.628, 4.976], [-6.689, 5.405], [1.75, 7.688], [1.677, 3.372]],
                            "o": [[-5.123, -9.814], [-19.431, 1.923], [0.236, 14.559], [3.798, 10.073], [0, 0], [0, 0],
                                [1.47, -2.784], [2.923, -2.362], [-2.616, -11.495], [-1.204, -2.421]],
                            "v": [[28.694, -25.246], [-3.988, -47.017], [-39.822, -18.281], [-25.928, 23.89],
                                [-23.74, 48.94], [19.333, 47.391], [20.841, 29.253], [34.774, 15.849], [38.308, 0.871],
                                [33.074, -19.047]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882353001015, 0.741175991881, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "board line",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [434, 300, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.554, -0.264], [0, 0], [0.264, 1.554], [0, 0], [-1.554, 0.264], [0, 0],
                                [-0.264, -1.554], [0, 0]],
                            "o": [[0, 0], [-1.554, 0.264], [0, 0], [-0.264, -1.554], [0, 0], [1.554, -0.264], [0, 0],
                                [0.264, 1.554]],
                            "v": [[8.546, 41.175], [5.533, 41.679], [2.241, 39.344], [-10.881, -37.884],
                                [-8.546, -41.175], [-5.532, -41.679], [-2.241, -39.344], [10.882, 37.884]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.298039215686, 0.254901960784, 0.6, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "rp",
                    "c": {"a": 0, "k": 10, "ix": 1},
                    "o": {"a": 0, "k": 0, "ix": 2},
                    "m": 1,
                    "ix": 4,
                    "tr": {
                        "ty": "tr",
                        "p": {"a": 0, "k": [18, -3.5], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 4},
                        "so": {"a": 0, "k": 100, "ix": 5},
                        "eo": {"a": 0, "k": 100, "ix": 6},
                        "nm": "Transform"
                    },
                    "nm": "Repeater 1",
                    "mn": "ADBE Vector Filter - Repeater",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-96, 44], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 11,
            "ty": 4,
            "nm": "board inner",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [420.5, 328.5, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.804, -0.307], [0, 0], [0.307, 1.804], [0, 0], [-1.804, 0.306], [0, 0],
                                [-0.307, -1.804], [0, 0]],
                            "o": [[0, 0], [-1.804, 0.307], [0, 0], [-0.307, -1.804], [0, 0], [1.804, -0.307], [0, 0],
                                [0.307, 1.804]],
                            "v": [[98.344, 32.399], [-82, 63.792], [-85.821, 61.08], [-101.056, -28.577],
                                [-98.344, -32.398], [82, -63.791], [85.821, -61.08], [101.056, 28.577]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.223529411765, 0.160784313725, 0.498039215686, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 12,
            "ty": 4,
            "nm": "board",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [420.5, 328.5, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0.437, -0.074], [0, 0], [0.074, 0.436], [0, 0], [-0.437, 0.074], [0, 0],
                                [-0.074, -0.436], [0, 0]],
                            "o": [[0, 0], [-0.437, 0.074], [0, 0], [-0.074, -0.437], [0, 0], [0.436, -0.074], [0, 0],
                                [0.074, 0.436]],
                            "v": [[107.822, 38.442], [-89.073, 71.898], [-89.997, 71.242], [-108.478, -37.517],
                                [-107.822, -38.442], [89.073, -71.898], [89.998, -71.242], [108.478, 37.517]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.172549019608, 0.117647058824, 0.337254901961, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 13,
            "ty": 4,
            "nm": "back arm",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [427, 300, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [3.227, -21.056], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[1.156, -25.715], [-11.946, 21.956], [11.946, 25.715], [11.946, -8.124]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.976470588235, 0.698039215686, 0.325490196078, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-55, -38], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 14,
            "ty": 4,
            "nm": "leg left shadow",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0.353, -0.065], [0, 0], [-1.124, -18.188], [-0.487, -9.455], [-0.188, -0.662],
                                [-0.656, 7.546], [1.773, 43.212]],
                            "o": [[0, 0], [9.367, 14.999], [0.583, 9.447], [0.035, 0.67], [2.528, -14.088],
                                [1.835, -21.122], [-0.352, 0.023]],
                            "v": [[8.287, -65.453], [-11.902, -15.931], [4.225, 35.086], [4.689, 63.482],
                                [5.026, 65.53], [10.068, 32.29], [9.342, -65.53]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.988235294118, 0.796078431373, 0.58431372549, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-11, 98], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 15,
            "ty": 4,
            "nm": "legs left",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [434, 300, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[3.277, 72.042], [0, 0], [-9.324, -29.702], [-2.474, -6.923], [0.942, -6.773],
                                [2.556, -19.925], [2.333, -20.694], [-0.882, 7.005], [-2.597, 29.897]],
                            "o": [[0, 0], [0, 0], [4.586, 14.606], [2.311, 6.467], [-0.942, 6.773], [-3.074, 23.963],
                                [-2.332, 20.694], [7.529, -59.858], [2.597, -29.898]],
                            "v": [[27.966, -153.257], [-22.138, -193.523], [-24.566, -135.491], [-22.381, -103.579],
                                [-4.026, 1.591], [-15.01, 47.356], [-17.941, 169.793], [-0.261, 186.518],
                                [31.292, 3.254]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-16, 275.5], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 16,
            "ty": 4,
            "nm": "feet  left",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [419.14, 765.657, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-1.296, -2.729], [-4.6, -2.79], [-0.874, -1.298], [1.474, -1.216],
                                [4.421, 0.621], [8.342, 0.811], [4.021, 1.938], [-0.259, 3.464], [-2.278, 4.466],
                                [0, 0]],
                            "o": [[0, 0], [1.297, 2.728], [4.827, 2.927], [0.873, 1.298], [-3.806, 3.139],
                                [-14.333, -2.016], [-1.44, -0.14], [-4.021, -1.938], [0.446, -5.976], [2.278, -4.466],
                                [0, 0]],
                            "v": [[2.36, -25.336], [-0.4, -0.856], [10.112, 12.573], [26.43, 20.162], [25.93, 24.596],
                                [11.007, 28.76], [-13.295, 19.654], [-21.366, 19.763], [-27.145, 10.734],
                                [-21.19, -4.7], [-17.716, -29.382]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 17,
            "ty": 4,
            "nm": "right leg",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [452.184, 584, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.067, 1.156], [5.361, 2.175], [1.228, 2.886], [-0.092, 6.168], [-0.885, 24.871],
                                [17.326, 69.198], [0, 0], [-6.116, -30.758], [-2.579, -6.923], [0, 0], [0.154, -26.541],
                                [-2.169, -26.013], [1.459, -3.891], [-0.295, -5.989], [-4.401, -1.316], [-1.506, 0.077],
                                [-15.068, 0.157], [-3.536, 3.684]],
                            "o": [[-1.067, -1.155], [-5.107, -2.073], [-1.227, -2.887], [0.635, -42.491],
                                [1.538, -43.231], [0, 0], [0, 0], [3.548, 17.845], [2.578, 6.922], [0, 0],
                                [-0.132, 22.655], [0.275, 5.084], [-1.789, 4.769], [0.172, 3.473], [4.4, 1.316],
                                [8.723, -0.449], [4.647, -0.049], [1.369, -1.426]],
                            "v": [[92.706, -128.675], [74.88, -133.742], [62.316, -145.471], [61.306, -169.217],
                                [69.626, -340.467], [47.777, -497.44], [-14.61, -544.323], [-25.505, -481.16],
                                [-4.52, -453.793], [36.644, -335.214], [29.447, -284.381], [42.447, -167.49],
                                [42.263, -146.348], [36.144, -129.963], [43.261, -121.884], [51.588, -123.205],
                                [77.857, -117.834], [92.751, -124.205]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 0.882352941176, 0.741176470588, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-31.25, 331], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 18,
            "ty": 4,
            "nm": "floor",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 793, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[58.122, 0], [0, -6.507], [-58.114, 0], [0, 6.504]],
                            "o": [[-58.114, 0], [0, 6.504], [58.122, 0], [0, -6.507]],
                            "v": [[-0.004, -11.782], [-105.231, 0.001], [-0.004, 11.782], [105.231, 0.001]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.643137254902, 0.839215686275, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_7", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "vertical line mask 2",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [554.75, 574, 0], "ix": 2},
                "a": {"a": 0, "k": [-118.25, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.541, 0], [0, 0], [0, -1.542], [0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0]],
                            "o": [[0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0], [1.541, 0], [0, 0], [0, -1.542]],
                            "v": [[117.184, -21.233], [-117.185, -21.233], [-119.976, -18.442], [-119.976, 18.442],
                                [-117.185, 21.233], [117.184, 21.233], [119.976, 18.442], [119.976, -18.442]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.458823529412, 0.674509803922, 0.988235294118, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {
                        "a": 1,
                        "k": [{
                            "i": {"x": 0.833, "y": 0.671},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p671_0p167_0p167",
                            "t": 0,
                            "s": [-267, 0],
                            "e": [-266.328, 0],
                            "to": [0.11206562817097, 0],
                            "ti": [-0.44228708744049, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.784},
                            "o": {"x": 0.167, "y": 0.112},
                            "n": "0p833_0p784_0p167_0p112",
                            "t": 1,
                            "s": [-266.328, 0],
                            "e": [-264.346, 0],
                            "to": [0.44228708744049, 0],
                            "ti": [-0.85625571012497, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.805},
                            "o": {"x": 0.167, "y": 0.136},
                            "n": "0p833_0p805_0p167_0p136",
                            "t": 2,
                            "s": [-264.346, 0],
                            "e": [-261.19, 0],
                            "to": [0.85625571012497, 0],
                            "ti": [-1.22894239425659, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.815},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p815_0p167_0p146",
                            "t": 3,
                            "s": [-261.19, 0],
                            "e": [-256.973, 0],
                            "to": [1.22894239425659, 0],
                            "ti": [-1.56244695186615, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.819},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p819_0p167_0p151",
                            "t": 4,
                            "s": [-256.973, 0],
                            "e": [-251.815, 0],
                            "to": [1.56244695186615, 0],
                            "ti": [-1.86193656921387, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p823_0p167_0p155",
                            "t": 5,
                            "s": [-251.815, 0],
                            "e": [-245.801, 0],
                            "to": [1.86193656921387, 0],
                            "ti": [-2.12595653533936, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 6,
                            "s": [-245.801, 0],
                            "e": [-239.06, 0],
                            "to": [2.12595653533936, 0],
                            "ti": [-2.34755873680115, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.827},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p827_0p167_0p16",
                            "t": 7,
                            "s": [-239.06, 0],
                            "e": [-231.716, 0],
                            "to": [2.34755873680115, 0],
                            "ti": [-2.53374981880188, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.161},
                            "n": "0p833_0p829_0p167_0p161",
                            "t": 8,
                            "s": [-231.716, 0],
                            "e": [-223.857, 0],
                            "to": [2.53374981880188, 0],
                            "ti": [-2.68486714363098, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 9,
                            "s": [-223.857, 0],
                            "e": [-215.606, 0],
                            "to": [2.68486714363098, 0],
                            "ti": [-2.79672288894653, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 10,
                            "s": [-215.606, 0],
                            "e": [-207.077, 0],
                            "to": [2.79672288894653, 0],
                            "ti": [-2.87147164344788, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 11,
                            "s": [-207.077, 0],
                            "e": [-198.378, 0],
                            "to": [2.87147164344788, 0],
                            "ti": [-2.90900015830994, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 12,
                            "s": [-198.378, 0],
                            "e": [-189.623, 0],
                            "to": [2.90900015830994, 0],
                            "ti": [-2.90909266471863, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 13,
                            "s": [-189.623, 0],
                            "e": [-180.923, 0],
                            "to": [2.90909266471863, 0],
                            "ti": [-2.87142086029053, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 14,
                            "s": [-180.923, 0],
                            "e": [-172.394, 0],
                            "to": [2.87142086029053, 0],
                            "ti": [-2.7968316078186, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.169},
                            "n": "0p833_0p837_0p167_0p169",
                            "t": 15,
                            "s": [-172.394, 0],
                            "e": [-164.142, 0],
                            "to": [2.7968316078186, 0],
                            "ti": [-2.68492078781128, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.171},
                            "n": "0p833_0p839_0p167_0p171",
                            "t": 16,
                            "s": [-164.142, 0],
                            "e": [-156.285, 0],
                            "to": [2.68492078781128, 0],
                            "ti": [-2.53523468971252, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 17,
                            "s": [-156.285, 0],
                            "e": [-148.931, 0],
                            "to": [2.53523468971252, 0],
                            "ti": [-2.34836030006409, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 18,
                            "s": [-148.931, 0],
                            "e": [-142.195, 0],
                            "to": [2.34836030006409, 0],
                            "ti": [-2.12398409843445, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.177},
                            "n": "0p833_0p845_0p167_0p177",
                            "t": 19,
                            "s": [-142.195, 0],
                            "e": [-136.187, 0],
                            "to": [2.12398409843445, 0],
                            "ti": [-1.86286532878876, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.849},
                            "o": {"x": 0.167, "y": 0.18},
                            "n": "0p833_0p849_0p167_0p18",
                            "t": 20,
                            "s": [-136.187, 0],
                            "e": [-131.017, 0],
                            "to": [1.86286532878876, 0],
                            "ti": [-1.5640230178833, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.186},
                            "n": "0p833_0p854_0p167_0p186",
                            "t": 21,
                            "s": [-131.017, 0],
                            "e": [-126.803, 0],
                            "to": [1.5640230178833, 0],
                            "ti": [-1.22721469402313, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.864},
                            "o": {"x": 0.167, "y": 0.195},
                            "n": "0p833_0p864_0p167_0p195",
                            "t": 22,
                            "s": [-126.803, 0],
                            "e": [-123.654, 0],
                            "to": [1.22721469402313, 0],
                            "ti": [-0.85341417789459, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.888},
                            "o": {"x": 0.167, "y": 0.216},
                            "n": "0p833_0p888_0p167_0p216",
                            "t": 23,
                            "s": [-123.654, 0],
                            "e": [-121.682, 0],
                            "to": [0.85341417789459, 0],
                            "ti": [-0.44235345721245, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.861},
                            "o": {"x": 0.167, "y": 0.324},
                            "n": "0p833_0p861_0p167_0p324",
                            "t": 24,
                            "s": [-121.682, 0],
                            "e": [-121, 0],
                            "to": [0.44235345721245, 0],
                            "ti": [-0.18630476295948, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.755},
                            "o": {"x": 0.167, "y": 0.207},
                            "n": "0p833_0p755_0p167_0p207",
                            "t": 25,
                            "s": [-121, 0],
                            "e": [-120.564, 0],
                            "to": [0.18630476295948, 0],
                            "ti": [0.07580041140318, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.728},
                            "o": {"x": 0.167, "y": 0.126},
                            "n": "0p833_0p728_0p167_0p126",
                            "t": 26,
                            "s": [-120.564, 0],
                            "e": [-121.455, 0],
                            "to": [-0.07580041140318, 0],
                            "ti": [0.48373630642891, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.805},
                            "o": {"x": 0.167, "y": 0.12},
                            "n": "0p833_0p805_0p167_0p12",
                            "t": 27,
                            "s": [-121.455, 0],
                            "e": [-123.467, 0],
                            "to": [-0.48373630642891, 0],
                            "ti": [0.78525948524475, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.815},
                            "o": {"x": 0.167, "y": 0.145},
                            "n": "0p833_0p815_0p167_0p145",
                            "t": 28,
                            "s": [-123.467, 0],
                            "e": [-126.166, 0],
                            "to": [-0.78525948524475, 0],
                            "ti": [0.9986863732338, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.819},
                            "o": {"x": 0.167, "y": 0.152},
                            "n": "0p833_0p819_0p167_0p152",
                            "t": 29,
                            "s": [-126.166, 0],
                            "e": [-129.459, 0],
                            "to": [-0.9986863732338, 0],
                            "ti": [1.18900728225708, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p823_0p167_0p155",
                            "t": 30,
                            "s": [-129.459, 0],
                            "e": [-133.3, 0],
                            "to": [-1.18900728225708, 0],
                            "ti": [1.35800099372864, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 31,
                            "s": [-133.3, 0],
                            "e": [-137.607, 0],
                            "to": [-1.35800099372864, 0],
                            "ti": [1.49886786937714, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.827},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p827_0p167_0p16",
                            "t": 32,
                            "s": [-137.607, 0],
                            "e": [-142.294, 0],
                            "to": [-1.49886786937714, 0],
                            "ti": [1.61795628070831, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.161},
                            "n": "0p833_0p829_0p167_0p161",
                            "t": 33,
                            "s": [-142.294, 0],
                            "e": [-147.315, 0],
                            "to": [-1.61795628070831, 0],
                            "ti": [1.71512496471405, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 34,
                            "s": [-147.315, 0],
                            "e": [-152.584, 0],
                            "to": [-1.71512496471405, 0],
                            "ti": [1.78573346138, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 35,
                            "s": [-152.584, 0],
                            "e": [-158.029, 0],
                            "to": [-1.78573346138, 0],
                            "ti": [1.83317577838898, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 36,
                            "s": [-158.029, 0],
                            "e": [-163.583, 0],
                            "to": [-1.83317577838898, 0],
                            "ti": [1.85709893703461, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 37,
                            "s": [-163.583, 0],
                            "e": [-169.172, 0],
                            "to": [-1.85709893703461, 0],
                            "ti": [1.85715389251709, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 38,
                            "s": [-169.172, 0],
                            "e": [-174.726, 0],
                            "to": [-1.85715389251709, 0],
                            "ti": [1.83439326286316, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 39,
                            "s": [-174.726, 0],
                            "e": [-180.178, 0],
                            "to": [-1.83439326286316, 0],
                            "ti": [1.78696739673615, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p837_0p167_0p17",
                            "t": 40,
                            "s": [-180.178, 0],
                            "e": [-185.448, 0],
                            "to": [-1.78696739673615, 0],
                            "ti": [1.71448278427124, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.171},
                            "n": "0p833_0p839_0p167_0p171",
                            "t": 41,
                            "s": [-185.448, 0],
                            "e": [-190.465, 0],
                            "to": [-1.71448278427124, 0],
                            "ti": [1.61925256252289, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 42,
                            "s": [-190.465, 0],
                            "e": [-195.164, 0],
                            "to": [-1.61925256252289, 0],
                            "ti": [1.49962508678436, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 43,
                            "s": [-195.164, 0],
                            "e": [-199.463, 0],
                            "to": [-1.49962508678436, 0],
                            "ti": [1.35651051998138, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.177},
                            "n": "0p833_0p845_0p167_0p177",
                            "t": 44,
                            "s": [-199.463, 0],
                            "e": [-203.303, 0],
                            "to": [-1.35651051998138, 0],
                            "ti": [1.18984234333038, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.849},
                            "o": {"x": 0.167, "y": 0.18},
                            "n": "0p833_0p849_0p167_0p18",
                            "t": 45,
                            "s": [-203.303, 0],
                            "e": [-206.602, 0],
                            "to": [-1.18984234333038, 0],
                            "ti": [0.99858325719833, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.185},
                            "n": "0p833_0p854_0p167_0p185",
                            "t": 46,
                            "s": [-206.602, 0],
                            "e": [-209.294, 0],
                            "to": [-0.99858325719833, 0],
                            "ti": [0.78387492895126, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.864},
                            "o": {"x": 0.167, "y": 0.195},
                            "n": "0p833_0p864_0p167_0p195",
                            "t": 47,
                            "s": [-209.294, 0],
                            "e": [-211.305, 0],
                            "to": [-0.78387492895126, 0],
                            "ti": [0.54647928476334, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.889},
                            "o": {"x": 0.167, "y": 0.215},
                            "n": "0p833_0p889_0p167_0p215",
                            "t": 48,
                            "s": [-211.305, 0],
                            "e": [-212.573, 0],
                            "to": [-0.54647928476334, 0],
                            "ti": [0.28251785039902, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.878},
                            "o": {"x": 0.167, "y": 0.331},
                            "n": "0p833_0p878_0p167_0p331",
                            "t": 49,
                            "s": [-212.573, 0],
                            "e": [-213, 0],
                            "to": [-0.28251785039902, 0],
                            "ti": [0.10443960130215, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.687},
                            "o": {"x": 0.167, "y": 0.261},
                            "n": "0p833_0p687_0p167_0p261",
                            "t": 50,
                            "s": [-213, 0],
                            "e": [-213.2, 0],
                            "to": [-0.10443960130215, 0],
                            "ti": [0.12507145106792, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.866},
                            "o": {"x": 0.167, "y": 0.114},
                            "n": "0p833_0p866_0p167_0p114",
                            "t": 51,
                            "s": [-213.2, 0],
                            "e": [-213.75, 0],
                            "to": [-0.12507145106792, 0],
                            "ti": [0.03799314424396, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.624},
                            "o": {"x": 0.167, "y": 0.219},
                            "n": "0p833_0p624_0p167_0p219",
                            "t": 52,
                            "s": [-213.75, 0],
                            "e": [-213.428, 0],
                            "to": [-0.03799314424396, 0],
                            "ti": [-0.2512885928154, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.815},
                            "o": {"x": 0.167, "y": 0.107},
                            "n": "0p833_0p815_0p167_0p107",
                            "t": 53,
                            "s": [-213.428, 0],
                            "e": [-212.243, 0],
                            "to": [0.2512885928154, 0],
                            "ti": [-0.43925940990448, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.819},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p819_0p167_0p151",
                            "t": 54,
                            "s": [-212.243, 0],
                            "e": [-210.792, 0],
                            "to": [0.43925940990448, 0],
                            "ti": [-0.52375674247742, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.824},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p824_0p167_0p155",
                            "t": 55,
                            "s": [-210.792, 0],
                            "e": [-209.1, 0],
                            "to": [0.52375674247742, 0],
                            "ti": [-0.59709775447845, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.825},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p825_0p167_0p158",
                            "t": 56,
                            "s": [-209.1, 0],
                            "e": [-207.21, 0],
                            "to": [0.59709775447845, 0],
                            "ti": [-0.66005885601044, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.828},
                            "o": {"x": 0.167, "y": 0.159},
                            "n": "0p833_0p828_0p167_0p159",
                            "t": 57,
                            "s": [-207.21, 0],
                            "e": [-205.14, 0],
                            "to": [0.66005885601044, 0],
                            "ti": [-0.71294873952866, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.161},
                            "n": "0p833_0p829_0p167_0p161",
                            "t": 58,
                            "s": [-205.14, 0],
                            "e": [-202.932, 0],
                            "to": [0.71294873952866, 0],
                            "ti": [-0.75487846136093, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.83},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p83_0p167_0p163",
                            "t": 59,
                            "s": [-202.932, 0],
                            "e": [-200.611, 0],
                            "to": [0.75487846136093, 0],
                            "ti": [-0.78714275360107, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 60,
                            "s": [-200.611, 0],
                            "e": [-198.209, 0],
                            "to": [0.78714275360107, 0],
                            "ti": [-0.80784487724304, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 61,
                            "s": [-198.209, 0],
                            "e": [-195.763, 0],
                            "to": [0.80784487724304, 0],
                            "ti": [-0.81744158267975, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 62,
                            "s": [-195.763, 0],
                            "e": [-193.304, 0],
                            "to": [0.81744158267975, 0],
                            "ti": [-0.8172527551651, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 63,
                            "s": [-193.304, 0],
                            "e": [-190.86, 0],
                            "to": [0.8172527551651, 0],
                            "ti": [-0.80730432271957, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 64,
                            "s": [-190.86, 0],
                            "e": [-188.461, 0],
                            "to": [0.80730432271957, 0],
                            "ti": [-0.78639453649521, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p837_0p167_0p17",
                            "t": 65,
                            "s": [-188.461, 0],
                            "e": [-186.142, 0],
                            "to": [0.78639453649521, 0],
                            "ti": [-0.75478118658066, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.171},
                            "n": "0p833_0p839_0p167_0p171",
                            "t": 66,
                            "s": [-186.142, 0],
                            "e": [-183.932, 0],
                            "to": [0.75478118658066, 0],
                            "ti": [-0.71294939517975, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 67,
                            "s": [-183.932, 0],
                            "e": [-181.864, 0],
                            "to": [0.71294939517975, 0],
                            "ti": [-0.65995985269547, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 68,
                            "s": [-181.864, 0],
                            "e": [-179.972, 0],
                            "to": [0.65995985269547, 0],
                            "ti": [-0.5974555015564, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.846},
                            "o": {"x": 0.167, "y": 0.176},
                            "n": "0p833_0p846_0p167_0p176",
                            "t": 69,
                            "s": [-179.972, 0],
                            "e": [-178.279, 0],
                            "to": [0.5974555015564, 0],
                            "ti": [-0.52282047271729, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.848},
                            "o": {"x": 0.167, "y": 0.181},
                            "n": "0p833_0p848_0p167_0p181",
                            "t": 70,
                            "s": [-178.279, 0],
                            "e": [-176.835, 0],
                            "to": [0.52282047271729, 0],
                            "ti": [-0.43906232714653, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.889},
                            "o": {"x": 0.167, "y": 0.184},
                            "n": "0p833_0p889_0p167_0p184",
                            "t": 71,
                            "s": [-176.835, 0],
                            "e": [-175.645, 0],
                            "to": [0.43906232714653, 0],
                            "ti": [-0.26418936252594, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.802},
                            "o": {"x": 0.167, "y": 0.331},
                            "n": "0p833_0p802_0p167_0p331",
                            "t": 72,
                            "s": [-175.645, 0],
                            "e": [-175.25, 0],
                            "to": [0.26418936252594, 0],
                            "ti": [0.02589913643897, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.886},
                            "o": {"x": 0.167, "y": 0.144},
                            "n": "0p833_0p886_0p167_0p144",
                            "t": 73,
                            "s": [-175.25, 0],
                            "e": [-175.8, 0],
                            "to": [-0.02589913643897, 0],
                            "ti": [0.12499672919512, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.728},
                            "o": {"x": 0.167, "y": 0.313},
                            "n": "0p833_0p728_0p167_0p313",
                            "t": 74,
                            "s": [-175.8, 0],
                            "e": [-176, 0],
                            "to": [-0.12499672919512, 0],
                            "ti": [0.10877461731434, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.67},
                            "o": {"x": 0.167, "y": 0.12},
                            "n": "0p833_0p67_0p167_0p12",
                            "t": 75,
                            "s": [-176, 0],
                            "e": [-176.453, 0],
                            "to": [-0.10877461731434, 0],
                            "ti": [0.29853972792625, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.784},
                            "o": {"x": 0.167, "y": 0.112},
                            "n": "0p833_0p784_0p167_0p112",
                            "t": 76,
                            "s": [-176.453, 0],
                            "e": [-177.791, 0],
                            "to": [-0.29853972792625, 0],
                            "ti": [0.5773429274559, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.806},
                            "o": {"x": 0.167, "y": 0.136},
                            "n": "0p833_0p806_0p167_0p136",
                            "t": 77,
                            "s": [-177.791, 0],
                            "e": [-179.917, 0],
                            "to": [-0.5773429274559, 0],
                            "ti": [0.82548803091049, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.815},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p815_0p167_0p146",
                            "t": 78,
                            "s": [-179.917, 0],
                            "e": [-182.744, 0],
                            "to": [-0.82548803091049, 0],
                            "ti": [1.04785978794098, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.82},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p82_0p167_0p151",
                            "t": 79,
                            "s": [-182.744, 0],
                            "e": [-186.204, 0],
                            "to": [-1.04785978794098, 0],
                            "ti": [1.24588739871979, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p823_0p167_0p155",
                            "t": 80,
                            "s": [-186.204, 0],
                            "e": [-190.219, 0],
                            "to": [-1.24588739871979, 0],
                            "ti": [1.41749131679535, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 81,
                            "s": [-190.219, 0],
                            "e": [-194.709, 0],
                            "to": [-1.41749131679535, 0],
                            "ti": [1.56220948696136, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.828},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p828_0p167_0p16",
                            "t": 82,
                            "s": [-194.709, 0],
                            "e": [-199.593, 0],
                            "to": [-1.56220948696136, 0],
                            "ti": [1.68148517608643, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.162},
                            "n": "0p833_0p829_0p167_0p162",
                            "t": 83,
                            "s": [-199.593, 0],
                            "e": [-204.798, 0],
                            "to": [-1.68148517608643, 0],
                            "ti": [1.77517461776733, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 84,
                            "s": [-204.798, 0],
                            "e": [-210.244, 0],
                            "to": [-1.77517461776733, 0],
                            "ti": [1.8401106595993, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 85,
                            "s": [-210.244, 0],
                            "e": [-215.839, 0],
                            "to": [-1.8401106595993, 0],
                            "ti": [1.87651813030243, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p833_0p167_0p166",
                            "t": 86,
                            "s": [-215.839, 0],
                            "e": [-221.503, 0],
                            "to": [-1.87651813030243, 0],
                            "ti": [1.88781917095184, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p834_0p167_0p167",
                            "t": 87,
                            "s": [-221.503, 0],
                            "e": [-227.165, 0],
                            "to": [-1.88781917095184, 0],
                            "ti": [1.87552893161774, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 88,
                            "s": [-227.165, 0],
                            "e": [-232.756, 0],
                            "to": [-1.87552893161774, 0],
                            "ti": [1.83890283107758, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.169},
                            "n": "0p833_0p837_0p167_0p169",
                            "t": 89,
                            "s": [-232.756, 0],
                            "e": [-238.199, 0],
                            "to": [-1.83890283107758, 0],
                            "ti": [1.77517795562744, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p839_0p167_0p17",
                            "t": 90,
                            "s": [-238.199, 0],
                            "e": [-243.407, 0],
                            "to": [-1.77517795562744, 0],
                            "ti": [1.6814159154892, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 91,
                            "s": [-243.407, 0],
                            "e": [-248.287, 0],
                            "to": [-1.6814159154892, 0],
                            "ti": [1.5614458322525, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 92,
                            "s": [-248.287, 0],
                            "e": [-252.776, 0],
                            "to": [-1.5614458322525, 0],
                            "ti": [1.41821014881134, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.176},
                            "n": "0p833_0p845_0p167_0p176",
                            "t": 93,
                            "s": [-252.776, 0],
                            "e": [-256.797, 0],
                            "to": [-1.41821014881134, 0],
                            "ti": [1.24658918380737, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.848},
                            "o": {"x": 0.167, "y": 0.18},
                            "n": "0p833_0p848_0p167_0p18",
                            "t": 94,
                            "s": [-256.797, 0],
                            "e": [-260.255, 0],
                            "to": [-1.24658918380737, 0],
                            "ti": [1.04838037490845, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.185},
                            "n": "0p833_0p854_0p167_0p185",
                            "t": 95,
                            "s": [-260.255, 0],
                            "e": [-263.087, 0],
                            "to": [-1.04838037490845, 0],
                            "ti": [0.82577800750732, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.864},
                            "o": {"x": 0.167, "y": 0.194},
                            "n": "0p833_0p864_0p167_0p194",
                            "t": 96,
                            "s": [-263.087, 0],
                            "e": [-265.21, 0],
                            "to": [-0.82577800750732, 0],
                            "ti": [0.57651507854462, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.888},
                            "o": {"x": 0.167, "y": 0.216},
                            "n": "0p833_0p888_0p167_0p216",
                            "t": 97,
                            "s": [-265.21, 0],
                            "e": [-266.546, 0],
                            "to": [-0.57651507854462, 0],
                            "ti": [0.29832947254181, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.329},
                            "n": "0p833_0p833_0p167_0p329",
                            "t": 98,
                            "s": [-266.546, 0],
                            "e": [-267, 0],
                            "to": [-0.29832947254181, 0],
                            "ti": [0.07566349208355, 0]
                        }, {"t": 99}],
                        "ix": 2
                    },
                    "a": {"a": 0, "k": [-121, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "vertical line 2",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [554.75, 574, 0], "ix": 2},
                "a": {"a": 0, "k": [-118.25, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.541, 0], [0, 0], [0, -1.542], [0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0]],
                            "o": [[0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0], [1.541, 0], [0, 0], [0, -1.542]],
                            "v": [[117.184, -21.233], [-117.185, -21.233], [-119.976, -18.442], [-119.976, 18.442],
                                [-117.185, 21.233], [117.184, 21.233], [119.976, 18.442], [119.976, -18.442]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.458823529412, 0.674509803922, 0.988235294118, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-121, 0], "ix": 2},
                    "a": {"a": 0, "k": [-121, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "mask",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [673, 525, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.541, 0], [0, 0], [0, -1.542], [0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0]],
                            "o": [[0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0], [1.541, 0], [0, 0], [0, -1.542]],
                            "v": [[117.184, -21.233], [-117.185, -21.233], [-119.976, -18.442], [-119.976, 18.442],
                                [-117.185, 21.233], [117.184, 21.233], [119.976, 18.442], [119.976, -18.442]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.611764705882, 0.843137254902, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {
                        "a": 1,
                        "k": [{
                            "i": {"x": 0.833, "y": 0.673},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p673_0p167_0p167",
                            "t": 0,
                            "s": [0, 0],
                            "e": [-0.21, 0],
                            "to": [-0.03504378721118, 0],
                            "ti": [0.13770379126072, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.782},
                            "o": {"x": 0.167, "y": 0.112},
                            "n": "0p833_0p782_0p167_0p112",
                            "t": 1,
                            "s": [-0.21, 0],
                            "e": [-0.826, 0],
                            "to": [-0.13770379126072, 0],
                            "ti": [0.26844757795334, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.805},
                            "o": {"x": 0.167, "y": 0.135},
                            "n": "0p833_0p805_0p167_0p135",
                            "t": 2,
                            "s": [-0.826, 0],
                            "e": [-1.821, 0],
                            "to": [-0.26844757795334, 0],
                            "ti": [0.38801914453506, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.815},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p815_0p167_0p146",
                            "t": 3,
                            "s": [-1.821, 0],
                            "e": [-3.154, 0],
                            "to": [-0.38801914453506, 0],
                            "ti": [0.49384164810181, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.82},
                            "o": {"x": 0.167, "y": 0.152},
                            "n": "0p833_0p82_0p167_0p152",
                            "t": 4,
                            "s": [-3.154, 0],
                            "e": [-4.784, 0],
                            "to": [-0.49384164810181, 0],
                            "ti": [0.58746182918549, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p823_0p167_0p155",
                            "t": 5,
                            "s": [-4.784, 0],
                            "e": [-6.679, 0],
                            "to": [-0.58746182918549, 0],
                            "ti": [0.66978937387466, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 6,
                            "s": [-6.679, 0],
                            "e": [-8.803, 0],
                            "to": [-0.66978937387466, 0],
                            "ti": [0.7396103143692, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.827},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p827_0p167_0p16",
                            "t": 7,
                            "s": [-8.803, 0],
                            "e": [-11.117, 0],
                            "to": [-0.7396103143692, 0],
                            "ti": [0.79864811897278, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.161},
                            "n": "0p833_0p829_0p167_0p161",
                            "t": 8,
                            "s": [-11.117, 0],
                            "e": [-13.595, 0],
                            "to": [-0.79864811897278, 0],
                            "ti": [0.84686535596848, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 9,
                            "s": [-13.595, 0],
                            "e": [-16.198, 0],
                            "to": [-0.84686535596848, 0],
                            "ti": [0.88184797763824, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 10,
                            "s": [-16.198, 0],
                            "e": [-18.886, 0],
                            "to": [-0.88184797763824, 0],
                            "ti": [0.90402626991272, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 11,
                            "s": [-18.886, 0],
                            "e": [-21.622, 0],
                            "to": [-0.90402626991272, 0],
                            "ti": [0.91488140821457, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 12,
                            "s": [-21.622, 0],
                            "e": [-24.375, 0],
                            "to": [-0.91488140821457, 0],
                            "ti": [0.91515791416168, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 13,
                            "s": [-24.375, 0],
                            "e": [-27.113, 0],
                            "to": [-0.91515791416168, 0],
                            "ti": [0.90467071533203, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 14,
                            "s": [-27.113, 0],
                            "e": [-29.803, 0],
                            "to": [-0.90467071533203, 0],
                            "ti": [0.88231122493744, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.838},
                            "o": {"x": 0.167, "y": 0.169},
                            "n": "0p833_0p838_0p167_0p169",
                            "t": 15,
                            "s": [-29.803, 0],
                            "e": [-32.407, 0],
                            "to": [-0.88231122493744, 0],
                            "ti": [0.84609395265579, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.171},
                            "n": "0p833_0p839_0p167_0p171",
                            "t": 16,
                            "s": [-32.407, 0],
                            "e": [-34.88, 0],
                            "to": [-0.84609395265579, 0],
                            "ti": [0.79691344499588, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.173},
                            "n": "0p833_0p84_0p167_0p173",
                            "t": 17,
                            "s": [-34.88, 0],
                            "e": [-37.188, 0],
                            "to": [-0.79691344499588, 0],
                            "ti": [0.73895233869553, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 18,
                            "s": [-37.188, 0],
                            "e": [-39.313, 0],
                            "to": [-0.73895233869553, 0],
                            "ti": [0.67108005285263, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.176},
                            "n": "0p833_0p845_0p167_0p176",
                            "t": 19,
                            "s": [-39.313, 0],
                            "e": [-41.215, 0],
                            "to": [-0.67108005285263, 0],
                            "ti": [0.58812493085861, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.849},
                            "o": {"x": 0.167, "y": 0.181},
                            "n": "0p833_0p849_0p167_0p181",
                            "t": 20,
                            "s": [-41.215, 0],
                            "e": [-42.842, 0],
                            "to": [-0.58812493085861, 0],
                            "ti": [0.49224629998207, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.186},
                            "n": "0p833_0p854_0p167_0p186",
                            "t": 21,
                            "s": [-42.842, 0],
                            "e": [-44.168, 0],
                            "to": [-0.49224629998207, 0],
                            "ti": [0.38691055774689, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.864},
                            "o": {"x": 0.167, "y": 0.194},
                            "n": "0p833_0p864_0p167_0p194",
                            "t": 22,
                            "s": [-44.168, 0],
                            "e": [-45.164, 0],
                            "to": [-0.38691055774689, 0],
                            "ti": [0.27014285326004, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.889},
                            "o": {"x": 0.167, "y": 0.216},
                            "n": "0p833_0p889_0p167_0p216",
                            "t": 23,
                            "s": [-45.164, 0],
                            "e": [-45.789, 0],
                            "to": [-0.27014285326004, 0],
                            "ti": [0.13941417634487, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.869},
                            "o": {"x": 0.167, "y": 0.331},
                            "n": "0p833_0p869_0p167_0p331",
                            "t": 24,
                            "s": [-45.789, 0],
                            "e": [-46, 0],
                            "to": [-0.13941417634487, 0],
                            "ti": [0.05518932268023, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.82},
                            "o": {"x": 0.167, "y": 0.229},
                            "n": "0p833_0p82_0p167_0p229",
                            "t": 25,
                            "s": [-46, 0],
                            "e": [-46.12, 0],
                            "to": [-0.05518932268023, 0],
                            "ti": [0.03632025793195, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.599},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p599_0p167_0p155",
                            "t": 26,
                            "s": [-46.12, 0],
                            "e": [-46.218, 0],
                            "to": [-0.03632025793195, 0],
                            "ti": [-0.07292249798775, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.805},
                            "o": {"x": 0.167, "y": 0.105},
                            "n": "0p833_0p805_0p167_0p105",
                            "t": 27,
                            "s": [-46.218, 0],
                            "e": [-45.683, 0],
                            "to": [0.07292249798775, 0],
                            "ti": [-0.20851749181747, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.814},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p814_0p167_0p146",
                            "t": 28,
                            "s": [-45.683, 0],
                            "e": [-44.967, 0],
                            "to": [0.20851749181747, 0],
                            "ti": [-0.26619210839272, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.821},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p821_0p167_0p151",
                            "t": 29,
                            "s": [-44.967, 0],
                            "e": [-44.086, 0],
                            "to": [0.26619210839272, 0],
                            "ti": [-0.31612709164619, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.156},
                            "n": "0p833_0p823_0p167_0p156",
                            "t": 30,
                            "s": [-44.086, 0],
                            "e": [-43.07, 0],
                            "to": [0.31612709164619, 0],
                            "ti": [-0.35993093252182, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.157},
                            "n": "0p833_0p826_0p167_0p157",
                            "t": 31,
                            "s": [-43.07, 0],
                            "e": [-41.926, 0],
                            "to": [0.35993093252182, 0],
                            "ti": [-0.39899298548698, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.828},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p828_0p167_0p16",
                            "t": 32,
                            "s": [-41.926, 0],
                            "e": [-40.676, 0],
                            "to": [0.39899298548698, 0],
                            "ti": [-0.43024176359177, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.162},
                            "n": "0p833_0p829_0p167_0p162",
                            "t": 33,
                            "s": [-40.676, 0],
                            "e": [-39.345, 0],
                            "to": [0.43024176359177, 0],
                            "ti": [-0.45529663562775, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.83},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p83_0p167_0p163",
                            "t": 34,
                            "s": [-39.345, 0],
                            "e": [-37.944, 0],
                            "to": [0.45529663562775, 0],
                            "ti": [-0.47490710020065, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 35,
                            "s": [-37.944, 0],
                            "e": [-36.495, 0],
                            "to": [0.47490710020065, 0],
                            "ti": [-0.48757457733154, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 36,
                            "s": [-36.495, 0],
                            "e": [-35.019, 0],
                            "to": [0.48757457733154, 0],
                            "ti": [-0.49358901381493, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 37,
                            "s": [-35.019, 0],
                            "e": [-33.534, 0],
                            "to": [0.49358901381493, 0],
                            "ti": [-0.49360013008118, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 38,
                            "s": [-33.534, 0],
                            "e": [-32.057, 0],
                            "to": [0.49360013008118, 0],
                            "ti": [-0.48711714148521, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 39,
                            "s": [-32.057, 0],
                            "e": [-30.611, 0],
                            "to": [0.48711714148521, 0],
                            "ti": [-0.47324061393738, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p837_0p167_0p17",
                            "t": 40,
                            "s": [-30.611, 0],
                            "e": [-29.218, 0],
                            "to": [0.47324061393738, 0],
                            "ti": [-0.45445382595062, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.838},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p838_0p167_0p17",
                            "t": 41,
                            "s": [-29.218, 0],
                            "e": [-27.884, 0],
                            "to": [0.45445382595062, 0],
                            "ti": [-0.43154150247574, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.841},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p841_0p167_0p172",
                            "t": 42,
                            "s": [-27.884, 0],
                            "e": [-26.629, 0],
                            "to": [0.43154150247574, 0],
                            "ti": [-0.40007552504539, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.175},
                            "n": "0p833_0p842_0p167_0p175",
                            "t": 43,
                            "s": [-26.629, 0],
                            "e": [-25.484, 0],
                            "to": [0.40007552504539, 0],
                            "ti": [-0.36079496145248, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.177},
                            "n": "0p833_0p845_0p167_0p177",
                            "t": 44,
                            "s": [-25.484, 0],
                            "e": [-24.464, 0],
                            "to": [0.36079496145248, 0],
                            "ti": [-0.31624653935432, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.862},
                            "o": {"x": 0.167, "y": 0.18},
                            "n": "0p833_0p862_0p167_0p18",
                            "t": 45,
                            "s": [-24.464, 0],
                            "e": [-23.586, 0],
                            "to": [0.31624653935432, 0],
                            "ti": [-0.24146342277527, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.211},
                            "n": "0p833_0p84_0p167_0p211",
                            "t": 46,
                            "s": [-23.586, 0],
                            "e": [-23.015, 0],
                            "to": [0.24146342277527, 0],
                            "ti": [-0.00729362294078, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.863},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p863_0p167_0p174",
                            "t": 47,
                            "s": [-23.015, 0],
                            "e": [-23.543, 0],
                            "to": [0.00729362294078, 0],
                            "ti": [0.14416374266148, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.887},
                            "o": {"x": 0.167, "y": 0.214},
                            "n": "0p833_0p887_0p167_0p214",
                            "t": 48,
                            "s": [-23.543, 0],
                            "e": [-23.88, 0],
                            "to": [-0.14416374266148, 0],
                            "ti": [0.07624224573374, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.706},
                            "o": {"x": 0.167, "y": 0.318},
                            "n": "0p833_0p706_0p167_0p318",
                            "t": 49,
                            "s": [-23.88, 0],
                            "e": [-24, 0],
                            "to": [-0.07624224573374, 0],
                            "ti": [0.070665538311, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.669},
                            "o": {"x": 0.167, "y": 0.116},
                            "n": "0p833_0p669_0p167_0p116",
                            "t": 50,
                            "s": [-24, 0],
                            "e": [-24.304, 0],
                            "to": [-0.070665538311, 0],
                            "ti": [0.20116560161114, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.784},
                            "o": {"x": 0.167, "y": 0.111},
                            "n": "0p833_0p784_0p167_0p111",
                            "t": 51,
                            "s": [-24.304, 0],
                            "e": [-25.207, 0],
                            "to": [-0.20116560161114, 0],
                            "ti": [0.38981422781944, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.806},
                            "o": {"x": 0.167, "y": 0.136},
                            "n": "0p833_0p806_0p167_0p136",
                            "t": 52,
                            "s": [-25.207, 0],
                            "e": [-26.643, 0],
                            "to": [-0.38981422781944, 0],
                            "ti": [0.55804663896561, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.814},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p814_0p167_0p146",
                            "t": 53,
                            "s": [-26.643, 0],
                            "e": [-28.555, 0],
                            "to": [-0.55804663896561, 0],
                            "ti": [0.71044051647186, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.819},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p819_0p167_0p151",
                            "t": 54,
                            "s": [-28.555, 0],
                            "e": [-30.906, 0],
                            "to": [-0.71044051647186, 0],
                            "ti": [0.84859818220139, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.824},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p824_0p167_0p155",
                            "t": 55,
                            "s": [-30.906, 0],
                            "e": [-33.647, 0],
                            "to": [-0.84859818220139, 0],
                            "ti": [0.96669143438339, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 56,
                            "s": [-33.647, 0],
                            "e": [-36.706, 0],
                            "to": [-0.96669143438339, 0],
                            "ti": [1.06526505947113, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.827},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p827_0p167_0p16",
                            "t": 57,
                            "s": [-36.706, 0],
                            "e": [-40.038, 0],
                            "to": [-1.06526505947113, 0],
                            "ti": [1.1511150598526, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.161},
                            "n": "0p833_0p829_0p167_0p161",
                            "t": 58,
                            "s": [-40.038, 0],
                            "e": [-43.612, 0],
                            "to": [-1.1511150598526, 0],
                            "ti": [1.22063314914703, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 59,
                            "s": [-43.612, 0],
                            "e": [-47.362, 0],
                            "to": [-1.22063314914703, 0],
                            "ti": [1.27108919620514, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 60,
                            "s": [-47.362, 0],
                            "e": [-51.239, 0],
                            "to": [-1.27108919620514, 0],
                            "ti": [1.30456709861755, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.165},
                            "n": "0p833_0p833_0p167_0p165",
                            "t": 61,
                            "s": [-51.239, 0],
                            "e": [-55.19, 0],
                            "to": [-1.30456709861755, 0],
                            "ti": [1.32089054584503, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p834_0p167_0p166",
                            "t": 62,
                            "s": [-55.19, 0],
                            "e": [-59.164, 0],
                            "to": [-1.32089054584503, 0],
                            "ti": [1.32125127315521, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.835},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p835_0p167_0p167",
                            "t": 63,
                            "s": [-59.164, 0],
                            "e": [-63.117, 0],
                            "to": [-1.32125127315521, 0],
                            "ti": [1.3059253692627, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 64,
                            "s": [-63.117, 0],
                            "e": [-67, 0],
                            "to": [-1.3059253692627, 0],
                            "ti": [1.27314388751984, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.169},
                            "n": "0p833_0p837_0p167_0p169",
                            "t": 65,
                            "s": [-67, 0],
                            "e": [-70.756, 0],
                            "to": [-1.27314388751984, 0],
                            "ti": [1.22125720977783, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.171},
                            "n": "0p833_0p839_0p167_0p171",
                            "t": 66,
                            "s": [-70.756, 0],
                            "e": [-74.327, 0],
                            "to": [-1.22125720977783, 0],
                            "ti": [1.15250062942505, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 67,
                            "s": [-74.327, 0],
                            "e": [-77.671, 0],
                            "to": [-1.15250062942505, 0],
                            "ti": [1.06742084026337, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 68,
                            "s": [-77.671, 0],
                            "e": [-80.732, 0],
                            "to": [-1.06742084026337, 0],
                            "ti": [0.96557825803757, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.177},
                            "n": "0p833_0p845_0p167_0p177",
                            "t": 69,
                            "s": [-80.732, 0],
                            "e": [-83.464, 0],
                            "to": [-0.96557825803757, 0],
                            "ti": [0.84528434276581, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.848},
                            "o": {"x": 0.167, "y": 0.181},
                            "n": "0p833_0p848_0p167_0p181",
                            "t": 70,
                            "s": [-83.464, 0],
                            "e": [-85.804, 0],
                            "to": [-0.84528434276581, 0],
                            "ti": [0.70942413806915, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.185},
                            "n": "0p833_0p854_0p167_0p185",
                            "t": 71,
                            "s": [-85.804, 0],
                            "e": [-87.721, 0],
                            "to": [-0.70942413806915, 0],
                            "ti": [0.55948585271835, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.865},
                            "o": {"x": 0.167, "y": 0.194},
                            "n": "0p833_0p865_0p167_0p194",
                            "t": 72,
                            "s": [-87.721, 0],
                            "e": [-89.16, 0],
                            "to": [-0.55948585271835, 0],
                            "ti": [0.38926860690117, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.899},
                            "o": {"x": 0.167, "y": 0.217},
                            "n": "0p833_0p899_0p167_0p217",
                            "t": 73,
                            "s": [-89.16, 0],
                            "e": [-90.057, 0],
                            "to": [-0.38926860690117, 0],
                            "ti": [0.1399187296629, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.718},
                            "o": {"x": 0.167, "y": 0.478},
                            "n": "0p833_0p718_0p167_0p478",
                            "t": 74,
                            "s": [-90.057, 0],
                            "e": [-90, 0],
                            "to": [-0.1399187296629, 0],
                            "ti": [-0.0846105068922, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.672},
                            "o": {"x": 0.167, "y": 0.118},
                            "n": "0p833_0p672_0p167_0p118",
                            "t": 75,
                            "s": [-90, 0],
                            "e": [-89.549, 0],
                            "to": [0.0846105068922, 0],
                            "ti": [-0.29570287466049, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.784},
                            "o": {"x": 0.167, "y": 0.112},
                            "n": "0p833_0p784_0p167_0p112",
                            "t": 76,
                            "s": [-89.549, 0],
                            "e": [-88.226, 0],
                            "to": [0.29570287466049, 0],
                            "ti": [-0.57039308547974, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.806},
                            "o": {"x": 0.167, "y": 0.136},
                            "n": "0p833_0p806_0p167_0p136",
                            "t": 77,
                            "s": [-88.226, 0],
                            "e": [-86.127, 0],
                            "to": [0.57039308547974, 0],
                            "ti": [-0.8154211640358, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.814},
                            "o": {"x": 0.167, "y": 0.146},
                            "n": "0p833_0p814_0p167_0p146",
                            "t": 78,
                            "s": [-86.127, 0],
                            "e": [-83.333, 0],
                            "to": [0.8154211640358, 0],
                            "ti": [-1.03677988052368, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.82},
                            "o": {"x": 0.167, "y": 0.151},
                            "n": "0p833_0p82_0p167_0p151",
                            "t": 79,
                            "s": [-83.333, 0],
                            "e": [-79.906, 0],
                            "to": [1.03677988052368, 0],
                            "ti": [-1.23264253139496, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.823},
                            "o": {"x": 0.167, "y": 0.155},
                            "n": "0p833_0p823_0p167_0p155",
                            "t": 80,
                            "s": [-79.906, 0],
                            "e": [-75.937, 0],
                            "to": [1.23264253139496, 0],
                            "ti": [-1.40182554721832, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.826},
                            "o": {"x": 0.167, "y": 0.158},
                            "n": "0p833_0p826_0p167_0p158",
                            "t": 81,
                            "s": [-75.937, 0],
                            "e": [-71.495, 0],
                            "to": [1.40182554721832, 0],
                            "ti": [-1.5452094078064, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.828},
                            "o": {"x": 0.167, "y": 0.16},
                            "n": "0p833_0p828_0p167_0p16",
                            "t": 82,
                            "s": [-71.495, 0],
                            "e": [-66.666, 0],
                            "to": [1.5452094078064, 0],
                            "ti": [-1.66268384456635, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.829},
                            "o": {"x": 0.167, "y": 0.162},
                            "n": "0p833_0p829_0p167_0p162",
                            "t": 83,
                            "s": [-66.666, 0],
                            "e": [-61.519, 0],
                            "to": [1.66268384456635, 0],
                            "ti": [-1.75572860240936, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.831},
                            "o": {"x": 0.167, "y": 0.163},
                            "n": "0p833_0p831_0p167_0p163",
                            "t": 84,
                            "s": [-61.519, 0],
                            "e": [-56.132, 0],
                            "to": [1.75572860240936, 0],
                            "ti": [-1.82022511959076, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.832},
                            "o": {"x": 0.167, "y": 0.164},
                            "n": "0p833_0p832_0p167_0p164",
                            "t": 85,
                            "s": [-56.132, 0],
                            "e": [-50.598, 0],
                            "to": [1.82022511959076, 0],
                            "ti": [-1.85605883598328, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.166},
                            "n": "0p833_0p833_0p167_0p166",
                            "t": 86,
                            "s": [-50.598, 0],
                            "e": [-44.995, 0],
                            "to": [1.85605883598328, 0],
                            "ti": [-1.86696195602417, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.834},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p834_0p167_0p167",
                            "t": 87,
                            "s": [-44.995, 0],
                            "e": [-39.396, 0],
                            "to": [1.86696195602417, 0],
                            "ti": [-1.85466957092285, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.836},
                            "o": {"x": 0.167, "y": 0.168},
                            "n": "0p833_0p836_0p167_0p168",
                            "t": 88,
                            "s": [-39.396, 0],
                            "e": [-33.867, 0],
                            "to": [1.85466957092285, 0],
                            "ti": [-1.81850433349609, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.837},
                            "o": {"x": 0.167, "y": 0.169},
                            "n": "0p833_0p837_0p167_0p169",
                            "t": 89,
                            "s": [-33.867, 0],
                            "e": [-28.485, 0],
                            "to": [1.81850433349609, 0],
                            "ti": [-1.75563108921051, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.839},
                            "o": {"x": 0.167, "y": 0.17},
                            "n": "0p833_0p839_0p167_0p17",
                            "t": 90,
                            "s": [-28.485, 0],
                            "e": [-23.334, 0],
                            "to": [1.75563108921051, 0],
                            "ti": [-1.66280961036682, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.84},
                            "o": {"x": 0.167, "y": 0.172},
                            "n": "0p833_0p84_0p167_0p172",
                            "t": 91,
                            "s": [-23.334, 0],
                            "e": [-18.508, 0],
                            "to": [1.66280961036682, 0],
                            "ti": [-1.54400062561035, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.842},
                            "o": {"x": 0.167, "y": 0.174},
                            "n": "0p833_0p842_0p167_0p174",
                            "t": 92,
                            "s": [-18.508, 0],
                            "e": [-14.07, 0],
                            "to": [1.54400062561035, 0],
                            "ti": [-1.40271854400635, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.845},
                            "o": {"x": 0.167, "y": 0.176},
                            "n": "0p833_0p845_0p167_0p176",
                            "t": 93,
                            "s": [-14.07, 0],
                            "e": [-10.092, 0],
                            "to": [1.40271854400635, 0],
                            "ti": [-1.23311638832092, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.848},
                            "o": {"x": 0.167, "y": 0.18},
                            "n": "0p833_0p848_0p167_0p18",
                            "t": 94,
                            "s": [-10.092, 0],
                            "e": [-6.671, 0],
                            "to": [1.23311638832092, 0],
                            "ti": [-1.03663241863251, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.854},
                            "o": {"x": 0.167, "y": 0.185},
                            "n": "0p833_0p854_0p167_0p185",
                            "t": 95,
                            "s": [-6.671, 0],
                            "e": [-3.872, 0],
                            "to": [1.03663241863251, 0],
                            "ti": [-0.81666320562363, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.864},
                            "o": {"x": 0.167, "y": 0.194},
                            "n": "0p833_0p864_0p167_0p194",
                            "t": 96,
                            "s": [-3.872, 0],
                            "e": [-1.771, 0],
                            "to": [0.81666320562363, 0],
                            "ti": [-0.57038527727127, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.888},
                            "o": {"x": 0.167, "y": 0.216},
                            "n": "0p833_0p888_0p167_0p216",
                            "t": 97,
                            "s": [-1.771, 0],
                            "e": [-0.449, 0],
                            "to": [0.57038527727127, 0],
                            "ti": [-0.29515558481216, 0]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.328},
                            "n": "0p833_0p833_0p167_0p328",
                            "t": 98,
                            "s": [-0.449, 0],
                            "e": [0, 0],
                            "to": [0.29515558481216, 0],
                            "ti": [-0.0749124661088, 0]
                        }, {"t": 99}],
                        "ix": 2
                    },
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "nm": "vertical line  up",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [673, 525, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.541, 0], [0, 0], [0, -1.542], [0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0]],
                            "o": [[0, 0], [-1.541, 0], [0, 0], [0, 1.541], [0, 0], [1.541, 0], [0, 0], [0, -1.542]],
                            "v": [[117.184, -21.233], [-117.185, -21.233], [-119.976, -18.442], [-119.976, 18.442],
                                [-117.185, 21.233], [117.184, 21.233], [119.976, 18.442], [119.976, -18.442]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.611764705882, 0.843137254902, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 5,
            "ty": 0,
            "nm": "lines",
            "refId": "comp_8",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [439.75, 552, 0], "ix": 2},
                "a": {"a": 0, "k": [115, 65, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "hasMask": true,
            "masksProperties": [{
                "inv": false,
                "mode": "a",
                "pt": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[244.25, 14], [120.25, 14], [120.25, 122], [244.25, 122]],
                        "c": true
                    },
                    "ix": 1
                },
                "o": {"a": 0, "k": 100, "ix": 3},
                "x": {"a": 0, "k": 0, "ix": 4},
                "nm": "Mask 1"
            }],
            "w": 230,
            "h": 130,
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "nm": "Shape Layer 6",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [390, 551, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "d": 1,
                    "ty": "el",
                    "s": {"a": 0, "k": [47, 47], "ix": 2},
                    "p": {"a": 0, "k": [0, 0], "ix": 3},
                    "nm": "Ellipse Path 1",
                    "mn": "ADBE Vector Shape - Ellipse",
                    "hd": false
                }, {
                    "ty": "tm",
                    "s": {"a": 0, "k": 0, "ix": 1},
                    "e": {
                        "a": 1,
                        "k": [{
                            "i": {"x": [0.667], "y": [1]},
                            "o": {"x": [0.333], "y": [0]},
                            "n": ["0p667_1_0p333_0"],
                            "t": 0,
                            "s": [25],
                            "e": [11]
                        }, {
                            "i": {"x": [0.667], "y": [1]},
                            "o": {"x": [0.333], "y": [0]},
                            "n": ["0p667_1_0p333_0"],
                            "t": 25,
                            "s": [11],
                            "e": [68]
                        }, {
                            "i": {"x": [0.667], "y": [1]},
                            "o": {"x": [0.333], "y": [0]},
                            "n": ["0p667_1_0p333_0"],
                            "t": 50,
                            "s": [68],
                            "e": [60]
                        }, {
                            "i": {"x": [0.667], "y": [1]},
                            "o": {"x": [0.333], "y": [0]},
                            "n": ["0p667_1_0p333_0"],
                            "t": 75,
                            "s": [60],
                            "e": [25]
                        }, {"t": 99}],
                        "ix": 2
                    },
                    "o": {"a": 0, "k": 0, "ix": 3},
                    "m": 1,
                    "ix": 2,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.611764705882, 0.843137254902, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 47, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Ellipse 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "Shape Layer 5",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [390, 551, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "d": 1,
                    "ty": "el",
                    "s": {"a": 0, "k": [95, 95], "ix": 2},
                    "p": {"a": 0, "k": [0, 0], "ix": 3},
                    "nm": "Ellipse Path 1",
                    "mn": "ADBE Vector Shape - Ellipse",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.611764705882, 0.843137254902, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Ellipse 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 8,
            "ty": 0,
            "nm": "wave warps",
            "refId": "comp_9",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [566, 398, 0], "ix": 2},
                "a": {"a": 0, "k": [250, 175, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "w": 500,
            "h": 350,
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 9,
            "ty": 0,
            "nm": "lines",
            "refId": "comp_8",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [212.75, 552, 0], "ix": 2},
                "a": {"a": 0, "k": [115, 65, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "w": 230,
            "h": 130,
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 20, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [212.75, 552, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.813, 0], [0, 0], [0, -1.812], [0, 0], [-1.813, 0], [0, 0], [0, 1.811], [0, 0]],
                            "o": [[0, 0], [-1.813, 0], [0, 0], [0, 1.811], [0, 0], [1.813, 0], [0, 0], [0, -1.812]],
                            "v": [[108.077, -46.895], [-108.077, -46.895], [-111.359, -43.613], [-111.359, 43.613],
                                [-108.077, 46.895], [108.077, 46.895], [111.359, 43.613], [111.359, -43.613]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 11,
            "ty": 0,
            "nm": "line graph",
            "refId": "comp_10",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [210, 451, 0], "ix": 2},
                "a": {"a": 0, "k": [125, 50, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "w": 250,
            "h": 100,
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 12,
            "ty": 4,
            "nm": "box 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [212.75, 449, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.813, 0], [0, 0], [0, -1.813], [0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0]],
                            "o": [[0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0], [1.813, 0], [0, 0], [0, -1.813]],
                            "v": [[108.077, -46.895], [-108.077, -46.895], [-111.359, -43.613], [-111.359, 43.613],
                                [-108.077, 46.895], [108.077, 46.895], [111.359, 43.613], [111.359, -43.613]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 13,
            "ty": 4,
            "nm": "line 10",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [641, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [6],
                        "e": [6.192]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [6.192],
                        "e": [6.745]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [6.745],
                        "e": [7.63]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [7.63],
                        "e": [8.813]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [8.813],
                        "e": [10.264]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [10.264],
                        "e": [11.951]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [11.951],
                        "e": [13.843]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [13.843],
                        "e": [15.908]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [15.908],
                        "e": [18.115]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [18.115],
                        "e": [20.432]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [20.432],
                        "e": [22.828]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [22.828],
                        "e": [25.271]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [25.271],
                        "e": [27.729]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [27.729],
                        "e": [30.172]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [30.172],
                        "e": [32.568]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [32.568],
                        "e": [34.885]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [34.885],
                        "e": [37.092]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [37.092],
                        "e": [39.157]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [39.157],
                        "e": [41.049]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [41.049],
                        "e": [42.736]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [42.736],
                        "e": [44.187]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [44.187],
                        "e": [45.37]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [45.37],
                        "e": [46.255]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [46.255],
                        "e": [46.808]
                    }, {
                        "i": {"x": [0.833], "y": [1.096]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1p096_0p167_0p324"],
                        "t": 24,
                        "s": [46.808],
                        "e": [47]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.045]},
                        "n": ["0p833_0p743_0p167_0p045"],
                        "t": 25,
                        "s": [47],
                        "e": [46.587]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [46.587],
                        "e": [45.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [45.725],
                        "e": [44.64]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [44.64],
                        "e": [43.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [43.419],
                        "e": [42.106]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [42.106],
                        "e": [40.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [40.726],
                        "e": [39.297]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [39.297],
                        "e": [37.831]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [37.831],
                        "e": [36.339]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [36.339],
                        "e": [34.827]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [34.827],
                        "e": [33.301]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [33.301],
                        "e": [31.768]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [31.768],
                        "e": [30.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [30.232],
                        "e": [28.699]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [28.699],
                        "e": [27.173]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [27.173],
                        "e": [25.661]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [25.661],
                        "e": [24.169]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [24.169],
                        "e": [22.703]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [22.703],
                        "e": [21.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [21.274],
                        "e": [19.894]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [19.894],
                        "e": [18.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [18.581],
                        "e": [17.36]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [17.36],
                        "e": [16.275]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [16.275],
                        "e": [15.413]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [15.413],
                        "e": [15]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [15],
                        "e": [15.413]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [15.413],
                        "e": [16.275]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [16.275],
                        "e": [17.36]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [17.36],
                        "e": [18.581]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [18.581],
                        "e": [19.894]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [19.894],
                        "e": [21.274]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [21.274],
                        "e": [22.703]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [22.703],
                        "e": [24.169]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [24.169],
                        "e": [25.661]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [25.661],
                        "e": [27.173]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [27.173],
                        "e": [28.699]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [28.699],
                        "e": [30.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [30.232],
                        "e": [31.768]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [31.768],
                        "e": [33.301]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [33.301],
                        "e": [34.827]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [34.827],
                        "e": [36.339]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [36.339],
                        "e": [37.831]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [37.831],
                        "e": [39.297]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [39.297],
                        "e": [40.726]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [40.726],
                        "e": [42.106]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [42.106],
                        "e": [43.419]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [43.419],
                        "e": [44.64]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [44.64],
                        "e": [45.725]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [45.725],
                        "e": [46.587]
                    }, {
                        "i": {"x": [0.833], "y": [0.955]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p955_0p167_0p257"],
                        "t": 74,
                        "s": [46.587],
                        "e": [47]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [-0.096]},
                        "n": ["0p833_0p676_0p167_-0p096"],
                        "t": 75,
                        "s": [47],
                        "e": [46.808]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [46.808],
                        "e": [46.255]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [46.255],
                        "e": [45.37]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [45.37],
                        "e": [44.187]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [44.187],
                        "e": [42.736]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [42.736],
                        "e": [41.049]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [41.049],
                        "e": [39.157]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [39.157],
                        "e": [37.092]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [37.092],
                        "e": [34.885]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [34.885],
                        "e": [32.568]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [32.568],
                        "e": [30.172]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [30.172],
                        "e": [27.729]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [27.729],
                        "e": [25.271]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [25.271],
                        "e": [22.828]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [22.828],
                        "e": [20.432]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [20.432],
                        "e": [18.115]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [18.115],
                        "e": [15.908]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [15.908],
                        "e": [13.843]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [13.843],
                        "e": [11.951]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [11.951],
                        "e": [10.264]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [10.264],
                        "e": [8.813]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [8.813],
                        "e": [7.63]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [7.63],
                        "e": [6.745]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [6.745],
                        "e": [6.192]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 14,
            "ty": 4,
            "nm": "line 9",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [620, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [77],
                        "e": [76.813]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [76.813],
                        "e": [76.273]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [76.273],
                        "e": [75.41]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [75.41],
                        "e": [74.256]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [74.256],
                        "e": [72.84]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [72.84],
                        "e": [71.194]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [71.194],
                        "e": [69.348]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [69.348],
                        "e": [67.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [67.333],
                        "e": [65.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [65.18],
                        "e": [62.92]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [62.92],
                        "e": [60.583]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [60.583],
                        "e": [58.199]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [58.199],
                        "e": [55.801]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [55.801],
                        "e": [53.417]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [53.417],
                        "e": [51.08]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [51.08],
                        "e": [48.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [48.82],
                        "e": [46.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [46.667],
                        "e": [44.652]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [44.652],
                        "e": [42.806]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [42.806],
                        "e": [41.16]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [41.16],
                        "e": [39.744]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [39.744],
                        "e": [38.59]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [38.59],
                        "e": [37.727]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [37.727],
                        "e": [37.187]
                    }, {
                        "i": {"x": [0.833], "y": [1.009]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1p009_0p167_0p324"],
                        "t": 24,
                        "s": [37.187],
                        "e": [37]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.008]},
                        "n": ["0p833_0p743_0p167_0p008"],
                        "t": 25,
                        "s": [37],
                        "e": [37.206]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [37.206],
                        "e": [37.637]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [37.637],
                        "e": [38.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [38.18],
                        "e": [38.79]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [38.79],
                        "e": [39.447]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [39.447],
                        "e": [40.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [40.137],
                        "e": [40.852]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [40.852],
                        "e": [41.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [41.584],
                        "e": [42.331]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [42.331],
                        "e": [43.087]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [43.087],
                        "e": [43.849]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [43.849],
                        "e": [44.616]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [44.616],
                        "e": [45.384]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [45.384],
                        "e": [46.151]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [46.151],
                        "e": [46.913]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [46.913],
                        "e": [47.669]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [47.669],
                        "e": [48.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [48.416],
                        "e": [49.148]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [49.148],
                        "e": [49.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [49.863],
                        "e": [50.553]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [50.553],
                        "e": [51.21]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [51.21],
                        "e": [51.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [51.82],
                        "e": [52.363]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [52.363],
                        "e": [52.794]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [52.794],
                        "e": [53]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [53],
                        "e": [52.794]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [52.794],
                        "e": [52.363]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [52.363],
                        "e": [51.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [51.82],
                        "e": [51.21]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [51.21],
                        "e": [50.553]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [50.553],
                        "e": [49.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [49.863],
                        "e": [49.148]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [49.148],
                        "e": [48.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [48.416],
                        "e": [47.669]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [47.669],
                        "e": [46.913]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [46.913],
                        "e": [46.151]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [46.151],
                        "e": [45.384]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [45.384],
                        "e": [44.616]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [44.616],
                        "e": [43.849]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [43.849],
                        "e": [43.087]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [43.087],
                        "e": [42.331]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [42.331],
                        "e": [41.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [41.584],
                        "e": [40.852]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [40.852],
                        "e": [40.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [40.137],
                        "e": [39.447]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [39.447],
                        "e": [38.79]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [38.79],
                        "e": [38.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [38.18],
                        "e": [37.637]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [37.637],
                        "e": [37.206]
                    }, {
                        "i": {"x": [0.833], "y": [0.992]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p992_0p167_0p257"],
                        "t": 74,
                        "s": [37.206],
                        "e": [37]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [-0.009]},
                        "n": ["0p833_0p676_0p167_-0p009"],
                        "t": 75,
                        "s": [37],
                        "e": [37.187]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [37.187],
                        "e": [37.727]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [37.727],
                        "e": [38.59]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [38.59],
                        "e": [39.744]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [39.744],
                        "e": [41.16]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [41.16],
                        "e": [42.806]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [42.806],
                        "e": [44.652]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [44.652],
                        "e": [46.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [46.667],
                        "e": [48.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [48.82],
                        "e": [51.08]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [51.08],
                        "e": [53.417]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [53.417],
                        "e": [55.801]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [55.801],
                        "e": [58.199]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [58.199],
                        "e": [60.583]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [60.583],
                        "e": [62.92]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [62.92],
                        "e": [65.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [65.18],
                        "e": [67.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [67.333],
                        "e": [69.348]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [69.348],
                        "e": [71.194]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [71.194],
                        "e": [72.84]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [72.84],
                        "e": [74.256]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [74.256],
                        "e": [75.41]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [75.41],
                        "e": [76.273]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [76.273],
                        "e": [76.813]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 15,
            "ty": 4,
            "nm": "line 8",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [599.5, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [4],
                        "e": [4.238]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [4.238],
                        "e": [4.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [4.927],
                        "e": [6.027]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [6.027],
                        "e": [7.499]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [7.499],
                        "e": [9.304]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [9.304],
                        "e": [11.403]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [11.403],
                        "e": [13.756]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [13.756],
                        "e": [16.325]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [16.325],
                        "e": [19.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [19.07],
                        "e": [21.952]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [21.952],
                        "e": [24.932]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [24.932],
                        "e": [27.971]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [27.971],
                        "e": [31.029]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [31.029],
                        "e": [34.068]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [34.068],
                        "e": [37.048]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [37.048],
                        "e": [39.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [39.93],
                        "e": [42.675]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [42.675],
                        "e": [45.244]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [45.244],
                        "e": [47.597]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [47.597],
                        "e": [49.696]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [49.696],
                        "e": [51.501]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [51.501],
                        "e": [52.973]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [52.973],
                        "e": [54.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [54.073],
                        "e": [54.762]
                    }, {
                        "i": {"x": [0.833], "y": [1.16]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1p16_0p167_0p324"],
                        "t": 24,
                        "s": [54.762],
                        "e": [55]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.055]},
                        "n": ["0p833_0p743_0p167_0p055"],
                        "t": 25,
                        "s": [55],
                        "e": [54.303]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [54.303],
                        "e": [52.848]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [52.848],
                        "e": [51.018]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [51.018],
                        "e": [48.958]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [48.958],
                        "e": [46.741]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [46.741],
                        "e": [44.412]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [44.412],
                        "e": [42.001]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [42.001],
                        "e": [39.528]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [39.528],
                        "e": [37.009]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [37.009],
                        "e": [34.458]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [34.458],
                        "e": [31.883]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [31.883],
                        "e": [29.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [29.296],
                        "e": [26.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [26.704],
                        "e": [24.117]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [24.117],
                        "e": [21.542]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [21.542],
                        "e": [18.991]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [18.991],
                        "e": [16.472]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [16.472],
                        "e": [13.999]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [13.999],
                        "e": [11.588]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [11.588],
                        "e": [9.259]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [9.259],
                        "e": [7.042]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [7.042],
                        "e": [4.982]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [4.982],
                        "e": [3.152]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [3.152],
                        "e": [1.697]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [1.697],
                        "e": [1]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [1],
                        "e": [1.697]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [1.697],
                        "e": [3.152]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [3.152],
                        "e": [4.982]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [4.982],
                        "e": [7.042]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [7.042],
                        "e": [9.259]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [9.259],
                        "e": [11.588]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [11.588],
                        "e": [13.999]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [13.999],
                        "e": [16.472]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [16.472],
                        "e": [18.991]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [18.991],
                        "e": [21.542]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [21.542],
                        "e": [24.117]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [24.117],
                        "e": [26.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [26.704],
                        "e": [29.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [29.296],
                        "e": [31.883]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [31.883],
                        "e": [34.458]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [34.458],
                        "e": [37.009]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [37.009],
                        "e": [39.528]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [39.528],
                        "e": [42.001]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [42.001],
                        "e": [44.412]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [44.412],
                        "e": [46.741]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [46.741],
                        "e": [48.958]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [48.958],
                        "e": [51.018]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [51.018],
                        "e": [52.848]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [52.848],
                        "e": [54.303]
                    }, {
                        "i": {"x": [0.833], "y": [0.945]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p945_0p167_0p257"],
                        "t": 74,
                        "s": [54.303],
                        "e": [55]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [-0.16]},
                        "n": ["0p833_0p676_0p167_-0p16"],
                        "t": 75,
                        "s": [55],
                        "e": [54.762]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [54.762],
                        "e": [54.073]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [54.073],
                        "e": [52.973]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [52.973],
                        "e": [51.501]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [51.501],
                        "e": [49.696]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [49.696],
                        "e": [47.597]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [47.597],
                        "e": [45.244]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [45.244],
                        "e": [42.675]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [42.675],
                        "e": [39.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [39.93],
                        "e": [37.048]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [37.048],
                        "e": [34.068]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [34.068],
                        "e": [31.029]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [31.029],
                        "e": [27.971]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [27.971],
                        "e": [24.932]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [24.932],
                        "e": [21.952]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [21.952],
                        "e": [19.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [19.07],
                        "e": [16.325]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [16.325],
                        "e": [13.756]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [13.756],
                        "e": [11.403]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [11.403],
                        "e": [9.304]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [9.304],
                        "e": [7.499]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [7.499],
                        "e": [6.027]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [6.027],
                        "e": [4.927]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [4.927],
                        "e": [4.238]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 16,
            "ty": 4,
            "nm": "line 7",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [579, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [43],
                        "e": [42.86]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [42.86],
                        "e": [42.455]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [42.455],
                        "e": [41.808]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [41.808],
                        "e": [40.942]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [40.942],
                        "e": [39.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [39.88],
                        "e": [38.645]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [38.645],
                        "e": [37.261]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [37.261],
                        "e": [35.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [35.75],
                        "e": [34.135]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [34.135],
                        "e": [32.44]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [32.44],
                        "e": [30.687]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [30.687],
                        "e": [28.9]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [28.9],
                        "e": [27.1]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [27.1],
                        "e": [25.313]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [25.313],
                        "e": [23.56]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [23.56],
                        "e": [21.865]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [21.865],
                        "e": [20.25]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [20.25],
                        "e": [18.739]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [18.739],
                        "e": [17.355]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [17.355],
                        "e": [16.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [16.12],
                        "e": [15.058]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [15.058],
                        "e": [14.192]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [14.192],
                        "e": [13.545]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [13.545],
                        "e": [13.14]
                    }, {
                        "i": {"x": [0.833], "y": [1.055]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_1p055_0p167_0p324"],
                        "t": 24,
                        "s": [13.14],
                        "e": [13]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.033]},
                        "n": ["0p833_0p743_0p167_0p033"],
                        "t": 25,
                        "s": [13],
                        "e": [13.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [13.232],
                        "e": [13.717]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [13.717],
                        "e": [14.327]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [14.327],
                        "e": [15.014]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [15.014],
                        "e": [15.753]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [15.753],
                        "e": [16.529]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [16.529],
                        "e": [17.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [17.333],
                        "e": [18.157]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [18.157],
                        "e": [18.997]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [18.997],
                        "e": [19.847]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [19.847],
                        "e": [20.706]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [20.706],
                        "e": [21.568]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [21.568],
                        "e": [22.432]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [22.432],
                        "e": [23.294]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [23.294],
                        "e": [24.153]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [24.153],
                        "e": [25.003]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [25.003],
                        "e": [25.843]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [25.843],
                        "e": [26.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [26.667],
                        "e": [27.471]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [27.471],
                        "e": [28.247]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [28.247],
                        "e": [28.986]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [28.986],
                        "e": [29.673]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [29.673],
                        "e": [30.283]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [30.283],
                        "e": [30.768]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [30.768],
                        "e": [31]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [31],
                        "e": [30.768]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [30.768],
                        "e": [30.283]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [30.283],
                        "e": [29.673]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [29.673],
                        "e": [28.986]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [28.986],
                        "e": [28.247]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [28.247],
                        "e": [27.471]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [27.471],
                        "e": [26.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [26.667],
                        "e": [25.843]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [25.843],
                        "e": [25.003]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [25.003],
                        "e": [24.153]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [24.153],
                        "e": [23.294]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [23.294],
                        "e": [22.432]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [22.432],
                        "e": [21.568]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [21.568],
                        "e": [20.706]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [20.706],
                        "e": [19.847]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [19.847],
                        "e": [18.997]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [18.997],
                        "e": [18.157]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [18.157],
                        "e": [17.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [17.333],
                        "e": [16.529]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [16.529],
                        "e": [15.753]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [15.753],
                        "e": [15.014]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [15.014],
                        "e": [14.327]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [14.327],
                        "e": [13.717]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [13.717],
                        "e": [13.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.967]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p967_0p167_0p257"],
                        "t": 74,
                        "s": [13.232],
                        "e": [13]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [-0.055]},
                        "n": ["0p833_0p676_0p167_-0p055"],
                        "t": 75,
                        "s": [13],
                        "e": [13.14]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [13.14],
                        "e": [13.545]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [13.545],
                        "e": [14.192]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [14.192],
                        "e": [15.058]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [15.058],
                        "e": [16.12]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [16.12],
                        "e": [17.355]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [17.355],
                        "e": [18.739]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [18.739],
                        "e": [20.25]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [20.25],
                        "e": [21.865]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [21.865],
                        "e": [23.56]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [23.56],
                        "e": [25.313]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [25.313],
                        "e": [27.1]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [27.1],
                        "e": [28.9]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [28.9],
                        "e": [30.687]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [30.687],
                        "e": [32.44]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [32.44],
                        "e": [34.135]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [34.135],
                        "e": [35.75]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [35.75],
                        "e": [37.261]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [37.261],
                        "e": [38.645]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [38.645],
                        "e": [39.88]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [39.88],
                        "e": [40.942]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [40.942],
                        "e": [41.808]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [41.808],
                        "e": [42.455]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [42.455],
                        "e": [42.86]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 17,
            "ty": 4,
            "nm": "line 6",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [559, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [13],
                        "e": [13.103]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [13.103],
                        "e": [13.4]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [13.4],
                        "e": [13.874]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [13.874],
                        "e": [14.509]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [14.509],
                        "e": [15.288]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [15.288],
                        "e": [16.193]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [16.193],
                        "e": [17.209]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [17.209],
                        "e": [18.317]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [18.317],
                        "e": [19.501]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [19.501],
                        "e": [20.744]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [20.744],
                        "e": [22.03]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [22.03],
                        "e": [23.34]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [23.34],
                        "e": [24.66]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [24.66],
                        "e": [25.97]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [25.97],
                        "e": [27.256]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [27.256],
                        "e": [28.499]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [28.499],
                        "e": [29.683]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [29.683],
                        "e": [30.791]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [30.791],
                        "e": [31.807]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [31.807],
                        "e": [32.712]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [32.712],
                        "e": [33.491]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [33.491],
                        "e": [34.126]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [34.126],
                        "e": [34.6]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [34.6],
                        "e": [34.897]
                    }, {
                        "i": {"x": [0.833], "y": [0.467]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p467_0p167_0p324"],
                        "t": 24,
                        "s": [34.897],
                        "e": [35]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.099]},
                        "n": ["0p833_0p743_0p167_0p099"],
                        "t": 25,
                        "s": [35],
                        "e": [35.555]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [35.555],
                        "e": [36.713]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [36.713],
                        "e": [38.171]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [38.171],
                        "e": [39.812]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [39.812],
                        "e": [41.577]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [41.577],
                        "e": [43.431]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [43.431],
                        "e": [45.351]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [45.351],
                        "e": [47.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [47.32],
                        "e": [49.326]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [49.326],
                        "e": [51.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [51.358],
                        "e": [53.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [53.408],
                        "e": [55.468]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [55.468],
                        "e": [57.532]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [57.532],
                        "e": [59.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [59.592],
                        "e": [61.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [61.642],
                        "e": [63.674]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [63.674],
                        "e": [65.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [65.68],
                        "e": [67.649]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [67.649],
                        "e": [69.569]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [69.569],
                        "e": [71.423]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [71.423],
                        "e": [73.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [73.188],
                        "e": [74.829]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [74.829],
                        "e": [76.287]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [76.287],
                        "e": [77.445]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [77.445],
                        "e": [78]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [78],
                        "e": [77.445]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [77.445],
                        "e": [76.287]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [76.287],
                        "e": [74.829]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [74.829],
                        "e": [73.188]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [73.188],
                        "e": [71.423]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [71.423],
                        "e": [69.569]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [69.569],
                        "e": [67.649]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [67.649],
                        "e": [65.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [65.68],
                        "e": [63.674]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [63.674],
                        "e": [61.642]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [61.642],
                        "e": [59.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [59.592],
                        "e": [57.532]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [57.532],
                        "e": [55.468]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [55.468],
                        "e": [53.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [53.408],
                        "e": [51.358]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [51.358],
                        "e": [49.326]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [49.326],
                        "e": [47.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [47.32],
                        "e": [45.351]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [45.351],
                        "e": [43.431]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [43.431],
                        "e": [41.577]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [41.577],
                        "e": [39.812]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [39.812],
                        "e": [38.171]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [38.171],
                        "e": [36.713]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [36.713],
                        "e": [35.555]
                    }, {
                        "i": {"x": [0.833], "y": [0.901]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p901_0p167_0p257"],
                        "t": 74,
                        "s": [35.555],
                        "e": [35]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.533]},
                        "n": ["0p833_0p676_0p167_0p533"],
                        "t": 75,
                        "s": [35],
                        "e": [34.897]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [34.897],
                        "e": [34.6]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [34.6],
                        "e": [34.126]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [34.126],
                        "e": [33.491]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [33.491],
                        "e": [32.712]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [32.712],
                        "e": [31.807]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [31.807],
                        "e": [30.791]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [30.791],
                        "e": [29.683]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [29.683],
                        "e": [28.499]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [28.499],
                        "e": [27.256]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [27.256],
                        "e": [25.97]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [25.97],
                        "e": [24.66]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [24.66],
                        "e": [23.34]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [23.34],
                        "e": [22.03]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [22.03],
                        "e": [20.744]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [20.744],
                        "e": [19.501]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [19.501],
                        "e": [18.317]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [18.317],
                        "e": [17.209]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [17.209],
                        "e": [16.193]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [16.193],
                        "e": [15.288]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [15.288],
                        "e": [14.509]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [14.509],
                        "e": [13.874]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [13.874],
                        "e": [13.4]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [13.4],
                        "e": [13.103]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 18,
            "ty": 4,
            "nm": "line 5",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [538, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [61],
                        "e": [60.822]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [60.822],
                        "e": [60.309]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [60.309],
                        "e": [59.49]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [59.49],
                        "e": [58.393]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [58.393],
                        "e": [57.048]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [57.048],
                        "e": [55.484]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [55.484],
                        "e": [53.731]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [53.731],
                        "e": [51.817]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [51.817],
                        "e": [49.771]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [49.771],
                        "e": [47.624]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [47.624],
                        "e": [45.404]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [45.404],
                        "e": [43.139]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [43.139],
                        "e": [40.861]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [40.861],
                        "e": [38.596]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [38.596],
                        "e": [36.376]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [36.376],
                        "e": [34.229]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [34.229],
                        "e": [32.183]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [32.183],
                        "e": [30.269]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [30.269],
                        "e": [28.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [28.516],
                        "e": [26.952]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [26.952],
                        "e": [25.607]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [25.607],
                        "e": [24.51]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [24.51],
                        "e": [23.691]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [23.691],
                        "e": [23.178]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p82_0p167_0p324"],
                        "t": 24,
                        "s": [23.178],
                        "e": [23]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p743_0p167_0p155"],
                        "t": 25,
                        "s": [23],
                        "e": [22.794]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [22.794],
                        "e": [22.363]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [22.363],
                        "e": [21.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [21.82],
                        "e": [21.21]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [21.21],
                        "e": [20.553]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [20.553],
                        "e": [19.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [19.863],
                        "e": [19.148]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [19.148],
                        "e": [18.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [18.416],
                        "e": [17.669]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [17.669],
                        "e": [16.913]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [16.913],
                        "e": [16.151]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [16.151],
                        "e": [15.384]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [15.384],
                        "e": [14.616]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [14.616],
                        "e": [13.849]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [13.849],
                        "e": [13.087]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [13.087],
                        "e": [12.331]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [12.331],
                        "e": [11.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [11.584],
                        "e": [10.852]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [10.852],
                        "e": [10.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [10.137],
                        "e": [9.447]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [9.447],
                        "e": [8.79]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [8.79],
                        "e": [8.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [8.18],
                        "e": [7.637]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [7.637],
                        "e": [7.206]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [7.206],
                        "e": [7]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [7],
                        "e": [7.206]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [7.206],
                        "e": [7.637]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [7.637],
                        "e": [8.18]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [8.18],
                        "e": [8.79]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [8.79],
                        "e": [9.447]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [9.447],
                        "e": [10.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [10.137],
                        "e": [10.852]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [10.852],
                        "e": [11.584]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [11.584],
                        "e": [12.331]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [12.331],
                        "e": [13.087]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [13.087],
                        "e": [13.849]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [13.849],
                        "e": [14.616]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [14.616],
                        "e": [15.384]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [15.384],
                        "e": [16.151]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [16.151],
                        "e": [16.913]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [16.913],
                        "e": [17.669]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [17.669],
                        "e": [18.416]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [18.416],
                        "e": [19.148]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [19.148],
                        "e": [19.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [19.863],
                        "e": [20.553]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [20.553],
                        "e": [21.21]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [21.21],
                        "e": [21.82]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [21.82],
                        "e": [22.363]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [22.363],
                        "e": [22.794]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p845_0p167_0p257"],
                        "t": 74,
                        "s": [22.794],
                        "e": [23]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p676_0p167_0p18"],
                        "t": 75,
                        "s": [23],
                        "e": [23.178]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [23.178],
                        "e": [23.691]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [23.691],
                        "e": [24.51]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [24.51],
                        "e": [25.607]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [25.607],
                        "e": [26.952]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [26.952],
                        "e": [28.516]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [28.516],
                        "e": [30.269]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [30.269],
                        "e": [32.183]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [32.183],
                        "e": [34.229]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [34.229],
                        "e": [36.376]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [36.376],
                        "e": [38.596]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [38.596],
                        "e": [40.861]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [40.861],
                        "e": [43.139]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [43.139],
                        "e": [45.404]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [45.404],
                        "e": [47.624]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [47.624],
                        "e": [49.771]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [49.771],
                        "e": [51.817]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [51.817],
                        "e": [53.731]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [53.731],
                        "e": [55.484]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [55.484],
                        "e": [57.048]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [57.048],
                        "e": [58.393]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [58.393],
                        "e": [59.49]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [59.49],
                        "e": [60.309]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [60.309],
                        "e": [60.822]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 19,
            "ty": 4,
            "nm": "line 4",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [517, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [22],
                        "e": [22.089]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [22.089],
                        "e": [22.345]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [22.345],
                        "e": [22.755]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [22.755],
                        "e": [23.304]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [23.304],
                        "e": [23.976]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [23.976],
                        "e": [24.758]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [24.758],
                        "e": [25.635]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [25.635],
                        "e": [26.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [26.592],
                        "e": [27.614]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [27.614],
                        "e": [28.688]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [28.688],
                        "e": [29.798]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [29.798],
                        "e": [30.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [30.93],
                        "e": [32.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [32.07],
                        "e": [33.202]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [33.202],
                        "e": [34.312]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [34.312],
                        "e": [35.386]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [35.386],
                        "e": [36.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [36.408],
                        "e": [37.365]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [37.365],
                        "e": [38.242]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [38.242],
                        "e": [39.024]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [39.024],
                        "e": [39.696]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [39.696],
                        "e": [40.245]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [40.245],
                        "e": [40.655]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [40.655],
                        "e": [40.911]
                    }, {
                        "i": {"x": [0.833], "y": [0.674]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p674_0p167_0p324"],
                        "t": 24,
                        "s": [40.911],
                        "e": [41]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p743_0p167_0p112"],
                        "t": 25,
                        "s": [41],
                        "e": [41.258]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [41.258],
                        "e": [41.797]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [41.797],
                        "e": [42.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [42.475],
                        "e": [43.238]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [43.238],
                        "e": [44.059]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [44.059],
                        "e": [44.921]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [44.921],
                        "e": [45.814]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [45.814],
                        "e": [46.73]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [46.73],
                        "e": [47.663]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [47.663],
                        "e": [48.608]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [48.608],
                        "e": [49.562]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [49.562],
                        "e": [50.52]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [50.52],
                        "e": [51.48]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [51.48],
                        "e": [52.438]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [52.438],
                        "e": [53.392]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [53.392],
                        "e": [54.337]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [54.337],
                        "e": [55.27]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [55.27],
                        "e": [56.186]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [56.186],
                        "e": [57.079]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [57.079],
                        "e": [57.941]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [57.941],
                        "e": [58.762]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [58.762],
                        "e": [59.525]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [59.525],
                        "e": [60.203]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [60.203],
                        "e": [60.742]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [60.742],
                        "e": [61]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [61],
                        "e": [60.742]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [60.742],
                        "e": [60.203]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [60.203],
                        "e": [59.525]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [59.525],
                        "e": [58.762]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [58.762],
                        "e": [57.941]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [57.941],
                        "e": [57.079]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [57.079],
                        "e": [56.186]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [56.186],
                        "e": [55.27]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [55.27],
                        "e": [54.337]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [54.337],
                        "e": [53.392]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [53.392],
                        "e": [52.438]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [52.438],
                        "e": [51.48]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [51.48],
                        "e": [50.52]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [50.52],
                        "e": [49.562]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [49.562],
                        "e": [48.608]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [48.608],
                        "e": [47.663]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [47.663],
                        "e": [46.73]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [46.73],
                        "e": [45.814]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [45.814],
                        "e": [44.921]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [44.921],
                        "e": [44.059]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [44.059],
                        "e": [43.238]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [43.238],
                        "e": [42.475]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [42.475],
                        "e": [41.797]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [41.797],
                        "e": [41.258]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_0p888_0p167_0p257"],
                        "t": 74,
                        "s": [41.258],
                        "e": [41]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.326]},
                        "n": ["0p833_0p676_0p167_0p326"],
                        "t": 75,
                        "s": [41],
                        "e": [40.911]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [40.911],
                        "e": [40.655]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [40.655],
                        "e": [40.245]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [40.245],
                        "e": [39.696]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [39.696],
                        "e": [39.024]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [39.024],
                        "e": [38.242]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [38.242],
                        "e": [37.365]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [37.365],
                        "e": [36.408]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [36.408],
                        "e": [35.386]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [35.386],
                        "e": [34.312]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [34.312],
                        "e": [33.202]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [33.202],
                        "e": [32.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [32.07],
                        "e": [30.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [30.93],
                        "e": [29.798]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [29.798],
                        "e": [28.688]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [28.688],
                        "e": [27.614]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [27.614],
                        "e": [26.592]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [26.592],
                        "e": [25.635]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [25.635],
                        "e": [24.758]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [24.758],
                        "e": [23.976]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [23.976],
                        "e": [23.304]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [23.304],
                        "e": [22.755]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [22.755],
                        "e": [22.345]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [22.345],
                        "e": [22.089]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 20,
            "ty": 4,
            "nm": "line 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [496.5, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [45],
                        "e": [44.879]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [44.879],
                        "e": [44.527]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [44.527],
                        "e": [43.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [43.967],
                        "e": [43.216]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [43.216],
                        "e": [42.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [42.296],
                        "e": [41.226]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [41.226],
                        "e": [40.026]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [40.026],
                        "e": [38.717]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [38.717],
                        "e": [37.317]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [37.317],
                        "e": [35.848]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [35.848],
                        "e": [34.329]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [34.329],
                        "e": [32.78]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [32.78],
                        "e": [31.22]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [31.22],
                        "e": [29.671]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [29.671],
                        "e": [28.152]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [28.152],
                        "e": [26.683]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [26.683],
                        "e": [25.283]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [25.283],
                        "e": [23.974]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [23.974],
                        "e": [22.774]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [22.774],
                        "e": [21.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [21.704],
                        "e": [20.784]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [20.784],
                        "e": [20.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [20.033],
                        "e": [19.473]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [19.473],
                        "e": [19.121]
                    }, {
                        "i": {"x": [0.833], "y": [0.926]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p926_0p167_0p324"],
                        "t": 24,
                        "s": [19.121],
                        "e": [19]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.701]},
                        "n": ["0p833_0p743_0p167_-0p701"],
                        "t": 25,
                        "s": [19],
                        "e": [19.013]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [19.013],
                        "e": [19.04]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [19.04],
                        "e": [19.074]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [19.074],
                        "e": [19.112]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [19.112],
                        "e": [19.153]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [19.153],
                        "e": [19.196]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [19.196],
                        "e": [19.241]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [19.241],
                        "e": [19.287]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [19.287],
                        "e": [19.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [19.333],
                        "e": [19.38]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [19.38],
                        "e": [19.428]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [19.428],
                        "e": [19.476]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [19.476],
                        "e": [19.524]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [19.524],
                        "e": [19.572]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [19.572],
                        "e": [19.62]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [19.62],
                        "e": [19.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [19.667],
                        "e": [19.713]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [19.713],
                        "e": [19.759]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [19.759],
                        "e": [19.804]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [19.804],
                        "e": [19.847]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [19.847],
                        "e": [19.888]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [19.888],
                        "e": [19.926]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [19.926],
                        "e": [19.96]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [19.96],
                        "e": [19.987]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [19.987],
                        "e": [20]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [20],
                        "e": [19.987]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [19.987],
                        "e": [19.96]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [19.96],
                        "e": [19.926]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [19.926],
                        "e": [19.888]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [19.888],
                        "e": [19.847]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [19.847],
                        "e": [19.804]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [19.804],
                        "e": [19.759]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [19.759],
                        "e": [19.713]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [19.713],
                        "e": [19.667]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [19.667],
                        "e": [19.62]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [19.62],
                        "e": [19.572]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [19.572],
                        "e": [19.524]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [19.524],
                        "e": [19.476]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [19.476],
                        "e": [19.428]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [19.428],
                        "e": [19.38]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [19.38],
                        "e": [19.333]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [19.333],
                        "e": [19.287]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [19.287],
                        "e": [19.241]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [19.241],
                        "e": [19.196]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [19.196],
                        "e": [19.153]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [19.153],
                        "e": [19.112]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [19.112],
                        "e": [19.074]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [19.074],
                        "e": [19.04]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [19.04],
                        "e": [19.013]
                    }, {
                        "i": {"x": [0.833], "y": [1.701]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1p701_0p167_0p257"],
                        "t": 74,
                        "s": [19.013],
                        "e": [19]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.074]},
                        "n": ["0p833_0p676_0p167_0p074"],
                        "t": 75,
                        "s": [19],
                        "e": [19.121]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [19.121],
                        "e": [19.473]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [19.473],
                        "e": [20.033]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [20.033],
                        "e": [20.784]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [20.784],
                        "e": [21.704]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [21.704],
                        "e": [22.774]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [22.774],
                        "e": [23.974]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [23.974],
                        "e": [25.283]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [25.283],
                        "e": [26.683]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [26.683],
                        "e": [28.152]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [28.152],
                        "e": [29.671]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [29.671],
                        "e": [31.22]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [31.22],
                        "e": [32.78]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [32.78],
                        "e": [34.329]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [34.329],
                        "e": [35.848]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [35.848],
                        "e": [37.317]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [37.317],
                        "e": [38.717]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [38.717],
                        "e": [40.026]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [40.026],
                        "e": [41.226]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [41.226],
                        "e": [42.296]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [42.296],
                        "e": [43.216]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [43.216],
                        "e": [43.967]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [43.967],
                        "e": [44.527]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [44.527],
                        "e": [44.879]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 21,
            "ty": 4,
            "nm": "line 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [476.5, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [18],
                        "e": [18.164]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [18.164],
                        "e": [18.636]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [18.636],
                        "e": [19.391]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [19.391],
                        "e": [20.401]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [20.401],
                        "e": [21.64]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [21.64],
                        "e": [23.08]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [23.08],
                        "e": [24.695]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [24.695],
                        "e": [26.458]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [26.458],
                        "e": [28.342]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [28.342],
                        "e": [30.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [30.32],
                        "e": [32.365]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [32.365],
                        "e": [34.451]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [34.451],
                        "e": [36.549]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [36.549],
                        "e": [38.635]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [38.635],
                        "e": [40.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [40.68],
                        "e": [42.658]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [42.658],
                        "e": [44.542]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [44.542],
                        "e": [46.305]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [46.305],
                        "e": [47.92]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [47.92],
                        "e": [49.36]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [49.36],
                        "e": [50.599]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [50.599],
                        "e": [51.609]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [51.609],
                        "e": [52.364]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [52.364],
                        "e": [52.836]
                    }, {
                        "i": {"x": [0.833], "y": [0.996]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p996_0p167_0p324"],
                        "t": 24,
                        "s": [52.836],
                        "e": [53]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [-0.005]},
                        "n": ["0p833_0p743_0p167_-0p005"],
                        "t": 25,
                        "s": [53],
                        "e": [52.845]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 26,
                        "s": [52.845],
                        "e": [52.522]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 27,
                        "s": [52.522],
                        "e": [52.115]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 28,
                        "s": [52.115],
                        "e": [51.657]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 29,
                        "s": [51.657],
                        "e": [51.165]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 30,
                        "s": [51.165],
                        "e": [50.647]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 31,
                        "s": [50.647],
                        "e": [50.111]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 32,
                        "s": [50.111],
                        "e": [49.562]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 33,
                        "s": [49.562],
                        "e": [49.002]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 34,
                        "s": [49.002],
                        "e": [48.435]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 35,
                        "s": [48.435],
                        "e": [47.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 36,
                        "s": [47.863],
                        "e": [47.288]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 37,
                        "s": [47.288],
                        "e": [46.712]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 38,
                        "s": [46.712],
                        "e": [46.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 39,
                        "s": [46.137],
                        "e": [45.565]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 40,
                        "s": [45.565],
                        "e": [44.998]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 41,
                        "s": [44.998],
                        "e": [44.438]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 42,
                        "s": [44.438],
                        "e": [43.889]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 43,
                        "s": [43.889],
                        "e": [43.353]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 44,
                        "s": [43.353],
                        "e": [42.835]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 45,
                        "s": [42.835],
                        "e": [42.343]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 46,
                        "s": [42.343],
                        "e": [41.885]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 47,
                        "s": [41.885],
                        "e": [41.478]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 48,
                        "s": [41.478],
                        "e": [41.155]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1_0p167_0p257"],
                        "t": 49,
                        "s": [41.155],
                        "e": [41]
                    }, {
                        "i": {"x": [0.833], "y": [0.743]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p743_0p167_0"],
                        "t": 50,
                        "s": [41],
                        "e": [41.155]
                    }, {
                        "i": {"x": [0.833], "y": [0.812]},
                        "o": {"x": [0.167], "y": [0.123]},
                        "n": ["0p833_0p812_0p167_0p123"],
                        "t": 51,
                        "s": [41.155],
                        "e": [41.478]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.15]},
                        "n": ["0p833_0p823_0p167_0p15"],
                        "t": 52,
                        "s": [41.478],
                        "e": [41.885]
                    }, {
                        "i": {"x": [0.833], "y": [0.827]},
                        "o": {"x": [0.167], "y": [0.157]},
                        "n": ["0p833_0p827_0p167_0p157"],
                        "t": 53,
                        "s": [41.885],
                        "e": [42.343]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 54,
                        "s": [42.343],
                        "e": [42.835]
                    }, {
                        "i": {"x": [0.833], "y": [0.83]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p83_0p167_0p163"],
                        "t": 55,
                        "s": [42.835],
                        "e": [43.353]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p831_0p167_0p164"],
                        "t": 56,
                        "s": [43.353],
                        "e": [43.889]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 57,
                        "s": [43.889],
                        "e": [44.438]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p832_0p167_0p165"],
                        "t": 58,
                        "s": [44.438],
                        "e": [44.998]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 59,
                        "s": [44.998],
                        "e": [45.565]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 60,
                        "s": [45.565],
                        "e": [46.137]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p833_0p167_0p166"],
                        "t": 61,
                        "s": [46.137],
                        "e": [46.712]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 62,
                        "s": [46.712],
                        "e": [47.288]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 63,
                        "s": [47.288],
                        "e": [47.863]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 64,
                        "s": [47.863],
                        "e": [48.435]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p834_0p167_0p167"],
                        "t": 65,
                        "s": [48.435],
                        "e": [49.002]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 66,
                        "s": [49.002],
                        "e": [49.562]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p835_0p167_0p168"],
                        "t": 67,
                        "s": [49.562],
                        "e": [50.111]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p836_0p167_0p169"],
                        "t": 68,
                        "s": [50.111],
                        "e": [50.647]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.17]},
                        "n": ["0p833_0p837_0p167_0p17"],
                        "t": 69,
                        "s": [50.647],
                        "e": [51.165]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 70,
                        "s": [51.165],
                        "e": [51.657]
                    }, {
                        "i": {"x": [0.833], "y": [0.843]},
                        "o": {"x": [0.167], "y": [0.173]},
                        "n": ["0p833_0p843_0p167_0p173"],
                        "t": 71,
                        "s": [51.657],
                        "e": [52.115]
                    }, {
                        "i": {"x": [0.833], "y": [0.85]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p85_0p167_0p177"],
                        "t": 72,
                        "s": [52.115],
                        "e": [52.522]
                    }, {
                        "i": {"x": [0.833], "y": [0.877]},
                        "o": {"x": [0.167], "y": [0.188]},
                        "n": ["0p833_0p877_0p167_0p188"],
                        "t": 73,
                        "s": [52.522],
                        "e": [52.845]
                    }, {
                        "i": {"x": [0.833], "y": [1.005]},
                        "o": {"x": [0.167], "y": [0.257]},
                        "n": ["0p833_1p005_0p167_0p257"],
                        "t": 74,
                        "s": [52.845],
                        "e": [53]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.004]},
                        "n": ["0p833_0p676_0p167_0p004"],
                        "t": 75,
                        "s": [53],
                        "e": [52.836]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [52.836],
                        "e": [52.364]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [52.364],
                        "e": [51.609]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [51.609],
                        "e": [50.599]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [50.599],
                        "e": [49.36]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [49.36],
                        "e": [47.92]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [47.92],
                        "e": [46.305]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [46.305],
                        "e": [44.542]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [44.542],
                        "e": [42.658]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [42.658],
                        "e": [40.68]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [40.68],
                        "e": [38.635]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [38.635],
                        "e": [36.549]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [36.549],
                        "e": [34.451]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [34.451],
                        "e": [32.365]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [32.365],
                        "e": [30.32]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [30.32],
                        "e": [28.342]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [28.342],
                        "e": [26.458]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [26.458],
                        "e": [24.695]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [24.695],
                        "e": [23.08]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [23.08],
                        "e": [21.64]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [21.64],
                        "e": [20.401]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [20.401],
                        "e": [19.391]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [19.391],
                        "e": [18.636]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [18.636],
                        "e": [18.164]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 22,
            "ty": 4,
            "nm": "line 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 30, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [455.5, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-335, -141.5], [-335, -63]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 15, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p676_0p167_0p167"],
                        "t": 0,
                        "s": [31],
                        "e": [30.855]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 1,
                        "s": [30.855],
                        "e": [30.437]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 2,
                        "s": [30.437],
                        "e": [29.768]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 3,
                        "s": [29.768],
                        "e": [28.873]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 4,
                        "s": [28.873],
                        "e": [27.776]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 5,
                        "s": [27.776],
                        "e": [26.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 6,
                        "s": [26.5],
                        "e": [25.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 7,
                        "s": [25.07],
                        "e": [23.508]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 8,
                        "s": [23.508],
                        "e": [21.84]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 9,
                        "s": [21.84],
                        "e": [20.088]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 10,
                        "s": [20.088],
                        "e": [18.277]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 11,
                        "s": [18.277],
                        "e": [16.43]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 12,
                        "s": [16.43],
                        "e": [14.57]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 13,
                        "s": [14.57],
                        "e": [12.723]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 14,
                        "s": [12.723],
                        "e": [10.912]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 15,
                        "s": [10.912],
                        "e": [9.16]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 16,
                        "s": [9.16],
                        "e": [7.492]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 17,
                        "s": [7.492],
                        "e": [5.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 18,
                        "s": [5.93],
                        "e": [4.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 19,
                        "s": [4.5],
                        "e": [3.224]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 20,
                        "s": [3.224],
                        "e": [2.127]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 21,
                        "s": [2.127],
                        "e": [1.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 22,
                        "s": [1.232],
                        "e": [0.563]
                    }, {
                        "i": {"x": [0.833], "y": [0.888]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p888_0p167_0p216"],
                        "t": 23,
                        "s": [0.563],
                        "e": [0.145]
                    }, {
                        "i": {"x": [0.833], "y": [0.917]},
                        "o": {"x": [0.167], "y": [0.324]},
                        "n": ["0p833_0p917_0p167_0p324"],
                        "t": 24,
                        "s": [0.145],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [-0.012]},
                        "n": ["0p833_1_0p167_-0p012"],
                        "t": 25,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 26,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 27,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 28,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 29,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 30,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 31,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 32,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 33,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 34,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 35,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 36,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 37,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 38,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 39,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 40,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 41,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 42,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 43,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 44,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 45,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 46,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 47,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 48,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 49,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 50,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 51,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 52,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 53,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 54,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 55,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 56,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 57,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 58,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 59,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 60,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 61,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 62,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 63,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 64,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 65,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 66,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 67,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 68,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 69,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 70,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 71,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 72,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [1]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_1_0p167_0"],
                        "t": 73,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.988]},
                        "o": {"x": [0.167], "y": [0]},
                        "n": ["0p833_0p988_0p167_0"],
                        "t": 74,
                        "s": [0],
                        "e": [0]
                    }, {
                        "i": {"x": [0.833], "y": [0.676]},
                        "o": {"x": [0.167], "y": [0.083]},
                        "n": ["0p833_0p676_0p167_0p083"],
                        "t": 75,
                        "s": [0],
                        "e": [0.145]
                    }, {
                        "i": {"x": [0.833], "y": [0.784]},
                        "o": {"x": [0.167], "y": [0.112]},
                        "n": ["0p833_0p784_0p167_0p112"],
                        "t": 76,
                        "s": [0.145],
                        "e": [0.563]
                    }, {
                        "i": {"x": [0.833], "y": [0.805]},
                        "o": {"x": [0.167], "y": [0.136]},
                        "n": ["0p833_0p805_0p167_0p136"],
                        "t": 77,
                        "s": [0.563],
                        "e": [1.232]
                    }, {
                        "i": {"x": [0.833], "y": [0.814]},
                        "o": {"x": [0.167], "y": [0.146]},
                        "n": ["0p833_0p814_0p167_0p146"],
                        "t": 78,
                        "s": [1.232],
                        "e": [2.127]
                    }, {
                        "i": {"x": [0.833], "y": [0.82]},
                        "o": {"x": [0.167], "y": [0.151]},
                        "n": ["0p833_0p82_0p167_0p151"],
                        "t": 79,
                        "s": [2.127],
                        "e": [3.224]
                    }, {
                        "i": {"x": [0.833], "y": [0.823]},
                        "o": {"x": [0.167], "y": [0.155]},
                        "n": ["0p833_0p823_0p167_0p155"],
                        "t": 80,
                        "s": [3.224],
                        "e": [4.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.826]},
                        "o": {"x": [0.167], "y": [0.158]},
                        "n": ["0p833_0p826_0p167_0p158"],
                        "t": 81,
                        "s": [4.5],
                        "e": [5.93]
                    }, {
                        "i": {"x": [0.833], "y": [0.828]},
                        "o": {"x": [0.167], "y": [0.16]},
                        "n": ["0p833_0p828_0p167_0p16"],
                        "t": 82,
                        "s": [5.93],
                        "e": [7.492]
                    }, {
                        "i": {"x": [0.833], "y": [0.829]},
                        "o": {"x": [0.167], "y": [0.161]},
                        "n": ["0p833_0p829_0p167_0p161"],
                        "t": 83,
                        "s": [7.492],
                        "e": [9.16]
                    }, {
                        "i": {"x": [0.833], "y": [0.831]},
                        "o": {"x": [0.167], "y": [0.163]},
                        "n": ["0p833_0p831_0p167_0p163"],
                        "t": 84,
                        "s": [9.16],
                        "e": [10.912]
                    }, {
                        "i": {"x": [0.833], "y": [0.832]},
                        "o": {"x": [0.167], "y": [0.164]},
                        "n": ["0p833_0p832_0p167_0p164"],
                        "t": 85,
                        "s": [10.912],
                        "e": [12.723]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.165]},
                        "n": ["0p833_0p833_0p167_0p165"],
                        "t": 86,
                        "s": [12.723],
                        "e": [14.57]
                    }, {
                        "i": {"x": [0.833], "y": [0.834]},
                        "o": {"x": [0.167], "y": [0.166]},
                        "n": ["0p833_0p834_0p167_0p166"],
                        "t": 87,
                        "s": [14.57],
                        "e": [16.43]
                    }, {
                        "i": {"x": [0.833], "y": [0.835]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p835_0p167_0p167"],
                        "t": 88,
                        "s": [16.43],
                        "e": [18.277]
                    }, {
                        "i": {"x": [0.833], "y": [0.836]},
                        "o": {"x": [0.167], "y": [0.168]},
                        "n": ["0p833_0p836_0p167_0p168"],
                        "t": 89,
                        "s": [18.277],
                        "e": [20.088]
                    }, {
                        "i": {"x": [0.833], "y": [0.837]},
                        "o": {"x": [0.167], "y": [0.169]},
                        "n": ["0p833_0p837_0p167_0p169"],
                        "t": 90,
                        "s": [20.088],
                        "e": [21.84]
                    }, {
                        "i": {"x": [0.833], "y": [0.839]},
                        "o": {"x": [0.167], "y": [0.171]},
                        "n": ["0p833_0p839_0p167_0p171"],
                        "t": 91,
                        "s": [21.84],
                        "e": [23.508]
                    }, {
                        "i": {"x": [0.833], "y": [0.84]},
                        "o": {"x": [0.167], "y": [0.172]},
                        "n": ["0p833_0p84_0p167_0p172"],
                        "t": 92,
                        "s": [23.508],
                        "e": [25.07]
                    }, {
                        "i": {"x": [0.833], "y": [0.842]},
                        "o": {"x": [0.167], "y": [0.174]},
                        "n": ["0p833_0p842_0p167_0p174"],
                        "t": 93,
                        "s": [25.07],
                        "e": [26.5]
                    }, {
                        "i": {"x": [0.833], "y": [0.845]},
                        "o": {"x": [0.167], "y": [0.177]},
                        "n": ["0p833_0p845_0p167_0p177"],
                        "t": 94,
                        "s": [26.5],
                        "e": [27.776]
                    }, {
                        "i": {"x": [0.833], "y": [0.849]},
                        "o": {"x": [0.167], "y": [0.18]},
                        "n": ["0p833_0p849_0p167_0p18"],
                        "t": 95,
                        "s": [27.776],
                        "e": [28.873]
                    }, {
                        "i": {"x": [0.833], "y": [0.854]},
                        "o": {"x": [0.167], "y": [0.186]},
                        "n": ["0p833_0p854_0p167_0p186"],
                        "t": 96,
                        "s": [28.873],
                        "e": [29.768]
                    }, {
                        "i": {"x": [0.833], "y": [0.864]},
                        "o": {"x": [0.167], "y": [0.195]},
                        "n": ["0p833_0p864_0p167_0p195"],
                        "t": 97,
                        "s": [29.768],
                        "e": [30.437]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.216]},
                        "n": ["0p833_0p833_0p167_0p216"],
                        "t": 98,
                        "s": [30.437],
                        "e": [30.855]
                    }, {"t": 99}],
                    "ix": 1
                },
                "e": {"a": 0, "k": 100, "ix": 2},
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 23,
            "ty": 4,
            "nm": "box",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 20, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [213, 348, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.812, 0], [0, 0], [0, -1.813], [0, 0], [-1.812, 0], [0, 0], [0, 1.813], [0, 0]],
                            "o": [[0, 0], [-1.812, 0], [0, 0], [0, 1.813], [0, 0], [1.812, 0], [0, 0], [0, -1.813]],
                            "v": [[108.077, -46.895], [-108.077, -46.895], [-111.359, -43.613], [-111.359, 43.613],
                                [-108.077, 46.895], [108.077, 46.895], [111.359, 43.613], [111.359, -43.613]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.376470588235, 0.956862745098, 0.874509803922, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 24,
            "ty": 4,
            "nm": "inner screen",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 20, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[359.147, -156.695], [-359.147, -156.695], [-359.147, 156.695], [359.147, 156.695]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.376470588235, 0.956862745098, 0.874509803922, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 25,
            "ty": 4,
            "nm": "button",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 644, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[9.091, 0], [0, 0], [0, 9.091], [0, 0], [-9.091, 0], [0, 0], [0, -9.091], [0, 0]],
                            "o": [[0, 0], [-9.091, 0], [0, 0], [0, -9.091], [0, 0], [9.091, 0], [0, 0], [0, 9.091]],
                            "v": [[0, 16.529], [0, 16.529], [-16.529, 0], [-16.529, 0], [0, -16.529], [0, -16.529],
                                [16.529, 0], [16.529, 0]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.23137254902, 0.356862745098, 0.819607843137, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 26,
            "ty": 4,
            "nm": "shade",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [264, 264, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[3.72, 0], [0, 0], [0, -3.72], [0, 0], [-3.73, 0], [0, 0], [0, 3.72], [0, 0]],
                            "o": [[0, 0], [-3.73, 0], [0, 0], [0, 3.72], [0, 0], [3.72, 0], [0, 0], [0, -3.72]],
                            "v": [[129, -84.935], [-129, -84.935], [-135.74, -78.195], [-135.74, 78.196],
                                [-129, 84.935], [129, 84.935], [135.74, 78.196], [135.74, -78.195]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.290196078431, 0.388235294118, 0.996078431373, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 27,
            "ty": 4,
            "nm": "screen",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 450, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, -9.824], [0, 0], [9.824, 0], [0, 0], [0, 9.824], [0, 0], [-9.851, 0], [0, 0]],
                            "o": [[0, 0], [0, 9.824], [0, 0], [-9.851, 0], [0, 0], [0, -9.824], [0, 0], [9.824, 0]],
                            "v": [[358.478, -206.507], [358.478, 206.507], [340.678, 224.306], [-340.678, 224.306],
                                [-358.478, 206.507], [-358.478, -206.507], [-340.678, -224.306], [340.678, -224.306]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.643137254902, 0.839215686275, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 28,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 681, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[72.942, 51.894], [-72.942, 51.894], [-57.096, -10.405], [-53.505, -24.534],
                                [-46.533, -51.894], [46.533, -51.894], [53.505, -24.534], [57.097, -10.405]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.290196078431, 0.388235294118, 0.996078431373, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 29,
            "ty": 4,
            "nm": "Shape Layer 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [450, 741, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[6.187, 0], [0, 0], [0, 6.187], [0, 0], [-6.187, 0], [0, 0], [0, -6.187], [0, 0]],
                            "o": [[0, 0], [-6.187, 0], [0, 0], [0, -6.187], [0, 0], [6.187, 0], [0, 0], [0, 6.187]],
                            "v": [[128.929, 11.203], [-128.929, 11.203], [-140.132, 0], [-140.132, 0],
                                [-128.929, -11.203], [128.929, -11.203], [140.132, 0], [140.132, 0]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.258823529412, 0.364705882353, 0.886274509804, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_8", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "mask",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [115, 65, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-106.5, -36], [106.5, -37.5], [-110.5, -30.5], [-110, -18], [60, -18.5], [60, -13],
                                [-111.5, -13], [-110, -0.5], [108, 0], [108.5, 6], [-111, 4], [-110, 17.5], [88, 17],
                                [87.5, 23.5], [-109.5, 25], [-110.5, 36.5], [98.5, 35]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 10, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {"a": 0, "k": 0, "ix": 1},
                "e": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [100]
                    }, {
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 50,
                        "s": [100],
                        "e": [100]
                    }, {"t": 99}],
                    "ix": 2
                },
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 4",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 50, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [107, 61, 0], "ix": 2},
                "a": {"a": 0, "k": [-244, 100, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0]],
                            "v": [[-340, 68], [-132.5, 68]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 8, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "rp",
                    "c": {"a": 0, "k": 5, "ix": 1},
                    "o": {"a": 0, "k": 0, "ix": 2},
                    "m": 1,
                    "ix": 4,
                    "tr": {
                        "ty": "tr",
                        "p": {"a": 0, "k": [0, 18], "ix": 2},
                        "a": {"a": 0, "k": [0, 0], "ix": 1},
                        "s": {"a": 0, "k": [100, 100], "ix": 3},
                        "r": {"a": 0, "k": 0, "ix": 4},
                        "so": {"a": 0, "k": 100, "ix": 5},
                        "eo": {"a": 0, "k": 100, "ix": 6},
                        "nm": "Transform"
                    },
                    "nm": "Repeater 1",
                    "mn": "ADBE Vector Filter - Repeater",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [-238, 107], "ix": 2},
                    "a": {"a": 0, "k": [-238, 108], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_9", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "box 2",
            "td": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 20, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [250, 175, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.813, 0], [0, 0], [0, -1.813], [0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0]],
                            "o": [[0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0], [1.813, 0], [0, 0], [0, -1.813]],
                            "v": [[223.622, -96.894], [-223.622, -96.894], [-226.904, -93.612], [-226.904, 93.612],
                                [-223.622, 96.894], [223.622, 96.894], [226.904, 93.612], [226.904, -93.612]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 1",
            "tt": 1,
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [250, 175, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr", "it": [{
                    "ind": 0, "ty": "sh", "ix": 1, "ks": {
                        "a": 1,
                        "k": [{
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p833_0p167_0p167",
                            "t": 0,
                            "s": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [107.505, 20.584], [21.005, 16.281], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-71.767, 9.06],
                                    [-38.851, -7.439], [-24.535, -19.017], [0, 0]],
                                "v": [[-237, 60], [-151, -59], [-75, 58], [0, -69], [72, 60], [126, -38], [189, 57],
                                    [232, 1], [230.061, 124.695], [-110.332, 137.732], [-255.147, 125.899], [-237, 60]],
                                "c": false
                            }],
                            "e": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-151, -77], [-75, 58], [0, -50], [72, 60], [127, -63], [189, 57],
                                    [232, 1], [231.061, 138.695], [-260.722, 139.801], [-273.172, 83.766], [-237, 60]],
                                "c": false
                            }]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p833_0p167_0p167",
                            "t": 25,
                            "s": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-151, -77], [-75, 58], [0, -50], [72, 60], [127, -63], [189, 57],
                                    [232, 1], [231.061, 138.695], [-260.722, 139.801], [-273.172, 83.766], [-237, 60]],
                                "c": false
                            }],
                            "e": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-150, -53], [-75, 58], [2, -72], [72, 60], [129, -41], [189, 57],
                                    [232, 1], [236.061, 163.695], [-290.722, 143.801], [-272.172, 79.766], [-237, 60]],
                                "c": false
                            }]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p833_0p167_0p167",
                            "t": 50,
                            "s": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-150, -53], [-75, 58], [2, -72], [72, 60], [129, -41], [189, 57],
                                    [232, 1], [236.061, 163.695], [-290.722, 143.801], [-272.172, 79.766], [-237, 60]],
                                "c": false
                            }],
                            "e": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-150, -73], [-75, 58], [2, -17], [72, 60], [132, -68], [189, 57],
                                    [232, 1], [271.061, 157.695], [-185.722, 156.801], [-285.172, 129.766], [-237, 60]],
                                "c": false
                            }]
                        }, {
                            "i": {"x": 0.833, "y": 0.833},
                            "o": {"x": 0.167, "y": 0.167},
                            "n": "0p833_0p833_0p167_0p167",
                            "t": 75,
                            "s": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-150, -73], [-75, 58], [2, -17], [72, 60], [132, -68], [189, 57],
                                    [232, 1], [271.061, 157.695], [-185.722, 156.801], [-285.172, 129.766], [-237, 60]],
                                "c": false
                            }],
                            "e": [{
                                "i": [[-4.654, 0.776], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [37.026, -4.674], [91.986, -11.551], [12.094, -1.518], [0, 0]],
                                "o": [[6, -1], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0],
                                    [-86.597, 10.932], [-28.832, 3.62], [-6.353, 0.797], [0, 0]],
                                "v": [[-237, 60], [-151, -59], [-75, 58], [0, -69], [72, 60], [126, -38], [189, 57],
                                    [232, 1], [244.061, 130.695], [-246.722, 150.801], [-305.172, 126.766], [-237, 60]],
                                "c": false
                            }]
                        }, {"t": 99}],
                        "ix": 2
                    }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 16, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }], "nm": "Shape 1", "np": 3, "cix": 2, "ix": 1, "mn": "ADBE Vector Group", "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "box",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 20, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [250, 175, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[1.813, 0], [0, 0], [0, -1.813], [0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0]],
                            "o": [[0, 0], [-1.813, 0], [0, 0], [0, 1.813], [0, 0], [1.813, 0], [0, 0], [0, -1.813]],
                            "v": [[223.622, -96.894], [-223.622, -96.894], [-226.904, -93.612], [-226.904, 93.612],
                                [-223.622, 96.894], [223.622, 96.894], [226.904, 93.612], [226.904, -93.612]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_10", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 4",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [125, 50, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                            "v": [[-97.5, 29.5], [-64, -15.5], [-32, 25.5], [21.5, -31.5], [63.5, 10.5],
                                [102.5, -33.5]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 3, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Shape 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "tm",
                "s": {"a": 0, "k": 0, "ix": 1},
                "e": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.357], "y": [1]},
                        "o": {"x": [0.333], "y": [0]},
                        "n": ["0p357_1_0p333_0"],
                        "t": 0,
                        "s": [0],
                        "e": [100]
                    }, {
                        "i": {"x": [0.667], "y": [1]},
                        "o": {"x": [0.333], "y": [0]},
                        "n": ["0p667_1_0p333_0"],
                        "t": 50,
                        "s": [100],
                        "e": [100]
                    }, {"t": 99}],
                    "ix": 2,
                    "x": "var $bm_rt;\n$bm_rt = loopOut('cycle');"
                },
                "o": {"a": 0, "k": 0, "ix": 3},
                "m": 1,
                "ix": 2,
                "nm": "Trim Paths 1",
                "mn": "ADBE Vector Filter - Trim",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_11", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "flower backdraft Outlines",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [960, 540, 0], "ix": 2},
                "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [17.797, 24.611], [0, 0], [24.684, 11.381], [0, 0], [19.513, 5.322], [0, 0],
                                [15.009, -27.054], [0, 0]],
                            "o": [[0, 0], [-11.225, -15.524], [0, 0], [-25.628, -11.817], [0, 0], [-19.516, -5.322],
                                [0, 0], [-13.618, 24.549], [0, 0]],
                            "v": [[13.784, 50.913], [69.804, -2.244], [36.892, 8.601], [40.742, -40.118],
                                [1.365, -8.897], [-8.613, -51.935], [-29.611, -14.219], [-69.82, -21.094],
                                [-42.1, 52.391]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.642999985639, 0.838999968884, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [610.114, 457.768], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 1",
                "np": 2,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[-4.435, 47.398], [23.385, -2.467], [10.253, 22.894], [23.646, -30.59],
                                [65.631, 24.337], [2.31, -14.408], [67.407, -6.104], [0, 0]],
                            "o": [[1.27, -13.57], [31.081, -17.656], [-21.448, -47.9], [0, 0], [-58.639, -21.743],
                                [2.121, -13.761], [-70.962, 6.425], [0, 0]],
                            "v": [[138.872, 85.073], [86.283, 73.944], [134.215, 10.522], [19.328, 46.016],
                                [59.115, -110.728], [-26.293, 24.78], [-73.506, -111.596], [-132.937, 112.896]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.470999983245, 0.885999971278, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [648.836, 386.788], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 3",
                "np": 2,
                "cix": 2,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [-17.796, 24.61], [0, 0], [-24.684, 11.381], [0, 0], [-19.514, 5.323], [0, 0],
                                [-15.009, -27.054], [0, 0]],
                            "o": [[0, 0], [11.226, -15.524], [0, 0], [25.627, -11.818], [0, 0], [19.516, -5.322],
                                [0, 0], [13.618, 24.549], [0, 0]],
                            "v": [[-13.783, 50.914], [-69.804, -2.243], [-36.891, 8.602], [-40.741, -40.117],
                                [-1.365, -8.896], [8.614, -51.935], [29.611, -14.218], [69.82, -21.093],
                                [42.1, 52.392]],
                            "c": false
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.642999985639, 0.838999968884, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [256.852, 452.776], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 4",
                "np": 2,
                "cix": 2,
                "ix": 3,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.5, 0.5, 0.5, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [220.071, 386.044], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 5",
                "np": 1,
                "cix": 2,
                "ix": 4,
                "mn": "ADBE Vector Group",
                "hd": false
            }, {
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[0, 0], [0, 0], [70.963, 6.426], [-2.121, -13.761], [58.64, -21.744], [0, 0],
                                [21.448, -47.9], [-25.579, -15.289], [5.423, -6.631], [-59.431, -1.182],
                                [-5.191, 5.842], [0, 0]],
                            "o": [[0, 0], [0, 0], [-67.406, -6.103], [-2.31, -14.408], [-65.63, 24.337],
                                [-23.645, -30.591], [-9.352, 20.885], [-16.365, -3.857], [-15.113, 18.481],
                                [21.537, 0.428], [0.721, 0.228], [0, 0]],
                            "v": [[132.103, 121.025], [109.927, 45.861], [72.672, -103.467], [25.46, 32.91],
                                [-59.949, -102.598], [-20.162, 54.146], [-126.183, 26.191], [-89.913, 81.807],
                                [-128.522, 82.719], [-53.287, 123.914], [-15.654, 114.047], [-14.48, 114.414]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0.470999983245, 0.885999971278, 1, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [220.071, 378.658], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 6",
                "np": 2,
                "cix": 2,
                "ix": 5,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_12", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "gear 1 Outlines",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.833], "y": [0.833]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "n": ["0p833_0p833_0p167_0p167"],
                        "t": 0,
                        "s": [0],
                        "e": [90]
                    }, {"t": 99}],
                    "ix": 10
                },
                "p": {"a": 0, "k": [368.314, 368.761, 0], "ix": 2},
                "a": {"a": 0, "k": [468.314, 468.761, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                        "a": 0,
                        "k": {
                            "i": [[75.76, 0], [0, 75.759], [-75.759, 0], [0, -75.763]],
                            "o": [[-75.759, 0], [0, -75.763], [75.76, 0], [0, 75.759]],
                            "v": [[-0.002, 137.178], [-137.179, 0.002], [-0.002, -137.177], [137.177, 0.002]],
                            "c": true
                        },
                        "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                }, {
                    "ind": 1, "ty": "sh", "ix": 2, "ks": {
                        "a": 0, "k": {
                            "i": [[10.857, 17.826], [0, 0], [32.144, 20.423], [0, 0], [20.839, 5.071], [0, 0],
                                [19.418, 0], [18.191, -4.058], [0, 0], [17.826, -10.858], [0, 0], [20.422, -32.145],
                                [0, 0], [5.07, -20.839], [0, 0], [0, -19.414], [-4.059, -18.193], [0, 0],
                                [-10.861, -17.825], [0, 0], [-32.141, -20.422], [0, 0], [-20.84, -5.069], [0, 0],
                                [-19.417, 0], [-18.194, 4.058], [0, 0], [-17.827, 10.859], [0, 0], [-20.422, 32.144],
                                [0, 0], [-5.069, 20.838], [0, 0], [0, 19.422], [4.062, 18.193], [0, 0]],
                            "o": [[0, 0], [-20.422, -32.141], [0, 0], [-17.827, -10.858], [0, 0], [-18.194, -4.058],
                                [-19.417, 0], [0, 0], [-20.837, 5.071], [0, 0], [-32.141, 20.42], [0, 0],
                                [-10.861, 17.826], [0, 0], [-4.059, 18.193], [0, 19.422], [0, 0], [5.07, 20.838],
                                [0, 0], [20.422, 32.144], [0, 0], [17.826, 10.859], [0, 0], [18.192, 4.061],
                                [19.418, 0], [0, 0], [20.839, -5.069], [0, 0], [32.144, -20.422], [0, 0],
                                [10.857, -17.825], [0, 0], [4.062, -18.193], [0, -19.414], [0, 0], [-5.069, -20.839]],
                            "v": [[176.276, -107.124], [218.1, -138.272], [138.269, -218.097], [107.124, -176.276],
                                [48.9, -200.414], [56.459, -252.076], [-0.002, -258.45], [-56.459, -252.076],
                                [-48.902, -200.414], [-107.124, -176.276], [-138.272, -218.097], [-218.099, -138.268],
                                [-176.275, -107.124], [-200.416, -48.9], [-252.078, -56.459], [-258.452, -0.002],
                                [-252.078, 56.461], [-200.416, 48.901], [-176.275, 107.123], [-218.099, 138.269],
                                [-138.272, 218.099], [-107.124, 176.276], [-48.902, 200.416], [-56.46, 252.076],
                                [-0.002, 258.45], [56.459, 252.076], [48.9, 200.416], [107.124, 176.276],
                                [138.269, 218.099], [218.1, 138.269], [176.276, 107.123], [200.413, 48.901],
                                [252.076, 56.461], [258.452, -0.002], [252.076, -56.461], [200.413, -48.9]],
                            "c": true
                        }, "ix": 2
                    }, "nm": "Path 2", "mn": "ADBE Vector Shape - Group", "hd": false
                }, {
                    "ty": "fl",
                    "c": {"a": 0, "k": [0, 0.642999985639, 0.838999968884, 1], "ix": 4},
                    "o": {"a": 0, "k": 100, "ix": 5},
                    "r": 1,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [468.314, 468.761], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Group 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }, {
        "id": "comp_13", "layers": [{
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [350, 350, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "d": 1,
                    "ty": "el",
                    "s": {"a": 0, "k": [562, 562], "ix": 2},
                    "p": {"a": 0, "k": [0, 0], "ix": 3},
                    "nm": "Ellipse Path 1",
                    "mn": "ADBE Vector Shape - Ellipse",
                    "hd": false
                }, {
                    "ty": "tm",
                    "s": {"a": 0, "k": 0, "ix": 1},
                    "e": {"a": 0, "k": 14, "ix": 2},
                    "o": {"a": 0, "k": 243.985, "ix": 3},
                    "m": 1,
                    "ix": 2,
                    "nm": "Trim Paths 1",
                    "mn": "ADBE Vector Filter - Trim",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.029619375865, 0.628648047354, 0.839215686275, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 60, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Ellipse 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 2",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [350, 350, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "d": 1,
                    "ty": "el",
                    "s": {"a": 0, "k": [562, 562], "ix": 2},
                    "p": {"a": 0, "k": [0, 0], "ix": 3},
                    "nm": "Ellipse Path 1",
                    "mn": "ADBE Vector Shape - Ellipse",
                    "hd": false
                }, {
                    "ty": "tm", "s": {"a": 0, "k": 0, "ix": 1}, "e": {"a": 0, "k": 25, "ix": 2}, "o": {
                        "a": 1,
                        "k": [{
                            "i": {"x": [0.833], "y": [0.671]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p671_0p167_0p167"],
                            "t": 0,
                            "s": [-42],
                            "e": [-41.845]
                        }, {
                            "i": {"x": [0.833], "y": [0.781]},
                            "o": {"x": [0.167], "y": [0.112]},
                            "n": ["0p833_0p781_0p167_0p112"],
                            "t": 1,
                            "s": [-41.845],
                            "e": [-41.388]
                        }, {
                            "i": {"x": [0.833], "y": [0.802]},
                            "o": {"x": [0.167], "y": [0.134]},
                            "n": ["0p833_0p802_0p167_0p134"],
                            "t": 2,
                            "s": [-41.388],
                            "e": [-40.642]
                        }, {
                            "i": {"x": [0.833], "y": [0.812]},
                            "o": {"x": [0.167], "y": [0.144]},
                            "n": ["0p833_0p812_0p167_0p144"],
                            "t": 3,
                            "s": [-40.642],
                            "e": [-39.619]
                        }, {
                            "i": {"x": [0.833], "y": [0.817]},
                            "o": {"x": [0.167], "y": [0.15]},
                            "n": ["0p833_0p817_0p167_0p15"],
                            "t": 4,
                            "s": [-39.619],
                            "e": [-38.332]
                        }, {
                            "i": {"x": [0.833], "y": [0.82]},
                            "o": {"x": [0.167], "y": [0.153]},
                            "n": ["0p833_0p82_0p167_0p153"],
                            "t": 5,
                            "s": [-38.332],
                            "e": [-36.794]
                        }, {
                            "i": {"x": [0.833], "y": [0.823]},
                            "o": {"x": [0.167], "y": [0.155]},
                            "n": ["0p833_0p823_0p167_0p155"],
                            "t": 6,
                            "s": [-36.794],
                            "e": [-35.016]
                        }, {
                            "i": {"x": [0.833], "y": [0.824]},
                            "o": {"x": [0.167], "y": [0.157]},
                            "n": ["0p833_0p824_0p167_0p157"],
                            "t": 7,
                            "s": [-35.016],
                            "e": [-33.012]
                        }, {
                            "i": {"x": [0.833], "y": [0.826]},
                            "o": {"x": [0.167], "y": [0.159]},
                            "n": ["0p833_0p826_0p167_0p159"],
                            "t": 8,
                            "s": [-33.012],
                            "e": [-30.795]
                        }, {
                            "i": {"x": [0.833], "y": [0.827]},
                            "o": {"x": [0.167], "y": [0.16]},
                            "n": ["0p833_0p827_0p167_0p16"],
                            "t": 9,
                            "s": [-30.795],
                            "e": [-28.376]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p828_0p167_0p161"],
                            "t": 10,
                            "s": [-28.376],
                            "e": [-25.769]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p828_0p167_0p161"],
                            "t": 11,
                            "s": [-25.769],
                            "e": [-22.985]
                        }, {
                            "i": {"x": [0.833], "y": [0.829]},
                            "o": {"x": [0.167], "y": [0.162]},
                            "n": ["0p833_0p829_0p167_0p162"],
                            "t": 12,
                            "s": [-22.985],
                            "e": [-20.038]
                        }, {
                            "i": {"x": [0.833], "y": [0.83]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p83_0p167_0p163"],
                            "t": 13,
                            "s": [-20.038],
                            "e": [-16.94]
                        }, {
                            "i": {"x": [0.833], "y": [0.83]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p83_0p167_0p163"],
                            "t": 14,
                            "s": [-16.94],
                            "e": [-13.704]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 15,
                            "s": [-13.704],
                            "e": [-10.342]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 16,
                            "s": [-10.342],
                            "e": [-6.867]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 17,
                            "s": [-6.867],
                            "e": [-3.291]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 18,
                            "s": [-3.291],
                            "e": [0.373]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 19,
                            "s": [0.373],
                            "e": [4.112]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 20,
                            "s": [4.112],
                            "e": [7.914]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 21,
                            "s": [7.914],
                            "e": [11.767]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 22,
                            "s": [11.767],
                            "e": [15.657]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 23,
                            "s": [15.657],
                            "e": [19.572]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 24,
                            "s": [19.572],
                            "e": [23.5]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 25,
                            "s": [23.5],
                            "e": [27.428]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 26,
                            "s": [27.428],
                            "e": [31.343]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 27,
                            "s": [31.343],
                            "e": [35.233]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 28,
                            "s": [35.233],
                            "e": [39.086]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 29,
                            "s": [39.086],
                            "e": [42.888]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 30,
                            "s": [42.888],
                            "e": [46.627]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 31,
                            "s": [46.627],
                            "e": [50.291]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 32,
                            "s": [50.291],
                            "e": [53.867]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 33,
                            "s": [53.867],
                            "e": [57.342]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 34,
                            "s": [57.342],
                            "e": [60.704]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.17]},
                            "n": ["0p833_0p837_0p167_0p17"],
                            "t": 35,
                            "s": [60.704],
                            "e": [63.94]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.17]},
                            "n": ["0p833_0p837_0p167_0p17"],
                            "t": 36,
                            "s": [63.94],
                            "e": [67.038]
                        }, {
                            "i": {"x": [0.833], "y": [0.838]},
                            "o": {"x": [0.167], "y": [0.171]},
                            "n": ["0p833_0p838_0p167_0p171"],
                            "t": 37,
                            "s": [67.038],
                            "e": [69.985]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p839_0p167_0p172"],
                            "t": 38,
                            "s": [69.985],
                            "e": [72.769]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p839_0p167_0p172"],
                            "t": 39,
                            "s": [72.769],
                            "e": [75.376]
                        }, {
                            "i": {"x": [0.833], "y": [0.84]},
                            "o": {"x": [0.167], "y": [0.173]},
                            "n": ["0p833_0p84_0p167_0p173"],
                            "t": 40,
                            "s": [75.376],
                            "e": [77.795]
                        }, {
                            "i": {"x": [0.833], "y": [0.841]},
                            "o": {"x": [0.167], "y": [0.174]},
                            "n": ["0p833_0p841_0p167_0p174"],
                            "t": 41,
                            "s": [77.795],
                            "e": [80.012]
                        }, {
                            "i": {"x": [0.833], "y": [0.843]},
                            "o": {"x": [0.167], "y": [0.176]},
                            "n": ["0p833_0p843_0p167_0p176"],
                            "t": 42,
                            "s": [80.012],
                            "e": [82.016]
                        }, {
                            "i": {"x": [0.833], "y": [0.845]},
                            "o": {"x": [0.167], "y": [0.177]},
                            "n": ["0p833_0p845_0p167_0p177"],
                            "t": 43,
                            "s": [82.016],
                            "e": [83.794]
                        }, {
                            "i": {"x": [0.833], "y": [0.847]},
                            "o": {"x": [0.167], "y": [0.18]},
                            "n": ["0p833_0p847_0p167_0p18"],
                            "t": 44,
                            "s": [83.794],
                            "e": [85.332]
                        }, {
                            "i": {"x": [0.833], "y": [0.85]},
                            "o": {"x": [0.167], "y": [0.183]},
                            "n": ["0p833_0p85_0p167_0p183"],
                            "t": 45,
                            "s": [85.332],
                            "e": [86.619]
                        }, {
                            "i": {"x": [0.833], "y": [0.856]},
                            "o": {"x": [0.167], "y": [0.188]},
                            "n": ["0p833_0p856_0p167_0p188"],
                            "t": 46,
                            "s": [86.619],
                            "e": [87.642]
                        }, {
                            "i": {"x": [0.833], "y": [0.866]},
                            "o": {"x": [0.167], "y": [0.198]},
                            "n": ["0p833_0p866_0p167_0p198"],
                            "t": 47,
                            "s": [87.642],
                            "e": [88.388]
                        }, {
                            "i": {"x": [0.833], "y": [0.888]},
                            "o": {"x": [0.167], "y": [0.219]},
                            "n": ["0p833_0p888_0p167_0p219"],
                            "t": 48,
                            "s": [88.388],
                            "e": [88.845]
                        }, {
                            "i": {"x": [0.833], "y": [1]},
                            "o": {"x": [0.167], "y": [0.329]},
                            "n": ["0p833_1_0p167_0p329"],
                            "t": 49,
                            "s": [88.845],
                            "e": [89]
                        }, {
                            "i": {"x": [0.833], "y": [0.671]},
                            "o": {"x": [0.167], "y": [0]},
                            "n": ["0p833_0p671_0p167_0"],
                            "t": 50,
                            "s": [89],
                            "e": [88.845]
                        }, {
                            "i": {"x": [0.833], "y": [0.781]},
                            "o": {"x": [0.167], "y": [0.112]},
                            "n": ["0p833_0p781_0p167_0p112"],
                            "t": 51,
                            "s": [88.845],
                            "e": [88.388]
                        }, {
                            "i": {"x": [0.833], "y": [0.802]},
                            "o": {"x": [0.167], "y": [0.134]},
                            "n": ["0p833_0p802_0p167_0p134"],
                            "t": 52,
                            "s": [88.388],
                            "e": [87.642]
                        }, {
                            "i": {"x": [0.833], "y": [0.812]},
                            "o": {"x": [0.167], "y": [0.144]},
                            "n": ["0p833_0p812_0p167_0p144"],
                            "t": 53,
                            "s": [87.642],
                            "e": [86.619]
                        }, {
                            "i": {"x": [0.833], "y": [0.817]},
                            "o": {"x": [0.167], "y": [0.15]},
                            "n": ["0p833_0p817_0p167_0p15"],
                            "t": 54,
                            "s": [86.619],
                            "e": [85.332]
                        }, {
                            "i": {"x": [0.833], "y": [0.82]},
                            "o": {"x": [0.167], "y": [0.153]},
                            "n": ["0p833_0p82_0p167_0p153"],
                            "t": 55,
                            "s": [85.332],
                            "e": [83.794]
                        }, {
                            "i": {"x": [0.833], "y": [0.823]},
                            "o": {"x": [0.167], "y": [0.155]},
                            "n": ["0p833_0p823_0p167_0p155"],
                            "t": 56,
                            "s": [83.794],
                            "e": [82.016]
                        }, {
                            "i": {"x": [0.833], "y": [0.824]},
                            "o": {"x": [0.167], "y": [0.157]},
                            "n": ["0p833_0p824_0p167_0p157"],
                            "t": 57,
                            "s": [82.016],
                            "e": [80.012]
                        }, {
                            "i": {"x": [0.833], "y": [0.826]},
                            "o": {"x": [0.167], "y": [0.159]},
                            "n": ["0p833_0p826_0p167_0p159"],
                            "t": 58,
                            "s": [80.012],
                            "e": [77.795]
                        }, {
                            "i": {"x": [0.833], "y": [0.827]},
                            "o": {"x": [0.167], "y": [0.16]},
                            "n": ["0p833_0p827_0p167_0p16"],
                            "t": 59,
                            "s": [77.795],
                            "e": [75.376]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p828_0p167_0p161"],
                            "t": 60,
                            "s": [75.376],
                            "e": [72.769]
                        }, {
                            "i": {"x": [0.833], "y": [0.828]},
                            "o": {"x": [0.167], "y": [0.161]},
                            "n": ["0p833_0p828_0p167_0p161"],
                            "t": 61,
                            "s": [72.769],
                            "e": [69.985]
                        }, {
                            "i": {"x": [0.833], "y": [0.829]},
                            "o": {"x": [0.167], "y": [0.162]},
                            "n": ["0p833_0p829_0p167_0p162"],
                            "t": 62,
                            "s": [69.985],
                            "e": [67.038]
                        }, {
                            "i": {"x": [0.833], "y": [0.83]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p83_0p167_0p163"],
                            "t": 63,
                            "s": [67.038],
                            "e": [63.94]
                        }, {
                            "i": {"x": [0.833], "y": [0.83]},
                            "o": {"x": [0.167], "y": [0.163]},
                            "n": ["0p833_0p83_0p167_0p163"],
                            "t": 64,
                            "s": [63.94],
                            "e": [60.704]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 65,
                            "s": [60.704],
                            "e": [57.342]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 66,
                            "s": [57.342],
                            "e": [53.867]
                        }, {
                            "i": {"x": [0.833], "y": [0.831]},
                            "o": {"x": [0.167], "y": [0.164]},
                            "n": ["0p833_0p831_0p167_0p164"],
                            "t": 67,
                            "s": [53.867],
                            "e": [50.291]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 68,
                            "s": [50.291],
                            "e": [46.627]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 69,
                            "s": [46.627],
                            "e": [42.888]
                        }, {
                            "i": {"x": [0.833], "y": [0.832]},
                            "o": {"x": [0.167], "y": [0.165]},
                            "n": ["0p833_0p832_0p167_0p165"],
                            "t": 70,
                            "s": [42.888],
                            "e": [39.086]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 71,
                            "s": [39.086],
                            "e": [35.233]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 72,
                            "s": [35.233],
                            "e": [31.343]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 73,
                            "s": [31.343],
                            "e": [27.428]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.166]},
                            "n": ["0p833_0p833_0p167_0p166"],
                            "t": 74,
                            "s": [27.428],
                            "e": [23.5]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 75,
                            "s": [23.5],
                            "e": [19.572]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 76,
                            "s": [19.572],
                            "e": [15.657]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 77,
                            "s": [15.657],
                            "e": [11.767]
                        }, {
                            "i": {"x": [0.833], "y": [0.834]},
                            "o": {"x": [0.167], "y": [0.167]},
                            "n": ["0p833_0p834_0p167_0p167"],
                            "t": 78,
                            "s": [11.767],
                            "e": [7.914]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 79,
                            "s": [7.914],
                            "e": [4.112]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 80,
                            "s": [4.112],
                            "e": [0.373]
                        }, {
                            "i": {"x": [0.833], "y": [0.835]},
                            "o": {"x": [0.167], "y": [0.168]},
                            "n": ["0p833_0p835_0p167_0p168"],
                            "t": 81,
                            "s": [0.373],
                            "e": [-3.291]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 82,
                            "s": [-3.291],
                            "e": [-6.867]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 83,
                            "s": [-6.867],
                            "e": [-10.342]
                        }, {
                            "i": {"x": [0.833], "y": [0.836]},
                            "o": {"x": [0.167], "y": [0.169]},
                            "n": ["0p833_0p836_0p167_0p169"],
                            "t": 84,
                            "s": [-10.342],
                            "e": [-13.704]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.17]},
                            "n": ["0p833_0p837_0p167_0p17"],
                            "t": 85,
                            "s": [-13.704],
                            "e": [-16.94]
                        }, {
                            "i": {"x": [0.833], "y": [0.837]},
                            "o": {"x": [0.167], "y": [0.17]},
                            "n": ["0p833_0p837_0p167_0p17"],
                            "t": 86,
                            "s": [-16.94],
                            "e": [-20.038]
                        }, {
                            "i": {"x": [0.833], "y": [0.838]},
                            "o": {"x": [0.167], "y": [0.171]},
                            "n": ["0p833_0p838_0p167_0p171"],
                            "t": 87,
                            "s": [-20.038],
                            "e": [-22.985]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p839_0p167_0p172"],
                            "t": 88,
                            "s": [-22.985],
                            "e": [-25.769]
                        }, {
                            "i": {"x": [0.833], "y": [0.839]},
                            "o": {"x": [0.167], "y": [0.172]},
                            "n": ["0p833_0p839_0p167_0p172"],
                            "t": 89,
                            "s": [-25.769],
                            "e": [-28.376]
                        }, {
                            "i": {"x": [0.833], "y": [0.84]},
                            "o": {"x": [0.167], "y": [0.173]},
                            "n": ["0p833_0p84_0p167_0p173"],
                            "t": 90,
                            "s": [-28.376],
                            "e": [-30.795]
                        }, {
                            "i": {"x": [0.833], "y": [0.841]},
                            "o": {"x": [0.167], "y": [0.174]},
                            "n": ["0p833_0p841_0p167_0p174"],
                            "t": 91,
                            "s": [-30.795],
                            "e": [-33.012]
                        }, {
                            "i": {"x": [0.833], "y": [0.843]},
                            "o": {"x": [0.167], "y": [0.176]},
                            "n": ["0p833_0p843_0p167_0p176"],
                            "t": 92,
                            "s": [-33.012],
                            "e": [-35.016]
                        }, {
                            "i": {"x": [0.833], "y": [0.845]},
                            "o": {"x": [0.167], "y": [0.177]},
                            "n": ["0p833_0p845_0p167_0p177"],
                            "t": 93,
                            "s": [-35.016],
                            "e": [-36.794]
                        }, {
                            "i": {"x": [0.833], "y": [0.847]},
                            "o": {"x": [0.167], "y": [0.18]},
                            "n": ["0p833_0p847_0p167_0p18"],
                            "t": 94,
                            "s": [-36.794],
                            "e": [-38.332]
                        }, {
                            "i": {"x": [0.833], "y": [0.85]},
                            "o": {"x": [0.167], "y": [0.183]},
                            "n": ["0p833_0p85_0p167_0p183"],
                            "t": 95,
                            "s": [-38.332],
                            "e": [-39.619]
                        }, {
                            "i": {"x": [0.833], "y": [0.856]},
                            "o": {"x": [0.167], "y": [0.188]},
                            "n": ["0p833_0p856_0p167_0p188"],
                            "t": 96,
                            "s": [-39.619],
                            "e": [-40.642]
                        }, {
                            "i": {"x": [0.833], "y": [0.866]},
                            "o": {"x": [0.167], "y": [0.198]},
                            "n": ["0p833_0p866_0p167_0p198"],
                            "t": 97,
                            "s": [-40.642],
                            "e": [-41.388]
                        }, {
                            "i": {"x": [0.833], "y": [0.833]},
                            "o": {"x": [0.167], "y": [0.219]},
                            "n": ["0p833_0p833_0p167_0p219"],
                            "t": 98,
                            "s": [-41.388],
                            "e": [-41.845]
                        }, {"t": 99}],
                        "ix": 3
                    }, "m": 1, "ix": 2, "nm": "Trim Paths 1", "mn": "ADBE Vector Filter - Trim", "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.290196078431, 0.388235294118, 0.996078431373, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 60, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Ellipse 1",
                "np": 4,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "Shape Layer 1",
            "sr": 1,
            "ks": {
                "o": {"a": 0, "k": 100, "ix": 11},
                "r": {"a": 0, "k": 0, "ix": 10},
                "p": {"a": 0, "k": [350, 350, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
            },
            "ao": 0,
            "shapes": [{
                "ty": "gr",
                "it": [{
                    "d": 1,
                    "ty": "el",
                    "s": {"a": 0, "k": [562, 562], "ix": 2},
                    "p": {"a": 0, "k": [0, 0], "ix": 3},
                    "nm": "Ellipse Path 1",
                    "mn": "ADBE Vector Shape - Ellipse",
                    "hd": false
                }, {
                    "ty": "st",
                    "c": {"a": 0, "k": [0.094117647059, 0.741176470588, 0.96862745098, 1], "ix": 3},
                    "o": {"a": 0, "k": 100, "ix": 4},
                    "w": {"a": 0, "k": 60, "ix": 5},
                    "lc": 1,
                    "lj": 1,
                    "ml": 4,
                    "nm": "Stroke 1",
                    "mn": "ADBE Vector Graphic - Stroke",
                    "hd": false
                }, {
                    "ty": "tr",
                    "p": {"a": 0, "k": [0, 0], "ix": 2},
                    "a": {"a": 0, "k": [0, 0], "ix": 1},
                    "s": {"a": 0, "k": [100, 100], "ix": 3},
                    "r": {"a": 0, "k": 0, "ix": 6},
                    "o": {"a": 0, "k": 100, "ix": 7},
                    "sk": {"a": 0, "k": 0, "ix": 4},
                    "sa": {"a": 0, "k": 0, "ix": 5},
                    "nm": "Transform"
                }],
                "nm": "Ellipse 1",
                "np": 3,
                "cix": 2,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
            }],
            "ip": 0,
            "op": 100,
            "st": 0,
            "bm": 0
        }]
    }],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 0,
        "nm": "man graph callout",
        "refId": "comp_0",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [1080, 399, 0], "ix": 2},
            "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
            "s": {"a": 0, "k": [34, 34, 100], "ix": 6}
        },
        "ao": 0,
        "w": 900,
        "h": 900,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 0,
        "nm": "pot",
        "refId": "comp_1",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [1088.124, 647.96, 0], "ix": 2},
            "a": {"a": 0, "k": [960, 540, 0], "ix": 1},
            "s": {"a": 0, "k": [37, 37, 100], "ix": 6}
        },
        "ao": 0,
        "w": 1920,
        "h": 1080,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 0,
        "nm": "pie",
        "refId": "comp_2",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [481, 214, 0], "ix": 2},
            "a": {"a": 0, "k": [960, 540, 0], "ix": 1},
            "s": {"a": 0, "k": [25, 25, 100], "ix": 6}
        },
        "ao": 0,
        "w": 1920,
        "h": 1080,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 4,
        "ty": 0,
        "nm": "callout 1",
        "refId": "comp_3",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [364, 422, 0], "ix": 2},
            "a": {"a": 0, "k": [960, 540, 0], "ix": 1},
            "s": {"a": 0, "k": [15, 15, 100], "ix": 6}
        },
        "ao": 0,
        "w": 1920,
        "h": 1080,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 5,
        "ty": 0,
        "nm": "man on top",
        "refId": "comp_4",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [866, 235.5, 0], "ix": 2},
            "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
            "s": {"a": 0, "k": [30, 30, 100], "ix": 6}
        },
        "ao": 0,
        "w": 900,
        "h": 900,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 6,
        "ty": 0,
        "nm": "Standing man on laptop",
        "refId": "comp_5",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [885, 604, 0], "ix": 2},
            "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
            "s": {"a": 0, "k": [50, 50, 100], "ix": 6}
        },
        "ao": 0,
        "w": 900,
        "h": 900,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 7,
        "ty": 0,
        "nm": "woman with data  sheet",
        "refId": "comp_6",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [448, 586, 0], "ix": 2},
            "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
            "s": {"a": 0, "k": [50, 50, 100], "ix": 6}
        },
        "ao": 0,
        "w": 900,
        "h": 900,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 8,
        "ty": 0,
        "nm": "computer screen animation",
        "refId": "comp_7",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [682, 460, 0], "ix": 2},
            "a": {"a": 0, "k": [450, 450, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "w": 900,
        "h": 900,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 9,
        "ty": 0,
        "nm": "flower backdraft",
        "refId": "comp_11",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [678, 728, 0], "ix": 2},
            "a": {"a": 0, "k": [960, 540, 0], "ix": 1},
            "s": {"a": 0, "k": [48, 48, 100], "ix": 6}
        },
        "ao": 0,
        "w": 1920,
        "h": 1080,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 10,
        "ty": 0,
        "nm": "gear1",
        "refId": "comp_12",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 15, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [186.203, 640, 0], "ix": 2},
            "a": {"a": 0, "k": [350, 350, 0], "ix": 1},
            "s": {"a": 0, "k": [25, 25, 100], "ix": 6}
        },
        "ao": 0,
        "w": 700,
        "h": 700,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 11,
        "ty": 0,
        "nm": "gear1",
        "refId": "comp_12",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 15, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [1132, 180, 0], "ix": 2},
            "a": {"a": 0, "k": [350, 350, 0], "ix": 1},
            "s": {"a": 0, "k": [34, 34, 100], "ix": 6}
        },
        "ao": 0,
        "w": 700,
        "h": 700,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 12,
        "ty": 0,
        "nm": "elipse graph",
        "refId": "comp_13",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 22, "ix": 11},
            "r": {"a": 0, "k": -88, "ix": 10},
            "p": {"a": 0, "k": [285, 422, 0], "ix": 2},
            "a": {"a": 0, "k": [350, 350, 0], "ix": 1},
            "s": {"a": 0, "k": [30, 30, 100], "ix": 6}
        },
        "ao": 0,
        "w": 700,
        "h": 700,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 13,
        "ty": 0,
        "nm": "elipse graph",
        "refId": "comp_13",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 22, "ix": 11},
            "r": {"a": 0, "k": -93, "ix": 10},
            "p": {"a": 0, "k": [758, 185, 0], "ix": 2},
            "a": {"a": 0, "k": [350, 350, 0], "ix": 1},
            "s": {"a": 0, "k": [9, 9, 100], "ix": 6}
        },
        "ao": 0,
        "w": 700,
        "h": 700,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 14,
        "ty": 0,
        "nm": "elipse graph",
        "refId": "comp_13",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 22, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [672, 91, 0], "ix": 2},
            "a": {"a": 0, "k": [350, 350, 0], "ix": 1},
            "s": {"a": 0, "k": [15, 15, 100], "ix": 6}
        },
        "ao": 0,
        "w": 700,
        "h": 700,
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 15,
        "ty": 4,
        "nm": "Shape Layer 1",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 10, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [708.395, 519.317, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[110.128, 0], [71.212, -67.737], [185.927, -10.512], [16.729, 0], [28.377, 2.121],
                            [23.933, 0], [53.152, -33.203], [0, 0], [0, 0], [0, 0]],
                        "o": [[-60.899, 0], [-221.827, 211.002], [-19.061, 1.078], [-36.772, 0], [-28.376, -2.121],
                            [-47.453, 0], [-138.11, 86.275], [0, 0], [0, 0], [0, 0]],
                        "v": [[403.811, -480.683], [201.487, -391.138], [-84.482, 203.024], [-138.075, 204.541],
                            [-234.81, 200.299], [-312.289, 196.057], [-455.494, 237.596], [-563.394, 480.683],
                            [593.605, 480.683], [593.605, -409.362]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0.643137254902, 0.839215686275, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Shape 1",
            "np": 3,
            "cix": 2,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 16,
        "ty": 4,
        "nm": "Shape Layer 2",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 10, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [658.518, 789.98, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[94.672, 0], [18.327, -4.676], [138.11, -32.61], [44.516, 0], [76.574, 4.991],
                            [64.588, 0], [29.539, -8.034], [0, 0], [0, 0], [0, 0]],
                        "o": [[-16.335, 0], [-187.278, 47.786], [-27.886, 6.584], [-63.68, 0], [-76.571, -4.991],
                            [-49.296, 0], [-134.001, 36.445], [0, 0], [0, 0], [0, 0]],
                        "v": [[489.606, -210.02], [437.568, -203.352], [163.122, 36.423], [52.39, 45.447],
                            [-164.206, 35.465], [-382.167, 25.482], [-503.181, 36.423], [-643.482, 210.02],
                            [643.477, 210.02], [643.482, -143.01]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0.643137254902, 0.839215686275, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Shape 1",
            "np": 3,
            "cix": 2,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 100,
        "st": 0,
        "bm": 0
    }],
    "markers": []
}