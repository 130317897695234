import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";

export const BlogProsAndConsPage = ElView.extend({
    el: 'pro-and-cons',
    events: {},
    datatable: null,

    init: function () {
        this.initProsAndConsTable();
        this.find('.j-select-2-pros-and-cons-type').select2({
            minimumResultsForSearch: -1,
        });
    },

    initProsAndConsTable() {
        this.datatable = this.find('#blog-pro-and-cons-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('blog-pros-and-cons-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#pros-and-cons-search'),
                    key: 'generalSearch'
                },
                columns: [

                    {
                        field: 'title',
                        width: 300,
                        title: capitalizeFirstLetter('titulo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },

                    {
                        field: 'type',
                        width: 300,
                        title: capitalizeFirstLetter('tipo'),
                        textAlign: 'center',
                        template: (row) => {
                            let $result;

                            if (row.type === 'pro') {
                                $result = 'Ventaja';
                            } else {
                                $result = 'Desventaja';

                            }

                            return `<span class="font-weight-bolder">${capitalizeFirstLetter($result)}</span>`;
                        }
                    },

                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 300,
                        template: (row) => {

                            const detailsRoute = this.route('admin-blog-pros-and-cons-details', {prosAndConsId: row.id});

                            return `
                                    <a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                                  `
                        },
                    },
                ],
            }
        );

        $('#pros-and-cons-search-type').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'type');
        });

    },


});