import {ElView} from "../shared/el-view";
import {PageSectionEditorComponent} from "../shared/component/page-section-editor-component";
import {DropzoneComponent} from "../shared/component/dropzone/dropzone-component";

export const BlogRoomPostExtendedDetailsPage = ElView.extend({
    el: 'room-post-extended',
    events: {
        'click .j-add-faq': 'addFaq',
        'click .j-add-tab': 'addTab',
        'click .j-save-room-post-extended': 'onSaveRoomPostExtended',
        'click .j-delete-faq': 'onDeleteFaq',
        'click .j-delete-tab': 'onDeleteTab',
        'click .j-copy-form': 'onCopyForm',
        'click .j-paste-form': 'onPasteForm',
    },

    sectionVariationType: null,
    titleCopyValue: null,
    shortTitleCopyValue: null,
    descriptionCopyValue: null,
    shortDescriptionCopyValue: null,
    contentCopyValue: null,

    localeToTabClassMap: {
        'es_ES': 'j-esEsContent-tab-pane',
        'es_GB': 'j-ukEsContent-tab-pane',
        'en_GB': 'j-ukEnContent-tab-pane',
        'es': 'j-esContent-tab-pane',
        'en': 'j-enContent-tab-pane',
    },

    hidePostContentClassAndEvent: {
        intl: 'hide-intl',
        ES: 'hide-es',
        GB: 'hide-gb'
    },

    showPostContentClassAndEvent: {
        intl: 'show-intl',
        ES: 'show-es',
        GB: 'show-gb'
    },

    errorOnPostContentEvent: {
        es_ES: 'error-es-es',
        es_GB: 'error-es-gb',
        en_GB: 'error-en-gb',
        es: 'error-es',
        en: 'error-en',
    },

    allAvailableCountries: ['ES', 'GB', 'intl'],

    mainNavIds: ['file', 'info', 'faqs', 'proAndCons', 'reviewTab', 'tabs'],


    async init() {
        this.checkErrors();

        this.onShowAvailableCountriesWarning = _.debounce(this.showAvailableCountriesWarning, 100);

        const $faqRows = this.find('.j-faq-row');
        const $tabRows = this.find('.j-tab-row');

        if ($faqRows.length < 1)
            this.find('.j-add-faq').click();

        if ($tabRows.length < 1)
            this.find('.j-add-tab').click();

        this.initLobbyUploader();
        this.onChangeAvailableCountries();
        await this.validateAvailableCountries();
        this.showErrorsOnMainNavIds(this.mainNavIds);
    },

    initLobbyUploader() {
        this.lobbyImageUploader = new DropzoneComponent({el: '.j-lobby-image-uploader'});
        this.lobbyImageUploader.onUpload((file) => {
            this.log(file)
            this.find('.j-lobby-image-url-input').val(file.url);
        });
    },

    showErrorsOnMainNavIds(navIds) {
        navIds.forEach((navId) => {
            const $navContent = this.find('#' + navId);
            const errorFoundInNavContent = $navContent.find('.j-content-editor-nav-error').length !== 0 ||
                $navContent.find('.invalid-feedback ul').length !== 0;
            if (errorFoundInNavContent)
                this.showErrorOnNavId(navId);
        });
    },

    showErrorOnNavId(navId) {
        const $nav = this.find('[href="#' + navId + '"]')

        $nav.css('border-bottom', '3px solid red');
    },

    async showAvailableCountriesWarning() {
        toastr.error('Rellene los contenidos de todos los países disponibles');
    },

    checkErrors() {
        const $errorContainer = this.find('.invalid-feedback').find('li');
        const errorIdTab = $errorContainer.closest('.tab-pane').attr('id');
        if (errorIdTab)
            this.find('[href="#' + errorIdTab + '"]').click();
    },

    addFaq() {
        const $template = this.find('.j-faq-template');
        let $faqsContainer = this.find('.j-faq-content-container');
        const $faqRows = this.find('.j-faq-row');
        const newRowIndex = $faqRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-faq-template');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-faq-row');

        $faqsContainer.append($newRowForm);

        $newRowForm.fadeIn();

        new PageSectionEditorComponent({
            el: $newRowForm[0]
        });

        if (newRowIndex > 0)
            $('html, body').animate({
                scrollTop: $('.card-sticky').height() -
                    $('page-section-component[class*="j-faq-container-"]').first().height() - 600
            }, "slow");
    },

    onDeleteFaq(event) {
        const $clicked = $(event.currentTarget);

        const $faqRows = $clicked.closest('.j-faq-row');

        $faqRows.fadeOut();
        $faqRows.remove();
    },

    addTab() {
        const $template = this.find('.j-tab-template');
        let $tabsContainer = this.find('.j-tab-content-container');
        const $tabRows = this.find('.j-tab-row');
        const newRowIndex = $tabRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-tab-template');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-tab-row');

        $tabsContainer.append($newRowForm);

        this.initTextEditor($newRowForm);

        $newRowForm.fadeIn();

        new PageSectionEditorComponent({
            el: $newRowForm[0]
        });

        if (newRowIndex > 0)
            $('html, body').animate({
                scrollTop: $('.card-sticky').height() -
                    $('page-section-component[class*="j-tab-container-"]').first().height() - 800
            }, "slow");
    },

    onDeleteTab(event) {
        const $clicked = $(event.currentTarget);

        const $tabRows = $clicked.closest('.j-tab-row');

        $tabRows.fadeOut();
        $tabRows.remove();
    },

    async onSaveRoomPostExtended() {
        this.find('.j-faq-template').remove();
        this.find('.j-tab-template').remove();

        this.find('.j-room-post-extended-form').submit();
    },

    initTextEditor($tabRow) {
        const $prototypeTextEditor = $tabRow.find('.note-editor')
        $prototypeTextEditor.remove();

        $tabRow.find('.j-text-editor').summernote({
            height: 200,
            tabsize: 2,
            popover: {
                image: [
                    ['custom', ['imageAttributes']],
                    ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                    ['float', ['floatLeft', 'floatRight', 'floatNone']],
                    ['remove', ['removeMedia']]
                ],
            },
            lang: 'es-ES',
            imageAttributes: {
                icon: '<i class="note-icon-pencil"/>',
                removeEmpty: false, // true = remove attributes | false = leave empty if present
                disableUpload: false // true = don't display Upload Options | Display Upload Options
            }
        });
    },

    onChangeAvailableCountries() {
        const $postContent = this.find('page-section-component');

        const selectedCountries = JSON.parse(this.find('.j-data').attr('data-available-countries'));
        const notSelectedCountries = this.allAvailableCountries.filter(
            (country) => !selectedCountries.includes(country));

        selectedCountries.forEach((country) => this.showPostContent(country, $postContent));
        notSelectedCountries.forEach((country) => this.hidePostContent(country, $postContent));
    },

    hidePostContent(country, $postContent) {
        $postContent.each((index, element) => {
            const $element = $(element);
            $element.removeClass(this.showPostContentClassAndEvent[country]);
            $element.addClass(this.hidePostContentClassAndEvent[country])
                .trigger(this.hidePostContentClassAndEvent[country]);
        });
    },

    showPostContent(country, $postContent) {
        $postContent.removeClass(this.hidePostContentClassAndEvent[country]);
        $postContent.addClass(this.showPostContentClassAndEvent[country])
            .trigger(this.showPostContentClassAndEvent[country]);
    },

    async validateAvailableCountries() {
        const availableCountries = JSON.parse(this.find('.j-data').attr('data-available-countries'));

        if (availableCountries.includes('ES') && this.localizedContentIsEmpty('es_ES')) {
            this.showPostContentErrorOnCountryAndContainer('es_ES', this.emptyContentSelectorsForLocale('es_ES'));
            await this.onShowAvailableCountriesWarning();
        }
        if (availableCountries.includes('GB')) {
            if (this.localizedContentIsEmpty('es_GB')) {
                this.showPostContentErrorOnCountryAndContainer('es_GB', this.emptyContentSelectorsForLocale('es_GB'));
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en_GB')) {
                this.showPostContentErrorOnCountryAndContainer('en_GB', this.emptyContentSelectorsForLocale('en_GB'));
                await this.onShowAvailableCountriesWarning();
            }

        }
        if (availableCountries.includes('intl')) {
            if (this.localizedContentIsEmpty('es')) {
                this.showPostContentErrorOnCountryAndContainer('es', this.emptyContentSelectorsForLocale('es'));
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en')) {
                this.showPostContentErrorOnCountryAndContainer('en', this.emptyContentSelectorsForLocale('en'));
                await this.onShowAvailableCountriesWarning();
            }
        }
    },

    showPostContentErrorOnCountryAndContainer(country, containerSelectors) {
        containerSelectors.forEach((selector) => {
            this.find(selector).addClass(this.errorOnPostContentEvent[country])
                .trigger(this.errorOnPostContentEvent[country]);
        });
    },

    localizedContentIsEmpty(locale) {
        return this.emptyContentSelectorsForLocale(locale).length !== 0;
    },

    emptyContentSelectorsForLocale(locale) {
        const roomSummary = this.roomSummaryIsEmpty(locale) ? '.j-room-summary-container' : '';
        const faqs = this.emptyFaqs(locale);
        const reviewTab = this.reviewTabIsEmpty(locale) ? '.j-review-tab-container' : '';
        const tabs = this.emptyTabs(locale);

        let classSelectorsArray = (roomSummary + faqs + reviewTab + tabs).split('.');
        classSelectorsArray.shift();

        const selectorsArray = classSelectorsArray.map((classSelector) => '.' + classSelector);

        return classSelectorsArray[0] === '' ? [] : selectorsArray;
    },

    roomSummaryIsEmpty(locale) {
        const $roomSummaryContainer = this.find('.j-room-summary-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $roomSummaryContainer.find('.' + tabIdsFromLocale);

        return this.roomSummaryContentIsEmpty($tabForLocale);
    },

    roomSummaryContentIsEmpty($tabContent) {
        const contentContent = $tabContent.find('.j-create-page-section-variation-form-content-input').val();

        return this.inputIsEmpty(contentContent);
    },

    reviewTabIsEmpty(locale) {
        const $roomSummaryContainer = this.find('.j-review-tab-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $roomSummaryContainer.find('.' + tabIdsFromLocale);

        return this.reviewTabContentIsEmpty($tabForLocale);
    },

    reviewTabContentIsEmpty($tabContent) {
        const contentContent = $tabContent.find('.j-create-page-section-variation-form-content-input').val();

        return this.inputIsEmpty(contentContent);
    },

    emptyFaqs(locale) {
        const $faqContainer = this.find('[class^="j-faq-container-"]:not(.j-faq-container-__name__)');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];

        let faqSelectorsWithErrors = '';
        $faqContainer.each((index, faq) => {
            const $faq = $(faq);
            const $tabForLocale = $faq.find('.' + tabIdsFromLocale);

            if (this.faqContentIsEmpty($tabForLocale)) {
                const faqContainerSelector = $faq.attr('class').split(' ')[0];
                faqSelectorsWithErrors = faqSelectorsWithErrors + '.' + faqContainerSelector;
            }
        })

        return faqSelectorsWithErrors;
    },

    faqContentIsEmpty($tabContent) {
        const titleContent = $tabContent.find('.j-create-page-section-variation-form-title-input').val();
        const descriptionContent = $tabContent.find('.j-create-page-section-variation-form-description-input').val();

        return this.inputIsEmpty(titleContent) || this.inputIsEmpty(descriptionContent);
    },

    emptyTabs(locale) {
        const $tabContainer = this.find('[class^="j-tab-container-"]:not(.j-tab-container-__name__)');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];

        let tabSelectorsWithErrors = '';
        $tabContainer.each((index, tab) => {
            const $tab = $(tab);
            const $tabForLocale = $tab.find('.' + tabIdsFromLocale);

            if (this.tabContentIsEmpty($tabForLocale)) {
                const tabContainerSelector = $tab.attr('class').split(' ')[0];
                tabSelectorsWithErrors = tabSelectorsWithErrors + '.' + tabContainerSelector;
            }
        })

        return tabSelectorsWithErrors;
    },

    tabContentIsEmpty($tabContent) {
        const titleContent = $tabContent.find('.j-create-page-section-variation-form-short-title-input').val();
        const descriptionContent = $tabContent.find('.j-create-page-section-variation-form-content-input').val();

        return this.inputIsEmpty(titleContent) || this.inputIsEmpty(descriptionContent);
    },

    inputIsEmpty(inputValue) {
        const inputCleared = this.removeHTMLTagsFromInput(inputValue);
        return _.isUndefined(inputCleared) || _.isNull(inputCleared) || inputCleared === '';
    },

    removeHTMLTagsFromInput(inputValue) {
        if (!inputValue)
            return inputValue;
        return inputValue.replaceAll(/<.+?>/g, '');
    },
});