import {ElView} from "../shared/el-view";

export const CorporateRoomPage = ElView.extend({
    el: '.j-corporate-room',
    events: {},

    init() {
        this.promotions = this.find('.j-data').attr('data-amount-promotions');
        this.initSwiper();


    },

    initSwiper() {
        let slidesIpad = this.promotions;
        let slidesPC = this.promotions;
        let swiperLoop = false;

        if (this.promotions > 2)
            slidesIpad = 2;

        if (this.promotions > 3)
            slidesPC = 3;

        if (this.promotions > 3)
            swiperLoop = true;

        new Swiper('.j-room-promotions-container', {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                direction: 'horizontal',
                clickable: true,
            },
            direction: 'horizontal',
            slidesPerView: this.promotions,
            loop: swiperLoop,
            autoHeight: false,
            spaceBetween: 0,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 100,
                    loop: false,
                },
                600: {
                    slidesPerView: slidesIpad,
                    spaceBetween: 0,
                    loop: false,
                },
                769: {
                    slidesPerView: slidesPC,
                    spaceBetween: 0,
                },
            },
        })

    },

});
