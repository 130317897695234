import {ElView} from "../shared/el-view";
import {Router} from "../shared/routing/router";
import {Translator} from "../shared/translation/translator";

export const CorporateProductDetailPage = ElView.extend({
    el: '.j-corporate-product-detail',
    events: {
        'click .j-related-products-button-next': 'onRelatedProductsButtonNext',
        'click .j-related-products-button-back': 'onRelatedProductsButtonBack',
        'click .j-add-product-to-cart-button': 'onClickAddProductToCart',
        'click .j-variation-select-item': 'onClickVariationSelectItem',
        'select2:select .j-variation-select': 'onSelectVariation',
    },

    init() {
        $('.j-variation-select').select2({
            minimumResultsForSearch: -1,
        })
        this.onSelectVariation();

        const slidesToShow = this.setSlidesToShowResponsive();

        let mySwiper = new Swiper('.swiper-container', {
            speed: 400,
            spaceBetween: 50,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: '.swiper-pagination'
            },
            navigation: {
                prevEl: '.j-swiper-button-prev',
                nextEl: '.j-swiper-button-next',
            },
        });

        let relatedProducts = new Swiper('.j-related-products-container', {
            direction: 'horizontal',
            slidesPerView: 5,
            spaceBetween: 25,
            height: 100,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
                720: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                },
            },
            loop: true,
            autoHeight: false,
            pagination: {
                el: '.related-products__swiper_pagination',
                type: 'bullets',
                direction: 'horizontal',
                clickable: true,
            },
            navigation: {
                prevEl: '.j-swiper-button-prev-related-products',
                nextEl: '.j-swiper-button-next-related-products',
            },

        })

        $('.j-quantity-plugin').TouchSpin({
            initval: 1,
            min: 1,
            max: 100,
            decimals: 0,
            step: 1,
            boostat: 5,
            maxboostedstep: 3,
        })
    },

    async onClickAddProductToCart(event) {
        let route = Router.generate('add-cart-product');

        const data = {
            productId: $(this.el).attr('data-product-id'),
            variationId: this.find('.j-variation-select').find(':selected').attr('data-variation-id'),
            units: this.find('.j-quantity-plugin').val(),
        };

        try {
            await $.post(route, data)

        } catch (exception) {
            toastr.error(Translator.trans('product-already-in-cart'));
            return;
        }

        if (this.isUserLoggedIn()) {
            toastr.success(Translator.trans('product-added-to-cart'));
        } else {
            toastr.error(Translator.trans('user-have-to-log-to-buy'));
        }

        this.reloadCartHeader();
        setTimeout(() => {
            $(document).find('.j-cart-header-dropdown').dropdown('toggle');
        }, 250);
    },

    setSlidesToShowResponsive() {
        const windowWidth = $(window).width();

        if (windowWidth < 600)
            return 1;

        if (windowWidth < 800)
            return 2;

        if (windowWidth < 1300)
            return 3;

        return 4;
    },

    onSelectVariation() {
        const $variationSelect = this.find('.j-variation-select');
        const priceInHabcoins = $variationSelect.find(':selected').attr('data-variation-price-in-habcoins');

        this.find('.j-product-price-in-habcoins').text(new Intl.NumberFormat('en-UK').format(priceInHabcoins));
    },

    reloadCartHeader() {
        $(document).find('.j-reload-cart-header').click();
    }
})