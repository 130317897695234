import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";

export const WithdrawHabcoinsPage = ElView.extend({
    el: '.j-withdraw-habcoins-page',
    events: {
        'keyup .j-add-wallet-movement-withdraw-form-currency-amount-input': 'onChangeCurrencyAmountFormInput',
        'click .j-user-reject-withdraw-request': 'onClickRejectWithdrawRequest',
    },
    currencyConversion: 100,

    appSession: null,

    async init() {
        this.appSession = await $.get(this.route('get-session-app'));

        this.resetWithdrawForm();
        this.initWalletMovementWithdrawalsTable();
    },

    resetWithdrawForm() {
        const $methodInput = this.find('.j-add-wallet-movement-withdraw-form-withdraw-method-input');
        $methodInput.val('');
        $methodInput.trigger('change');

        const $amountInput = this.find('.j-add-wallet-movement-withdraw-form-currency-amount-input');
        $amountInput.val('');

        const $walletDirection = this.find('.j-add-wallet-movement-withdraw-form-wallet-direction-input');
        $walletDirection.val('');
    },

    initWalletMovementWithdrawalsTable() {
        const _this = this;
        this.datatable = this.find('#j-tool-wallet-movements-withdrawals-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: _this.route('tool-api-withdrawal-request-table'),
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: _this.find('#wallet-withdrawals-request-search-by-method'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },
                columns: [
                    {
                        field: 'sequence',
                        title: '#',
                        textAlign: 'center',
                        width: 50,
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.sequence}</span>`;
                        }
                    },
                    {
                        field: 'createdAt',
                        title: Translator.trans('date'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'withdrawal_method',
                        title: _this.translate('withdrawal-method'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.withdrawal_method}</span>`;
                        }
                    },
                    {
                        field: 'currency_amount',
                        title: _this.translate('currency-amount'),
                        textAlign: 'center',
                        width: 175,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.currency_amount.toFixed(2)}</span>`;
                        }
                    },
                    {
                        field: 'habcoins',
                        title: this.appSession['coin'],
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.habcoins.toFixed(0)}</span>`;
                        }
                    },
                    {
                        field: 'walletDirection',
                        title: Translator.trans('walletDirection'),
                        textAlign: 'center',
                        width: 200,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.walletDirection}</span>`;
                        }
                    },
                    {
                        field: 'status',
                        title: Translator.trans('status'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-warning" data-toggle="tooltip" data-placement="top" title="${Translator.trans('pending')}" style="cursor: default;"><span class="far fa-clock" style="font-size: 1.5rem;"></span></button>`;
                            if (row.status === 'rejected')
                                return `<button class="btn btn-icon btn-light-danger" data-toggle="tooltip" data-placement="top" title="${Translator.trans('rejected')}" style="cursor: default;"><span class="flaticon2-delete"></span></button>`;
                            if (row.status === 'accepted')
                                return `<button class="btn btn-icon btn-light-primary" data-toggle="tooltip" data-placement="top" title="${Translator.trans('accepted')}" style="cursor: default;"><span class="flaticon2-check-mark" style="font-size: 1.5rem;"></span></button>`;
                        }
                    },
                    {
                        field: 'actions',
                        title: 'Acciones',
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-danger j-user-reject-withdraw-request" data-withdraw-id="${row.id}" data-toggle="tooltip" data-placement="top" title="${Translator.trans('reject')}"><span class="flaticon2-delete"></span></button>`;
                            else
                                return ``;
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#affiliate-wallet-movement-withdrawals-widthdrawal-method').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'withdrawal_method');
        });
    },

    onChangeCurrencyAmountFormInput(event) {
        const $currencyAmountInput = $(event.currentTarget);
        const $habcoinsAmountInput = this.find('.j-habcoins-recieved');

        $habcoinsAmountInput.attr('value', '');
        $habcoinsAmountInput.text('');

        const $habcoinsAmount = $currencyAmountInput.val() / this.currencyConversion;

        if (isNaN($habcoinsAmount)) {
            $habcoinsAmountInput.attr('value', 0);
            $habcoinsAmountInput.text(0);

        } else {
            $habcoinsAmountInput.attr('value', $habcoinsAmount);
            $habcoinsAmountInput.text($habcoinsAmount);
        }
    },

    async onClickRejectWithdrawRequest(event) {
        const _this = this;
        const withdrawId = $(event.currentTarget).attr('data-withdraw-id');

        const route = '/reject-withdraw-request/' + withdrawId;
        console.log('route', route);
        try {
            await $.get(route);

            _this.datatable.reload();
            toastr.info(Translator.trans('requestRejected'));
        }catch(exception)
        {
            toastr.error(Translator.trans('errorOccurred'));
        }
    },
});