import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";
import {Router} from "../shared/routing/router";
import {Translator} from "../shared/translation/translator";

export const SalesDashboardPage = ElView.extend({
    el: 'sales-dashboard',
    events: {
        'click .j-add-user-room-credentials-button': 'onClickAddOrUpdateUserRoomCredentials',
        'click .j-add-user-room-credentials-modal-save': 'onClickUpdateUserCredentialsModalSave',
        'hidden.bs.modal #add-user-room-credentials-modal': 'onClickUpdateUserCredentialsModalClose',
    },

    init() {
        this.revenueChart();
        this.roomsRevenuePieChart();

        $('.j-affiliate-invite-friends-button').on('click', (event) => {
            this.onClickInviteFriends(event);
        });

        $.get(Router.generate('tool-affiliate-user-credentials-data'))
            .done((data) => {
                this.initAffiliateUserCredentialsTable(JSON.parse(data));
            })
    },

    initAffiliateUserCredentialsTable(data) {
        this.datatable = this.find('#j-sales-affiliate-user-credentials-datatable').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#refered-user-credentials-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'nick',
                        title: Translator.trans('nick'),
                        width: 200,
                        template: row => {
                            return `<span class="font-weight-bold">${row.nick}</span>`;
                        }
                    },
                    {
                        field: 'login',
                        title: Translator.trans('login'),
                        width: 150,
                        template: row => {
                            return `<span class="font-weight-bold">${row.login}</span>`;
                        }
                    },
                    {
                        field: 'room',
                        title: Translator.trans('room'),
                        width: 150,
                        template: row => {
                            return `<span class="font-weight-bold text-muted">${row.room}</span>`;
                        }
                    },
                    {
                        field: 'habcoins',
                        title: Translator.trans('rake-euros'),
                        width: 150,
                        template: row => {
                            return `<span class="font-weight-bold text-muted">${row.habcoins.toFixed(2)}</span>`;
                        }
                    },
                    {
                        field: 'status',
                        title: 'Estado',
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-warning" data-toggle="tooltip" data-placement="top" title="Pendiente" style="cursor: default;"><span class="far fa-clock" style="font-size: 1.5rem;"></span></button>`;
                            if (row.status === 'verified')
                                return `<button class="btn btn-icon btn-light-primary" data-toggle="tooltip" data-placement="top" title="Verificado" style="cursor: default;"><span class="flaticon2-check-mark" style="font-size: 1.5rem;"></span></button>`;
                        }
                    },
                    // {
                    //     field: 'action',
                    //     title: 'Action',
                    //     width: 125,
                    //     template: (row) => {
                    //         return `<button type="button" class="btn btn-sm btn-outline-primary">
                    //                     <i class="flaticon-eye ml-1"  style="font-size: 1.5rem;"></i>
                    //                 </button>`;
                    //     }
                    // },
                ],
            }
        );

        $('#refered-user-room-credentials-search').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'nick');
        });
    },

    onClickInviteFriends() {
        toastr.options = {
            positionClass: 'toast-bottom-left',

            progressBar: true,
            preventDuplicates: true,
            closeButton: true,
        };
        toastr.success(capitalizeFirstLetter(Translator.trans('invitation-send')));

        this.find('.j-affiliate-invite-friends-form').submit()

        setTimeout(() => {
            location.reload();
        }, 1000);
    },

    onClickAddOrUpdateUserRoomCredentials(event) {
        const route = Router.generate('tool-api-affiliate-create-user-room-credentials');
        const data = {
            affiliateId: $(event.currentTarget).attr('data-affiliate-id'),
        }

        $.get(route, data)
            .done((response) => {
                const $modalBody = $(document).find('#add-user-room-credentials-modal .modal-body');
                $modalBody.append(response);
                $modalBody.find('.j-user-room-credentials-form-room-id-input').select2();
            })
            .fail(() => {
                toastr.options = {
                    positionClass: 'toast-bottom-left',

                    progressBar: true,
                    preventDuplicates: true,
                    closeButton: true,
                };
                toastr.error(capitalizeFirstLetter(Translator.trans('error')));
            })

    },

    onClickUpdateUserCredentialsModalSave(event) {
        const $modal = $(event.currentTarget).closest('#add-user-room-credentials-modal');
        const affiliateId = $modal.attr('data-affiliate-id');
        $modal.find('.j-user-room-credentials-form-affiliate-id-input').val(affiliateId);
        const nick = $modal.find('.j-user-room-credentials-form-nick-input').val();
        const roomId = $modal.find('.j-user-room-credentials-form-room-id-input').val();
        if (nick === '' || roomId === '') {
            toastr.options = {
                positionClass: 'toast-bottom-left',

                progressBar: true,
                preventDuplicates: true,
                closeButton: true,
            };
            toastr.error(capitalizeFirstLetter(Translator.trans('nick-and-room-required')));
            return;
        }

        $modal.find('.j-user-room-credentials-form').submit();
    },

    onClickUpdateUserCredentialsModalClose(event) {
        const $modal = $(event.currentTarget).closest('#add-user-room-credentials-modal');
        $modal.find('.modal-body').empty();
    },

    revenueChart () {
        const _this = this;
        const apexChart = "#revenue-chart";
        const chartData = JSON.parse(this.find(apexChart).attr('data-chart'));

        let labels = [];
        let values = [];
        chartData.forEach((dataEntry, index) => {
            labels[index] = this.translate(dataEntry['month']);
            values[index] = parseInt(dataEntry['amount']);
        })

        const options = {
            series: [
                {
                    name: _this.translate('revenue'),
                    data: values
                },
            ],
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#77B6EA'],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: labels,
                title: {
                    text: _this.translate('month'),
                },
                labels: {
                    rotateAlways: true,
                }
            },
            yaxis: {
                title: {
                    text: _this.translate('revenue')
                },
                min: _.min(values) * 0.9,
                max: _.max(values) * 1.1
            },
            tooltip: {
                shared: false,
                y: {
                    formatter: function (val) {
                        return val + '  (€)';
                    }
                }
            }
        };

        const chart = new ApexCharts(this.find(apexChart)[0], options);
        chart.render();
    },

    roomsRevenuePieChart() {
        const apexChart = "#revenue-per-room-chart";
        const chartData = JSON.parse(this.find(apexChart).attr('data-chart'));

        let labels = [];
        let values = [];
        chartData.forEach((dataEntry, index) => {
            labels[index] = dataEntry['roomName'];
            values[index] = parseInt(dataEntry['amount']);
        })

        const options = {
            series: values,
            chart: {
                width: 475,
                type: 'pie',
            },
            labels: labels,
            responsive: [{
                breakpoint: 576,
                options: {
                    chart: {
                        width: 400
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            colors: ['#fe3995', '#f6aa33', '#6e4ff5', '#2abe81', '#c7d2e7', '#593ae1']
        };

        const chart = new ApexCharts(document.querySelector(apexChart), options);
        chart.render();
    },
})