import {ElView} from "../../shared/el-view";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";

export const ToolAffiliateRoomLinksPage = ElView.extend({
    el: '.j-tool-affiliates-room-links',
    events: {
        'click .j-copy-room-link-modal-button': 'onClickModalCopyRoomLink',
        'click .j-add-user-room-credentials-modal-save': 'onClickUpdateUserCredentialsModalSave',
        'hidden.bs.modal #add-user-room-credentials-modal': 'onClickUpdateUserCredentialsModalClose',
    },

    init() {
    },

    onClickModalCopyRoomLink(event) {
        const roomLink = $(event.currentTarget).attr('data-room-link');
        const $modal = this.find('#modal-copy-room-link');

        $modal.find('.j-room-link-input').val(roomLink);
        $modal.find('.j-copy-to-clipboard').attr('data-url', roomLink);
    }
})