import _ from "lodash";

export const maxFromValues = (object) => {
    let arrayOfIntegers = [];

    Object.values(object).forEach(element => {
        arrayOfIntegers.push(parseInt(element));
    })

    return _.max(arrayOfIntegers);
}

export const minFromValues = (object, minValueIsZero = false) => {
    let arrayOfIntegers = [];

    Object.values(object).forEach(element => {
        arrayOfIntegers.push(parseInt(element));
    })

    const absoluteMin = _.min(arrayOfIntegers);

    return absoluteMin < 0.0 ?
        (minValueIsZero === true ? 0.0 : absoluteMin) :
        absoluteMin;

}
