import {ElView} from "../shared/el-view";
import slugify from "@sindresorhus/slugify";

export const BlogCategoryDetailsPage = ElView.extend({
    el: '.j-blog-category-details',
    events: {
        'keyup .j-create-category-form-title-input': 'onKeyPressed',
        'click .j-save-category': 'onSaveCategory',
        'click .j-remove-category': 'onDeleteCategory',
        'change #create_or_update_category_form_section': 'refreshCategoriesOptions',
        'change #create_or_update_category_form_type': 'refreshCategoriesOptions'
    },

    init() {
        this.refreshCategoriesOptions();
    },

    onKeyPressed(event) {
        const $titleInput = this.$(event.currentTarget);
        const titleValue = $titleInput.val();

        if (!titleValue)
            return;

        const newSlug = slugify(titleValue);
        const $parentContainer = $titleInput.closest('.j-category-content-container');
        const $slugInput = $parentContainer.find('.j-create-category-slug');

        $slugInput.val(newSlug);

    },

    async onDeleteCategory(event) {
        const categoryId = $(event.currentTarget).attr('data-category-id');
        const route = this.route('remove-category', {categoryId});

        const result = await this.confirmation('¿Deseas borrar la categoría?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Categoría eliminada');

                const redirectRooute = this.route('admin-blog-categories');
                location.replace(redirectRooute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'La categoría no ha sido eliminada');
            }
        }
    },

    onSaveCategory() {
        // this.find('.j-create-category-slug').prop('disabled', false);
        this.find('.j-category-form').submit();
    },

    async refreshCategoriesOptions() {
        let select = $('#create_or_update_category_form_parentId');
        let selectedOption = $('#create_or_update_category_form_parentId option:selected').val();
        select.empty();

        select.append('<option value >No tiene categoría padre</option>');

        let section = $('#create_or_update_category_form_section').val();
        let type = $('#create_or_update_category_form_type').val();

        const route = this.route('parent-categories-by-section-and-type');
        const data = {
            section: section,
            type: type,
        };

        let categories = await $.post(route, data);

        console.log(categories);

        for (let category of categories) {
            let title = '';

            if (category['esContent.title']) {
                title = category['esContent.title'];
            } else if (category['esEsContent.title']) {
                title = category['esEsContent.title'];
            } else if (category['ukEsContent.title']) {
                title = category['ukEsContent.title'];
            } else if (category['ukEnContent.title']) {
                title = category['ukEnContent.title'];
            } else if (category['enContent.title']) {
                title = category['enContent.title'];
            }

            const geoLocations = category['geoLocations'];
            let geoLocationsText = '';

            for (const [index, geoLocation] of geoLocations.entries()) {
                geoLocationsText += index === 0 ? geoLocation : ', ' + geoLocation;
            }

            if (title !== '') {
                let optionText = title + ' (' + geoLocationsText + ')';
                let optionValue = category.id;
                let option = $('<option>', { value: optionValue, text: optionText, selected: category['id'] === selectedOption });
                select.append(option);
            }
        }
    }
});