export class ExcelHelper {
    static hasMultipleCellsToTheRight(worksheet, cell, cellsToTheRight) {
        const row = this.getRow(cell);
        const column = this.getColumn(cell);

        let cellsFound = 0;
        let currentColumn = this.nextCol(column);
        for (let index = 0; index < 30; index++) {
            if (!_.isEmpty(worksheet[currentColumn + row]) && !_.isUndefined(worksheet[currentColumn + row].v))
                cellsFound++;

            currentColumn = this.nextCol(currentColumn);
        }

        return cellsFound >= cellsToTheRight;
    }

    static firstCellValueToTheLeft(worksheet, cell) {
        const row = this.getRow(cell);
        const column = this.getColumn(cell);

        let currentColumn = this.prevCol(column);
        for (let index = 0; index < 30; index++) {
            if (!_.isEmpty(worksheet[currentColumn + row]) && !_.isUndefined(worksheet[currentColumn + row].v))
                return [worksheet[currentColumn + row].v + `(${column}${row})`, `${column}${row}`];

            currentColumn = this.prevCol(currentColumn);
        }
        return '';

    }

    static validateRange(coordinate) {
        return /^\$?[A-Z]+\$?\d+(?::\$?[A-Z]+\$?\d+)?(?:,\s*(?:\$?[A-Z]+\$?\d+(?::\$?[A-Z]+\$?\d+)?))*$/.test(
            coordinate);
    }

    static nextChar(c) {
        return c ? String.fromCharCode(c.charCodeAt(0) + 1) : 'A'
    }

    static prevChar(c) {
        return c ? String.fromCharCode(c.charCodeAt(0) - 1) : 'A'
    }

    static prevCol(s) {
        let integerColumn = this.excelColToInt(s);
        integerColumn--;
        return this.intToExcelCol(integerColumn);

    }

    static nextCol(s) {
        return s.replace(/([^Z]?)(Z*)$/, (_, a, z) => this.nextChar(a) + z.replace(/Z/g, 'A'))
    }

    static hasMultipleCellsToUnderneath(worksheet, cell, cellsToTheRight) {
        const row = this.getRow(cell);
        const column = this.getColumn(cell);

        let cellsFound = 0;
        let currentRow = parseInt(row) + 1;
        for (let index = 0; index < 30; index++) {
            if (!_.isEmpty(worksheet[column + currentRow]) && !_.isUndefined(worksheet[column + currentRow].v))
                cellsFound++;

            currentRow++;
        }

        return cellsFound > cellsToTheRight;
    }

    static getColumn(cell) {
        return cell.substring(0, cell.search(/[\d+]/));
    }

    static getRow(cell) {
        return cell.substring(cell.search(/[\d+]/));
    }

    static intToExcelCol(number) {
        let colName = '',
            dividend = Math.floor(Math.abs(number)),
            rest;

        while (dividend > 0) {
            rest = (dividend - 1) % 26;
            colName = String.fromCharCode(65 + rest) + colName;
            dividend = parseInt((dividend - rest) / 26);
        }
        return colName;
    }


    static excelColToInt(colName) {
        let digits = colName.toUpperCase().split(''),
            number = 0;

        for (let i = 0; i < digits.length; i++) {
            number += (digits[i].charCodeAt(0) - 64) * Math.pow(26, digits.length - i - 1);
        }

        return number;
    }

    static increaseCol(cell) {
        const row = this.getRow(cell);
        const column = this.getColumn(cell);

        return this.nextCol(column) + row;
    }

    static increaseRow(cell) {
        let row = this.getRow(cell);
        const column = this.getColumn(cell);
        row++;
        return column + row;
    }
}
