import {ElView} from "../shared/el-view";
import {Translator} from "../shared/translation/translator";
import {Router} from "../shared/routing/router";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";

export const AdminBannersPage = ElView.extend({
    el: '.j-admin-blog-banners',
    events: {
        'click .j-click-enable-or-disable-banner': 'onClickEnableOrDisableBanner',
    },
    datatable: null,
    bannerStatusClicked: false,

    init: function () {
        this.initUsersTable();

        this.find('.j-select-2-banner-status').select2({
            minimumResultsForSearch: -1,
        });
    },

    onClickEnableOrDisableBanner(event) {
        if (this.bannerStatusClicked)
            return;

        this.bannerStatusClicked = true;
        const $clicked = $(event.currentTarget);
        const bannerId = $clicked.attr('data-banner-id');

        const route = Router.generate('blog-enable-or-disable-banner', {bannerId: bannerId});
        $.post(route)
            .always(() => {
                location.reload();
            });
    },

    initUsersTable() {
        const _this = this;
        this.datatable = this.find('#admin-banners-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('banners-table-data'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#banners-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'title',
                        title: capitalizeFirstLetter(Translator.trans('titulo')),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },
                    {
                        field: 'section',
                        title: capitalizeFirstLetter(Translator.trans('section')),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.section}</span>`;

                        }
                    },
                    {
                        field: 'availableCountries',
                        title: capitalizeFirstLetter('Países disponibles'),
                        template: (row) => {
                            let values = '';
                            JSON.parse(row.availableCountries).forEach((value, index) => {
                                if (index !== JSON.parse(row.availableCountries).length)
                                    values += _this.translate(value) + '  ';

                            })
                            return `<div class="d-flex justify-content-between font-weight-bold text-muted">${values}</div>`;
                        }
                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter('status'),
                        textAlign: 'center',
                        template: (row) => {
                            const typetoCssClassMap = {
                                'draft': 'info',
                                'published': 'primary'
                            };

                            let $result;

                            if (row.status === 'draft') {
                                $result = 'Borrador';
                            } else {
                                $result = 'Publicado';

                            }

                            return `<span class="j-click-enable-or-disable-banner label label-inline label-rounded font-weight-bold label-light-${typetoCssClassMap[row.status]}" data-banner-id="${row.id}" style="cursor: pointer;">${capitalizeFirstLetter($result)}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        textAlign: 'center',
                        width: 150,
                        template: function (row) {
                            const editRoute = Router.generate('admin-blog-banner-details', {'bannerId': row.id});

                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                          <span class="fas fa-pencil-alt">
                                          </span>
                                    </a>`
                        },

                    },

                ],
            }
        );

        $('#banner-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#banner-search-websection').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'section');
        });

        $('#banner-search-lang').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'availableCountries');
        });

    },
});