import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";

export const AdminOrderDetails = ElView.extend({
    el: 'admin-order-details',
    events: {
        'click .j-admin-accept-order': 'onClickAcceptOrder',
        'click .j-admin-reject-order': 'onClickRejectOrder',
    },

    async init() {
    },

    async onClickAcceptOrder(event) {
        const orderId = $(event.currentTarget).attr('data-order-id');
        const route = this.route('accept-order', {orderId})

        try {
            await $.post(route);
            await this.confirmationOk('Pedido aceptado');

            location.reload();

        } catch (exception) {
            console.log(exception);
            await this.confirmationError('Error', exception.statusText);
        }
    },

    async onClickRejectOrder(event) {
        const orderId = $(event.currentTarget).attr('data-order-id');
        const route = this.route('reject-order', {orderId})

        try {
            await $.post(route);
            await this.confirmationOk('Pedido rechazado');

            location.reload();

        } catch (exception) {
            this.confirmationError('Error', exception.statusText);
        }
    },
})