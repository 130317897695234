import {ElView} from "../../../shared/el-view";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {Translator} from "../../../shared/translation/translator";

export const AdminUndoRakesPage = ElView.extend({
    el: 'admin-undo-rakes-page',
    events: {
        'changeDate .j-upload-date': 'onChangeUploadDate',
        'select2:select .j-select-rooms': 'onSelectRooms',
        'select2:unselect .j-select-rooms': 'onSelectRooms',
        'click .j-confirm-date-step': 'onConfirmDateStep',
        'click .j-confirm-rooms-step': 'onConfirmRoomsStep',
        'click .j-undo-rakes_confirmation': 'onUndoRakes',
    },

    datatable: null,
    uploadDate: null,
    selectedRooms: null,

    init() {
        this.uploadDate = this.find('.j-data').attr('data-upload-date');

        $('.j-upload-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
            minDate: new Date(2021, 6, 30),
        });
    },

    initRakesTable() {
        const _this = this;
        this.datatable = this.find('#admin-undo-rakes-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: _this.route('undo-rakes-table-data', {
                        date: _this.uploadDate,
                        roomIds: _this.selectedRooms
                    }),
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: $(this.el).find('#undo-rakes-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },
                columns: [
                    {
                        field: 'createdAt',
                        title: 'Fecha de subida',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`
                        },
                    },
                    {
                        field: 'username',
                        title: capitalizeFirstLetter(Translator.trans('username')),
                        template: (row) => {
                            if (row.username === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" 
                                            style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">${row.username}
                                        </label>`;
                            return `<a href="${_this.route('admin-user-details', {'userId': row.userId})}" class="font-weight-bolder">${row.username}</a>`
                        },
                    },
                    {
                        field: 'roomId',
                        title: capitalizeFirstLetter(Translator.trans('game.room.room')),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.roomName}</span>`
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.nick}</span>`
                        }
                    },
                    {
                        field: 'affiliateName',
                        title: 'Afiliado',
                        template: (row) => {
                            return `<a href="${_this.route('admin-affiliate-details', {'affiliateId': row.affiliateId})}" class="font-weight-bolder">${row.affiliateName}</a>`
                        },
                    },
                    {
                        field: 'amount',
                        title: capitalizeFirstLetter(Translator.trans('rake')),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.amount.toFixed(2)} ${row.currencySymbol}</span>`
                        }
                    },
                    {
                        field: 'status',
                        title: 'Estado',
                        textAlign: 'center',
                        template: (row) => {
                            if (row.status === 'status_charged')
                                return `<label class="font-weight-bolder" style="background-color: #dcf4da; border-radius: 15px; padding: 0px 5px;">Cobrado</label>`

                            return `<label class="font-weight-bolder" style="background-color: #ee523557; border-radius: 15px; padding: 0px 5px;">Sin cobrar</label>`
                        }
                    },
                ],
            }
        );
    },

    onChangeUploadDate() {
        this.uploadDate = this.find('.j-upload-date').val();

        if (this.datatable !== null)
            this.onUpdateTable();
    },

    onSelectRooms() {
        this.selectedRooms = this.find('.j-select-rooms').val();

        if (this.datatable !== null)
            this.onUpdateTable();
    },

    onConfirmDateStep(event) {
        const $currentTarget = $(event.currentTarget);
        $currentTarget.prop('disabled', true);

        this.showNextStep('.j-admin-undo-rakes_step--select-rooms');
    },

    async onConfirmRoomsStep(event) {
        const $currentTarget = $(event.currentTarget);
        $currentTarget.prop('disabled', true);

        await this.showNextStep('.j-admin-undo-rakes_step--show-rakes');

        if (!_.isNull(this.uploadDate) && !_.isNull(this.selectedRooms))
            this.initRakesTable();
    },

    showNextStep(selector) {
        const $step = this.find(selector);
        $step.show('slow');

        return $([document.documentElement, document.body]).animate({
            scrollTop: $step.offset().top - 170
        }, 1000).promise();
    },

    reloadDatatable() {
        if (!_.isNull(this.uploadDate) && !_.isNull(this.selectedRooms))
            this.datatable.reload();
        else
            this.confirmationError('Error', 'Los datos proporcionados no son correctos');
    },

    async onUndoRakes() {
        const route = this.route('core-api-undo-rakes');
        const data = {
            roomIds: this.selectedRooms,
            uploadDate: this.uploadDate,
        };

        try {
            await $.post(route, data);

            this.confirmationOk('Rakes eliminados', '');
        } catch (exception) {
            if (exception.responseText === 'invalid-date')
                this.confirmationError('Error', 'No se pueden deshacer reportes anteriores a Jul-2021');
            else
                this.confirmationError('Error', 'Ha ocurrido algún error');
        }

        this.onUpdateTable()
    },

    onUpdateTable() {
        this.datatable.destroy();
        this.initRakesTable();
    },
});