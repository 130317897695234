import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";

export const MyRoomsPage = ElView.extend({
    el: '.j-my-rooms-page',
    events: {
        'click .j-habpoker-verify-button': 'onClickVerifyButton',
        'click .j-habpoker-remove-button': 'onClickRemoveButton',
    },

    init() {
        this.disableInputs('.j-user-room-credentials-form-nick-input');
        this.disableInputs('.j-user-room-credentials-form-login-input');
        this.disableInputs('.j-user-room-credentials-form-code-input');
    },

    async onClickVerifyButton(event) {
        const $clicked = $(event.currentTarget);
        const $rowId = $clicked.attr('row');
        const $row = $clicked.closest('.j-habpoker-row');
        const $selectsDiv = $(`.row-${$rowId}`);

        const data = {
            userId: $selectsDiv.find('.j-user-room-credentials-form-user-id-input').val(),
            roomId: $selectsDiv.find('.j-user-room-credentials-form-room-id-input').val(),
            affiliateId: $selectsDiv.find('.j-user-room-credentials-form-affiliate-id-input').val(),
            nick: $row.find('.j-user-room-credentials-form-nick-input').val(),
            login: $row.find('.j-user-room-credentials-form-login-input').val(),
            code: $row.find('.j-user-room-credentials-form-code-input').val(),
            affiliateIsVerified: $row.find('.j-user-room-credentials-form-affiliate-is-verified-input').val(),
        }

        const route = Router.generate('tool-api-add-user-room-credentials')

        await $.post(route, data);

        location.reload();
    },

    async onClickRemoveButton(event) {
        const $clicked = $(event.currentTarget);
        const $row = $clicked.closest('.j-habpoker-row');

        const data = {userRoomCredentialsId: $row.find('.j-user-room-credentials-form-id-input').val()}
        const route = Router.generate('tool-api-remove-user-room-credentials');

        try {
            await $.post(route, data);
            await this.confirmationOk(Translator.trans('credentials-removed'));

        } catch (exception) {
            await this.confirmationError(Translator.trans('errors.detected'));
        }

        location.reload();
    },

    disableInputs(selector) {
        const $inputs = this.find(selector);
        $inputs.each((index, element) => {
            if ($(element).val() !== '')
                $(element).attr('disabled', 'disabled');
        })
    },
})