import {ElView} from "../shared/el-view";
import {DropzoneComponent} from "../shared/component/dropzone/dropzone-component";
import slugify from "@sindresorhus/slugify";

export const BlogPostDetailsPage = ElView.extend({
    el: '.j-blog-post-details',
    events: {
        'keyup .j-create-post-form-title-input': 'onKeyPressed',
        'click .j-save-post': 'onSavePost',
        'click .j-remove-post': 'onDeletePost',
        'onChange .j-text-editor': 'onContentChange',
        'select2:select .j-create-post-form-lang-input': 'wrapperOnChangeAvailableCountries',
        'select2:unselect .j-create-post-form-lang-input': 'wrapperOnChangeAvailableCountries',
        'select2:select .j-create-post-form-parent-room-post-id-input': 'onChangeAvailableCountriesForPromotion',
        'select2:unselect .j-create-post-form-parent-room-post-id-input': 'onChangeAvailableCountriesForPromotion',
    },

    isPromotion: false,
    isContent: false,

    dropZoneUploader: null,
    dropZoneUploader1: null,
    dropZoneUploader2: null,
    dropZoneUploader3: null,
    dropZoneUploader4: null,
    dropZoneUploader5: null,


    localeToTabIdMap: {
        'es_ES': 'esEsContent',
        'es_GB': 'esGbContent',
        'en_GB': 'enGbContent',
        'es': 'esContent',
        'en': 'enContent',
    },

    hidePostContentClassAndEvent: {
        intl: 'hide-intl',
        ES: 'hide-es',
        GB: 'hide-gb'
    },

    showPostContentClassAndEvent: {
        intl: 'show-intl',
        ES: 'show-es',
        GB: 'show-gb'
    },

    errorOnPostContentEvent: {
        es_ES: 'error-es-es',
        es_GB: 'error-es-gb',
        en_GB: 'error-en-gb',
        es: 'error-es',
        en: 'error-en',
    },

    allAvailableCountries: ['ES', 'GB', 'intl'],

    parentRoomPostAvailableCountries: [],

    async init() {
        this.postId = this.find('.j-data').attr('data-post-id');

        this.isPromotion = this.find('.j-data').hasClass('j-is-promotion');
        this.isContent = this.find('.j-data').hasClass('j-is-content');

        this.onShowAvailableCountriesWarning = _.debounce(this.showAvailableCountriesWarning, 500);

        this.initStatusSelect();
        this.initDropzones();

        const $availableCountriesSelect = this.find('.j-create-post-form-lang-input');
        this.onChangeAvailableCountries($availableCountriesSelect);
        await this.onChangeAvailableCountriesForPromotion();

        this.validateAvailableCountries();
    },

    initDropzones() {
        this.dropZoneUploader = new DropzoneComponent({el: '.j-blog-main-image-uploader'});
        this.dropZoneUploader1 = new DropzoneComponent({el: '#esContent .j-image-form'});
        this.dropZoneUploader2 = new DropzoneComponent({el: '#esEsContent .j-image-form'});
        this.dropZoneUploader3 = new DropzoneComponent({el: '#esGbContent .j-image-form'});
        this.dropZoneUploader4 = new DropzoneComponent({el: '#enGbContent .j-image-form'});
        this.dropZoneUploader5 = new DropzoneComponent({el: '#enContent .j-image-form'});

        this.dropZoneUploader.onUpload((file) => {
            this.log(file)
            this.find('.j-image-url-input').val(file.url);
        });

        this.dropZoneUploader1.onUpload((file) => {
            this.log(file)
            this.find('#esContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader2.onUpload((file) => {
            this.log(file)
            this.find('#esEsContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader3.onUpload((file) => {
            this.log(file)
            this.find('#ukEsContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader4.onUpload((file) => {
            this.log(file)
            this.find('#ukEnContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader5.onUpload((file) => {
            this.log(file)
            this.find('#enContent').find('.j-image-from-url-input').val(file.url);
        });
    },

    async showAvailableCountriesWarning() {
        toastr.error('Rellene los contenidos de todos los países disponibles');
    },

    onContentChange() {
        this.log('change')
    },

    onKeyPressed(event) {
        const $titleInput = this.$(event.currentTarget);
        const titleValue = $titleInput.val();

        if (!titleValue)
            return;

        const newSlug = slugify(titleValue);
        const $parentContainer = $titleInput.closest('.j-post-content-container');
        const $slugInput = $parentContainer.find('.j-create-post-slug');

        $slugInput.val(newSlug);
    },

    async onDeletePost(event) {
        const postId = $(event.currentTarget).attr('data-post-id');
        const route = this.route('remove-post', {postId});
        const type = this.find('.j-data').attr('data-post-type');
        let result;
        if (type === 'promotion') {
            result = await this.confirmation('¿Deseas borrar la promoción?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');
        } else if (type === 'room') {
            result = await this.confirmation('¿Deseas borrar la sala?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');
        } else if (type === 'post') {
            result = await this.confirmation('¿Deseas borrar la noticia?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');
        } else if (type === 'content') {
            result = await this.confirmation('¿Deseas borrar el contenido?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');
        }

        if (result.isConfirmed) {
            try {
                await $.post(route);

                let redirectRoute;

                if (type === 'promotion') {
                    await this.confirmationOk('Promoción eliminada');
                    redirectRoute = this.route('admin-blog-promotions');

                } else if (type === 'room') {
                    await this.confirmationOk('Sala eliminada');
                    redirectRoute = this.route('admin-blog-rooms-post');

                } else if (type === 'post') {
                    await this.confirmationOk('Noticia eliminada');
                    redirectRoute = this.route('admin-blog-posts');

                } else if (type === 'content') {
                    await this.confirmationOk('Contenido eliminado');
                    redirectRoute = this.route('admin-blog-contents');
                }

                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El post no ha sido eliminado');
            }
        }
    },

    async onSavePost() {
        this.clearErrorsOnPostContents();
        this.validateAvailableCountries();

        const isPublished = this.find('.j-create-post-form-status-input').select2('data')[0].id === 'published';

        let response;
        if (!isPublished)
            response = await this.confirmation('Va a guardar la publicación como borrador', '¿Quiere continuar?', 'Aceptar', 'Cancelar');

        if (isPublished || !response.isDismissed)
            this.find('.j-post-form').submit();
    },

    clearErrorsOnPostContents() {
        this.find('post-content-component').trigger('clear-errors');
    },

    initStatusSelect() {
        this.find('.j-create-post-form-status-input').select2({
            minimumResultsForSearch: -1,
        });
    },

    async validateAvailableCountries() {
        const availableCountries = this.find('.j-create-post-form-lang-input').val();

        if (availableCountries.includes('ES') && this.localizedContentIsEmpty('es_ES')) {
            this.showPostContentErrorOnLocale('es_ES');
            await this.onShowAvailableCountriesWarning();
        }
        if (availableCountries.includes('GB')) {
            if (this.localizedContentIsEmpty('es_GB')) {
                this.showPostContentErrorOnLocale('es_GB');
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en_GB')) {
                this.showPostContentErrorOnLocale('en_GB');
                await this.onShowAvailableCountriesWarning();
            }

        }

        if (availableCountries.includes('intl')) {
            if (this.localizedContentIsEmpty('es')) {
                this.showPostContentErrorOnLocale('es');
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en')) {
                this.showPostContentErrorOnLocale('en');
                await this.onShowAvailableCountriesWarning();
            }

        }

        if (this.isPromotion)
            await this.validatePromotionAvailableCountries(availableCountries);
    },

    async validatePromotionAvailableCountries(availableCountries) {
        const promotionHasCorrectCountries = _.union(availableCountries, Object.values(this.parentRoomPostAvailableCountries)).length === _.uniq(availableCountries).length;

        if (!promotionHasCorrectCountries)
            return this.showPromotionAvailableCountriesWarning();
    },

    async onChangeAvailableCountriesForPromotion() {
        if (!this.isPromotion)
            return;

        const $parentRoomPostsSelect = this.find('.j-create-post-form-parent-room-post-id-input');
        const selectedRoomsPosts = $parentRoomPostsSelect.select2('data');

        if (selectedRoomsPosts.length === 0)
            return;

        const selectedRoomPostIds = selectedRoomsPosts.map((option) => option.id);

        this.parentRoomPostAvailableCountries = await this.fetchAvailableCountriesForPromotion(selectedRoomPostIds);

        this.updateSelectForRoomsAvailableCountries(this.parentRoomPostAvailableCountries);
    },

    updateSelectForRoomsAvailableCountries(availableCountries) {
        const $select = this.find('.j-available-countries-for-promotion');

        $select.val(null).trigger('change');
        $select.val(availableCountries).trigger('change');
    },

    async showPromotionAvailableCountriesWarning() {
        toastr.error('Los países disponibles no coinciden con los de las salas asignadas');

        return new Promise((resolve, reject) => {
            setTimeout(function () {
                resolve();
            }, 2000);
        });
    },

    async fetchAvailableCountriesForPromotion(roomPostIds) {
        const route = this.route('available-countries-of-room-posts');
        const data = {
            roomPostIds: roomPostIds,
        }

        return await $.post(route, data);
    },

    showPostContentErrorOnLocale(country) {
        this.find('post-content-component').addClass(this.errorOnPostContentEvent[country]).trigger(this.errorOnPostContentEvent[country]);
    },

    localizedContentIsEmpty(locale) {
        const tabIdFromLocale = this.localeToTabIdMap[locale];
        const $tabForLocale = this.find('#' + tabIdFromLocale);

        return this.tabContentIsEmpty($tabForLocale);
    },

    tabContentIsEmpty($tabContent) {
        const contentTitle = $tabContent.find('.j-create-post-form-title-input').val();
        const contentMetaTitle = $tabContent.find('.j-create-category-form-metaTitle-input').val();
        const contentSlug = $tabContent.find('.j-create-post-slug').val();
        const contentMetaDescription = $tabContent.find('.j-create-category-form-metaDescription-input').val();
        const contentContent = $tabContent.find('.j-create-post-form-content-input').val();

        if (this.isPromotion) {
            const contentDescription = $tabContent.find('.j-create-post-form-shortDescription-input').val();

            return this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentMetaTitle) ||
                this.inputIsEmpty(contentSlug) || this.inputIsEmpty(contentDescription) || this.inputIsEmpty(contentMetaDescription) || this.inputIsEmpty(contentContent);
        }

        if (this.isContent) {
            return this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentSlug) || this.inputIsEmpty(contentContent);
        }

        return this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentMetaTitle) ||
            this.inputIsEmpty(contentSlug) || this.inputIsEmpty(contentMetaDescription) || this.inputIsEmpty(contentContent);
    },

    inputIsEmpty(inputValue) {
        const inputCleared = this.removeHTMLTagsFromInput(inputValue);

        return _.isUndefined(inputCleared) || _.isNull(inputCleared) || inputCleared === '';
    },

    removeHTMLTagsFromInput(inputValue) {
        return inputValue.replaceAll(/<.+?>/g, '');
    },

    wrapperOnChangeAvailableCountries(event) {
        const $select = $(event.currentTarget);

        this.onChangeAvailableCountries($select);
    },

    onChangeAvailableCountries($element) {
        const $postContent = this.find('post-content-component');

        const selectedCountries = $element.select2('data').map((selected) => selected.id);
        const notSelectedCountries = this.allAvailableCountries.filter((country) => !selectedCountries.includes(country));

        selectedCountries.forEach((country) => this.showPostContent(country, $postContent));
        notSelectedCountries.forEach((country) => this.hidePostContent(country, $postContent));
    },

    hidePostContent(country, $postContent) {
        $postContent.removeClass(this.showPostContentClassAndEvent[country])
        $postContent.addClass(this.hidePostContentClassAndEvent[country]).trigger(this.hidePostContentClassAndEvent[country]);
    },

    showPostContent(country, $postContent) {
        $postContent.removeClass(this.hidePostContentClassAndEvent[country])
        $postContent.addClass(this.showPostContentClassAndEvent[country]).trigger(this.showPostContentClassAndEvent[country]);
    },
});