import {ElView} from "../../shared/el-view";
import {DropzoneComponent} from "../../shared/component/dropzone/dropzone-component";
import {Router} from "../../shared/routing/router";

const MODE_CREATE = 'create'
const MODE_UPDATE = 'update'
export const BlogSlideCreateOrUpdateComponent = ElView.extend({
    el: 'slide-create-or-update',
    events: {
        'submit .j-slide-form': 'onSubmitForm',
        'click .j-select-banner': 'onSelectBanner',
    },
    mode: '',
    isSubmitting: '',
    orderOfBanners: [],
    init: function () {
        this.isSubmitting = false;
        const modeDiv = this.find('.j-data');
        const modeBool = modeDiv.attr('data-mode');
        if (modeBool)
            this.mode = MODE_UPDATE;
        else
            this.mode = MODE_CREATE;
        this.find('.j-checkbox-width').css('width', '20px');
        this.dropZoneUploader = new DropzoneComponent({el: '.j-blog-main-image-uploader'});
        this.dropZoneUploader.onUpload((file) => {
            this.find('.j-blogImageUrl').val(file.url);

        });

        if (this.mode === MODE_UPDATE) {
            const bannersIdsEncodeDiv = this.find('.j-data');
            const bannersIdEncode = bannersIdsEncodeDiv.attr('data-banners-ids');
            const bannersIds = JSON.parse(bannersIdEncode);
            bannersIds.forEach((element, index) => {

                this.orderOfBanners.push(element);
                const card = this.find(`#banner-card-${element}`);
                card.css('backgroundColor', '#DAF4E8');
                card.attr('data-selected', 'true');

            });
        }

        this.render();

    },

    onSelectBanner(event) {
        const $target = $(event.currentTarget);
        const id = $target.attr('data-id');
        const card = this.find(`#banner-card-${id}`);
        if (card.attr('data-selected') === 'false') {
            card.attr('data-selected', 'true');
            card.css('backgroundColor', '#DAF4E8');
            this.orderOfBanners.push(card.attr('data-id'));
        } else {
            this.removeItemFromArr(this.orderOfBanners, `${card.attr('data-id')}`);
            card.attr('data-selected', 'false');
            card.css('backgroundColor', 'white');
        }
        this.render();
    },

    drawBannerOrder() {
        this.find('.j-card').each((index, element) => {
            const position = this.orderOfBanners.indexOf($(element).attr('data-id')) + 1;
            if (position !== 0) {
                $(element).find('.j-number-banner').text(`${position}`);
                $(element).find('.j-number-banner').show();
            } else {
                $(element).find('.j-number-banner').text('');
                $(element).find('.j-number-banner').hide();
            }

        });
    },

    render() {
        this.drawBannerOrder();
    },


    removeItemFromArr(arr, item) {
        var i = arr.indexOf(item);

        if (i !== -1) {
            arr.splice(i, 1);
        }
    },

    onSubmitForm(event) {
        if (!this.isSubmitting) {
            const $target = $(event.currentTarget);
            event.preventDefault();
            event.stopPropagation();

            this.isSubmitting = true;
            // const banners = [];
            // this.find('.j-card').each((index, element) => {
            //     if ($(element).attr('data-selected') === "true")
            //         banners.push($(element).attr('data-id'));
            // });
            // console.log(banners);
            // const bannersToString = JSON.stringify(Object.assign({}, banners));
            // // const stringToJsonObject = JSON.parse(bannersToString);

            const bannersToString = JSON.stringify(Object.assign({}, this.orderOfBanners));
            this.find('.j-banners').val(bannersToString);
            this.find('.j-slide-form').submit();
        } else
            this.isSubmitting = false;
    },

});