import {ElView} from "../../../shared/el-view";
import {AdminChargeRakesPreviewTable} from "./admin-charge-rakes-preview-table";
import {formatNumber} from "../../../shared/helper/number-helper";

export const AdminChargeRakesPage = ElView.extend({
    el: 'admin-charge-rakes',
    events: {
        'click .j-button-select-options': 'onClickContinueSelectOptions',
        'click .j-continue-button-currencies': 'onClickPreview',
        'click .j-undo-button': 'onClickUndo',
        'click .j-charge-rakes': 'onClickChargeRakes',
    },
    datatable: null,
    affiliateTable: null,

    roomIds: null,
    poundToEuro: null,
    dollarToEuro: null,
    turkishLiraToEuro: null,

    init() {
        $('.j-start-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
            // startDate: ,
            // endDate: ,
        });

        $('.j-end-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        this.initCurrencyConversions();
    },

    async onClickContinueSelectOptions() {
        this.showStep(2);
    },

    async onClickPreview() {
        this.roomIds = this.find('.j-charge-rakes-room-select').val();
        this.userId = this.find('.j-charge-rakes-user-select').val();
        this.poundToEuro = this.find('.j-pounds-to-euro').val();
        this.dollarToEuro = this.find('.j-dollars-to-euro').val();
        this.turkishLiraToEuro = this.find('.j-turkish-lira-to-euro').val();
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        const route = this.route('core-api-charge-rakes');
        const data = {
            poundToEuro: this.poundToEuro,
            dollarToEuro: this.dollarToEuro,
            turkishLiraToEuro: this.turkishLiraToEuro,
            isPreview: 1,
            roomIds: this.roomIds,
            userId: this.userId,
            startDate: this.startDate,
            endDate: this.endDate,
        }

        try {
            const tableData = await $.post(route, data);

            this.previewTable = new AdminChargeRakesPreviewTable({
                el: '#admin-charge-rakes-datatable',
                data: tableData,
            });
            this.showRoomResume(tableData);

            this.showStep(3);

        } catch (exception) {
            await this.confirmationError('Fechas inválidas', 'Las fechas deben corresponder al mismo mes');
            location.reload();
        }
    },

    async onClickChargeRakes() {
        const route = this.route('core-api-charge-rakes');
        const data = {
            poundToEuro: this.poundToEuro,
            dollarToEuro: this.dollarToEuro,
            turkishLiraToEuro: this.turkishLiraToEuro,
            isPreview: 0,
            roomIds: this.roomIds,
            userId: this.userId,
            startDate: this.startDate,
            endDate: this.endDate,
            distributeToAffiliates: this.find('.j-distribute-to-affiliates').is(':checked'),
        }

        try {
            await $.post(route, data);

            await this.confirmationOk('Reparto realizado con éxito');

            location.reload();
        } catch (exception) {
            await this.confirmationError('Ha ocurrido algún error', 'Por favor revise los datos');
        }
    },

    showStep(index) {
        let $step = this.find(`.j-charge-rakes_step--${index}`);
        $step.show('slow');

        $([document.documentElement, document.body]).animate({
            scrollTop: $step.offset().top - 170
        }, 1000);

    },

    onClickUndo() {
        location.reload();
    },

    async initCurrencyConversions() {
        const route = this.route('get-conversion-rates')

        const conversionRates = await $.get(route);

        this.find('.j-pounds-to-euro-reference').val((conversionRates['pound']).toFixed(4));
        this.find('.j-dollars-to-euro-reference').val(conversionRates['dollar'].toFixed(4));
        this.find('.j-turkish-lira-to-euro-reference').val(conversionRates['turkish_lira'].toFixed(4));
    },

    showRoomResume(tableData) {
        const roomResume = {};
        tableData.forEach(function (row) {
            const roomId = row['roomId'];
            const roomName = row['roomName'];
            const userReward = row['userReward'];

            if (roomResume[roomId]) {
                roomResume[roomId].totalUserReward += userReward;
            } else {
                roomResume[roomId] = {
                    id: roomId,
                    name: roomName,
                    totalUserReward: userReward
                };
            }
        });

        const adminChargeRakesResume = document.getElementById("admin-charge-rakes-resume");
        Object.values(roomResume).forEach(room => {
            const row = document.createElement("div");
            row.classList.add("my-5", "d-flex", "flex-row", "align-items-center");

            const nameCell = document.createElement("div");
            nameCell.textContent = room.name;
            nameCell.classList.add("font-weight-bold", "mr-3");

            const totalUserRewardCell = document.createElement("div");
            totalUserRewardCell.textContent = formatNumber(room.totalUserReward, 2) + '€';
            totalUserRewardCell.classList.add("font-weight-bold", "btn", "btn-primary");

            row.appendChild(nameCell);
            row.appendChild(totalUserRewardCell);

            adminChargeRakesResume.appendChild(row);
        });
    }
});