import {ElView} from "../../el-view";
import {Router} from "../../routing/router";

export const TextEditor = ElView.extend({
    el: '.j-text-editor',
    events: {},

    init() {
        this.editor = $('.j-text-editor').summernote({
            height: 200,
            tabsize: 2,
            popover: {
                image: [
                    ['custom', ['imageAttributes']],
                    ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                    ['float', ['floatLeft', 'floatRight', 'floatNone']],
                    ['remove', ['removeMedia']]
                ],
            },
            lang: 'es-ES',
            imageAttributes: {
                icon: '<i class="note-icon-pencil"/>',
                removeEmpty: false, // true = remove attributes | false = leave empty if present
                disableUpload: false // true = don't display Upload Options | Display Upload Options
            },
            callbacks: {
                onImageUpload: function (files) {
                    function sendFile(file, editor) {
                        const data = new FormData();
                        data.append("file", file);
                        $.ajax({
                            data: data,
                            type: "POST",
                            url: Router.generate('core-api-upload-image-v2'),
                            cache: false,
                            contentType: false,
                            processData: false,
                            success: (response) => {
                                const image = $('<img>').attr('src', response.fullUrl);
                                $(editor).summernote("insertNode", image[0]);
                            }
                        });
                    }

                    sendFile(files[0], this);
                }
            }
        });
    },


})