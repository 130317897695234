import {ElView} from "../../../shared/el-view";
import EnglishUppyLocale from "@uppy/locales/lib/en_US";
import {Translator} from "../../../shared/translation/translator";
import SpanishUppyLocale from "@uppy/locales/lib/es_ES";
import XHRUpload from "@uppy/xhr-upload";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";


export const AdminCategoryDetailsPage = ElView.extend({
    el: '.j-admin-category-details',
    events: {
        'keyup .j-category-title': 'onKeyPressed',
        'click .j-save-category': 'onSaveCategory',
        'click .j-remove-category': 'onRemoveCategory',
        'click .uppy-list-remove': 'onRemoveCategoryImage',
    },
    imageId: null,
    bannerImageId: null,

    init() {
        this.initFileUploader();
    },

    onKeyPressed() {
        let text = this.find('.j-category-title').val();

        this.find('.j-category-slug').val(slugify(text));
    },

    onSaveCategory() {
        this.find('.j-category-form').submit();
    },

    async onRemoveCategory(event) {
        const $clicked = $(event.currentTarget);
        const categoryId = $clicked.attr('data-category-id');

        const route = this.route('remove-product-category', {categoryId});

        const firstConfirmation = await this.confirmation('Va a eliminar la categoría', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                await $.post(route)
                await this.confirmationOk('Eliminado!', 'La categoría ha sido eliminada');

                const redirectRoute = this.route('admin-product-categories');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'La categoría no ha sido eliminada');
            }
        }

    },

    initFileUploader: function () {
        this.$data = this.find('.j-data');
        this.imageId = this.$data.attr('data-image-id');
        this.bannerImageId = this.$data.attr('data-banner-image-id');

        this.initImageUploader('j-image-uploader');
        this.initImageUploader('j-banner-image-uploader');
    },

    initImageUploader(elemId) {
        const SpanishUppyLocale = require('@uppy/locales/lib/es_ES');
        const EnglishUppyLocale = require('@uppy/locales/lib/en_US');
        const XHRUpload = require('@uppy/xhr-upload');

        const StatusBar = Uppy.StatusBar;
        const FileInput = Uppy.FileInput;
        const Informer = Uppy.Informer;

        const id = `#${elemId}`;
        const $statusBar = $(id + ' .uppy-status');
        const $uploadedList = $(id + ' .uppy-list');

        let locale = EnglishUppyLocale;
        if (Translator.isCurrentLanguageSpanish())
            locale = SpanishUppyLocale;

        this.uppyPlugin = Uppy.Core({
            locale: locale,
            debug: true,
            autoProceed: true,
            showProgressDetails: true,
            restrictions: {
                maxFileSize: 5000000,
                allowedFileTypes: ['image/*']
            }
        });

        this.uppyPlugin.use(XHRUpload, {
            endpoint: this.route('core-api-upload-image'),
            responseUrlFieldName: 'fullUrl'
        })
        this.uppyPlugin.use(FileInput, {target: id + ' .uppy-wrapper', pretty: false});
        this.uppyPlugin.use(Informer, {target: id + ' .uppy-informer'});

        this.uppyPlugin.use(StatusBar, {
            target: id + ' .uppy-status',
            hideUploadButton: true,
            hideAfterFinish: false
        });

        $(id + ' .uppy-FileInput-input').addClass('uppy-input-control d-none').attr('id', elemId + '_input_control');
        $(id + ' .uppy-FileInput-container').append(
            `<label class="uppy-input-label btn btn-light-primary btn-sm btn-bold" for="${elemId}_input_control"><i class="fas fa-paperclip mr-2"></i>${capitalizeFirstLetter(
                Translator.trans('upload-images'))}</label>`);

        const $fileLabel = $(id + ' .uppy-input-label');

        this.uppyPlugin.on('upload', function (data) {
            $fileLabel.text("Uploading...");
            $statusBar.addClass('uppy-status-ongoing');
            $statusBar.removeClass('uppy-status-hidden');
        });

        this.uppyPlugin.on('complete', (file) => {
            $.each(file.successful, (index, value) => {
                let sizeLabel = "bytes";
                let filesize = value.size;
                if (filesize > 1024) {
                    filesize = filesize / 1024;
                    sizeLabel = "kb";

                    if (filesize > 1024) {
                        filesize = filesize / 1024;
                        sizeLabel = "MB";
                    }
                }

                if (this.imageId === '' && elemId === 'j-image-uploader')
                    this.imageId = value.response.body.fileId;
                else if (this.bannerImageId === 'j-banner-image-uploader')
                    this.bannerImageId = value.response.body.fileId;

                const uploadListHtml = `
                <div class="col-md-4" data-image-id="${value.response.body.fileId}">
                    <div class="uppy-list-item d-flex flex-column" data-id="${value.id}" data-image-id="${value.response.body.fileId}">
                        <div class="d-flex justify-content-md-end w-100">
                            <div class="uppy-list-remove float-right"
                                 data-id="${value.id}" data-image-id="${value.response.body.fileId}">
                                <i class="flaticon2-cancel-music" id="${elemId}"></i>
                            </div>
                        </div>
                        <a class="mt-2" href="${value.response.uploadURL}">
                            <img src="${value.response.uploadURL}" class="image-fluid">
                        </a>
                        <a href="${value.response.uploadURL}" class="uppy-list-label mt-2 a-hover-color-force">${value.name}(${Math.round(
                    filesize, 2)}${sizeLabel})</a>
                    </div>
                </div>
                `;
                $uploadedList.empty();
                $uploadedList.append(uploadListHtml);

                if (elemId === 'j-image-uploader')
                    this.find('.j-image-id-input').val(value.response.body.fileId);
                else if (elemId === 'j-banner-image-uploader')
                    this.find('.j-banner-image-id-input').val(value.response.body.fileId);
            });

            $fileLabel.html(`<i class='fas fa-paperclip mr-2'></i>${capitalizeFirstLetter(
                Translator.trans('add-more-images'))}`);
            $statusBar.addClass('uppy-status-hidden');
            $statusBar.removeClass('uppy-status-ongoing');

            this.undelegateEvents();
            this.delegateEvents();
        });

    },


    onRemoveCategoryImage(event) {
        if (event.target.id === 'j-image-uploader') {
            this.find('.j-image-id-input').val('');
            this.find('#j-image-uploader .uppy-list').empty();

        } else if (event.target.id === 'j-banner-image-uploader') {
            this.find('.j-banner-image-id-input').val('');
            this.find('#j-banner-image-uploader .uppy-list').empty();
        }
    },
});