import {ElView} from "../../shared/el-view";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";

export const ToolAffiliatePage = ElView.extend({
    el: '.j-tool-affiliate-page',
    events: {
        'click .j-add-user-room-credentials-button': 'onClickAddOrUpdateUserRoomCredentials',
        'click .j-add-user-room-credentials-modal-save': 'onClickUpdateUserCredentialsModalSave',
        'hidden.bs.modal #add-user-room-credentials-modal': 'onClickUpdateUserCredentialsModalClose',
    },

    datatable: null,

    init() {
        $('.j-affiliate-invite-friends-button').on('click', (event) => {
            this.onClickInviteFriends(event);
        });
    },

    onClickInviteFriends() {
        const $modal = this.find('#modal-invite-friends-by-email');
        const route = Router.generate('tool-refereds');

        $.post(route, $modal.find('.j-affiliate-invite-friends-form').serialize())

            .always((response) => {

                    if (response.responseText === '400') {

                        swal.fire({
                            title: 'Error',
                            text: Translator.trans('email-already-exists'),
                            icon: "warning",
                            confirmButtonText: Translator.trans('accept'),

                        }).then(() => {
                            this.find('#modal-invite-friends-by-email').modal('hide');
                            location.reload();
                        })
                    }

                    if (response.responseText === '401') {

                        swal.fire({
                            title: 'Error',
                            text: Translator.trans('not-valid-email-address'),
                            icon: "warning",
                            confirmButtonText: Translator.trans('accept'),

                        }).then(() => {
                            this.find('#modal-invite-friends-by-email').modal('hide');
                            location.reload();
                        })
                    }

                    if (response === '200') {

                        swal.fire({
                            title: 'Error',
                            text: Translator.trans('email-already-exists'),
                            icon: "warning",
                            confirmButtonText: Translator.trans('accept'),

                        }).then(() => {
                            this.find('#modal-invite-friends-by-email').modal('hide');
                        })

                        this.confirmationOk(Translator.trans('invitaion-email-sent'))
                            .then(() => {
                                this.find('#modal-invite-friends-by-email').modal('hide');
                                location.reload();
                            })
                    }
                }
            )
    },

    onClickAddOrUpdateUserRoomCredentials(event) {
        const route = Router.generate('tool-api-affiliate-create-user-room-credentials');
        const data = {
            affiliateId: $(event.currentTarget).attr('data-affiliate-id'),
        }

        $.get(route, data)
            .done((response) => {
                const $modalBody = $(document).find('#add-user-room-credentials-modal .modal-body');
                $modalBody.append(response);
                $modalBody.find('.j-user-room-credentials-form-room-id-input').select2();
            })

            .fail((response) => {
                let msg = Translator.trans('error');
                if (response === 400)
                    msg = Translator.trans('credentials-already-registered-on-database');

                toastr.options = {
                    positionClass: 'toast-bottom-left',

                    progressBar: true,
                    preventDuplicates: true,
                    closeButton: true,
                };

                toastr.error(msg);
            })

    },

    onClickUpdateUserCredentialsModalSave(event) {
        const $modal = $(event.currentTarget).closest('#add-user-room-credentials-modal');
        const affiliateId = $modal.attr('data-affiliate-id');
        $modal.find('.j-user-room-credentials-form-affiliate-id-input').val(affiliateId);
        const nick = $modal.find('.j-user-room-credentials-form-nick-input').val();
        const roomId = $modal.find('.j-user-room-credentials-form-room-id-input').val();
        if (nick === '' || roomId === '') {
            toastr.options = {
                positionClass: 'toast-bottom-left',

                progressBar: true,
                preventDuplicates: true,
                closeButton: true,
            };
            toastr.error(capitalizeFirstLetter(Translator.trans('nick-and-room-required')));
            return;
        }

        const route = Router.generate('tool-api-affiliate-create-user-room-credentials');
        $.post(route, $modal.find('.j-user-room-credentials-form').serialize())
            .always((response) => {
                if (response.responseText === '400') {
                    swal.fire({
                        title: 'Error',
                        text: Translator.trans('user-credentials-already-exist'),
                        icon: "warning",
                        confirmButtonText: Translator.trans('accept'),

                    }).then(() => {
                        this.find('.j-add-user-room-credentials-modal-close').click();
                    })
                } else {
                    this.confirmationOk(Translator.trans('user-room-credentials-created'))
                        .then(() => {
                            this.find('.j-add-user-room-credentials-modal-close').click();
                            this.datatable.reload();
                        })
                }
            })
    },

    onClickUpdateUserCredentialsModalClose(event) {
        const $modal = $(event.currentTarget).closest('#add-user-room-credentials-modal');
        $modal.find('.modal-body').empty();
    },
})