import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";

export const SalesSubAffiliateDetails = ElView.extend({
    el: 'sub-affiliate-details',
    events: {
        'select2:select .j-select-2-room-commissions': 'onSelectRoom',
        'click .j-delete-room-commission': 'onClickDeleteRoomCommission',
        'click .j-submit': 'onClickSaveChanges',
        'click .j-remove-sub-affiliate': 'onRemoveSubAffiliate'
    },
    parentAffiliateId: null,
    newRowTemplate: null,
    select2Data: null,
    init: function () {
        this.parentAffiliateId = this.find('.j-data').attr('data-parent-affiliate-id');
        this.initRoomSelect2();
    },

    initRoomSelect2() {
        const $select = this.find('.j-select-2-room-commissions');
        const data = JSON.parse(this.find('.j-data').attr('data-rooms-without-commission'));
        const $roomIds = this.find('.j-room-id-form-input');

        data.forEach((value, index) => {
            let alreadyAdded = false;
            $roomIds.each((roomIdIndex, element) => {
                const roomId = $(element).val();
                if (roomId === value['id'])
                    alreadyAdded = true;
            })
            if (!alreadyAdded) {
                const newOption = new Option(value['roomName'], value['id'], false, false);
                $select.append(newOption).trigger('change');
            }
        })
    },

    onSelectRoom(event) {
        const $select = $(event.currentTarget);
        const data = $select.select2('data');
        const roomId = data[0]['id'];
        const roomName = data[0]['text'];

        $select.empty();
        this.createNewRowForm(roomId, roomName);
        this.initRoomSelect2();
        this.setOwnCommissionInRoom(roomId);
    },


    createNewRowForm(roomId, roomName) {
        let $newRow = this.find('.j-room-commission-form-template').clone();
        $newRow.removeClass('j-room-commission-form-template');
        $newRow.children().addClass('j-room-commission-form-row');
        let lastIndex = this.find('.j-room-commission-form-row').length;

        $newRow = $($newRow.html().replaceAll(/__name__/g, lastIndex));
        $newRow.find('.j-room-id-form-input').val(roomId);
        $newRow.find('.j-room-name-input').val(roomName);
        $newRow.find('.j-room-commission-form-input').val(0.02);

        const $rowsContainer = this.find('.j-room-commission-form-container');

        $rowsContainer.append($newRow);
        $newRow.fadeIn();

        $('.j-room-commission-form-container').animate({scrollTop: $('.j-room-commission-form-container').get(0).scrollHeight}, 'slow');
    },

    onClickDeleteRoomCommission(event) {
        const $clicked = $(event.currentTarget);
        const $row = $clicked.closest('.j-room-commission-form-row');
        $row.remove();
    },

    onClickSaveChanges(event) {
        this.find('.j-user-room-credentials-form-template').remove();
        this.find('.j-room-commission-form-template').remove();

        const $form = this.find('.j-sub-affiliate-form');
        const formData = new FormData($form[0]);
        const invitationMail = this.find('.j-new-affiliate-invitation-mail').val();
        formData.append('invitationMail', _.isUndefined(invitationMail) ? '' : invitationMail);

        const route = this.route('sales-sub-affiliate-details', {'subAffiliateId': this.find('.j-data').attr('data-sub-affiliate-id')});

        $.ajax({
            url: route,
            data: formData,
            contentType: false,
            processData: false,
            cache: false,
            type: 'POST',

        }).done((data) => {
            if (data === 'created')
                this.confirmationOk('Afiliado creado', 'Se ha enviado un email a la dirección que ha proporcionado')
                    .then(() => {
                        location.reload();
                    })

            if (data === 'updated')
                this.confirmationOk('Afiliado actualizado', '')
                    .then(() => {
                        location.reload();
                    })

        }).fail(() => {
                this.confirmationError('Error', 'Ha ocurrido algún error')
                    .then(() => {
                        location.reload();
                    })
            })
    },

    async onRemoveSubAffiliate(event) {
        const $clicked = $(event.currentTarget);
        const subAffiliateId = $clicked.attr('data-sub-affiliate-id');

        const route = this.route('sales-api-remove-affiliate', {affiliateId: subAffiliateId});

        const firstConfirmation = await this.confirmation('Va a eliminar el sub afiliado', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                await $.post(route)
                await this.confirmationOk('Eliminado!', 'El sub afiliado ha sido eliminado');

                const redirectRoute = this.route('sales-sub-affiliates');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El sub afiliado no ha sido eliminado');
            }
        }
    },

    async setOwnCommissionInRoom(roomId) {
        const route = this.route('sales-api-get-affiliate-commission-in-room');
        const data = {
            affiliateId: this.parentAffiliateId,
            roomId: roomId};

        const commission = await $.get(route, data);
        this.find('.j-room-commission-form-container').find('.j-affiliate-commission-reference').last().val(commission);

    }
});