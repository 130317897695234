export const trans_es =
    {
        promotion: 'promoción',
        content: 'contenido',
        successful: {
            register: 'Registro completado. Comprueba tu correo para confirmar tu correo electrónico.'
        },
        email: {
            sent: 'Te hemos enviado un correo electrónico con las instrucciones necesarias para restablecer tu contraseña.'
        },
        errors: {
            detected: 'Lo siento, parece que se han detectado algunos errores, por favor inténtalo de nuevo.',
            user: {
                use: 'El nombre de usuario ya está en uso',
            },
            email: {
                use: 'El email ya está en uso',
            },
            invalid: {
                credentials: 'Credenciales inválidas, por favor inténtalo de nuevo.',
                email: 'Correo electrónico inválido',
                ranges: 'Rangos inválidos',
                contact: 'Si desea ser contactado debe introducir un prefijo, número de teléfono y al menos un método de contacto'
            },
            notFound: {
                image: 'Image not uploaded',
            },
            generic: 'Internal server error.'
        },
        ok: {
            gotit: 'Entendido!'
        },
        name: 'Nombre',
        webSection: {
            webSection: 'Sección web',
            habpoker: 'habpoker',
            habbets: 'habbets',
            habcasino: 'habbcasino',
            habstore: 'habstore',
            none: '',
        },
        game: {
            room: {
                web: 'web',
                room: 'sala'
            }
        },
        user: {
            fullname: 'nombre completo',
            username: 'nickname',
            affiliateName: 'afiliado',
            roomNickname: 'Nombre en la sala',
            roomEmail: 'Email en la sala',
            roles: 'roles',
            role: {
                ROLE_USER: 'usuario',
                ROLE_SUPERADMIN: 'superadmin',
                ROLE_SUPPORT: 'soporte',
                ROLE_ADMIN: 'administrador',
                ROLE_SALES: 'comercial',
                ROLE_READ_ONLY_ADMIN: 'admin solo lectura',
            }
        },
        datatable: {
            actions: 'acciones',
            status: 'estado'
        },
        empty: 'vacío',
        group: {
            'number-of-users': 'number of users'
        },
        product: {
            image: 'imagen',
            publicIdentifier: 'id',
            title: 'título',
            status: {
                status: 'estado',
                enabled: 'activo',
                disabled: 'deshabilitado'
            }
        },
        category: {
            category: 'categoría',
            subcategory: 'subcategoría'
        },
        type: 'tipo',
        cookie: {
            message: 'Esta página web emplea cookies para enriquecer su experiencia de uso',
            dismiss: 'De acuerdo',
            link: 'Leer más',
        },
        'upload-images': 'subir imagenes',
        'add-more-images': 'añadir más imágenes',
        'new-variation': 'nueva variación',
        'analyzing-data': 'analizando datos...',
        'total-habcoins': 'Habcoins totales',
        'generated-rake': 'Rake generado',
        'from': 'desde',
        'to': 'hasta',
        'no-more-rakes-to-load': 'No hay más rakes que mostrar',
        'multiplier': 'Multiplicador',
        'rakes': 'Rakes',
        'habcoins': 'Habcoins',
        'walletDirection': 'dirección',
        'rakeback-ranges-of': 'Rakebacks para',
        'applied-formula': 'Formula',
        'rake-multiplier': 'Multiplicador de rake',
        'number-of-refered-users': 'número de usuarios referidos',
        'rake-habcoins': 'Habcoins en rakes',
        'copied': 'copiado al portapapeles',
        'error': 'error',
        'invitation-sent': 'Invitación enviada',
        'withdrawal-method': 'Monedero',
        'currency-withdrawn': 'Moneda',
        'currency-amount': 'Cantidad (€)',
        'conversion-applied': 'Conversión',
        'status': 'Estado',
        'type_withdraw': 'Retirada',
        'type_purchase': 'Compra',
        'type_rake_earnings': 'Rakeback',
        'type_affiliate_earnings': 'Afiliados',
        'type_correction': 'Corrección',
        'pending': 'Pendiente',
        'accepted': 'Aceptada',
        'rejected': 'Rechazada',
        'date': 'Fecha',
        'referal_friend': 'Referal Friend',
        'affiliate': 'Afiliado Habwin',
        'affiliate_vip': 'Afiliado Externo',
        'sales': 'Sales',
        'room': 'Sala',
        'actions': 'Acciones',
        'user-room-credentials-created': 'Credenciales de usuario añadidas',
        'user-credentials-already-exist': 'Las credenciales ya existen',
        'rake': 'Rake',
        'no-file-selected-error': 'Seleccione un fichero',
        'amount': 'Cantidad',
        'euro': 'Euro',
        'dollar': 'Dolar',
        'pound': 'Libra',
        'turkish_lira': 'Lira Turca',
        'credentials-removed': 'Credenciales eliminadas',
        'product-added-to-cart': 'Añadido al carrito',
        'user-have-to-log-to-buy': 'Debe de iniciar sesión para poder comprar',
        'product-already-in-cart': 'Ya se encuentra en el carrito',
        'title-confirmation-payment': '¿Desea confirmar el pedido?',
        'confirmation-payment': 'Pedido realizado',
        'confirm': 'Confirmar',
        'cancel': 'Cancelar',
        'billing-data': 'Dirección de envío',
        'number-of-items': 'Unidades',
        'state': 'Estado',
        'base-price-amount': 'Precio total',
        'created-at': 'Creado',
        'ordered-at': 'Pedido',
        'details': 'Detalles',
        'paid': 'Pagado',
        'processed': 'Procesado',
        'fill-out-country-field': 'Indique el país en el que se encuentra',
        'products': 'Products',
        'revenue': 'Ganancias',
        'month': 'Mes',
        'nick': 'Nick',
        'login ': 'Login',
        'rake-euros': 'Rake (€)',
        'Jan': 'Ene',
        'Feb': 'Feb',
        'Mar': 'Mar',
        'Apr': 'Abr',
        'May': 'May',
        'Jun': 'Jun',
        'Jul': 'Jul',
        'Aug': 'Ago',
        'Sep': 'Sep',
        'Oct': 'Oct',
        'Nov': 'Nov',
        'Dec': 'Dic',
        'GB': 'Reino Unido',
        'ES': 'España',
        'intl': 'Internacional',
        'no-items-match-your-search': 'No hay resultados',
        'invitaion-email-sent': 'Invitación enviada',
        'of': 'de',
        'Displaying': 'Mostrando',
        'download-file-confirmation': 'Desea descargar la siguiente mano?',
        'email-not-found': 'El email proporcionado no corresponse a ningún usuario',
        'activate-account': 'Active su cuenta para poder hacer login',
        'resend-confirmation-email': 'Enviar correo de confirmación',
        'affiliate_verified': 'Verificadas',
        'affiliate_not_verified': 'Pendientes',
        'origin': 'Origen',
        'requestRejected': 'Solicitud rechazada',
        'errorOccurred': 'Ha ocurrido un error',
        'reject': 'Rechazar'
    };