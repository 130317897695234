import {ElView} from "../el-view";
import {Translator} from "../translation/translator";

export const CookieConsentComponent = ElView.extend({
    el: 'body',
    events: {},

    init() {
        this.initCookieConsent();
    },

    initCookieConsent() {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#edf4f1",
                    "text": "#000000"
                },
                "button": {
                    "background": "#01a35a",
                    "text": "#ffffff"
                }
            },
            "theme": "edgeless",
            "position": "bottom-right",
            "content": {
                "message": this.translate('cookie.message'),
                "dismiss": this.translate('cookie.dismiss'),
                "link": this.translate('cookie.link'),
                "href": this.routeFromPartyToHabwin(`terms-conditions.${Translator.currentLanguageShortCode()}`),
            }
        });
    },
});