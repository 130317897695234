import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";

export const AdminRoomsPage = ElView.extend({
    el: '.j-admin-rooms',
    events: {
        'click .j-click-enable-or-disable-room': 'onClickEnableOrDisableRoom',
        'click .j-click-remove-room': 'onClickRemoveRoom',
    },
    datatable: null,
    roomStatusClicked: false,

    init: function () {
        this.find('.j-select-2-room-status').select2({
            minimumResultsForSearch: -1,
        });
        this.find('.j-select-2-room-locale').select2({
            minimumResultsForSearch: -1,
        });

        this.initRoomsTable();
    },

    initRoomsTable() {
        this.datatable = this.find('#admin-rooms-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('rooms-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#rooms-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                columns: [
                    {
                        field: 'name',
                        title: Translator.trans('name'),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.name}</span>`;
                        },
                    },
                    {
                        field: 'roomGroup',
                        title: 'Grupo de sala',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.roomGroup}</span>`;
                        },
                    },
                    {
                        field: 'webSection',
                        title: Translator.trans('webSection.webSection'),
                        template: function (row) {
                            return capitalizeFirstLetter(Translator.trans('webSection.' + row.webSection));
                        }
                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter(Translator.trans('datatable.status')),
                        template: function (row) {
                            const statusToCssClassMap = {
                                'enabled': 'primary',
                                'disabled': 'danger'
                            };

                            return `<span class="j-click-enable-or-disable-room label label-inline label-rounded font-weight-bold label-light-${statusToCssClassMap[row.status]}" data-room-id="${row.id}" style="cursor: pointer;">${capitalizeFirstLetter(
                                row.status)}</span>`;
                        }
                    },
                    {
                        field: 'locale',
                        title: capitalizeFirstLetter('Países(Prioridad)'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.locale) {
                                if (row.locale[index] === 'ES')
                                    html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.locale[index]} (${row.orderPriorityEs})</span>`
                                if (row.locale[index] === 'GB')
                                    html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.locale[index]} (${row.orderPriorityGb})</span>`
                                if (row.locale[index] === 'intl')
                                    html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.locale[index]} (${row.orderPriorityIntl})</span>`
                            }

                            return html;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            const detailsRoute = this.route('admin-room-details', {roomId: row.id});

                            return `<a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`;
                        },
                    },
                ],
            }
        );

        $('#rooms-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#rooms-search-websection').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'webSection');
        });

        $('#rooms-search-locale').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'locale');
        });
    },

    onClickEnableOrDisableRoom(event) {
        if (this.roomStatusClicked)
            return;

        this.roomStatusClicked = true;
        const $clicked = $(event.currentTarget);
        const roomId = $clicked.attr('data-room-id');

        const route = Router.generate('core-api-enable-or-disable-room', {roomId: roomId});
        $.post(route)
            .always(() => {
                location.reload();
            });
    },
});