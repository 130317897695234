import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";

export const AdminOrders = ElView.extend({
    el: 'admin-orders',
    events: {
        'click .j-admin-order-details': 'onClickOrderDetails',
    },

    async init() {
        this.find('#orders-search-by-state').select2({
            minimumResultsForSearch: -1,
        });

        const route = this.route('admin-orders-table-data')
        const response = await $.get(route);
        this.initOrdersTable(response);
    },

    initOrdersTable(data) {
        this.datatable = this.find('#admin-orders-table').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#affiliates-search'),
                    key: 'generalSearch'
                },

                columns: [
                    {
                        field: 'publicId',
                        title: 'ID',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.publicId}</span>`;
                        }
                    },
                    {
                        field: 'createdAt',
                        title: this.translate('ordered-at'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'fullname',
                        title: 'Nombre',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.fullname}</span>`;
                        }
                    },
                    {
                        field: 'billingData',
                        title: 'Dirección de envío',
                        textAlign: 'center',
                        template: (row) => {
                            const billingData = row.billingData;
                            const shortened = (billingData.length > 10) ? (billingData.substr(0, 30) + '...') : billingData;

                            return `<span class="text-muted" data-toggle="tooltip" data-placement="top" title="${billingData}">${shortened}</span>`;
                        }
                    },
                    // {
                    //     field: 'numberOfItems',
                    //     title: 'Número de productos',
                    //     textAlign: 'center',
                    //     template: (row) => {
                    //         return `<span class="text-muted">${row.numberOfItems}</span>`;
                    //     }
                    // },
                    {
                        field: 'state',
                        title: 'Estado',
                        textAlign: 'center',
                        template: (row) => {
                            let backgroundColor = '';
                            let textColor = '';

                            if (row.state === 'pending') {
                                backgroundColor = '#FFF4DE';
                                textColor = 'warning';
                            }
                            if (row.state === 'paid') {
                                backgroundColor = '#C9F7F5';
                                textColor = 'primary';
                            }

                            if (row.state === 'rejected') {
                                backgroundColor = '#FFE2E5';
                                textColor = 'danger';
                            }

                            if (row.state === 'processed') {
                                backgroundColor = '#eaffe2';
                                textColor = 'primary';
                            }

                            return `<label class="font-weight-bold text-${textColor}" 
                                            style="background-color: ${backgroundColor}; border-radius: 15px; padding: 0px 5px;">
                                                ${Translator.trans(row.state)}
                                        </label>`;
                        }
                    },
                    {
                        field: 'amountInEuros',
                        title: 'Habcoins',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${row.amountInEuros.toFixed(2)}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: 'Acciones',
                        textAlign: 'center',
                        template: (row) => {
                            const route = this.route('admin-order-details', {orderId: row.id})

                            return `<a href="${route}" class="btn btn-icon btn-light-info j-admin-order-details" data-order-id="${row.id}" data-toggle="tooltip" data-placement="top" title="Detalles"><span class="flaticon2-list-3" style="font-size: 1.5rem;"></span></a>`;
                        },
                    },
                ],
            }
        );

        $('#orders-search-by-state').on('select2:select', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'state');
        });

        $('#order-search-id').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'publicId');
        });

        this.find('#orders-search-by-state').trigger('select2:select');
    },

    async onClickOrderDetails(event) {
       /* const orderId = $(event.currentTarget).attr('data-order-id');
        const route = this.route('admin-order-details', {orderId})

        location.href = route;*/
    }
})