import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";

export const SalesSubAffiliatesPage = ElView.extend({
    el: 'sales-sub-affiliates',
    events: {},
    datatable: null,
    parentAffiliateId: null,

    init: function () {
        this.parentAffiliateId = this.find('.j-data').attr('data-parent-affiliate-id');
        $.get(this.route('sub-affiliates-table-data', {parentAffiliateId: this.parentAffiliateId}))
            .done((data) => {
                this.initSubAffiliatesTable(data);
            })
    },

    initSubAffiliatesTable(data) {
        this.datatable = this.find('#sub-affiliates-datatable').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#sub-affiliates-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'name',
                        width: 200,
                        title: capitalizeFirstLetter(Translator.trans('name')),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.name}</span>`;
                        }
                    },
                    {
                        field: 'type',
                        title: capitalizeFirstLetter(Translator.trans('type')),
                        width: 150,
                        template: (row) => {
                            return `<span class="font-weight-bold">${capitalizeFirstLetter(Translator.trans(row.type))}</span>`;
                        }
                    },
                    {
                        field: 'referedsCount',
                        title: 'Credenciales referidas',
                        textAlign: 'center',
                        width: 200,
                        template: (row) => {
                            return `<span class="text-muted">${capitalizeFirstLetter(Translator.trans(row.referedsCount))}</span>`;
                        }
                    },
                    {
                        field: 'subAffiliatesCount',
                        title: 'Sub afiliados',
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            return `<span class="text-muted">${capitalizeFirstLetter(Translator.trans(row.subAffiliatesCount))}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        title: Translator.trans('edit'),
                        sortable: false,
                        textAlign: 'center',
                        width: 100,
                        template: function (row) {
                            const editRoute = Router.generate('sales-sub-affiliate-details', {'subAffiliateId': row.id});

                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`
                        },

                    },

                ],
            }
        );

        $('#sub-affiliates-search').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'name');
        });
    },
});