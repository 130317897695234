import {ElView} from "../shared/el-view";
import {Router} from '../shared/routing/router';

export const LanguagePage = ElView.extend({
    el: '.j-language-view',
    events: {
        'click .j-usa-locale': 'onLocaleChange',
        'click .j-spain-locale': 'onLocaleChange'
    },
    init: function () {
    },

    onLocaleChange: function (event) {
        const $selectedLocale = $(event.currentTarget);
        const locale = $selectedLocale.attr('data-locale');
        this.updateLocale(locale);
    },

    updateLocale: function (locale) {
        const data = {locale}
        const route = Router.generate('update-locale')
        $.post(route, data)
            .done(() => location.reload());
    }

});
