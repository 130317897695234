import {ElView} from "../../../shared/el-view";

export const CartHeader = ElView.extend({
    el: 'cart-header',
    events: {
        'click .j-remove-cart-product': 'onClickRemoveProductFromCart',
        'click .j-reload-cart-header': 'onClickReloadCartHeader',
    },

    init() {
    },

    async onClickRemoveProductFromCart(event) {
        const cartProductId = $(event.currentTarget).attr('data-cart-product-id');
        const route = this.route('remove-cart-product', {cartProductId});

        await $.post(route)
        $(event.currentTarget).closest('.j-cart-product-row').remove();
        this.updateCartSubtotal();
        this.updateProductCount();
    },

    async onClickReloadCartHeader() {
        const $cartHeader = $('.j-cart-header-container');

        const route = this.route('cart-header');
        const newCart = await $.get(route);

        $cartHeader.empty();
        $cartHeader.append($(newCart).find('.j-cart-header-container').children());

        this.initScroll();
    },

    initScroll() {
        $('[data-scroll="true"]').each(function () {
            const el = $(this);

            window.KTUtil.scrollInit(this, {
                mobileNativeScroll: true,
                handleWindowResize: true,
                rememberPosition: (el.data('remember-position') == 'true' ? true : false)
            });
        });
    },

    updateCartSubtotal() {
        const $prices = this.find('.j-product-price');
        let subtotal = 0;

        $prices.each((index, element) => {
            subtotal += parseFloat($(element).attr('data-product-price'));
        });
        this.find('.j-subtotal-price').text(subtotal + ' Habcoins');
    },

    updateProductCount() {
        const productCount = this.find('.j-cart-product-row').length;
        this.find('.j-product-count').text(productCount);
    }
})