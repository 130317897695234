import {ElView} from "../../../shared/el-view";
import {formatNumber} from "../../../shared/helper/number-helper";

export const RoomReportManualUserCredentialsTable = ElView.extend({
    el: '#room-report-manual-user-credentials-table',
    events: {},
    rakeData: {},

    init(params) {
        this.initReportPreviewTable(params.data);
    },

    initReportPreviewTable(data) {
        this.datatable = this.$el.KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#report-user-credentials-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'username',
                        title: 'Usuario Habwin',
                        width: 350,
                        template: row => {
                            if (row.username === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" style="background-color: #1bc5bd45; border-radius: 15px; padding: 0 5px;">${row.username}</label>
                                    <span class="j-user-id-row-select-user-credentials" style="visibility: hidden">${row.userId}</span>`;
                            return `<a href="${this.route('admin-user-details', {userId: row.userId})}" class="j-username-row-select-user-credentials font-weight-bold">${row.username}</a>
                                    <span class="j-user-id-row-select-user-credentials" style="visibility: hidden">${row.userId}</span>`;
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        width: 150,
                        template: row => {
                            return `<span class="j-nick-row-select-user-credentials">${row.nick}</span>`;
                        }
                    },
                    {
                        field: 'login',
                        title: 'Login',
                        width: 150,
                        template: row => {
                            return `<span class="j-login-row-select-user-credentials">${row.login}</span>`;
                        }
                    },
                    {
                        field: 'code',
                        title: 'ID Number',
                        width: 150,
                        template: row => {
                            return `<span class="j-code-row-select-user-credentials">${row.code}</span>`;
                        }
                    },
                    {
                        field: 'input',
                        title: 'Rake generado',
                        width: 175,
                        template: row => {
                            let rakeValue = 0;
                            if (!_.isUndefined(this.rakeData[row.userId]))
                                rakeValue = this.rakeData[row.userId];

                            return `<input type="text" class="j-rake-input-row form-control" value="${formatNumber(rakeValue, 2)}" placeholder="rake...">`;
                        }
                    },
                    {
                        field: 'action',
                        visible: false,
                        template: row => {
                            return `<button type="button" class="btn btn-sm btn-outline-primary j-table-row-select-user-credentials table-row-select-user-credentials">
                                        <i class="far fa-check-circle ml-1"  style="font-size: 1.3rem;"></i>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-danger j-table-row-remove-user-credentials table-row-select-user-credentials">
                                        <i class="fas fa-times ml-1"  style="font-size: 1.3rem;"></i>
                                    </button>`;
                        }
                    },
                ],
            }
        );

        $('#report-manual-username-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });

        $('#report-manual-nick-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'nick');
        });

        $('#report-manual-login-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'login');
        });

        $('#report-manual-code-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'code');
        });
    },
});