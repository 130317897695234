import {ElView} from "../../../shared/el-view";

export const AdminRoomGroupDetailsPage = ElView.extend({
    el: '.j-admin-room-groups-details',
    events: {
        'keyup .j-room-group-name': 'onKeyPressed',
        'click .j-save-room-group': 'onSaveRoomGroup',
        'click .j-remove-room-group': 'onRemoveRoomGroup'
    },

    init() {
    },

    onKeyPressed() {
        let text = this.find('.j-room-group-name').val();

        this.find('.j-room-group-slug-handsFolder').val(slugify(text));

    },

    onSaveRoomGroup() {
        const roomGroupId = this.find('.j-data').attr('room-group-id');
        const route = this.route('admin-room-group-details', {roomGroupId: roomGroupId});
        const $form = this.find('.j-room-group-form');

        $form.submit();
        // $.post(route, $form.serialize())
        //     .always((response) => {
                // location.reload();
            // });
    },

    async onRemoveRoomGroup(event) {
        const $clicked = $(event.currentTarget);
        const roomGroupId = $clicked.attr('data-room-group-id');

        const route = this.route('core-api-remove-room-group', {roomGroupId: roomGroupId});

        const firstConfirmation = await this.confirmation('Va a eliminar la red', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                const response = await $.post(route)
                await this.confirmationOk('Eliminada!', 'La red ha sido eliminada');

                const redirectRoute = this.route('admin-room-groups');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'La red no ha sido eliminada');
            }
        }

    }

});