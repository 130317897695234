import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";

export const AdminWithdrawRequests = ElView.extend({
    el: '.j-admin-withdraw-requests',
    events: {
        'click .j-admin-reject-withdraw-request': 'onClickRejectWithdrawRequest',
        'click .j-admin-accept-withdraw-request': 'onClickAcceptWithdrawRequest',
        'click .j-copy-to-clipboard': 'onClickCopyToClipboard',
    },
    datatable: null,

    init: function () {
        this.find('.j-select-2-withdraws-status').select2({
            minimumResultsForSearch: -1,
        });

        this.initWalletMovementWithdrawalsTable();
    },

    initWalletMovementWithdrawalsTable() {
        const _this = this;
        this.datatable = this.find('#j-admin-withdraw-requests-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('withdrawal-requests-table'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                    customScrollbar: true,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: _this.find('#wallet-withdrawals-request-search-by-method'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'sequence',
                        title: '#',
                        textAlign: 'center',
                        width: 50,
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.sequence}</span>`;
                        }
                    },
                    {
                        field: 'createdAt',
                        title: Translator.trans('date'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            return `<a href="${Router.generate('admin-user-details', {userId: row.userId})}" class="font-weight-bold">${row.username}</a>`;
                        }
                    },
                    {
                        field: 'withdrawal_method',
                        title: Translator.trans('withdrawal-method'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.withdrawal_method}</span>`;
                        }
                    },
                    {
                        field: 'currency_amount',
                        title: 'Cantidad (€)',
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.currency_amount.toFixed(2)}</span>`;
                        }
                    },
                    {
                        field: 'habcoins',
                        title: Translator.trans('habcoins'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.habcoins.toFixed(0)}</span>`;
                        }
                    },
                    {
                        field: 'walletDirection',
                        title: Translator.trans('walletDirection'),
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            return `<a href="javascript:void(0)" class="font-size-sm j-copy-to-clipboard" data-url="${row.walletDirection}"
                                        data-toggle="tooltip" data-placement="top" title="${row.walletDirection}">${row.walletDirection}</a>`;
                        }
                    },
                    {
                        field: 'status',
                        title: Translator.trans('status'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-warning" data-toggle="tooltip" data-placement="top" title="Pendiente" style="cursor: default;"><span class="far fa-clock" style="font-size: 1.5rem;"></span></button>`;
                            if (row.status === 'accepted')
                                return `<button class="btn btn-icon btn-light-primary" data-toggle="tooltip" data-placement="top" title="Aceptada" style="cursor: default;"><span class="flaticon2-check-mark" style="font-size: 1.3rem;"></span></button>`;
                            if (row.status === 'rejected')
                                return `<button class="btn btn-icon btn-light-danger" data-toggle="tooltip" data-placement="top" title="Rechazada" style="cursor: default;"><span class="flaticon2-delete" style="font-size: 1rem;"></span></button>`;
                        }
                    },
                    {
                        field: 'actions',
                        title: 'Acciones',
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-danger j-admin-reject-withdraw-request" data-withdraw-id="${row.id}" data-toggle="tooltip" data-placement="top" title="Rechazar"><span class="flaticon2-delete"></span></button>
                                        <button class="btn btn-icon btn-light-primary j-admin-accept-withdraw-request" data-withdraw-id="${row.id}" data-toggle="tooltip" data-placement="top" title="Aceptar"><span class="flaticon2-check-mark" style="font-size: 1.5rem;"></span></button>`;
                            else
                                return ``;
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#withdraws-search-status').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#withdraws-search-wallet').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'withdrawal_method');
        });

        $('#wallet-withdrawals-request-search').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });
    },


    async onClickRejectWithdrawRequest(event) {
        const _this = this;
        const withdrawId = $(event.currentTarget).attr('data-withdraw-id');
        const data = {isAccepted: false};

        const route = Router.generate('core-api-accept-or-reject-withdraw-request', {withdrawId});
        try {
            await $.get(route, data)
                .done(() => {
                    toastr.success('Solicitud rechazada',  result.statusText);
                })
                .fail((result) => {
                    toastr.error('Ha ocurrido un error',  result.statusText);
                });

            _this.datatable.reload();
        }catch(exception)
        {
            toastr.error('Ha ocurrido un error');
        }
    },

    onClickAcceptWithdrawRequest(event) {
        const _this = this;
        const withdrawId = $(event.currentTarget).attr('data-withdraw-id');
        const data = {isAccepted: true};

        const route = Router.generate('core-api-accept-or-reject-withdraw-request', {withdrawId})
        $.get(route, data)
            .always(() => {
                _this.datatable.reload();
            })
            .done((result) => {
                toastr.success('Solicitud aceptada',  result.statusText);
            })
            .fail((result) => {
                toastr.error('Ha ocurrido un error',  result.statusText);
            });
    },

    onClickCopyToClipboard(event) {
        const $clicked = $(event.currentTarget);

        toastr.options = {
            positionClass: 'toast-bottom-left',

            progressBar: true,
            preventDuplicates: true,
            closeButton: true,
        };

        const copyTextarea = $clicked.attr('data-url');

        navigator.clipboard.writeText(copyTextarea).then(function () {
            toastr.success(capitalizeFirstLetter(Translator.trans('copied')));

        }, function (err) {
            toastr.warning(capitalizeFirstLetter(Translator.trans('error')));
        });
    },
})