import {Translator} from '../translation/translator';

export class LocaleManager {

    static defaultLanguage() {
        return 'en';
    };

    static currentLocale() {
        const language = Translator.currentLanguage();
        if (!_.isEmpty(LocaleManager.languagesToLocaleMap()[language]))
            return LocaleManager.languagesToLocaleMap()[language];

        return LocaleManager.languagesToLocaleMap()[LocaleManager.defaultLanguage()];
    }

    static languagesToLocaleMap()  {
        return {
        'es_ES': 'es_ES',
        'es': 'es_ES',
        'es_GB': 'es_ES',
        'en_GB': 'en_US',
        'en': 'en_US'
        }
    }

}
