import {ElView} from "../../shared/el-view";

export const RequestUpdateLocalePage = ElView.extend({
    el: 'request-update-locale',
    events: {
        'click .j-location-request-form-submit': 'onClickLocationRequestFormSubmit'
    },

    init() {
    },

    async onClickLocationRequestFormSubmit(event) {
        const country = this.find('.j-request-update-locale-form-country-input').val();

        if (_.isEmpty(country))
            await this.confirmationError(this.translate('fill-out-country-field'));

        else {
            const $form = this.find('.j-location-request-form');
            $form.submit();
        }
    }
})

