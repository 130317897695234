import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";

export const AdminProductDetailsPage = ElView.extend({
    el: '.j-admin-product-details',
    events: {
        'click .j-save-product': 'onSaveProduct',
        'click .uppy-list-remove': 'onRemoveProductImage',
        'click .j-select-main-image': 'onSelectMainImage',
        'click .j-add-variation': 'onAddVariation',
        'click .j-delete-variation': 'onDeleteVariation',
        'input .j-variation-title': 'onInputVariationTitle',
        'click .j-remove-product': 'onRemoveProduct'
    },
    datatable: null,


    init() {
        this.$data = this.find('.j-data');
        this.productId = this.$data.attr('data-product-id');
        this.mainImageId = this.$data.attr('data-main-image-id');
        this.imageIdsInput = this.find('.j-image-ids-input');
        this.productImagesIds = [];

        const $errorContainers = this.find('.j-form-error');
        let $lastErrorContainer = null;
        $errorContainers.each((index, element) => {
            if (!_.isEmpty($(element).html()))
                $lastErrorContainer = $(element);
        });
        if (!_.isEmpty($lastErrorContainer)) {
            const tabToGoIndex = $lastErrorContainer.first().closest('.j-activable-tab').index();
            this.focusTab(tabToGoIndex);
        }

        this.initProductImageIds();
        this.initImageUploader();
    },

    focusTab: function (tabToGoIndex) {
        this.find('.j-activable-tab').removeClass('active');
        this.find('.j-activable-tab').addClass('fade');
        this.find('.j-activable-link').removeClass('active');

        $(this.find('.j-activable-tab').get(tabToGoIndex)).addClass('active');
        $(this.find('.j-activable-tab').get(tabToGoIndex)).removeClass('fade');
        $(this.find('.j-activable-link').get(tabToGoIndex)).addClass('active');
    },

    createVariationTab: function ($variationTabs) {
        const $firstVariation = $variationTabs.children().first();
        const currentVariationsCount = $variationTabs.children().length;
        const $clonedVariation = $firstVariation.clone();
        $clonedVariation.html($clonedVariation.html().replaceAll('_0_', `_${currentVariationsCount}_`));
        $clonedVariation.html($clonedVariation.html().replaceAll('[0]', `[${currentVariationsCount}]`));
        $clonedVariation.html($clonedVariation.html().replaceAll('-0', `-${currentVariationsCount}`));
        const $clonedInputs = $clonedVariation.find('input');
        $clonedInputs.val('');
        this.find('.j-activable-tab').removeClass('active');
        $clonedVariation.addClass('active');
        $clonedVariation.removeClass('fade');
        $clonedVariation.attr('id', `variation-tab-${currentVariationsCount}`);
        $clonedVariation.find('.j-delete-variation').show();

        return $clonedVariation;
    },

    createVariationTabTitle: function ($variationsList) {
        const $firstVariationTabTitleElement = $variationsList.children().first();
        const currentVariationsCount = $variationsList.children().length - 1;
        const $clonedTabTitleElement = $firstVariationTabTitleElement.clone();
        $clonedTabTitleElement.html($clonedTabTitleElement.html().replaceAll('-0', `-${currentVariationsCount}`));
        $clonedTabTitleElement.find('a').text(capitalizeFirstLetter(Translator.trans('new-variation')));
        this.find('.j-activable-link').removeClass('active');
        $clonedTabTitleElement.find('.j-activable-link').addClass('active');
        return $clonedTabTitleElement;
    },

    onAddVariation() {
        const $variationTabs = this.find('.j-variations-tabs');
        const $variationsList = this.find('.j-variations-list');

        const $clonedVariation = this.createVariationTab($variationTabs);
        $variationTabs.append($clonedVariation);

        const $clonedTabTitleElement = this.createVariationTabTitle($variationsList);
        $clonedTabTitleElement.insertBefore('.j-add-variation-list-item');
        this.find('.j-delete-variation').show();
        this.find('.j-delete-variation').first().hide();
    },

    onDeleteVariation(event) {
        if (this.find('.j-variations-tabs').children().length <= 1)
            return;

        const $clickedBtn = $(event.currentTarget);
        const $tabToRemove = $clickedBtn.closest('.j-activable-tab');
        const index = $clickedBtn.closest('.j-activable-tab').index();
        const $tabToRemoveId = $tabToRemove.attr('id');
        const $tabTitleToRemove = this.find(`[href="#${$tabToRemoveId}"]`).closest('li');
        const $idInput = this.find('#update_product_form_variations_' + index);

        $tabToRemove.remove();
        $tabTitleToRemove.remove();
        $idInput.remove();

        this.focusTab(index - 1);
    },

    initProductImageIds() {
        this.find('.j-product-image-col').each((index, element) => {
            const imageId = $(element).attr('data-image-id');
            if (!_.isEmpty(imageId))
                this.productImagesIds.push(imageId);
        });
    },

    initImageUploader() {
        const SpanishUppyLocale = require('@uppy/locales/lib/es_ES');
        const EnglishUppyLocale = require('@uppy/locales/lib/en_US');
        const XHRUpload = require('@uppy/xhr-upload');

        const StatusBar = Uppy.StatusBar;
        const FileInput = Uppy.FileInput;
        const Informer = Uppy.Informer;

        const elemId = 'j-image-uploader';
        this.productImageUploaderId = `#${elemId}`;
        const id = `#${elemId}`;
        const $statusBar = $(id + ' .uppy-status');
        const $uploadedList = $(id + ' .uppy-list');

        let locale = EnglishUppyLocale;
        if (Translator.isCurrentLanguageSpanish())
            locale = SpanishUppyLocale;

        this.uppyPlugin = Uppy.Core({
            locale: locale,
            debug: true,
            autoProceed: true,
            showProgressDetails: true,
            restrictions: {
                maxFileSize: 5000000,
                maxNumberOfFiles: 6,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
        });

        this.uppyPlugin.use(XHRUpload, {
            endpoint: this.route('upload-product-image'),
            responseUrlFieldName: 'fullUrl'
        })
        this.uppyPlugin.use(FileInput, {target: id + ' .uppy-wrapper', pretty: false});
        this.uppyPlugin.use(Informer, {target: id + ' .uppy-informer'});

        this.uppyPlugin.use(StatusBar, {
            target: id + ' .uppy-status',
            hideUploadButton: true,
            hideAfterFinish: false
        });

        $(id + ' .uppy-FileInput-input').addClass('uppy-input-control d-none').attr('id', elemId + '_input_control');
        $(id + ' .uppy-FileInput-container').append(
            `<label class="uppy-input-label btn btn-light-primary btn-sm btn-bold" for="${elemId}_input_control"><i class="fas fa-paperclip mr-2"></i>${capitalizeFirstLetter(
                Translator.trans('upload-images'))}</label>`);

        const $fileLabel = $(id + ' .uppy-input-label');

        this.uppyPlugin.on('upload', function (data) {
            $fileLabel.text("Uploading...");
            $statusBar.addClass('uppy-status-ongoing');
            $statusBar.removeClass('uppy-status-hidden');
        });

        this.uppyPlugin.on('complete', (file) => {
            $.each(file.successful, (index, value) => {
                let sizeLabel = "bytes";
                let filesize = value.size;
                if (filesize > 1024) {
                    filesize = filesize / 1024;
                    sizeLabel = "kb";

                    if (filesize > 1024) {
                        filesize = filesize / 1024;
                        sizeLabel = "MB";
                    }
                }

                let star = '';
                if (this.mainImageId === '') {
                    star = 'fav';
                    this.mainImageId = value.response.body.fileId;
                }

                const uploadListHtml = `
                <div class="col-md-4 j-product-image-col" data-image-id="${value.response.body.fileId}">
                    <div class="uppy-list-item d-flex flex-column" data-id="${value.id}" data-image-id="${value.response.body.fileId}">
                        <div class="d-flex justify-content-between w-100">
                            <div class="j-select-main-image float-left" >
                                <i data-image-id="${value.response.body.fileId}" class="fas fa-star j-fav-star ${star}" style="cursor: pointer"></i>
                            </div>
                            <span class="uppy-list-remove float-right" data-id="${value.id}" data-image-id="${value.response.body.fileId}">
                                <i class="flaticon2-cancel-music"></i>
                            </span>
                        </div>
                        <a class="mt-2" href="${value.response.uploadURL}">
                            <img src="${value.response.uploadURL}" class="image-fluid" style="height: 180px!important; max-width: 100%">
                        </a>
                        <a href="${value.response.uploadURL}" class="uppy-list-label mt-2 a-hover-color-force">${value.name}(${Math.round(
                    filesize, 2)}${sizeLabel})</a>
                    </div>
                </div>
                `;

                $uploadedList.append(uploadListHtml);
                $uploadedList.find('.uppy-list-remove').click(this.onRemoveProductImage.bind(this));

                this.productImagesIds.push(value.response.body.fileId);
                this.imageIdsInput.val(JSON.stringify(this.productImagesIds));
            });

            $fileLabel.html(`<i class='fas fa-paperclip mr-2'></i>${capitalizeFirstLetter(
                Translator.trans('add-more-images'))}`);
            $statusBar.addClass('uppy-status-hidden');
            $statusBar.removeClass('uppy-status-ongoing');

            this.undelegateEvents();
            this.delegateEvents();
        });

    },

    removeProductImageFromPlugin(itemId) {
        this.uppyPlugin.removeFile(itemId);
    },

    removeProductImageById(imageId) {
        this.productImagesIds = this.productImagesIds.filter(value => value !== imageId);
        this.imageIdsInput.val(JSON.stringify(this.productImagesIds));
        $(`${this.productImageUploaderId} .j-product-image-col[data-image-id="${imageId}"]`).remove();
    },

    onRemoveProductImage(event) {
        const $clickedEl = $(event.currentTarget);
        const pluginImageId = $clickedEl.attr('data-id');
        const imageId = $clickedEl.attr('data-image-id');
        this.removeProductImageFromPlugin(pluginImageId);
        this.removeProductImageById(imageId);
    },

    onSelectMainImage(event) {
        const $clicked = $(event.target);
        const imageId = $clicked.attr('data-image-id');
        if (imageId === this.mainImageId)
            return;

        this.find('.j-fav-star').removeClass('fav')
        $clicked.addClass('fav');
        this.mainImageId = imageId;
    },

    onSaveProduct() {
        this.imageIdsInput.val(JSON.stringify(this.productImagesIds));
        if (jQuery.isEmptyObject(this.productImagesIds)) {
            alert(Translator.trans('should-upload-at-least-one-image'))
            return;
        }
        this.find('.j-main-image-id').val(this.mainImageId);
        this.find('.j-product-form').submit();
    },

    onInputVariationTitle(event) {
        const $input = $(event.currentTarget);
        const $tab = $input.closest('.j-activable-tab');
        const tabId = $tab.attr('id');
        this.find(`.j-activable-link[href="#${tabId}"]`).text($input.val());
    },

    async onRemoveProduct() {
        const $clicked = $(".j-remove-product");
        const productId = $clicked.attr('data-product-id');
        const route = this.route('remove-product', {productId});

        const firstConfirmation = await this.confirmation('Va a eliminar el producto', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                await $.post(route)
                await this.confirmationOk('Eliminado!', 'El producto ha sido eliminado');

                const redirectRoute = this.route('admin-products');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El producto no ha sido eliminado');
            }
        }
    }
});