import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";
import {AddReferedUserToAffiliateModalComponent} from "../shared/add-refered-user-to-affiliate-modal-component";
import {formatDateToEs} from "../../shared/helper/date-helper";
import {formatNumber} from "../../shared/helper/number-helper";

export const AdminAffiliateDetailsPage = ElView.extend({
    el: '.j-admin-affiliate-details', events: {
        'change .j-start-date': 'onChangeDate',
        'change .j-end-date': 'onChangeDate',
        'select2:select .j-select-2-room-commissions': 'onSelectRoom',
        'click .j-delete-room-commission': 'onClickDeleteRoomCommission',
        'click .j-submit': 'onClickSaveChanges',
        'click .j-update-or-create-user-room-credentials-button': 'onClickAddOrUpdateUserRoomCredentials',
        'click .j-delete-user-room-credentials': 'onClickDeleteUserCredentials',
        'click .j-update-or-create-user-room-credentials-modal-save': 'onClickUpdateUserCredentialsModalSave',
        'hidden.bs.modal #update-or-create-user-room-credentials-modal': 'onClickUpdateUserCredentialsModalClose',
        'click .j-remove-affiliate': 'onRemoveAffiliate',
        'click .j-back-button': 'onClickBackButton',
        'click .j-remove-refered-user-from-affiliate': 'onClickRemoveReferedUserFromAffiliate',
        'shown.bs.tab #nav-link-refereds-tab': 'onClickReferedsTab',
    }, newRowTemplate: null, select2Data: null, defaultRoomCommission: 2, affiliateId: null,

    startDate: null, endDate: null,

    referedsDatatable: null,

    init: function () {
        this.affiliateId = this.find('.j-data').attr('data-affiliate-id');

        this.initDates();
        this.initSelectWithoutSearch('.j-affiliate-form-parent-id-input');
        this.initSelectWithoutSearch('.j-user-room-credentials-form-type-input');

        this.initRoomSelect2();
        this.initUserCredentialsRoomSelects();

        this.onClickReferedsTab();

        new AddReferedUserToAffiliateModalComponent({datatable: this.referedsDatatable});
    },

    onClickReferedsTab() {
        if (!this.referedsDatatable) this.initReferedsTable();
    },

    initDates() {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();
    },

    initSelectWithoutSearch(selector) {
        const $select = this.find(selector);
        $select.select2({
            minimumResultsForSearch: -1,
        });
    },

    initRoomSelect2() {
        const $select = this.find('.j-select-2-room-commissions');
        const data = JSON.parse(this.find('.j-data').attr('data-rooms-without-commission'));
        const $roomIds = this.find('.j-room-id-form-input');

        data.forEach((value, index) => {
            let alreadyAdded = false;
            $roomIds.each((roomIdIndex, element) => {
                const roomId = $(element).val();
                if (roomId === value['id']) alreadyAdded = true;
            })
            if (!alreadyAdded) {
                const newOption = new Option(value['roomName'], value['id'], false, false);
                $select.append(newOption).trigger('change');
            }
        })
    },

    onSelectRoom(event) {
        const $select = $(event.currentTarget);
        const data = $select.select2('data');
        const roomId = data[0]['id'];
        const roomName = data[0]['text'];

        $select.empty();
        this.createNewRowForm(roomId, roomName);
        this.initRoomSelect2();
    },


    createNewRowForm(roomId, roomName) {
        let $newRow = this.find('.j-room-commission-form-template').clone();
        $newRow.removeClass('j-room-commission-form-template');
        $newRow.children().addClass('j-room-commission-form-row');
        let lastIndex = this.find('.j-room-commission-form-row').length;

        $newRow = $($newRow.html().replaceAll(/__name__/g, lastIndex));
        $newRow.find('.j-room-id-form-input').val(roomId);
        $newRow.find('.j-room-name-input').val(roomName);
        $newRow.find('.j-room-commission-form-input').val(this.defaultRoomCommission);

        const $rowsContainer = this.find('.j-room-commission-form-container');

        $rowsContainer.append($newRow);
        $newRow.fadeIn();

        $('.j-room-commission-form-container').animate({scrollTop: $('.j-room-commission-form-container').get(0).scrollHeight}, 'slow');
    },

    onClickDeleteRoomCommission(event) {
        const $clicked = $(event.currentTarget);
        const $row = $clicked.closest('.j-room-commission-form-row');
        $row.remove();
    },

    onClickSaveChanges(event) {
        this.find('.j-user-room-credentials-form-template').remove();
        this.find('.j-room-commission-form-template').remove();

        const $form = this.find('.j-affiliate-form');
        const formData = new FormData($form[0]);
        const invitationMail = this.find('.j-new-affiliate-invitation-mail').val();
        formData.append('invitationMail', _.isUndefined(invitationMail) ? '' : invitationMail);

        const route = this.route('admin-affiliate-details', {'affiliateId': this.find('.j-data').attr('data-affiliate-id')});

        $.ajax({
            url: route, data: formData, contentType: false, processData: false, cache: false, type: 'POST',

        }).done((data) => {
            if (data === 'created') this.confirmationOk('Afiliado creado', '')
                .then(() => {
                    location.reload();
                })

            if (data === 'updated') this.confirmationOk('Afiliado actualizado', '')
                .then(() => {
                    location.reload();
                })

        }).fail((error) => {
            this.confirmationError('Error', 'Ha ocurrido algún error')
                .then(() => {
                    location.reload();
                })
        })
    },

    async onClickDeleteUserCredentials(event) {
        const route = Router.generate('core-api-verify-or-reject-affiliate-credentials');
        const data = {
            credentialsId: $(event.currentTarget).attr('data-user-room-credentials-id'), affiliateStatus: 'reject',
        }
        const confirmation = await this.confirmation('Eliminar afiliado de las credenciales', '', 'Confirmar', 'Cancelar');

        if (!confirmation.isDismissed) $.get(route, data)
            .done(() => {
                location.reload();
            })
            .fail(() => {
                toastr.options = {
                    positionClass: 'toast-bottom-left',

                    progressBar: true, preventDuplicates: true, closeButton: true,
                };
                toastr.error('Error');
            })
    },

    async initUserCredentialsRoomSelects() {
        const $inputs = this.find('.j-user-room-credentials-form-room-id-input');
        const route = Router.generate('core-api-get-rooms');
        let rooms = JSON.parse(await $.get(route));

        $inputs.each((index, element) => {
            const selected = $(element).attr('data-room-id');
            $(element).val(rooms[selected]);
        })
    },

    onClickAddOrUpdateUserRoomCredentials(event) {
        const credentialsId = $(event.currentTarget).attr('data-user-room-credentials-id')
        const route = Router.generate('core-api-create-or-update-user-room-credentials', {credentialsId: credentialsId});
        const data = {
            affiliateId: $(event.currentTarget).attr('data-affiliate-id'), showVerify: 'not_show', hideUser: true,
        }

        $.get(route, data)
            .done((response) => {
                const $modalBody = $(this.el).find('#update-or-create-user-room-credentials-modal .modal-body');
                $modalBody.append(response);
                $modalBody.find('.j-user-room-credentials-form-user-id-input').select2();
                $modalBody.find('.j-user-room-credentials-form-room-id-input').select2();
            })
            .fail(() => {
                toastr.options = {
                    positionClass: 'toast-bottom-left',

                    progressBar: true, preventDuplicates: true, closeButton: true,
                };
                toastr.error('Error');
            })

    },

    onClickUpdateUserCredentialsModalClose(event) {
        const $modal = $(event.currentTarget).closest('#update-or-create-user-room-credentials-modal');
        $modal.find('.modal-body').empty();
    },

    onClickUpdateUserCredentialsModalSave(event) {
        const $modal = $(event.currentTarget).closest('#update-or-create-user-room-credentials-modal');
        const affiliateId = $modal.attr('data-affiliate-id');
        $modal.find('.j-user-room-credentials-form-affiliate-id-input').val(affiliateId);
        const id = $modal.find('.j-user-room-credentials-form-id-input').val();
        const nick = $modal.find('.j-user-room-credentials-form-nick-input').val();
        const roomId = $modal.find('.j-user-room-credentials-form-room-id-input').val();
        if (nick === '' || roomId === '') {
            toastr.options = {
                positionClass: 'toast-bottom-left',

                progressBar: true, preventDuplicates: true, closeButton: true,
            };
            toastr.error('Nick y sala requeridos');
            return;
        }

        const route = Router.generate('core-api-create-or-update-user-room-credentials', {credentialsId: id});
        $.post(route, $modal.find('.j-user-room-credentials-form').serialize())
            .done(() => {
                location.reload();
            })
            .fail(() => {
                toastr.error('Error: credenciales no actualizadas');
            })
    },

    async onRemoveAffiliate(event) {
        const $clicked = $(event.currentTarget);
        const affiliateId = $clicked.attr('data-affiliate-id');

        const route = this.route('core-api-remove-affiliate', {affiliateId});

        const firstConfirmation = await this.confirmation('Va a eliminar el afiliado', '¿Está seguro?', 'Confirmar', 'Cancelar')
        if (!firstConfirmation.isDismissed) {
            try {
                await $.post(route)
                await this.confirmationOk('Eliminado!', 'El afiliado ha sido eliminado');

                const redirectRoute = this.route('admin-affiliates');
                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El afiliado no ha sido eliminado');
            }
        }
    },

    onClickBackButton() {
        window.history.back();
    },

    initReferedsTable() {
        this.referedsDatatable = this.find('#refereds-datatable').KTDatatable({
            data: {
                type: 'remote', source: Router.generate('affiliate-refereds-table-data', {
                    affiliateId: this.affiliateId, startDate: this.startDate, endDate: this.endDate
                }), pageSize: 10,
            }, rows: {
                autoHide: false,
            }, layout: {
                scroll: false,
            }, sortable: true, pagination: true, search: {
                input: this.find('#refereds-table-search'), key: 'generalSearch'
            }, translate: {
                toolbar: {
                    pagination: {
                        items: {
                            info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                        },
                    },
                },
            }, columns: [{
                field: 'username', title: 'Usuario', template: (row) => {
                    const route = this.route('admin-user-details', {userId: row.userId});
                    return `<a href="${route}" class="font-weight-bolder">${row.username}</a>`;
                },
            }, {
                field: 'numberOfCredentials', title: 'nº credenciales', textAlign: 'center', template: (row) => {
                    return `<span class="font-weight-bold text-muted">${row.numberOfCredentials}</span>`;
                },
            }, {
                field: 'lastCredentialCreated',
                title: 'Última credencial añadida',
                textAlign: 'center',
                template: (row) => {
                    let formatedDate = '';
                    try {
                        formatedDate = formatDateToEs(row.lastCredentialCreated)
                    } catch (exception) {
                    }
                    return `<span class="font-weight-bolder text-muted">${formatedDate}</span>`;
                },
            }, {
                field: 'overallRake', title: 'Rake (€)', textAlign: 'center', template: (row) => {
                    return `<span class="font-weight-bold text-muted">${formatNumber(row.overallRake, 2)}</span>`;
                },
            }, {
                field: 'overallHabcoinEstimate',
                title: 'Rakeback usuario (€)',
                textAlign: 'center',
                template: (row) => {
                    return `<span class="font-weight-bold text-muted">${formatNumber(row.overallHabcoinEstimate, 2)}</span>`;
                },
            }, {
                field: 'overallIncomeForAffiliate',
                title: 'Rakeback afiliado (€)',
                textAlign: 'center',
                template: (row) => {
                    return `<span class="font-weight-bold text-muted">${formatNumber(row.overallIncomeForAffiliate, 2)}</span>`;
                },
            }, {
                field: 'actions', title: 'Eliminar jugador referido', textAlign: 'center', template: (row) => {
                    return `<button class="j-remove-refered-user-from-affiliate btn btn-sm btn-icon btn-light-danger mr-2" data-user-id="${row.userId}" title="Eliminar jugador referido">
                                        <span class="flaticon2-delete">
                                        </span>
                                    </button>`;
                },
            },],
        });
    },

    onChangeDate() {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.referedsDatatable.destroy();
        this.initReferedsTable();
    },

    async onClickRemoveReferedUserFromAffiliate(event) {
        const $target = $(event.currentTarget);
        const userId = $target.attr('data-user-id');
        const route = this.route('core-api-add-user-credentials-to-affiliate');
        const data = {
            userId: userId,
        }

        const confirmation = await this.confirmation('¿Seguro que desea eliminar al jugador referido del afiliado?', 'Podrá volver a añadirlo más tarde', 'Confirmar', 'Cancelar');

        if (!confirmation.isDismissed) try {
            $.post(route, data)
                .done((result) => {
                    this.confirmationOk('Jugador eliminado', result.statusText);
                })
                .fail((result) => {
                    this.confirmationError('No se ha podido eliminar el jugador', result.statusText);
                });

            this.referedsDatatable.reload();

        } catch (exception) {
            this.confirmationError('No se ha podido eliminar el jugador');
        }
    }
});