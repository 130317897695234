import {HomePage} from './corporate/home-page';
import {AuthPage} from './corporate/auth/auth-page';
import {Translator} from './shared/translation/translator';
import {LanguagePage} from "./corporate/language-page";
import {AdminRoomsPage} from "./admin/game/room/admin-rooms-page";
import {AdminUserDetailsPage} from "./admin/user/user-details-page";
import {AdminUsersPage} from "./admin/user/users-page";
import {AdminProductsPage} from "./admin/store/product/products-page";
import {AdminProductCategoriesPage} from "./admin/store/category/categories-page";
import {ToastComponent} from "./shared/component/toast/toast-component";
import {Select2Component} from "./shared/form/select-2-component";
import {AdminProductDetailsPage} from "./admin/store/product/product-details-page";
import {AdminRoomReportsPage} from "./admin/game/rake/admin-room-reports-page";
import {AdminCategoryDetailsPage} from "./admin/store/category/category-details-page";
import {AdminHeaderView} from "./admin/shared/header-view";
import {ProfilePage} from "./tool/profile/profile-page";
import {StorePage} from "./tool/store/store-page";
import {AdminRoomDetailsPage} from "./admin/game/room/admin-room-details-page";
import {ProductDetailPage} from "./tool/store/product-detail-page";
import {UserDashboardPage} from "./tool/home/user-dashboard-page";
import {MyRakesPage} from "./tool/rakes/my-rakes-page";
import {MyRoomsPage} from "./tool/rooms/my-rooms-page";
import {AdminAffiliatesPage} from "./admin/affiliate/affiliates-page";
import {AdminAffiliateDetailsPage} from "./admin/affiliate/affiliate-details";
import {ToolAffiliatePage} from "./tool/affiliate/affiliate-page";
import {CopyToClipboardComponent} from "./shared/component/copy-to-clipboard-component";
import {WithdrawHabcoinsPage} from "./tool/wallet/withdraw-habcoins-page";
import {WalletMovementsPage} from "./tool/wallet/wallet-movements-page";
import {AdminUserCredentialsManagement} from "./admin/game/room/admin-user-credentials-management";
import {ToolAffiliateRoomLinksPage} from "./tool/affiliate/room-links";
import {AdminHands} from "./admin/game/hands/admin-hands-page";
import {AdminWithdrawRequests} from "./admin/wallet/withdraw-requests-page";
import {AdminWallets} from "./admin/wallet/wallets-page";
import {AdminWalletMovements} from "./admin/wallet/wallet-movements-page";
import {SalesDashboardPage} from "./sales/dashboard-page";
import {SalesSubAffiliatesPage} from "./sales/affiliate/sub-affiliates-page";
import {SalesSubAffiliateDetails} from "./sales/affiliate/sales-sub-affiliate-details";
import {AdminLocationRequests} from "./admin/user/admin-location-requests";
import {AdminRoomGroupsPage} from "./admin/game/roomgroups/admin-room-groups-page";
import {ToolHands} from "./tool/affiliate/tool-hands-page";
import {BlogCategoriesPage} from "./blog/blog-categories-page";
import {BlogCategoryDetailsPage} from "./blog/blog-category-details-page";
import {AdminRoomGroupDetailsPage} from "./admin/game/roomgroups/admin-room-group-details-page";
import {BlogPostsPage} from "./blog/blog-posts-page";
import {BlogPostDetailsPage} from "./blog/blog-post-details-page";
import {BlogSectionsPage} from "./blog/blog-sections-page";
import {BlogSectionDetailsPage} from "./blog/blog-section-details-page";
import {TextEditor} from "./shared/component/texteditor/text-editor-component";
import {AdminSalesPage} from "./admin/sales/sales-page";
import {CartHeader} from "./tool/store/payment/cart-header";
import {BlogRoomPostDetailsPage} from "./blog/blog-room-post-details-page";
import {CartDetails} from "./tool/store/payment/cart-details";
import {ToolMyOrders} from "./tool/store/payment/my-orders-page";
import {AdminOrders} from "./admin/store/payment/admin-orders-page";
import {AdminSlidesPage} from "./blog/blog-slides-page";
import {BlogSlideCreateOrUpdateComponent} from "./admin/shared/blog-slides-create-or-update-component";
import {RequestUpdateLocalePage} from "./tool/location/request-update-locale-page";
import {AddProductToCartComponent} from "./shared/component/cart/add-product-to-cart-component";
import {BlogBannerCreateOrUpdate} from "./blog/blog-banners-details-page";
import {AdminBannersPage} from "./blog/blog-banners-page";
import {SwiperHomeComponent} from "./shared/component/swiper/swiper-home-component";
import {CorporateRoomsPage} from "./corporate/rooms-page";
import {AdminOrderDetails} from "./admin/store/payment/admin-order-details";
import {AdminZoho} from "./blog/zoho/admin-zoho-page";
import {CorporateHabstorePage} from "./corporate/habstore-page";
import {AdminChargeRakesPage} from "./admin/game/rake/admin-charge-rakes";
import {CorporateRoomPage} from "./corporate/room-page";
import {HabpokerPage} from "./corporate/habpoker-page";
import {CorporateProductDetailPage} from "./corporate/habstore-product-detail-page";
import {CheckAgeComponent} from "./shared/component/check-age-component";
import {CookieConsentComponent} from "./shared/component/cookie-consent-component";
import {AdminAffiliateCredentialsManagement} from "./admin/game/room/admin-affiliate-credentials-management";
import {AdminWithdrawTypesDetailsPage} from "./admin/wallet/withdraw-types-details-page";
import {LanguageGreetingPage} from "./corporate/language-greeting-page";
import {RakeChartComponent} from "./shared/component/reports/rake-chart-component";
import {AdminRakeHistoriesPage} from "./admin/game/rake/admin-rake-histories-page";
import {AdminManualUploadRakesPage} from "./admin/game/rake/admin-manual-upload-rakes-page";
import {AdminUndoRakesPage} from "./admin/game/rake/admin-undo-rakes-page";
import {DatepickerComponent} from "./shared/component/datepicker/datepicker-component";
import {BlogRoomPostExtendedDetailsPage} from "./blog/blog-room-post-extended-details-page";
import {BlogProsAndConsDetailsPage} from "./blog/blog-pros-and-cons-details-page";
import {BlogProsAndConsPage} from "./blog/blog-pros-and-cons-page";
import {PageSectionEditorComponent} from "./shared/component/page-section-editor-component";
import {PostContentEditorComponent} from "./shared/component/post-content-editor-component";
import {UserAnalyticsComponent} from "./shared/component/reports/user-analytics-component";
import {ContentLogsPage} from "./blog/content-logs-page";
import {GameLogsPage} from "./admin/logs/game-logs-page";
import {PopUp} from "./corporate/newsletter-pop-up";

const HabwinApp = function () {
    console.time('Welcome!');
    new Translator().init();
    try {
        new HomePage();
        new BlogRoomPostExtendedDetailsPage();
        new AuthPage();
        new LanguagePage();
        new LanguageGreetingPage();
        new AdminRoomsPage();
        new AdminUsersPage();
        new AdminManualUploadRakesPage();
        new AdminRakeHistoriesPage();
        new AdminUserDetailsPage();
        new AdminProductsPage();
        new AdminProductCategoriesPage();
        new ToastComponent();
        new Select2Component();
        new AdminProductDetailsPage();
        new AdminRoomReportsPage();
        new AdminCategoryDetailsPage();
        new AdminHeaderView();
        new ProfilePage();
        new StorePage();
        new PageSectionEditorComponent();
        new PostContentEditorComponent();
        new AdminRoomDetailsPage();
        new ProductDetailPage();
        new UserDashboardPage();
        new MyRakesPage();
        new MyRoomsPage();
        new AdminAffiliatesPage();
        new AdminAffiliateDetailsPage();
        new ToolAffiliatePage();
        new CopyToClipboardComponent();
        new WithdrawHabcoinsPage();
        new WalletMovementsPage();
        new AdminUserCredentialsManagement();
        new ToolAffiliateRoomLinksPage();
        new AdminHands();
        new AdminWithdrawRequests();
        new AdminWallets();
        new AdminWalletMovements();
        new SalesDashboardPage();
        new SalesSubAffiliatesPage();
        new SalesSubAffiliateDetails();
        new AdminLocationRequests();
        new AdminRoomGroupsPage();
        new AdminRoomGroupDetailsPage();
        new ToolHands();
        new BlogCategoriesPage();
        new BlogCategoryDetailsPage();
        new BlogPostsPage();
        new BlogPostDetailsPage();
        new BlogSectionsPage();
        new BlogSectionDetailsPage();
        new TextEditor();
        new AdminSalesPage();
        new CartHeader();
        new BlogRoomPostDetailsPage();
        new CartDetails();
        new ToolMyOrders();
        new AdminOrders();
        new AdminSlidesPage();
        new AdminBannersPage();
        new BlogSlideCreateOrUpdateComponent();
        new BlogBannerCreateOrUpdate();
        new RequestUpdateLocalePage();
        new AddProductToCartComponent();
        new SwiperHomeComponent();
        new CorporateRoomsPage();
        new AdminOrderDetails();
        new AdminZoho();
        new CorporateHabstorePage();
        new AdminChargeRakesPage();
        new CorporateRoomPage();
        new HabpokerPage();
        new CorporateProductDetailPage();
        new CheckAgeComponent();
        new CookieConsentComponent();
        new AdminAffiliateCredentialsManagement();
        new AdminWithdrawTypesDetailsPage();
        new RakeChartComponent();
        new AdminUndoRakesPage();
        new DatepickerComponent();
        new BlogProsAndConsDetailsPage();
        new BlogProsAndConsPage();
        new PageSectionEditorComponent();
        new UserAnalyticsComponent();
        new ContentLogsPage();
        new GameLogsPage();
        new PopUp();
    } catch (e) {

    } finally {
        setTimeout(() => {
            $('.load-mask').hide();
        }, 0);

        console.timeEnd('Welcome!');
    }
}
();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = HabwinApp;
}

