import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";

export const AdminSalesPage = ElView.extend({
    el: '.j-admin-sales',
    events: {},
    datatable: null,

    init: function () {
        this.find('.j-subaffiliate-type-select').select2({
            minimumResultsForSearch: -1,
        });
        this.initSalesTable();
    },

    initSalesTable() {
        const _this = this;
        this.datatable = this.find('#admin-sales-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('sales-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#sales-search'),
                    key: 'generalSearch',
                },

                columns: [
                    {
                        field: 'name',
                        title: capitalizeFirstLetter(Translator.trans('name')),
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.name}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        template: (row) => {
                            return `<a  href="${_this.route('admin-user-details', {'userId': row.userId})}" class="font-weight-bolder">${row.username}</a>`;
                        }
                    },
                    {
                        field: 'type',
                        title: capitalizeFirstLetter(Translator.trans('type')),
                        template: (row) => {
                            return `<span class="text-muted">${Translator.trans(row.type)}</span>`;
                        }
                    },
                    {
                        field: 'subAffiliate',
                        title: 'Sub afiliado',
                        textAlign: 'center',
                        template: (row) => {
                            if (row.subAffiliate === 'subaffiliate')
                                return `<label class="font-weight-bold" style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">
                                            Sub afiliado
                                        </label>`;

                            if (row.subAffiliate === 'sales')
                                return `<label class="font-weight-bold" style="background-color: #1e1bc542; border-radius: 15px; padding: 0px 5px;">
                                            Comercial
                                        </label>`;
                        }
                    },
                    {
                        field: 'referedsCount',
                        title: 'Credenciales referidas',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${capitalizeFirstLetter(Translator.trans(row.referedsCount))}</span>`;
                        }
                    },
                    {
                        field: 'subAffiliatesCount',
                        title: 'Sub afiliados',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="text-muted">${capitalizeFirstLetter(Translator.trans(row.subAffiliatesCount))}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        textAlign: 'center',
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        template: function (row) {
                            const editRoute = Router.generate('admin-sales-details', {'affiliateId': row.id});
                            const infoRoute = Router.generate('admin-user-analytics', {'userId': row.userId});

                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                                    <a href="${infoRoute}" class="btn btn-sm btn-icon btn-light-info" title="Comisiones">
                                        <span class="far fa-chart-bar" style="font-size: 1.3rem;">
                                        </span>
                                    </a>`;
                        },
                    },
                ],
            }
        );

        $('#admin-sales-search').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'name');
        });
        $('#subaffiliate-type-search').on('select2:select', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'subAffiliate');
        });
        $('#affiliate-type-search').on('select2:select', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'type');
        });
    },
});