import {ElView} from "../shared/el-view";
import {DropzoneComponent} from "../shared/component/dropzone/dropzone-component";

export const BlogProsAndConsDetailsPage = ElView.extend({
    el: 'pros-and-cons-details',
    events: {
        'click .j-save-pros-and-cons': 'onSaveProsAndCons',
        'click .j-remove-pros-and-cons': 'onDeleteProsAndCons',
    },

    dropZoneUploader: null,
    imageUpload: '',
    isCreated: '',

    localeToTabIdMap: {
        'es_ES': 'esEsContent',
        'es_GB': 'ukEsContent',
        'en_GB': 'ukEnContent',
        'es': 'esContent',
        'en': 'enContent',
    },

    errorOnPostContentEvent: {
        es_ES: 'error-es-es',
        es_GB: 'error-es-gb',
        en_GB: 'error-en-gb',
        es: 'error-es',
        en: 'error-en',
    },

    allAvailableCountries: ['ES', 'GB', 'intl'],

    init() {
        this.onShowAvailableCountriesWarning = _.debounce(this.showAvailableCountriesWarning, 500);

        this.isCreated = this.find('.j-data').attr('data-isCreated');

        this.validateAvailableCountries();
        this.initDropzone();
    },

    async showAvailableCountriesWarning() {
        toastr.error('Rellene los contenidos de todos los países');
    },

    async validateAvailableCountries() {
        this.validateLocale('es_ES');
        this.validateLocale('es_GB');
        this.validateLocale('en_GB');
        this.validateLocale('es');
        this.validateLocale('en');
    },

    async validateLocale(locale) {
        if (this.localizedContentIsEmpty(locale)) {
            this.showPostContentErrorOnLocale(locale);
            await this.onShowAvailableCountriesWarning();
        }
    },

    showPostContentErrorOnLocale(country) {
        this.find('page-section-component').addClass(this.errorOnPostContentEvent[country]).trigger(this.errorOnPostContentEvent[country]);
    },

    localizedContentIsEmpty(locale) {
        const tabIdFromLocale = this.localeToTabIdMap[locale];
        const $tabForLocale = this.find('#' + tabIdFromLocale);

        return this.tabContentIsEmpty($tabForLocale);
    },

    tabContentIsEmpty($tabContent) {
        const contentTitle = $tabContent.find('.j-page-section-variation-form-title-input').val();
        const contentDescription = $tabContent.find('.j-page-section-variation-form-short-description-input').val();

        return this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentDescription);
    },

    inputIsEmpty(inputValue) {
        return _.isUndefined(inputValue) || _.isNull(inputValue) || inputValue === '';
    },

    initDropzone() {
        this.dropZoneUploader = new DropzoneComponent({el: '.j-image-form'});
        this.dropZoneUploader.onUpload((file) => {
            this.log(file);
            this.imageUpload = file;
            this.find('.j-image-from-url-input').val(file.url);
        });
    },

    onSaveProsAndCons() {
        if (!this.isCreated) {
            this.find('.j-pros-and-cons-form').submit();
        } else {
            if (this.formIsValid())
                this.find('.j-pros-and-cons-form').submit();
            else
                toastr.error('Inserte una imagen');
        }
    },

    formIsValid() {
        return this.imageUpload !== '';
    },

    async onDeleteProsAndCons(event) {
        const proAndContId = $(event.currentTarget).attr('data-pros-and-cons-id');
        const route = this.route('remove-pros-and-cons', {prosAndConsId: proAndContId});

        const result = await this.confirmation('¿Deseas borrarla?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Eliminada');
                const redirectRooute = this.route('admin-blog-pros-and-cons');

                location.replace(redirectRooute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'No ha sido eliminada');
            }
        }
    },
});