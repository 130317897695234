import {ElView} from "../../el-view";
import {Router} from "../../routing/router";
import {Translator} from "../../translation/translator";

export const AddProductToCartComponent = ElView.extend({
    el: 'add-product-to-cart',
    events: {
        'click .j-add-default-product-to-cart-button': 'onClickAddDefaultProductToCart',
    },

    init() {
    },

    async onClickAddDefaultProductToCart(event) {
        let route = Router.generate('add-cart-product-default');

        const data = {
            productId: $(event.currentTarget).attr('data-product-id'),
        };

        try {
            await $.post(route, data)

        }catch(exception){
            toastr.error(Translator.trans('product-already-in-cart'));
            return;
        }
        toastr.success(Translator.trans('product-added-to-cart'));

        this.reloadCartHeader();
        setTimeout(() => {
            $(document).find('.j-cart-header-dropdown').dropdown('toggle');
        }, 250);
    },

    reloadCartHeader() {
        $(document).find('.j-reload-cart-header').click();
    }
})