import {ElView} from "../../../shared/el-view";
import {AdminRoomReportPreviewTablePage} from "./admin-room-report-preview-table";
import {Router} from "../../../shared/routing/router";
import {RoomReportManualUserCredentialsTable} from "../room/admin-room-report-manual-user-credentials-table";
import {AdminChargeRakesPreviewTable} from "./admin-charge-rakes-preview-table";

export const AdminRoomReportManualUpload = ElView.extend({
    el: 'room-report-manual-upload',
    selectedRooms: [],
    rakeDataManually: [],

    events: {
        'click .j-button-add-report-manually': 'onClickAddReportManually',
        'select2:select .j-room-select-manually': 'onSelectRoomManually',
        'blur .j-rake-input-row': 'onEnterInRakeInputField',
        'keyup .j-rake-input-row': 'onEnterChangeFocusToNextInput',
        'click .j-table-row-select-user-credentials': 'onClickSelectUserCredentials',
        'click .j-table-row-remove-user-credentials': 'onClickRemoveUserCredentials',
        'click .j-preview-habcoins-manual': 'onClickPreviewHabcoinsManually',
        'click .j-update-habcoins-manual': 'onClickUpdateRakesManually',
        'click .j-reset-preview-manual': 'onClickResetPreviewManual',
    },

    init: function () {
    },

    showStep(index) {
        let $step = this.find(`.j-room-reports__step-${index}`);
        $step.show('slow');

        $([document.documentElement, document.body]).animate({
            scrollTop: $step.offset().top - 170
        }, 1000);
    },

    hideStep(index) {
        this.find(`.j-room-reports__step-${index}`).hide();
    },

    initRoomSelectManually(selector) {
        const $roomSelect = this.find(selector);
        const route = this.route('rooms-select-data');
        $roomSelect.select2({});
        $.get(route)
            .done((rooms) => {
                rooms.forEach(room => {
                    const newOption = new Option(room.name, room.id, false, false);
                    this.find(selector).append(newOption).trigger('change');
                });
                this.find(selector).val(null).trigger('change');
            });
    },

    destroyPreviewTable() {
        if (this.previewTable)
            this.previewTable.undelegateEvents();
    },

    onSelectRoomManually() {
        const selectedRoomId = this.find('.j-room-select-manually').find(':selected').val();

        const route = Router.generate('core-api-get-user-room-credentials', {'roomId': selectedRoomId});

        $.post(route)
            .always(responseData => {
                this.previewTable = new RoomReportManualUserCredentialsTable({data: JSON.parse(responseData)})
                this.showStep(11);
            });
    },

    onClickSelectUserCredentials(event) {
        const $clicked = $(event.currentTarget);
        const $row = $clicked.closest('.datatable-row');
        const username = $row.find('.j-username-row-select-user-credentials').text();
        const nick = $row.find('.j-nick-row-select-user-credentials').text();
        const login = $row.find('.j-login-row-select-user-credentials').text();
        const code = $row.find('.j-code-row-select-user-credentials').text();
        let rakeValue = $row.find('.j-rake-input-row').val();
        let userId = $row.find('.j-user-id-row-select-user-credentials').text();

        rakeValue = this.sanetizeRakeValue(rakeValue);

        const $input = $clicked.closest('.datatable-row').find('.j-rake-input-row');
        $input.val(rakeValue);

        if (userId === 'Sin asignar')
            userId = nick;
        this.previewTable.rakeData[userId] = rakeValue;

        this.removeCredentialsFromRakeData(nick);

        this.rakeDataManually[userId] = {
            username: username,
            nickname: nick,
            login: login,
            code: code,
            rake: rakeValue,
        };
    },

    // onClickRemoveUserCredentials(event) {
    //     const $clicked = $(event.currentTarget);
    //     const $row = $clicked.closest('.datatable-row');
    //     const userId = $row.find('.j-user-id-row-select-user-credentials').text();
    //     $row.find('.j-rake-input-row').val(0);
    //
    //     this.rakeDataManually[userId] = {};
    // },

    async onClickPreviewHabcoinsManually() {
        const _this = this;
        const roomId = this.find('.j-room-select-manually').find(':selected').val();
        const roomName = this.find('.j-room-select-manually').find(':selected').text();
        const currency = this.find('.j-room-currency-input-manual').find(':selected').val();

        const route = this.route('preview-import-room-report');
        let data = {
            reportStructureId: _this.reportStructureId,
            roomsData: [{roomId: roomId}],
            userRakesData: Object.values(this.rakeDataManually),
            currency: currency,
            uploadDate: $('.j-upload-date').val(),
        }

        $.post(route, data)
            .always(responseData => {
                this.rakesFromReportPreviewTable = new AdminRoomReportPreviewTablePage({
                    el: '.j-admin-report-preview-datatable-manual',
                    data: responseData['rakesFromReport'],
                    typeOfReport: 'manual',
                })

                this.chargeRakesPreviewTable = new AdminChargeRakesPreviewTable({
                    el: '#admin-charge-rakes-datatable-manual',
                    data: responseData['chargeRakesPreview'],
                    typeOfReport: 'manual',
                });

                this.showStep(12);
            });
    },

    async onClickUpdateRakesManually() {
        const roomId = this.find('.j-room-select-manually').find(':selected').val();
        const route = this.route('room-report-update-habcoins');

        const currency = this.find('.j-room-currency-input-manual').val();

        const data = {
            roomsData: [{roomId: roomId}],
            userRakesData: Object.values(this.rakeDataManually),
            currency: currency,
            uploadDate: $('.j-upload-date').val(),
            uploadIsManual: true,
        }

        const confirmation = await this.confirmation('¿Desea actualizar los rakes?', '', 'Confirmar', 'Cancelar');
        if (confirmation.isConfirmed) {
            try {
                await $.post(route, data)
                await this.confirmationOk('Rakes actualizados');

                location.reload();
            } catch (exception) {
                await this.confirmationError('Error, rakes no actualizados');
            }
        }
    },

    onClickResetPreviewManual() {
        this.resetPreviewTableManual();
        this.find('.j-room-reports__step-12').hide();
        this.showStep(11);
    },

    async resetPreviewTableManual() {
        this.find('.j-room-rakeback-ranges-manual').empty();
        this.find('.j-habcoins-formula-manual').empty();
        await this.previewTable.destroy();
        await this.chargeRakesPreviewTable.destroy();

        this.previewTable = new RoomReportManualUserCredentialsTable({data: this.rakeDataManually})
    },

    onEnterInRakeInputField(event) {
        const $selectCredentialsButton = $(event.currentTarget).closest('.datatable-row').find('.j-table-row-select-user-credentials');

        $selectCredentialsButton.click();
    },

    onEnterChangeFocusToNextInput(event) {
        const keyIsEnter = event.keyCode === 13;
        if (keyIsEnter) {
            const $nextInput = $(event.currentTarget).closest('.datatable-row').next().find('.j-rake-input-row');
            $nextInput.focus();
        }
    },

    sanetizeRakeValue(rakeValue) {
        rakeValue = rakeValue.replace(',', '.');
        rakeValue = Number(rakeValue);

        if (isNaN(rakeValue))
            return 0

        return rakeValue;
    },

    removeCredentialsFromRakeData(nick) {
        this.rakeDataManually.forEach((value, index) => {
            if (value['nickname'] === nick)
                this.rakeDataManually.splice(index, 1);

        })
    }
});