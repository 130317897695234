import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";

export const ContentLogsPage = ElView.extend({
    el: '.j-content-logs',
    events: {},
    datatable: null,
    init: async function () {
        this.initContentLogsTable();
        this.datatable.sort('createdAt', 'desc');
    },

    initContentLogsTable() {
        this.datatable = this.find('#content-logs-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('content-logs-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#logs-search'),
                    delay: 300
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: this.translate('Displaying') + ' {{start}} - {{end}} ' +
                                    this.translate('of') + ' {{total}}'
                            },
                        },
                    },
                },

                columns: [
                    {
                        field: 'createdAt',
                        title: capitalizeFirstLetter('fecha'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.createdAt}`;
                        }
                    },
                    {
                        field: 'username',
                        title: capitalizeFirstLetter('usuario'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.username}</span>`;
                        }
                    },
                    {
                        field: 'ip',
                        title: capitalizeFirstLetter('ip'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.ip}`;
                        }
                    },
                    {
                        field: 'postType',
                        title: capitalizeFirstLetter('Tipo'),
                        textAlign: 'center',
                        template: (row) => {
                            let postType = row.postType;
                            if (row.postType === 'post')
                                postType = 'Noticia';

                            return `${capitalizeFirstLetter(this.translate(postType))}`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            let detailsRoute = this.route('admin-blog-post-details', {postId: row.postId});
                            if (row.postType === 'promotion')
                                detailsRoute = this.route('admin-blog-promotion-details', {postId: row.postId});

                            if (row.postType === 'room')
                                detailsRoute = this.route('admin-blog-room-post-details', {postId: row.postId});

                            if (row.postType === 'content')
                                detailsRoute = this.route('admin-blog-contents-details', {postId: row.postId});

                            return `
                                    <a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                                  `
                        }
                    }
                ],
            }
        );
    },

});