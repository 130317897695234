import {ElView} from "../el-view";
import Cookies from 'js-cookie'


export const CheckAgeComponent = ElView.extend({
    el: '.j-age-check-modal',
    events: {
        'click .cancelBtnModal': 'onClickCancelBtnModal',
        'click .acceptBtnModal': 'onClickAcceptBtnModal',
    },

    init() {
        this.initAgeModal();
    },

    initAgeModal: function () {
        if (Cookies.get('adultAge') === undefined || !Cookies.get('adultAge')) {
            $(window).on('load', function () {
                $('.ageModal').modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true
                });
            });
        }
    },

    onClickCancelBtnModal() {
        $('.j-age-check-modal .modal-content').hide();
    },
    onClickAcceptBtnModal() {
        Cookies.set('adultAge', 'true', {expires: 365});
    },

});
