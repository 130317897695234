import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";
import {formatNumber} from "../../shared/helper/number-helper";

export const WalletMovementsPage = ElView.extend({
    el: '.j-wallet-movements-page',
    events: {},
    currencyConversion: 100,

    init() {
        $.get(Router.generate('tool-api-wallet-movements'))
            .done((data) => {
                this.initWalletMovementsTable(JSON.parse(data));
            })
    },

    initWalletMovementsTable(data) {
        const _this = this;
        this.datatable = this.find('#j-tool-wallet-movements-table').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                    customScrollbar: true,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: _this.find('#wallet-movements-search-by-type'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'sequence',
                        title: '#',
                        textAlign: 'center',
                        width: 50,
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.sequence}</span>`;
                        }
                    },
                    {
                        field: 'createdAt',
                        title: Translator.trans('date'),
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'type',
                        title: Translator.trans('type'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${Translator.trans(row.type)}</span>`;
                        }
                    },
                    {
                        field: 'rakeRoomAndMonth',
                        title: this.translate('origin'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.rakeRoomAndMonth}</span>`;
                        }
                    },
                    {
                        field: 'habcoins',
                        title: Translator.trans('amount'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.isSales)
                                return `<span class="font-weight-bold">${formatNumber(row.habcoins, 2)} €</span>`;

                            return `<span class="font-weight-bold">${formatNumber(row.habcoins, 0)}</span>`;
                        }
                    },
                ],
            },
        );

        this.datatable.sort('createdAt', 'desc');

        $('#wallet-movements-search-by-type').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'type');
        });
    },
});