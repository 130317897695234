import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";

export const ProfilePage = ElView.extend({
    el: '.j-profile',
    events: {
        'change .j-user-image-input': 'updateUserImage',
        'click .j-remove-user-img': 'removeUserImage',
        'click .j-remove-user': 'onRemoveUser',
        'click .j-save-user': 'saveUser'
    },

    init() {
        this.find('.j-language-form-input').select2({
            minimumResultsForSearch: -1,
        });

        this.find('.j-location-form-input').select2({
            minimumResultsForSearch: -1,
        });

        const _this = this;
        this.userId = this.find('.j-data').attr('data-user-id');
        new AvatarImage('user-edit-image');

        const $validationLabel = this.find('.j-validation-failed');

        $validationLabel.each(function(index, html) {
            if($(html).find('ul').length !== 0) {
                _this.setFocus($(html).closest('.tab-pane'));
                return true;
            }
        });

        const action = this.find('.j-action').attr('data-action');
        if (action === 'change_password')
            this.find('.j-password-nav-link').click();
    },

    setFocus($element) {
        const elementId = $element.attr('id');
        let $elementNavLink = this.find('a[href="#' + elementId + '"]');

        $elementNavLink.click();
    },

    updateUserImage() {
        const _this = this;
        const fd = new FormData();
        const files = this.find('.j-user-image-input')[0].files[0];

        fd.append('file', files);
        fd.append('userId', this.userId);

        $.ajax({
            url: Router.generate('core-api-update-user-image'),
            type: 'post',
            data: fd,
            contentType: false,
            processData: false,
            success: (data) => {
                _this.find('.j-user-image-id-input').val(data);
            }
        });
    },

    saveUser() {
        const userId = this.find('.j-data').attr('data-user-id');
        const route = this.route('tool-profile', {userId});
        const $form = this.find('.j-user-profile-form').serialize();
        this.find('.j-user-profile-form').submit(
            // $.post(route, $form)
            //     .then((response) => {
                    // location.reload();
            // })
        );
    },

    removeUserImage() {
        const route = Router.generate('core-api-update-user-image');
        const data = {userId: this.userId};

        $.post(route, data);
    },

    onRemoveUser: function (event) {
        const $deleteBtn = $(event.currentTarget);
        const userId = $deleteBtn.attr('data-user-id')
        const route = this.route('tool-api-remove-user');
        $.post(route, {userId})
            .always(() => location.reload());
    }


});