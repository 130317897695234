import {ElView} from "../../../shared/el-view";
import {AdminRoomReportPreviewTablePage} from "./admin-room-report-preview-table";
import {ExcelHelper} from "../../../shared/helper/excel-helper";
import {marketingAnalyzingAnimation} from "../../../shared/animations/marketing-analyzing-animation";
import {Translator} from "../../../shared/translation/translator";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {AdminRoomReportManualUpload} from "./admin-room-reports-manual-upload-page";
import {AdminChargeRakesPreviewTable} from "./admin-charge-rakes-preview-table";

export const AdminRoomReportsPage = ElView.extend({
    el: '.j-room-reports',
    nicknameStartingCell: '',
    loginStartingCell: '',
    codeStartingCell: '',
    rakeStartingCell: '',
    nicknameColor: '#ffeb93',
    loginColor: '#98f1da',
    codeColor: '#F4EEE5',
    rakeColor: '#ffb7bc',
    columnColors: ['#FFF4DE', '#C9F7F5', '#F4EEE5', '#FFE2E5'],
    columnColorsUsed: [],
    canPreviewData: false,
    rakebackRangeColors: [
        '#03045eff'
        , '#023e8aff'
        , '#0077b6ff'
        , '#0096c7ff'
        , '#00b4d8ff'
        , '#48cae4ff'
        , '#90e0efff'
        , '#ade8f4ff'
        , '#caf0f8ff'
    ].reverse(),
    selectedRooms: [],
    roomColumnCorrespondences: null,
    roomStartingCell: '',

    events: {
        'click .j-import-report': 'onClickImportReport',
        'change .j-input-file': 'onChangeInputFile',
        'click .j-preview-data': 'previewData',
        'click .j-update-habcoins': 'updateRakes',
        'click .j-reset-columns-selection': 'onResetSelectedColumns',
        'click .j-button-add-report-manually': 'onClickAddReportManually',
        'click .j-reset-preview': 'onClickResetPreview',
        'click #distribution-preview-nav-tab': 'initChargeRakesTable',
    },

    init: function () {
        $('.j-upload-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        this.initRoomSelect('.j-room-select');
        this.initLottieAnimation();
    },

    initLottieAnimation() {
        lottie.loadAnimation({
            container: this.find('.j-analyzing-animation')[0],
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: marketingAnalyzingAnimation
        })
    },

    onClickImportReport() {
        this.find('.j-input-file').click();
    },

    launchAnalyzingDataModal: function (title) {
        this.find('.j-analyzing-title').text(title);
        this.find('.j-analyzing-animation-modal').modal({
            show: true,
            backdrop: 'static',
            keyboards: false
        });
    },

    closeAnalyzingModal: function () {
        this.find('.j-analyzing-animation-modal').modal('hide');
    },

    initReader: function () {
        const reader = new FileReader();
        this.launchAnalyzingDataModal(capitalizeFirstLetter(Translator.trans('analyzing-data')));

        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                this.workbook = XLSX.read(data, {type: 'array'});
                this.showColumnOptions();
                await this.autocompleteColumnsIfSavedStructure();
                this.launchAnalyzingDataModal(capitalizeFirstLetter(Translator.trans('analyzing-data')));
                setTimeout(() => {
                    this.closeAnalyzingModal();
                    this.showStep(2);
                    this.canPreviewData = true;
                    setTimeout(() => {
                        if (this.areAllColumnsSelected()) {
                            this.previewData();
                        }
                    }, 1000);


                }, 2000);
            } catch (e) {
                console.error(e);
            }
        };
        return reader;
    },

    updateUploadedFileNameLabel: function () {
        this.find('.j-file-name-wrapper').show();
        this.find('.j-file-name').text(this.file.name);
    },

    retrieveRoomsFromSelection() {
        const _this = this;
        const $select = this.find('.j-room-select').find(':selected');

        $select.each((index, value) => {
            const $selection = $(value);
            _this.selectedRooms.push({roomId: $selection.val(), roomName: $selection.text()})
        })
    },

    onChangeInputFile(event) {
        this.retrieveRoomsFromSelection();
        // Request MultiRoomReportConfig from rooms ids
        this.resetSelectedColumns();
        this.destroyPreviewTable();
        this.hideStep(2);
        this.hideStep(3);
        this.hideStep(4);
        this.file = event.target.files[0];
        this.updateUploadedFileNameLabel();
        const reader = this.initReader();
        reader.readAsArrayBuffer(this.file);
    },

    showColumnOptions() {
        const first_sheet_name = this.workbook.SheetNames[0];
        const worksheet = this.workbook.Sheets[first_sheet_name];

        const cells = Object.keys(worksheet);
        let firstCell = '';
        let cellsToTheRight = 5;
        while (firstCell === '' && cellsToTheRight !== 0) {
            cells.forEach(cell => {
                if (ExcelHelper.validateRange(cell) && _.isEmpty(firstCell) &&
                    !_.isEmpty(worksheet[cell]) &&
                    ExcelHelper.hasMultipleCellsToTheRight(worksheet, cell, cellsToTheRight) &&
                    ExcelHelper.hasMultipleCellsToUnderneath(worksheet, cell, cellsToTheRight))

                    firstCell = cell;
            });

            cellsToTheRight -= 1;
        }

        const tableDataArray = this.buildTableDataArray(worksheet, firstCell);
        this.buildSelectColumnsTable(tableDataArray);

        const firstRow = ExcelHelper.getRow(firstCell);
        const firstColumn = ExcelHelper.getColumn(firstCell);
        const firstColumnRowValues = {};
        firstColumnRowValues[firstCell] = worksheet[firstCell].v;

        let currentColumn = ExcelHelper.nextCol(firstColumn);
        while (ExcelHelper.hasMultipleCellsToTheRight(worksheet, currentColumn + firstRow, 1)) {
            if (_.isUndefined(worksheet[currentColumn + firstRow]))
                firstColumnRowValues[currentColumn + firstRow] =
                    ExcelHelper.firstCellValueToTheLeft(worksheet, currentColumn + firstRow);
            else
                firstColumnRowValues[currentColumn + firstRow] = worksheet[currentColumn + firstRow].v;

            currentColumn = ExcelHelper.nextCol(currentColumn);
        }
    },

    previewData() {
        const _this = this;
        this.canPreviewData = false;
        this.destroyPreviewTable();
        const selectedReportStructure = {
            nickname: {
                startingCell: this.nicknameStartingCell,
                value: this.currentWorksheet()[this.nicknameStartingCell].v
            },
            login: {
                startingCell: this.loginStartingCell,
                value: this.currentWorksheet()[this.loginStartingCell].v
            },
            code: {
                startingCell: this.codeStartingCell,
                value: this.currentWorksheet()[this.codeStartingCell].v
            },
            rake: {
                startingCell: this.rakeStartingCell,
                value: this.currentWorksheet()[this.rakeStartingCell].v
            },
            room: {
                startingCell: this.roomStartingCell,
                value: null
            }
        };

        selectedReportStructure.room.value = this.currentWorksheet()[this.roomStartingCell] ?
            this.currentWorksheet()[this.roomStartingCell].v : null;
        const rows = this.getColumnsData();

        const reportMultiplier = this.find('.j-room-report-multiplier-input').val();

        const route = this.route('preview-import-room-report');

        let data = {
            reportStructureId: _this.reportStructureId,
            roomsData: this.selectedRooms,
            userRakesData: rows,
            uploadDate: $('.j-upload-date').val(),
            selectedReportStructure,
            reportMultiplier: reportMultiplier,
            roomCellsCorrespondences: this.roomColumnCorrespondences
        };

        $.post(route, data)
            .always(responseData => {
                this.rakesFromReportPreviewTable = new AdminRoomReportPreviewTablePage({
                    el: '.j-admin-report-preview-datatable-file',
                    data: responseData['rakesFromReport'],
                    typeOfReport: 'file',
                })

                this.chargeRakesPreviewTable = new AdminChargeRakesPreviewTable({
                    el: '#admin-charge-rakes-datatable-file',
                    data: responseData['chargeRakesPreview'],
                    typeOfReport: 'file',
                });

                this.showStep(4);
            });
    },

    showStep(index) {
        let $step = this.find(`.j-room-reports__step-${index}`);
        $step.show('slow');

        $([document.documentElement, document.body]).animate({
            scrollTop: $step.offset().top - 170
        }, 1000);
    },

    hideStep(index) {
        this.find(`.j-room-reports__step-${index}`).hide();
    },

    initRoomSelect(selector) {
        const $roomSelect = this.find(selector);
        const route = this.route('rooms-select-data');
        $roomSelect.select2({});
        $.get(route)
            .done((rooms) => {
                rooms.forEach(room => {
                    const newOption = new Option(room.name, room.id, false, false);
                    this.find(selector).append(newOption).trigger('change');
                });
                this.find(selector).val(null).trigger('change');
            });
    },

    getColumnData: function (workSheet, startingCell) {
        if(!startingCell)
            return null;

        const regex = /\d+/;
        let row = startingCell.match(regex);
        let cell = startingCell;
        row = parseInt(row) + 1;

        const columnCells = [];
        while (cell.replace(regex, row) in workSheet) {
            cell = cell.replace(regex, parseInt(row));
            row = row + 1;
            columnCells.push(cell);
        }

        const values = [];
        columnCells.forEach(cellNode => {
            values.push(workSheet[cellNode].v);
        });

        return values;
    },

    getColumnsData() {
        const workSheet = this.workbook.Sheets[this.workbook.SheetNames[0]];
        const rakeStartingCell = this.rakeStartingCell;
        const loginStartingCell = this.loginStartingCell;
        const nicknameStartingCell = this.nicknameStartingCell;
        const codeStartingCell = this.codeStartingCell;
        const roomStartingCell = this.roomStartingCell;

        const nicknames = this.getColumnData(workSheet, nicknameStartingCell);
        const rakes = this.getColumnData(workSheet, rakeStartingCell);
        const logins = this.getColumnData(workSheet, loginStartingCell);
        const codes = this.getColumnData(workSheet, codeStartingCell);
        const rooms = this.getColumnData(workSheet, roomStartingCell);

        const rows = [];
        for (let index = 0; index < nicknames.length; index++) {
            //TODO: Negatives off
            // if (rakes[index] < 0.0)
            //     continue;

            rows.push({nickname: nicknames[index], login: logins[index], code: codes[index] , rake: rakes[index], room: rooms ? rooms[index] : null});
        }

        return rows;
    },

    destroyPreviewTable() {
        if (this.rakesFromReportPreviewTable)
            this.rakesFromReportPreviewTable.undelegateEvents();
    },

    buildTableDataArray(worksheet, firstCell) {
        const data = [];
        let cell = firstCell;
        const initialRow = parseInt(ExcelHelper.getRow(firstCell));
        const initialColumn = ExcelHelper.getColumn(firstCell);
        for (let row = initialRow; row < initialRow + 6; row++) {
            cell = initialColumn + row;
            const columnsData = [];
            let continueSearchingCells = true;
            while (continueSearchingCells) {
                if (!(ExcelHelper.hasMultipleCellsToTheRight(worksheet, cell, 1)))
                    continueSearchingCells = false;

                if (_.isUndefined(worksheet[cell])) {
                    const firstValueToTheLeft = ExcelHelper.firstCellValueToTheLeft(worksheet, cell);
                    if (!_.isEmpty(firstValueToTheLeft))
                        columnsData.push(firstValueToTheLeft);

                } else
                    columnsData.push([worksheet[cell].v, `${cell}`]);

                cell = ExcelHelper.increaseCol(cell);
            }

            data.push(columnsData);
        }

        return data;
    },

    buildSelectColumnsRawTable: function (data) {
        const $tableWrapper = this.find('.j-select-table-wrapper');
        $tableWrapper.empty();
        const table = document.createElement('table');
        $(table).addClass('table table-hover table-responsive');
        $(table).attr('data-simplebar', '');

        const tableHead = document.createElement('thead');
        const tableBody = document.createElement('tbody');

        data.forEach((columnValues, rowIndex) => {
            let tr = document.createElement('tr');
            columnValues.forEach((columnValue, columnIndex) => {
                let td;
                if (rowIndex === 0)
                    td = document.createElement('th');
                else
                    td = document.createElement('td');

                $(td).attr('data-position', columnValue[1]);
                $(td).css('cursor', 'pointer');
                td.appendChild(document.createTextNode(columnValue[0]));
                tr.appendChild(td)
            });

            if (rowIndex === 0)
                tableHead.appendChild(tr);
            else
                tableBody.appendChild(tr);
        });

        let tr = document.createElement('tr');
        for (let index = 0; index < data[0].length; index++) {
            let td = document.createElement('td');
            td.appendChild(document.createTextNode('...'))
            tr.appendChild(td)
        }
        tableBody.appendChild(tr);

        table.appendChild(tableHead);
        table.appendChild(tableBody);
        $tableWrapper.append(table);
    },

    buildSelectColumnsTable(data) {
        this.buildSelectColumnsRawTable(data);
        this.initColumnListeners();
    },

    updateAttrStartingCell: function ($table, columnIndex) {
        $table.find('th').each((index, element) => {
            if (index !== columnIndex)
                return;

            if (_.isEmpty(this.nicknameStartingCell)) {
                this.nicknameStartingCell = $(element).attr('data-position');
                this.markSelectedTableColumn('nickname');
                this.find('.j-login-selector-container').show();
                this.find('.j-select-nickname-tip').html(`Nickname selected: <b>${$(element).text()}</b>`);
                return;
            }

            if (_.isEmpty(this.loginStartingCell)) {
                this.loginStartingCell = $(element).attr('data-position');
                this.markSelectedTableColumn('login');
                this.find('.j-code-selector-container').show();
                this.find('.j-select-login-tip').html(`Login selected: <b>${$(element).text()}</b>`);

                return;
            }

            if (_.isEmpty(this.codeStartingCell)) {
                this.codeStartingCell = $(element).attr('data-position');
                this.markSelectedTableColumn('code');
                this.find('.j-rake-selector-container').show();
                this.find('.j-select-code-tip').html(`ID Number selected: <b>${$(element).text()}</b>`);
                return;
            }

            if (_.isEmpty(this.rakeStartingCell)) {
                this.rakeStartingCell = $(element).attr('data-position');
                this.markSelectedTableColumn('rake');
                this.find('.j-select-rake-tip').html(`Rake selected: <b>${$(element).text()}</b>`);
            }
        });
    },

    updateTableColumnColor: function ($table, columnIndex) {
        let colorToPaintColumn = '';
        this.columnColors.forEach(color => {
            if (!_.isEmpty(colorToPaintColumn))
                return;

            if (!_.isUndefined(_.find(this.columnColorsUsed, colorAlreadyUsed => colorAlreadyUsed === color)))
                return;

            colorToPaintColumn = color;
        });
        this.columnColorsUsed.push(colorToPaintColumn);

        $table.find('th').each((index, element) => {
            if (index === columnIndex)
                $(element).css('background-color', colorToPaintColumn);
        })

        $table.find('tr').each((index, element) => {
            $(element).children().each((innerIndex, tdElement) => {
                if (innerIndex === columnIndex)
                    $(tdElement).css('background-color', colorToPaintColumn);
            });
        })
    },

    initColumnListeners() {
        const $table = this.find('.j-select-table-wrapper table');

        $table.find('td').click((e) => {
            this.onClickColumn(e, $table);
        });

        $table.find('th').click((e) => {
            this.onClickColumn(e, $table);
        });
    },

    onClickColumn: function (e, $table) {
        if (this.areAllColumnsSelected())
            return;

        const columnIndex = $(e.currentTarget).index();
        this.updateAttrStartingCell($table, columnIndex);
        this.updateTableColumnColor($table, columnIndex);

        if (this.areAllColumnsSelected() && this.canPreviewData) {
            this.previewData();
        }
    },

    resetSelectedColumns() {
        const $check = this.find(`.fa-check`);
        const $spinner = this.find(`.spinner`);
        $spinner.show();
        $check.hide();
        this.nicknameStartingCell = '';
        this.loginStartingCell = '';
        this.codeStartingCell = '';
        this.rakeStartingCell = '';
        this.find('.j-login-selector-container').hide();
        this.find('.j-code-selector-container').hide();
        this.find('.j-rake-selector-container').hide();
        this.find('th').css('background-color', 'unset');
        this.find('td').css('background-color', 'unset');
        this.columnColorsUsed = [];
        this.find('.j-select-tip').each((index, element) => {
            $(element).html($(element).attr('data-default'));
        });
        this.hideStep(4);
    },

    areAllColumnsSelected: function () {
        return !_.isEmpty(this.nicknameStartingCell) && !_.isEmpty(this.loginStartingCell) &&
            !_.isEmpty(this.codeStartingCell) && !_.isEmpty(this.rakeStartingCell);
    },

    markSelectedTableColumn(attr) {
        const $check = this.find(`.j-${attr}-check`);
        const $spinner = this.find(`.j-${attr}-spinner`);
        $spinner.hide();
        $check.show();
        $check.css('color', this[`${attr}Color`]);
    },

    currentWorksheet() {
        const firstSheetName = this.workbook.SheetNames[0];
        return this.workbook.Sheets[firstSheetName];
    },

    async updateRakes() {
        const _this = this;
        const rows = this.getColumnsData();
        const selectedReportStructure = {
            nickname: {
                startingCell: this.nicknameStartingCell,
                value: this.currentWorksheet()[this.nicknameStartingCell].v
            },
            login: {
                startingCell: this.loginStartingCell,
                value: this.currentWorksheet()[this.loginStartingCell].v
            },
            code: {
                startingCell: this.codeStartingCell,
                value: this.currentWorksheet()[this.codeStartingCell].v
            },
            rake: {
                startingCell: this.rakeStartingCell,
                value: this.currentWorksheet()[this.rakeStartingCell].v
            }
        };

        const reportMultiplier = this.find('.j-room-report-multiplier-input').val();

        const route = this.route('room-report-update-habcoins');

        const data = {
            reportStructureId: _this.reportStructureId,
            roomsData: this.selectedRooms,
            userRakesData: rows,
            selectedReportStructure,
            reportMultiplier: reportMultiplier,
            uploadDate: $('.j-upload-date').val(),
        }

        try {
            await $.post(route, data)
            await this.confirmationOk('Rakes actualizados');
            location.reload();

        } catch (error) {
            this.confirmationError('Error, rakes no actualizados');
        }
    },

    async autocompleteColumnsIfSavedStructure() {
        const jsonSavedStructure = await this.getSavedStructure();

        if (_.isEmpty(jsonSavedStructure))
            return;

        try {
            const savedStructure = JSON.parse(jsonSavedStructure);
            let nicknameCell = savedStructure['nicknameCell']['columnRowName'];

            if ((this.currentWorksheet()[nicknameCell].v !== savedStructure['nicknameCell']['value'])) {
                return;
            }

            let loginCell = savedStructure['loginCell']['columnRowName'];

            if ((this.currentWorksheet()[loginCell].v !== savedStructure['loginCell']['value'])) {
                return;
            }

            let codeCell = savedStructure['codeCell']['columnRowName'];
            if ((this.currentWorksheet()[codeCell].v !== savedStructure['codeCell']['value'])) {
                return;
            }

            let rakeCell = savedStructure['rakeCell']['columnRowName'];

            if ((this.currentWorksheet()[rakeCell].v !== savedStructure['rakeCell']['value'])) {
                return;
            }

            let roomCell = savedStructure['roomCell']['columnRowName'];

            // if ((this.currentWorksheet()[roomCell].v !== savedStructure['roomCell']['value'])) {
            //     console.log('room');
            //     return;
            // }

            this.find(`th[data-position="${nicknameCell}"]`).click();
            this.find(`th[data-position="${loginCell}"]`).click();
            this.find(`th[data-position="${codeCell}"]`).click();
            this.find(`th[data-position="${rakeCell}"]`).click();

            this.roomStartingCell = roomCell;
            this.roomColumnCorrespondences = savedStructure.roomCellsCorrespondences ? savedStructure.roomCellsCorrespondences : null;

        } catch (e) {
        }
    },

    getSavedStructure() {
        const route = this.route('room-report-structure');

        let data = {roomIds: []};
        this.selectedRooms.forEach((value, index) => {
            data['roomIds'].push(value['roomId']);
        })

        return $.get(route, data)
            .done(response => {
                this.reportStructureId = JSON.parse(response)['id'];
            });
    },

    onResetSelectedColumns() {
        this.canPreviewData = true;
        this.resetSelectedColumns();
        this.resetPreviewTable();
        this.resetChargeRakesPreviewTable();
    },

    onClickAddReportManually() {
        this.find('.j-room-reports__step-4').empty();

        const manualImport = new AdminRoomReportManualUpload();

        manualImport.initRoomSelectManually('.j-room-select-manually');
        manualImport.showStep(10);
    },

    resetPreviewTable() {
        this.rakesFromReportPreviewTable.destroy();
    },

    resetChargeRakesPreviewTable() {
        this.chargeRakesPreviewTable.destroy();
    },

    onClickResetPreview() {
        this.onResetSelectedColumns();
    },

    initChargeRakesTable() {
        if (this.chargeRakesPreviewTable === null)
            this.chargeRakesPreviewTable = new AdminChargeRakesPreviewTable({
                el: '#admin-charge-rakes-datatable',
                data: this.chargeRakesPreviewData,
            });
    }
});