import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";

export const CartDetails = ElView.extend({
    el: 'cart-details',
    events: {
        'click .j-add-one-unit': 'onClickAddOneUnit',
        'click .j-remove-one-unit': 'onClickRemoveOneUnit',
        'click .j-remove-product': 'onClickRemoveProduct',
        'click .j-place-order-button': 'onPlaceOrder',
        // 'click .j-set-default-billing-data': 'onSetDefaultBillingData',
    },
    init() {
    },

    async onClickAddOneUnit(event) {
        const $productRow = $(event.currentTarget).closest('.j-product-row');
        const cartProductId = $productRow.attr('data-product-id');

        const route = this.route('add-unit-to-cart-product', {cartProductId});
        await $.post(route);

        const $units = $productRow.find('.j-product-units');
        const newUnits = parseInt($units.text()) + 1;
        $units.text(newUnits);

        this.updateProductPrice($productRow);
        this.updateSubtotal();
        this.reloadCartHeader();
    },

    async onClickRemoveOneUnit(event) {
        const $productRow = $(event.currentTarget).closest('.j-product-row');
        const cartProductId = $productRow.attr('data-product-id');
        const $units = $productRow.find('.j-product-units');

        if (parseInt($units.text()) === 1)
            this.removeProduct($productRow);

        else {
            const newUnits = parseInt($units.text()) - 1;
            $units.text(newUnits);
        }

        const route = this.route('remove-unit-from-cart-product', {cartProductId});
        await $.post(route);

        this.updateProductPrice($productRow);
        this.updateSubtotal();
        this.reloadCartHeader();
    },

    async onClickRemoveProduct(event) {
        const $productRow = $(event.currentTarget).closest('.j-product-row');
        this.removeProduct($productRow);
    },

    async updateProductPrice($productRow) {
        const cartProductId = $productRow.attr('data-product-id');
        const route = this.route('get-cart-product-price', {cartProductId})
        const newPrice = await $.get(route);

        $productRow.find('.j-product-price').text(newPrice + ' Habcoins');
    },

    async updateSubtotal() {
        const route = this.route('get-cart-subtotal')
        const newPrice = await $.get(route);

        this.find('.j-cart-subtotal').text(newPrice + ' Habcoins');
        this.find('.j-cart-subtotal').attr('data-cart-subtotal', newPrice);
    },

    async removeProduct($productRow) {
        const cartProductId = $productRow.attr('data-product-id');

        const route = this.route('remove-cart-product', {cartProductId});
        await $.post(route);

        $productRow.remove();
        this.updateSubtotal();
        this.reloadCartHeader();
    },

    reloadCartHeader() {
        $(document).find('.j-reload-cart-header').click();
    },

    async onPlaceOrder() {
        const totalPrice = this.find('.j-cart-subtotal').text();
        const confirmation = await this.confirmation(Translator.trans('title-confirmation-payment'), 'Total: ' + totalPrice,
            Translator.trans('confirm'), Translator.trans('cancel'))

        if (confirmation.isConfirmed) {
            try {

                const $form = this.find('.j-billing-data-form');

                await $.post(this.route('tool-cart-details'), $form.serialize());

                await this.confirmationOk(this.translate('confirmation-payment'));
                location.replace(this.route('tool-store'));

            } catch (exception) {
                const textError = exception.responseText;
                if (_.isEmpty(textError))
                    this.confirmationError('generic.error');
                else{
                    this.confirmationError(textError);

                }
            }
        }
    },

    // async onSetDefaultBillingData() {
    //     const $form = this.find('.j-billing-data-form');
    //
    //     const route = this.route('tool-api-update-user-billing-data');
    //     const data = { billingDataForm: $form }
    //
    //     try {
    //         await $.post(route, $form.serialize());
    //         this.confirmationOk('billing-data-updated');
    //
    //     }catch(exception){
    //         this.confirmationError('generic.error');
    //     }
    //
    // }
})