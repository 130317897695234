import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {rakeReportCalculator} from "../../../shared/data/rake-report-calculator";
import {formatNumber} from "../../../shared/helper/number-helper";
import {dateToYearMonthString} from "../../../shared/helper/date-helper";

export const AdminRakeHistoriesPage = ElView.extend({
    el: 'admin-rakes-histories-page',
    events: {
        'changeDate .j-start-date': 'onChangeDate',
        'changeDate .j-end-date': 'onChangeDate',
    },
    datatable: null,

    startDate: null,
    endDate: null,
    userIds: null,
    nick: null,
    login: null,
    code: null,
    roomIds: null,
    affiliateIds: null,

    globalTableHeaders: null,

    headerNameToDataMap: {
        userId: 'Usuario',
        affiliateId: 'Afiliado',
        nick: 'Nick',
        login: 'Login',
    },

    init() {
        $('.j-start-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });


        $('.j-end-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();
        this.userIds = this.find('#rakes-userId-search').val();
        this.nick = this.find('#rakes-nick-search').val();
        this.login = this.find('#rakes-login-search').val();
        this.code = this.find('#rakes-code-search').val();
        this.roomIds = this.find('#rakes-roomId-search').val();
        this.affiliateIds = this.find('#rakes-affiliateId-search').val();

        this.initSearchListeners();
        this.onInitRakeHistoriesTable = _.debounce(this.initRakeHistoriesTable, 200);
        this.onFiltersChanged = _.debounce(this.filtersChanged, 200);

        this.onInitRakeHistoriesTable();
    },

    initSearchListeners() {
        $('#rakes-userId-search').on('select2:select', (event) => {
            this.usernameSearchAction(event);
        });

        $('#rakes-userId-search').on('select2:unselect', (event) => {
            this.usernameSearchAction(event);
        });

        $('#rakes-nick-search').on('input', (event) => {
            this.nick = $(event.currentTarget).val();

            this.clearUsernameSearch();
            this.clearAffiliateSearch();
            this.clearLoginSearch();
            this.clearCodeSearch();

            this.onFiltersChanged();
        });

        $('#rakes-login-search').on('input', (event) => {
            this.login = $(event.currentTarget).val();

            this.clearUsernameSearch();
            this.clearAffiliateSearch();
            this.clearNickSearch();
            this.clearCodeSearch();

            this.onFiltersChanged();
        });

        $('#rakes-code-search').on('input', (event) => {
            this.code = $(event.currentTarget).val();

            this.clearUsernameSearch();
            this.clearAffiliateSearch();
            this.clearNickSearch();
            this.clearLoginSearch();

            this.onFiltersChanged();
        });

        $('#rakes-roomId-search').on('change', (event) => {
            this.roomIds = $(event.currentTarget).val();

            this.onFiltersChanged();
        });

        $('#rakes-affiliateId-search').on('select2:select', (event) => {
            this.affiliateSearchAction(event);
        });

        $('#rakes-affiliateId-search').on('select2:unselect', (event) => {
            this.affiliateSearchAction(event);
        });
    },

    async initRakeHistoriesTable() {
        const _this = this;
        this.datatable = this.find('#admin-rake-histories-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('rake-histories-table-data', {
                        startDate: _this.startDate,
                        endDate: _this.endDate,
                        userIds: _this.userIds,
                        nick: _this.nick,
                        login: _this.login,
                        code: _this.code,
                        roomIds: _this.roomIds,
                        affiliateIds: _this.affiliateIds,
                    }),
                    pageSize: 10,
                },
                layout: {
                    scroll: false,
                },
                rows: {
                    autoHide: true,
                },

                sortable: true,
                pagination: true,
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },

                columns: [
                    {
                        field: 'createdAt',
                        title: 'Reporte',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${dateToYearMonthString(row.createdAt)}</span>`
                        },
                    },
                    {
                        field: 'userId',
                        title: capitalizeFirstLetter(Translator.trans('username')),
                        template: (row) => {
                            if (row.username === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" 
                                            style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">${row.username}
                                        </label>`;
                            return `<a href="${_this.route('admin-user-details', {'userId': row.userId})}" class="font-weight-bolder">${row.username}</a>`
                        },
                    },
                    {
                        field: 'roomId',
                        title: capitalizeFirstLetter(Translator.trans('game.room.room')),
                        template: (row) => {
                            const route = this.route('admin-room-details', {roomId: row.roomId})

                            return `<a href="${route}" class="font-weight-bolder">${row.roomName}</a>`
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.nick}</span>`
                        }
                    },
                    {
                        field: 'affiliateId',
                        title: 'Afiliado',
                        template: (row) => {
                            return `<a href="${_this.route('admin-affiliate-details', {'affiliateId': row.affiliateId})}" class="font-weight-bolder">${row.affiliateName}</a>`
                        },
                    },
                    {
                        field: 'rake',
                        title: capitalizeFirstLetter(Translator.trans('rake')),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(row.rake, 2)} ${row.symbol}</span>`
                        }
                    },
                    {
                        field: 'userRakeback',
                        title: 'Rakeback (Habcoins)',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(row.userRakeback, 0)}</span>`
                        }
                    },
                    {
                        field: 'parentRakeback',
                        title: 'Afiliado (Habcoins)',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(parseFloat(row.parentRakeback), 0)}</span>`
                        }
                    },
                    {
                        field: 'subAffiliateRakeback',
                        title: 'Sub afiliado (Habcoins)',
                        textAlign: 'center',
                        visible: false,
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(parseFloat(row.subaffiliateRakeback), 0)}</span>`
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#admin-rake-histories-datatable').on('datatable-on-ajax-done', () => this.updateGlobalStats());
    },

    onChangeDate() {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.datatable.destroy();

        this.onInitRakeHistoriesTable();
    },

    updateGlobalStats() {
        const data = this.datatable.getDataSet();
        if (!data)
            return;

        let generatedRake = 0;
        let userRakebacks = 0;
        let affiliateRakebacks = 0;

        Object.values(data).forEach((value) => {
            generatedRake += value.rakeInEuros;
            userRakebacks += value.userRakeback;
            affiliateRakebacks += parseFloat(value.parentRakeback) + parseFloat(value.subaffiliateRakeback);
        })

        this.find('.j-global-stats-rake').text(formatNumber(generatedRake, 2));
        this.find('.j-global-stats-user-rakebacks').text(formatNumber(userRakebacks / 100, 2));
        this.find('.j-global-stats-parent-rakebacks').text(formatNumber(affiliateRakebacks / 100, 2));

        this.reloadGlobalsTable(data);
    },

    reloadRakeHistoriesTable() {
        this.datatable.destroy();
        this.onInitRakeHistoriesTable();
    },

    usernameSearchAction(event) {
        this.userIds = $(event.currentTarget).val();

        this.clearAffiliateSearch();
        this.clearNickAndLoginAndCodeSearch()
        this.onFiltersChanged();
    },

    affiliateSearchAction(event) {
        this.affiliateIds = $(event.currentTarget).val();

        this.clearUsernameSearch();
        this.clearNickAndLoginAndCodeSearch()
        this.onFiltersChanged();
    },

    clearNickSearch() {
        this.nick = null;
        this.find('#rakes-nick-search').val('');
    },

    clearLoginSearch() {
        this.login = null;
        this.find('#rakes-login-search').val('');
    },

    clearCodeSearch() {
        this.nick = null;
        this.find('#rakes-code-search').val('');
    },

    clearAffiliateSearch() {
        this.affiliateIds = [];
        this.find('#rakes-affiliateId-search').val(null).trigger('change');
    },

    clearUsernameSearch() {
        this.userIds = [];
        this.find('#rakes-userId-search').val(null).trigger('change');
    },

    clearNickAndLoginAndCodeSearch() {
        this.nick = '';
        this.find('#rakes-nick-search').val('');

        this.login = '';
        this.find('#rakes-login-search').val('');

        this.code = '';
        this.find('#rakes-code-search').val('');
    },

    filtersChanged() {
        this.reloadRakeHistoriesTable();
    },

    reloadGlobalsTable(data) {
        const userIds = _.isEmpty(this.userIds) ? ['*'] : this.userIds;
        const roomIds = _.isEmpty(this.roomIds) ? ['*'] : this.roomIds;
        const affiliateIds = _.isEmpty(this.affiliateIds) ? ['*'] : this.affiliateIds;
        const nick = _.isEmpty(this.nick) ? ['*'] : [this.nick];
        const login = _.isEmpty(this.login) ? ['*'] : [this.login];

        const result = rakeReportCalculator().calculate(
            userIds,
            roomIds,
            affiliateIds,
            nick,
            login,
            data
        );

        this.buildGlobalsTable(result);
    },

    buildGlobalsTable(data) {
        const $template = this.find('.j-template-row');

        this.find('.j-added-row').remove();

        data.forEach((value) => {
            const $row = $($template.clone());
            $row.removeClass('j-template-row');
            $row.addClass('j-added-row');

            const mainFieldName = this.getNameFromId(value.mainField, value.fieldId);
            const roomName = this.getNameFromId('roomId', value.roomId);

            if (value.generatedRake == 0 && value.userRakeback == 0 && value.affiliatesRakeback == 0)
                return;

            const $mainField = $row.find('.j-username-or-affiliate');
            if (mainFieldName === '*')
                $mainField.css('background-color', 'ghostwhite');
            else {
                let route = this.route('admin-user-details', {userId: value.fieldId})
                if (value.mainField === 'affiliateId')
                    route = this.route('admin-affiliate-details', {affiliateId: value.fieldId})

                $mainField.html(`<a href="${route}">${mainFieldName}</a>`);
            }


            const $roomField = $row.find('.j-room-or-empty');
            if (roomName === '*')
                $roomField.css('background-color', 'ghostwhite');
            else {
                const route = this.route('admin-room-details', {roomId: value.roomId})
                $roomField.html(`<a href="${route}">${roomName}</a>`);
            }


            $row.find('.j-rake').text(formatNumber(value.generatedRake, 2));
            $row.find('.j-user-rakebacks').text(formatNumber(value.userRakeback / 100, 2));
            $row.find('.j-parent-rakebacks').text(formatNumber(value.affiliatesRakeback / 100, 2));

            $row.insertAfter($template);
            $row.show();
        });

        const headerName = this.headerNameToDataMap[data[0].mainField];
        this.find('.j-header-username-or-affiliate').text(headerName);
    },

    getNameFromId(field, id) {
        if (id === '*')
            return '*';

        if (field === 'nick' || field === 'login')
            return id;

        return this.find('#rakes-' + field + '-search').select2('data').find((item) => item.id === id).text;
    },
})