import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";

export const AdminWalletMovements = ElView.extend({
    el: '.j-admin-wallet-movements',
    events: {
        'click .j-add-wallet-movement-submit': 'onClickAddMovement',
    },

    init() {
        const walletId = $(this.el).attr('data-wallet-id');
        $.get(Router.generate('wallet-movements-table', {walletId}))
            .done((data) => {
                this.initWalletsTable(JSON.parse(data));
            })
    },

    initWalletsTable(data) {
        const _this = this;
        this.datatable = this.find('#j-admin-wallet-movements-datatable').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                    customScrollbar: true,
                },
                sortable: true,
                pagination: true,
                columns: [
                    {
                        field: 'sequence',
                        title: '#',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.sequence}</span>`;
                        }
                    },
                    {
                        field: 'createdAt',
                        title: 'Creado',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.username}</span>`;
                        }
                    },
                    {
                        field: 'type',
                        title: 'Tipo',
                        textAlign: 'center',
                        template: (row) => {

                            if (row.type === 'type_correction' && row.concept !== '')
                                return `<span class="font-weight-bold text-muted" data-toggle="tooltip" data-placement="top" title="${row.concept}">${row.concept}</span>`;

                            return `<span class="font-weight-bold text-muted">${this.translate(row.type)}</span>`;
                        }
                    },
                    {
                        field: 'rakeRoomAndMonth',
                        title: this.translate('origin'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold text-muted">${row.rakeRoomAndMonth}</span>`;
                        }
                    },
                    {
                        field: 'euros',
                        title: this.translate('euros'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.euros.toFixed(2)} €</span>`;
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#wallets-movements-search-by-sequence').on('input', (event) => {
            _this.datatable.search($(event.currentTarget).val(), 'sequence');
        });

        $('#wallet-movements-search-type').on('change', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'type');
        });
    },

    async onClickAddMovement() {
        const route = this.route('core-api-add-wallet-movement-correction');
        const data = {
            walletId: $(this.el).attr('data-wallet-id'),
            amountInEuros: this.find('.j-wallet-movement-correction-amount').val(),
            concept: this.find('.j-wallet-movement-correction-concept').val(),
        }

        await $.post(route, data);

        location.reload();
    }
})