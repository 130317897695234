export const trans_en =
    {
        successful: {
            register: 'Registration complete. Check your email to confirm your email address.'
        },
        email: {
            sent: 'An email has been sent to you. Check your email provider and follow the steps.'
        },
        errors: {
            detected: 'Sorry, looks like there are some errors detected, please try again.',
            user: {
                use: 'User already exists',
            },
            email: {
                use: 'Email already is in use',
            },
            invalid: {
                credentials: 'Invalid credentials, please try again.',
                email: 'Invalid email',
                ranges: 'Invalid ranges',
                contact: 'If you wish to be contacted, you must enter a prefix, telephone number and at least one contact method'
            },
            notFound: {
                image: 'Image not uploaded',
            },
            generic: 'Internal error.'
        },
        ok: {
            gotit: 'Ok, got it!'
        },
        name: 'Name',
        webSection: {
            webSection: 'Web section',
            habpoker: 'habpoker',
            habbets: 'habbets',
            habbcasino: 'habbcasino',
            habstore: 'habstore',
        },
        game: {
            room: {
                web: 'web',
                room: 'room'
            }
        },
        user: {
            fullname: 'fullname',
            username: 'nickname',
            affiliateName: 'affiliate',
            roomNickname: 'room nickname',
            roomEmail: 'room email',
            roles: 'roles',
            role: {
                ROLE_USER: 'user',
                ROLE_SUPERADMIN: 'superadmin',
                ROLE_ADMIN: 'administrator',
                ROLE_SALES: 'comercial',
                ROLE_READ_ONLY_ADMIN: 'admin read only',
            }
        },
        datatable: {
            actions: 'actions',
            status: 'status'
        },
        empty: 'empty',
        group: {
            'number-of-users': 'number of users'
        },
        product: {
            image: 'image',
            publicIdentifier: 'id',
            title: 'title',
            status: {
                status: 'status',
                enabled: 'enabled',
                disabled: 'disabled'
            }
        },
        category: {
            category: 'category',
            subcategory: 'subcategory'
        },
        type: 'type',
        cookie: {
            message: 'This website uses cookies to enrich your user experience',
            dismiss: 'I agree',
            link: 'Read more',
        },
        'upload-images': 'upload images',
        'add-more-images': 'add more images',
        'new-variation': 'new variation',
        'analyzing-data': 'analyzing data...',
        'total-habcoins': 'Total habcoins',
        'generated-rake': 'Rake generado',
        'from': 'from',
        'to': 'to',
        'no-more-rakes-to-load': 'No more rakes to load',
        'multiplier': 'Multiplier',
        'rakes': 'Rakes',
        'habcoins': 'Habcoins',
        'walletDirection': 'direction',
        'rakeback-ranges-of': 'Rakebacks of',
        'applied-formula': 'Formula',
        'rake-multiplier': 'Rake multiplier',
        'number-of-refered-users': 'number of refered users',
        'rake-habcoins': 'Rake habcoins',
        'copied': 'copied to clipboard',
        'error': 'error',
        'invitation-sent': 'Invitation sent',
        'withdrawal-method': 'Wallet',
        'currency-withdrawn': 'Currency',
        'currency-amount': 'Amount (€)',
        'conversion-applied': 'Conversion',
        'status': 'Status',
        'type_withdraw': 'Withdraw',
        'type_purchase': 'Order',
        'type_rake_earnings': 'Rakeback',
        'type_affiliate_earnings': 'Affiliates',
        'pending': 'Pending',
        'accepted': 'Accepted',
        'rejected': 'Rejected',
        'date': 'Date',
        'referal_friend': 'Referal friend',
        'affiliate': 'Affiliate',
        'affiliate_vip': 'Affiliate VIP',
        'sales': 'Sales',
        'room': 'Room',
        'actions': 'Actions',
        'user-room-credentials-created': 'User credentials added',
        'user-credentials-already-exist': 'Credentials already exist',
        'rake': 'Rake',
        'no-file-selected-error': 'No file selected',
        'amount': 'amount',
        'euro': 'Euro',
        'dollar': 'Dollar',
        'pound': 'Pound',
        'turkish_lira': 'Turkish Lira',
        'credentials-removed': 'Credentials removed',
        'product-added-to-cart': 'Added to cart',
        'user-have-to-log-to-buy': 'You must log in to buy',
        'product-already-in-cart': 'Already in cart',
        'title-confirmation-payment': 'Confirm payment',
        'confirmation-payment': 'Order placed',
        'confirm': 'Confirm',
        'cancel': 'Cancel',
        'billing-data': 'Billing data',
        'number-of-items': 'Units',
        'state': 'State',
        'base-price-amount': 'Total',
        'created-at': 'Created',
        'ordered-at': 'Ordered',
        'details': 'Details',
        'fill-out-country-field': 'Fill out country field',
        'products': 'Products',
        'revenue': 'Revenue',
        'month': 'Month',
        'nick': 'Nick',
        'login ': 'Login',
        'rake-euros': 'Rake (€)',
        'Jan': 'Jan',
        'Feb': 'Feb',
        'Mar': 'Mar',
        'Apr': 'Apr',
        'May': 'May',
        'Jun': 'Jun',
        'Jul': 'Jul',
        'Aug': 'Aug',
        'Sep': 'Sep',
        'Oct': 'Oct',
        'Nov': 'Nov',
        'GB': 'United Kingdom',
        'ES': 'Spain',
        'intl': 'International',
        'no-items-match-your-search': 'No items match your search',
        'invitaion-email-sent': 'Invitation sent',
        'of': 'of',
        'Displaying': 'Displaying',
        'download-file-confirmation': 'Do you want to download the next hand?',
        'email-not-found': 'Wrong email',
        'activate-account': 'Activate your account before you login',
        'resend-confirmation-email': 'Send confirmation email',
        'affiliate_verified': 'Verified',
        'affiliate_not_verified': 'Pending',
        'origin': 'Source',
        'requestRejected': 'Request rejected',
        'errorOccurred': 'An error has occurred',
        'reject': 'Reject'
    };