import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";
import {Translator} from "../shared/translation/translator";

export const BlogPostsPage = ElView.extend({
    el: '.j-blog-posts',
    events: {},
    datatable: null,
    type: null,
    isLogin: false,

    init: async function () {
        this.isLogin = this.find('.j-data-login').attr('data-is-login');

        this.loginNotication(this.isLogin);

        this.type = this.find('.j-data').attr('data-post-type');

        this.find('.j-select-2-post-status').select2({
            minimumResultsForSearch: -1,
        });
        this.find('.j-select-2-post-available-countries').select2({
            minimumResultsForSearch: -1,
        });

        if (this.type === 'promotion')
            this.initPromotionsTable();
        else
            this.initPostsTable();

    },

    loginNotication(isLogin) {
        const _this = this;
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-bottom-full-width",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "500",
            "hideDuration": "1000",
            "timeOut": "10000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };


        const userPending = _this.find('.j-data-login').attr('data-user-pending');
        const affiliatePending = _this.find('.j-data-login').attr('data-affiliate-pending');
        const orderPaid = _this.find('.j-data-login').attr('data-order-paid');
        if (isLogin) {
            if (userPending > 0)
                toastr.info("Hay " + userPending + " peticiones de usuario");
            if (affiliatePending > 0)
                toastr.info("Hay " + affiliatePending + " peticiones de afiliado");
            if (orderPaid > 0)
                toastr.info("Hay " + orderPaid + " pedidos pendientes de procesar");
        }

    },

    initPostsTable() {
        this.datatable = this.find('#blog-posts-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('blog-posts-table-data', {postType: this.type}),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#posts-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                columns: [

                    {
                        field: 'publishedAt',
                        title: capitalizeFirstLetter('fecha de publicación'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.publishedAt}`;
                        }
                    },
                    {
                        field: 'title',
                        title: capitalizeFirstLetter('titulo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },
                    {
                        field: 'sections',
                        title: capitalizeFirstLetter('secciones'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.sections)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.sections[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter('status'),
                        textAlign: 'center',
                        template: (row) => {
                            const typeToCssClassMap = {
                                'draft': 'info',
                                'published': 'primary'
                            };

                            let $result;

                            if (row.status === 'draft') {
                                $result = 'Borrador';
                            } else {
                                $result = 'Publicado';

                            }

                            return `<span class="label label-inline label-rounded font-weight-bold label-light-${typeToCssClassMap[row.status]}">${capitalizeFirstLetter($result)}</span>`;
                        }
                    },
                    {
                        field: 'availableCountries',
                        title: capitalizeFirstLetter('Países'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.availableCountries)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.availableCountries[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {

                            let detailsRoute = this.route('admin-blog-post-details', {postId: row.id});

                            if (this.type === 'promotion')
                                detailsRoute = this.route('admin-blog-promotion-details', {postId: row.id});

                            if (this.type === 'room')
                                detailsRoute = this.route('admin-blog-room-post-details', {postId: row.id});

                            if (this.type === 'content')
                                detailsRoute = this.route('admin-blog-contents-details', {postId: row.id});


                            return `
                                    <a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                            
                                  `
                        },
                    },
                ],
            }
        );

        $('#posts-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#posts-search-websection').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'sections');
        });

        $('#posts-search-available-countries').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'availableCountries');
        });

        $('#posts-search-category').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'categories');
        });
    },

    initPromotionsTable() {
        this.datatable = this.find('#blog-posts-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('blog-posts-table-data', {postType: this.type}),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#posts-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                columns: [

                    {
                        field: 'publishedAt',
                        title: capitalizeFirstLetter('fecha de publicación'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.publishedAt}`;
                        }
                    },
                    {
                        field: 'title',
                        title: capitalizeFirstLetter('titulo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },
                    {
                        field: 'roomsName',
                        title: capitalizeFirstLetter('Sala'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';
                            for (let index in row.roomsName)
                                html += `<span class="label label-inline m-1">${row.roomsName[index]}</span>`
                            return html;
                        }
                    },
                    {
                        field: 'sections',
                        title: capitalizeFirstLetter('secciones'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.sections)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.sections[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter('status'),
                        textAlign: 'center',
                        template: (row) => {
                            const typetoCssClassMap = {
                                'draft': 'info',
                                'published': 'primary'
                            };

                            let $result;

                            if (row.status === 'draft') {
                                $result = 'Borrador';
                            } else {
                                $result = 'Publicado';

                            }

                            return `<span class="label label-inline label-rounded font-weight-bold label-light-${typetoCssClassMap[row.status]}">${capitalizeFirstLetter($result)}</span>`;
                        }
                    },

                    {
                        field: 'availableCountries',
                        title: capitalizeFirstLetter('Países'),
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.availableCountries)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.availableCountries[index]}</span>`

                            return html;
                        }
                    },

                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {

                            let detailsRoute = this.route('admin-blog-post-details', {postId: row.id});

                            if (this.type === 'promotion')
                                detailsRoute = this.route('admin-blog-promotion-details', {postId: row.id});

                            if (this.type === 'room')
                                detailsRoute = this.route('admin-blog-room-post-details', {postId: row.id});

                            if (this.type === 'content')
                                detailsRoute = this.route('admin-blog-contents-details', {postId: row.id});


                            return `
                                    <a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                            
                                  `
                        },
                    },
                ],
            }
        );

        $('#posts-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#posts-search-websection').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'sections');
        });

        $('#posts-search-available-countries').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'availableCountries');
        });

        $('#promotions-search-roomPost').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'roomsName');
        });

        $('#promotions-search-category').on('change', (event) => {
            console.log($(event.currentTarget).val());
            console.log(this.datatable);

            this.datatable.search($(event.currentTarget).val(), 'categories');
        });
    },
});