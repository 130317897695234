import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";

export const AdminWallets = ElView.extend({
    el: '.j-admin-wallets',
    events: {
        'click .j-admin-wallet-details-button': 'onClickWalletDetails'
    },

    init() {
        this.initWalletsTable();
    },

    initWalletsTable() {
        const _this = this;
        this.datatable = this.find('#j-admin-wallets-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('wallets-table'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: true,
                    customScrollbar: true,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: _this.find('#wallets-search-by-username'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'createdAt',
                        title: 'Creado',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'updatedAt',
                        title: 'Actualizado',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.updatedAt))}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.username}</span>`;
                        }
                    },
                    // {
                    //     field: 'movementsCount',
                    //     title: 'Movimientos',
                    //     textAlign: 'center',
                    //     template: (row) => {
                    //         return `<span class="font-weight-bold text-muted">${row.movementsCount}</span>`;
                    //     }
                    // },
                    {
                        field: 'euros',
                        title: 'Euros',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.euros.toFixed(2)} €</span>`;
                        }
                    },
                    {
                        field: 'actions',
                        title: 'Movimientos',
                        textAlign: 'center',
                        template: (row) => {
                            const route = this.route('admin-wallet-movements', {walletId: row.id})
                            return `<a href="${route}" class="btn btn-icon btn-light-warning j-admin-wallet-details-button" data-wallet-id="${row.id}" data-toggle="tooltip" data-placement="top" title="Ver movimientos"><span class="fas fa-clipboard-list" style="font-size: 1.5rem;"></span></a>`;
                        }
                    },
                ],
            }
        );

        $('#wallets-search-by-username').on('input', (event) => {
            _this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });
    },

    onClickWalletDetails(event) {
        // const walletId = $(event.currentTarget).attr('data-wallet-id');
        //
        // location.href = Router.generate('admin-wallet-movements', {walletId});
    }
})