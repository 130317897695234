import {ElView} from "../shared/el-view";
import {Translator} from "../shared/translation/translator";
import {Router} from "../shared/routing/router";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";

export const AdminSlidesPage = ElView.extend({
    el: '.j-admin-blog-slides',
    events: {
    },
    datatable: null,
    init: function () {
        this.initUsersTable();
        $('.j-create-slide').on('click', async (event) => {
           await this.onCreateSlide();
        });
    },

    initUsersTable() {
        this.datatable = this.find('#admin-slides-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('slides-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                // search: {
                //     input: this.find('#users-search'),
                //     key: 'generalSearch'
                // },
                columns: [
                    {
                        field: 'title',
                        title: capitalizeFirstLetter(Translator.trans('titulo')),
                        // textAlign: 'center',
                        template: (row) => {
                            return `
                                <div class="d-flex font-weight-bold">
                                        ${capitalizeFirstLetter(row.title)}
                                </div>`;

                        }
                    },
                    {
                        field: 'description',
                        title: capitalizeFirstLetter(Translator.trans('description')),
                        template: (row) => {
                            return `
                                <div class="d-flex font-weight-bold">
                                        ${capitalizeFirstLetter(row.description)}
                                </div>`;

                        }
                    },
                    {
                        field: 'section',
                        title: capitalizeFirstLetter(Translator.trans('section')),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bolder">${row.section}</div>`;

                        }
                    },
                    {
                        field: 'geoLoc',
                        title: capitalizeFirstLetter(Translator.trans('geoLoc')),
                        template: (row) => {
                            var esp = ' - ';
                            var uk = ' - ';
                            var int = ' - ';
                            JSON.parse(row.geoLoc).forEach((element, index) => {
                                if (element === 'Espana')
                                    esp = '  ES ';
                                if (element === 'International')
                                    int = ' INT  ';

                                if (element === 'UK')
                                    uk = '  UK  ';
                            });

                            return `<div class="d-flex font-weight-bold text-muted">
                                    ${int}${esp}${uk}
                                </div>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        textAlign: 'center',
                        width: 150,
                        template: function (row) {
                            const editRoute = Router.generate('edit-slide', {'slideId': row.id});
                            const duplicateAndDeleteRoute = Router.generate('admin-slide-duplicate', {'slideId': row.id});

                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                          <span class="fas fa-pencil-alt">
                                          </span>
                                    </a>
                                    <a href="${duplicateAndDeleteRoute}" class="btn btn-sm btn-icon btn-light-success mr-2" title="Duplicate slide">
                                          <span class="flaticon2-copy">
                                          </span>
                                    </a>
                                        `
                        },

                    },

                ],
            }
        );

        // $('#users-search-rol').on('change', (event) => {
        //     this.datatable.search($(event.currentTarget).val().toLowerCase(), 'higherRole');
        // });
    },
    async onCreateSlide(event){
        // const route = ('create-slide');
        window.location = Router.generate('create-slide');
        // const url = Router.generate('create-slide');
        // await $.post(url);

    },
});