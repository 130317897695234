import {ElView} from "../shared/el-view";
import {DropzoneComponent} from "../shared/component/dropzone/dropzone-component";

export const BlogBannerCreateOrUpdate = ElView.extend({
    el: '.j-banner-create-or-update',
    events: {
        'click .j-save-banner': 'onSaveBanner',
        'click .j-remove-banner': 'onDeleteBanner',
    },
    isCreated: '',
    dropZoneUploader: '',
    imageUpload: '',
    toast: '',

    init() {
        toastr.options = {
            "preventDuplicates": true,
            "positionClass": "toast-bottom-left",
        }


        this.dropZoneUploader = new DropzoneComponent({el: '.j-banner-image'});
        this.dropZoneUploader.onUpload((file) => {
            this.log(file)
            this.imageUpload = file;
            this.find('.j-imageUrl').val(file.url);
            this.find('.j-image-update').attr('src', `/${file.url}`);
        });

        this.isCreated = this.find('.j-isCreated').attr('data-isCreated');
        if (!this.isCreated) {
            const divData = this.find('.j-data');
            const defaultUrl = divData.attr('data-default-image');
            this.find('.j-image-update').attr('src', `${defaultUrl}`);
        }

    },

    async onDeleteBanner(event) {
        const bannerId = $(event.currentTarget).attr('data-banner-id');
        const route = this.route('remove-banner', {bannerId: bannerId});

        const result = await this.confirmation('¿Deseas borrar el banner?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Banner eliminado');

                const redirectRooute = this.route('admin-blog-banners');
                location.replace(redirectRooute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'La sección no ha sido eliminada');
            }
        }
    },

    onSaveBanner() {
        if (this.isCreated) {
            this.find('.j-banner-form').submit();
        } else {
            if (this.formIsValid())
                this.find('.j-banner-form').submit();
            else
                toastr.error('Inserte una imagen');
        }
    },

    formIsValid() {
        return this.imageUpload !== '';
    },

});