import {ElView} from "../shared/el-view";


export const PopUp = ElView.extend({
    el: 'body',
    events: {
    },

    init() {
        console.log('hola inicial');
        setTimeout(() => {
            console.log('hola 5 segundos');
            // let button = $('#show-modal-button');
            // console.log(button);
            // button.click();
        }, 5000);
    },
});
