import {ElView} from "../../el-view";

export const DropzoneComponent = ElView.extend({
    events: {'click .dz-image': 'onDeleteImage',},
    dropzone: null,
    onUploadCallback: null,
    init() {
        this.initDropzone();
    },

    initDropzone() {
        const _this = this;
        let currentFile = null;
        this.dropzone = $(this.el).dropzone({
            url: _this.route('upload-blog-images'),
            maxFiles: 1,
            maxFileSize: 100,
            addRemoveLinks: true,
            success: (file, response) => {
                if (this.onUploadCallback)
                    this.onUploadCallback(response);
            },

            init: function () {
                this.on("addedfile", function (file) {
                    if (currentFile)
                        this.removeFile(currentFile);

                    currentFile = file;
                });
            }
        });
    },

    onUpload(callback) {
        this.onUploadCallback = callback;
    },
})