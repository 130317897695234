import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {Translator} from "../../shared/translation/translator";
import _ from "lodash";
import {convertDatesToYearMonthArray, dateFromString, formatYearMonthString} from "../../shared/helper/date-helper";
import {maxFromValues, minFromValues} from "../../shared/helper/object-helper";

export const MyRakesPage = ElView.extend({
    el: '.j-my-rakes-page',
    events: {
        'click .j-button-show-more': 'onClickPage',
        'click .j-room-name-row': 'onClickRoomNameRow',
        'show.bs.tab': 'onChangeTab',
        'changeDate .j-data-start-date-area-chart': 'onChangeDates',
        'changeDate .j-data-end-date-area-chart': 'onChangeDates',
    },
    page: 0,
    elementsPerPage: 9,

    $rowTemplate: null,

    startDate: null,
    endDate: null,
    datesXAxisAreaChart: {},

    generatedRakeAreaChart: {},

    rakeHistoriesTableData: {},
    rakeHistoriesPageData: {},

    appSession: null,

    async init() {
        this.appSession = await $.get(this.route('get-session-app'));

        $('.j-data-start-date-area-chart').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
            // startDate: ,
            // endDate: ,
        });

        $('.j-data-end-date-area-chart').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        this.startDate = $('.j-data-start-date-area-chart').val();
        this.endDate = $('.j-data-end-date-area-chart').val();
        this.changeDateXAxisAreaChart();
        this.$rowTemplate = this.find('.j-rake-row-template');

        const $firstTab = this.find('.j-my-rakes-pane').first()

        await this.getRakeHistoriesData($firstTab);
        this.renderAreaChart($firstTab);
        this.initRoomMetrics($firstTab);
        this.renderPieChart();
    },

    async getRakeHistoriesData($tab) {
        const $element = $tab.find('.j-room-charts');
        if (!$element)
            return;

        const roomId = $element.attr('data-room-id');
        if (_.isEmpty(roomId))
            return;

        const route = Router.generate('my-rakes-rooms-statistics');
        const data = {
            startAt: $element.closest('.j-my-rakes-pane').find('.j-data-start-date-area-chart').val(),
            endAt: $element.closest('.j-my-rakes-pane').find('.j-data-end-date-area-chart').val(),
            userId: '',
            roomIds: [roomId],
            affiliateId: '',
        }

        await $.get(route, data)
            .done(response => {
                this.setAreaChartDataSet(response);
                this.updateTableData(response);
            })
    },

    updateTableData(data) {
        if (data.length === 0)
            return;

        if (!_.isEmpty(this.myRakesTable) || !_.isUndefined(this.myRakesTable))
            this.myRakesTable.destroy();

        const roomName = this.find('.nav-link.active').find('.nav-text').text();

        this.initMyRakesTable(data, roomName);

    },

    initMyRakesTable(data, roomName) {
        this.myRakesTable = this.find('#tool-my-rakes-table').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#users-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'room',
                        title: this.translate('room'),
                        textAlign: 'left',
                        template: (row) => {
                            return `
                                <div class="d-flex align-items-center">
                                    <a class="j-room-name-row cursor-pointer font-weight-bolder font-size-lg">${roomName}</a>
                                </div>`;
                        }
                    },
                    {
                        field: 'date',
                        title: this.translate('date'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatYearMonthString(row.yearMonth)}</span>`
                        }
                    },
                    {
                        field: 'generatedRake',
                        title: 'Rake',
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.generatedRake.toFixed(2)} €</span>`
                        }
                    },
                    {
                        field: 'userRakeback',
                        title: this.translate('Rakeback') + ' (' + this.appSession['coin'] + ')',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.userRakeback.toFixed()}</span>`
                        }
                    },
                ],
            }
        );

        this.find('.datatable-table').css('background-color', 'transparent');
    },

    renderPieChart() {
        const apexChart = "#percentage-of-total-earnings-per-room-chart";
        let chartData = this.find('#percentage-of-total-earnings-per-room-chart').attr('data-chart');

        if (!_.isEmpty(chartData))
            chartData = JSON.parse(chartData);
        else
            return;

        let labels = [];
        let values = [];

        chartData.forEach((dataEntry, index) => {
            labels[index] = dataEntry['roomName'];
            values[index] = parseInt(dataEntry['percentage']);
        })

        const options = {
            series: values,
            chart: {
                width: 500,
                type: 'pie',
            },
            labels: labels,
            responsive: [{
                breakpoint: 776,
                options: {
                    chart: {
                        width: 350
                    },
                    legend: {
                        position: 'bottom'
                    }
                },
            }],
            colors: ['#fe3995', '#f6aa33', '#6e4ff5', '#2abe81', '#c7d2e7', '#593ae1']
        };

        const chart = new ApexCharts(document.querySelector(apexChart), options);
        chart.render();
    },

    async onChangeTab(event) {
        const $clicked = $(event.target);
        const tabContentId = $clicked.attr('href');
        const $tabContent = $clicked.closest('.j-my-rakes-statistics').find(tabContentId);

        await this.getRakeHistoriesData($tabContent);
        this.renderAreaChart($tabContent);
        this.initRoomMetrics($tabContent);
        this.find('.j-button-show-more').eq(1).click()
    }
    ,

    setAreaChartDataSet(data) {
        let generatedRake = {};
        this.generatedRakeAreaChart = {};

        Object.values(data).forEach((rake) => {
            const date = formatYearMonthString(rake['yearMonth']);

            generatedRake[date] = rake['generatedRake'];
        });

        Object.values(this.datesXAxisAreaChart).forEach((date) => {
            this.generatedRakeAreaChart[date] = !_.isUndefined(generatedRake[date]) ? generatedRake[date].toFixed() : 0.0;
        });

    },

    async renderAreaChart($tab) {
        const $element = $tab.find('.j-room-charts');
        $element.empty();
        if ($element.length === 0)
            return;

        const generatedRake = Object.values(this.generatedRakeAreaChart);
        const minValue = minFromValues(generatedRake, true);
        const maxValue = maxFromValues(generatedRake);

        const options = {
            series: [
                {
                    name: 'Rake',
                    data: generatedRake
                }
            ],
            chart: {
                type: 'area',
                stacked: true,
                height: 350,
                toolbar: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 2,
            },
            xaxis: {
                categories: this.datesXAxisAreaChart,
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: KTAppSettings['colors']['gray']['gray-500'],
                        fontSize: '12px',
                        fontFamily: KTAppSettings['font-family']
                    }
                },

            },
            yaxis: {
                min: minValue * 0.9,
                max: maxValue * 1.1,
                labels: {
                    style: {
                        colors: KTAppSettings['colors']['gray']['gray-500'],
                        fontSize: '12px',
                        fontFamily: KTAppSettings['font-family']
                    }
                }
            },
            fill: {
                opacity: 10
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                    fontFamily: KTAppSettings['font-family']
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            colors: [KTAppSettings['colors']['theme']['base']['primary']],
            grid: {
                borderColor: KTAppSettings['colors']['gray']['gray-200'],
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            }
        };

        const chart = new ApexCharts($element[0], options);
        await chart.render();
    }
    ,

    onClickRoomNameRow(event) {
        const $clicked = $(event.currentTarget);
        const roomId = $clicked.closest('.j-rake-row-template').attr('data-room-id');
        const $tab = this.find('#' + roomId + '-tab');

        window.scrollTo({top: 0, behavior: 'smooth'});
        $tab.click();
    }
    ,

    initRoomMetrics($paneSelected) {
        const roomId = $paneSelected.find('.j-room-charts').attr('data-room-id');

        if (_.isEmpty(roomId))
            return;

        const route = Router.generate('room-rake-metrics', {'roomId': roomId});
        const dates = {
            startDate: this.startDate,
            endDate: this.endDate,
        }

        $.get(route, dates)
            .done((data) => {
                data = JSON.parse(data);

                $paneSelected.find('.j-statistics-generated-rake').text(data['generatedRake'] + ' €');
                $paneSelected.find('.j-statistics-user-rakeback').text(data['userRakeback']);
                $paneSelected.find('.j-earnings-from-refereds').text(data['earningsFromRefereds']);
                $paneSelected.find('.j-last-rake-update').text(data['lastRakeUpdate']);
                $paneSelected.find('.j-last-distribution-date').text(data['lastDistributionDate']);
                $paneSelected.find('.j-user-room-credentials-nick').text(data['nick']);
                $paneSelected.find('.j-user-room-credentials-login').text(data['login']);
            })
    },

    async onChangeDates(event) {
        const $target = $(event.currentTarget);
        const renderedChart = $target.closest('.j-my-rakes-pane');

        this.updateAllDates($target);
        this.changeDateXAxisAreaChart();
        await this.getRakeHistoriesData(renderedChart);
        this.initRoomMetrics(renderedChart);
        this.renderAreaChart(renderedChart);
    },

    updateAllDates($target) {
        let dateSelector = '';
        const dateAsString = $target.val();
        const dateValue = dateFromString(dateAsString, 'd/m/yyyy');
        const targetIsStartDate = $target.hasClass('j-data-start-date-area-chart')

        if (targetIsStartDate) {
            this.startDate = dateAsString;
            dateSelector = '.j-data-start-date-area-chart';

        } else {
            this.endDate = dateAsString;
            dateSelector = '.j-data-end-date-area-chart';
        }

        this.find(dateSelector).datepicker('update', dateValue);
    },

    changeDateXAxisAreaChart() {
        const startDate = dateFromString(this.startDate, 'd/m/Y');
        const endDate = dateFromString(this.endDate, 'd/m/Y');

        this.datesXAxisAreaChart = convertDatesToYearMonthArray(startDate, endDate);
    }
})