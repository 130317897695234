import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {Router} from "../../../shared/routing/router";

export const AdminRoomDetailsPage = ElView.extend({
    el: '.j-admin-room-details-page',
    events: {
        'click .add-new-RakebackRange': 'onAddNewRakebackRange',
        'click .remove-RakebackRange': 'onRemoveRakebackRange',
        'click .j-save-room': 'onSaveRoom',
        'click .j-click-remove-room': 'onClickRemoveRoom',
        'click .j-remove-origin-url': 'onClickRemoveOriginUrl',
        'select2:select .j-create-or-update-room-form-locale-input': 'onAddLocaleInput',
        'select2:unselect .j-create-or-update-room-form-locale-input': 'onRemoveLocaleInput',
    },
    data: null,
    imageId: null,
    locales: ['ES', 'GB', 'intl'],

    init() {
        this.initFileUploader();
        this.initOrderPrioritiesForm();
    },

    initFileUploader: function () {
        this.$data = this.find('.j-data');
        this.imageId = this.$data.attr('data-image-id');

        this.initImageUploader();
    },

    initOrderPrioritiesForm: function () {
        const localeInputs = this.find('.j-create-or-update-room-form-locale-input').select().val();
        localeInputs.forEach((element) => {
            this.showOrderPriority(element);
        });
    },

    onAddLocaleInput() {
        const localeInputs = this.find('.j-create-or-update-room-form-locale-input').select().val();
        localeInputs.forEach((element) => {
            this.showOrderPriority(element);
        });
    },

    onRemoveLocaleInput() {
        const localeInputs = this.find('.j-create-or-update-room-form-locale-input').select().val();
        const difference = this.locales.filter(x => localeInputs.indexOf(x) === -1);
        difference.forEach((element) => {
            this.hideOrderPriority(element);
        });
    },

    hideOrderPriority($element) {
        if ($element === 'intl') {
            this.find('.j-orderPriorityIntl').addClass('d-none');
        }
        if ($element === 'ES') {
            this.find('.j-orderPriorityEs').addClass('d-none');
        }
        if ($element === 'GB') {
            this.find('.j-orderPriorityGb').addClass('d-none');
        }
    },

    showOrderPriority($element) {
        if ($element === 'intl') {
            this.find('.j-orderPriorityIntl').removeClass('d-none');
        }
        if ($element === 'ES') {
            this.find('.j-orderPriorityEs').removeClass('d-none');
        }
        if ($element === 'GB') {
            this.find('.j-orderPriorityGb').removeClass('d-none');
        }
    },

    initImageUploader() {
        const _this = this;
        const SpanishUppyLocale = require('@uppy/locales/lib/es_ES');
        const EnglishUppyLocale = require('@uppy/locales/lib/en_US');
        const XHRUpload = require('@uppy/xhr-upload');

        const StatusBar = Uppy.StatusBar;
        const FileInput = Uppy.FileInput;
        const Informer = Uppy.Informer;

        const elemId = 'j-image-uploader';
        this.productImageUploaderId = `#${elemId}`;
        const id = `#${elemId}`;
        const $statusBar = $(id + ' .uppy-status');
        const $uploadedList = $(id + ' .uppy-list');

        let locale = EnglishUppyLocale;
        if (Translator.isCurrentLanguageSpanish())
            locale = SpanishUppyLocale;

        this.uppyPlugin = Uppy.Core({
            locale: locale,
            debug: true,
            autoProceed: true,
            showProgressDetails: true,
            restrictions: {
                maxFileSize: 5000000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
        });

        this.uppyPlugin.use(XHRUpload, {
            endpoint: this.route('core-api-upload-image'),
            responseUrlFieldName: 'fullUrl'
        })
        this.uppyPlugin.use(FileInput, {target: id + ' .uppy-wrapper', pretty: false});
        this.uppyPlugin.use(Informer, {target: id + ' .uppy-informer'});

        this.uppyPlugin.use(StatusBar, {
            target: id + ' .uppy-status',
            hideUploadButton: true,
            hideAfterFinish: false
        });

        $(id + ' .uppy-FileInput-input').addClass('uppy-input-control d-none').attr('id', elemId + '_input_control');
        $(id + ' .uppy-FileInput-container').append(
            `<label class="uppy-input-label btn btn-light-primary btn-sm btn-bold" for="${elemId}_input_control"><i class="fas fa-paperclip mr-2"></i>${capitalizeFirstLetter(
                Translator.trans('upload-images'))}</label>`);

        const $fileLabel = $(id + ' .uppy-input-label');

        this.uppyPlugin.on('upload', function (data) {
            $fileLabel.text("Uploading...");
            $statusBar.addClass('uppy-status-ongoing');
            $statusBar.removeClass('uppy-status-hidden');
        });

        this.uppyPlugin.on('complete', (file) => {
            $.each(file.successful, (index, value) => {
                let sizeLabel = "bytes";
                let filesize = value.size;
                if (filesize > 1024) {
                    filesize = filesize / 1024;
                    sizeLabel = "kb";

                    if (filesize > 1024) {
                        filesize = filesize / 1024;
                        sizeLabel = "MB";
                    }
                }

                if (this.imageId === '') {
                    this.imageId = value.response.body.fileId;
                }

                const uploadListHtml = `
                <div class="col-md-4 j-room-image-col" data-image-id="${value.response.body.fileId}">
                    <div class="uppy-list-item d-flex flex-column" data-id="${value.id}" data-image-id="${value.response.body.fileId}">
                        <a class="mt-2" href="${value.response.uploadURL}">
                            <img src="${value.response.uploadURL}" class="image-fluid" style="height: 180px!important; max-width: 100%">
                        </a>
                        <a href="${value.response.uploadURL}" class="uppy-list-label mt-2 a-hover-color-force">${value.name}(${Math.round(
                    filesize, 2)}${sizeLabel})</a>
                    </div>
                </div>
                `;
                $uploadedList.empty();
                $uploadedList.append(uploadListHtml);
                _this.imageId = _this.find('.j-room-image-col').attr('data-image-id');
            });

            $fileLabel.html(`<i class='fas fa-paperclip mr-2'></i>${capitalizeFirstLetter(
                Translator.trans('add-more-images'))}`);
            $statusBar.addClass('uppy-status-hidden');
            $statusBar.removeClass('uppy-status-ongoing');

            this.undelegateEvents();
            this.delegateEvents();
        });

    },

    onSaveRoom() {
        const _this = this;
        const $formImage = $(this.el).find('.j-room-image-col').attr('data-image-id');
        let $rakeForm = this.find('.j-rakebacks-form-container');
        let $rakebackRows = $rakeForm.find('.j-rakeback-form-row');

        // if (_.isEmpty(this.find('.j-add-room-to-room-group-form-room-group-id-input').val())) {
        //     Swal.fire('Asigne la sala a una red', '', "error");
        //     return;
        // }

        if ($rakebackRows.length === 0) {
            Swal.fire('Defina los rangos de rakeback', '', "error");
            return;
        }

        if (_.isNull(this.imageId) || _.isEmpty(this.imageId)) {
            Swal.fire('Suba una imagen', '', "error");
            return;
        }

        if (_.isEmpty(this.find('.j-update-room-form-name-input').val())) {
            Swal.fire('Rellene el nombre de la sala', '', "error");
            return;
        }

        if (!this.formIsValid()) {
            Swal.fire(this.translate('errors.invalid.ranges'), this.translate('errors.detected'), 'error');
            return;
        }

        this.enableRakebackInputs();
        this.removeRakebackPrototype();

        this.find('.j-image-id-input').val(this.imageId);

        const roomId = this.find('.j-data').attr('data-room-id');
        const route = Router.generate('admin-room-details', {roomId});
        this.find('.j-update-room-form').submit(
            // $.post(route, _this.find('.j-update-room-form').serialize())
            //     .always((response) => {
            // location.reload();
            // }
            // )
        );
    },

    formIsValid() {
        const $formRows = this.find('.j-rakeback-form-row');

        let prevTo = '0';
        let isValid = true;
        $formRows.each((rowIndex, htmlElement) => {
            const $row = $(htmlElement);
            const from = $row.find('.j-form-value-from').val();
            const to = $row.find('.j-form-value-to').val();

            if (to !== '∞' && from !== '0')
                if (parseInt(from) > parseInt(to) || parseInt(prevTo) + 1 !== parseInt(from)) {
                    isValid = false;
                }

            prevTo = to;
        });

        return isValid;
    },

    onAddNewRakebackRange: function (event) {
        const $template = this.find('.j-rakeback-form-row-template');
        let $rakebacksContainer = this.find('.j-rakebacks-form-container');
        const $rakebackRows = this.find('.j-rakeback-form-row');

        const newRowIndex = $rakebackRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-form-row-template');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-rakeback-form-row');

        $rakebacksContainer.append($newRowForm);

        if (newRowIndex === 0) {
            $newRowForm.find('.j-form-value-from').val(0);
            $newRowForm.find('.j-form-value-to').val('∞');
            $newRowForm.find('.j-form-value-multiplier').val(1);

        } else {
            const $lastRowForm = $newRowForm.prev();
            const $lastRowFormFrom = $lastRowForm.find('.j-form-value-from');
            const $lastRowFormTo = $lastRowForm.find('.j-form-value-to');
            $lastRowFormTo.val(parseInt($lastRowFormFrom.val()) + 1);

            $newRowForm.find('.j-form-value-from').val(parseInt($lastRowFormFrom.val()) + 2);
            $newRowForm.find('.j-form-value-to').val('∞');
            $newRowForm.find('.j-form-value-to').prop('disabled', true);
            $newRowForm.find('.j-form-value-multiplier').val(1);
        }

        $newRowForm.find('.j-form-value-userId').val('all');
        $newRowForm.find('.j-form-value-roomId').val(this.find('.j-data').attr('data-room-id'));

        $newRowForm.fadeIn();

        this.disableRakebackInputs();
    },

    onRemoveRakebackRange: function (event) {
        const $clicked = $(event.currentTarget);

        const $formRow = $clicked.closest('.j-rakeback-form-row');
        const $prevRow = $formRow.prev();
        const $nextRow = $formRow.next();

        if ($prevRow.length === 0) {

            if ($nextRow.find('.j-form-value-to').val() !== '∞') {
                const fromValue = $nextRow.next().find('.j-form-value-from').val();
                $nextRow.find('.j-form-value-to').val(fromValue - 1);
            }

            $nextRow.find('.j-form-value-from').val(0);

        } else if ($nextRow.length === 0) {
            $prevRow.find('.j-form-value-to').val('∞');

        } else {
            const prevRowTo = parseInt($prevRow.find('.j-form-value-to').val()) + 1;

            $nextRow.find('.j-form-value-from').val(prevRowTo);
        }

        $formRow.fadeOut();
        $formRow.remove();

        this.disableRakebackInputs();
    },

    disableRakebackInputs() {

        this.find('.j-rakebacks-form-container').find('.j-form-value-from').each((index, element) => {
            if ($(element).val() === '0') {
                $(element).prop('disabled', true);
            } else
                $(element).prop('disabled', false);
        })

        this.find('.j-rakebacks-form-container').find('.j-form-value-to').each((index, element) => {
            if ($(element).val() === '∞') {
                $(element).prop('disabled', true);
            } else
                $(element).prop('disabled', false);
        })
    },

    enableRakebackInputs() {

        this.find('.j-rakebacks-form-container').find('.j-form-value-from').each((index, element) => {
            if ($(element).val() === '0')
                $(element).prop('disabled', false);
        })

        this.find('.j-rakebacks-form-container').find('.j-form-value-to').each((index, element) => {
            if ($(element).val() === '∞')
                $(element).prop('disabled', false);
        })
    },

    removeRakebackPrototype() {
        this.find('.j-rakeback-form-row-template').remove();
    },

    onClickRemoveRoom(event) {
        const $clicked = $(event.currentTarget);
        const roomId = $clicked.attr('data-room-id');

        const route = this.route('core-api-remove-room', {roomId: roomId});

        swal.fire({
            title: 'Va a eliminar la sala',
            text: '¿Está seguro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar'

        }).then(function (result) {
            if (result.value) {

                // Swal.fire(
                //     "Eliminada!",
                //     "La sala ha sido eliminada",
                //     "success"
                // ).then(function (result) {
                //     $.post(route)
                //         .always(() => {
                //             const redirectRoute = Router.generate('admin-rooms');
                //             location.replace(redirectRoute);
                //         });
                // })

                $.post(route)
                    .done(function (data) {
                        Swal.fire(
                            "Eliminada!",
                            "La sala ha sido eliminada",
                            "success"
                        ).then(function (result) {
                            const redirectRoute = Router.generate('admin-rooms');
                            location.replace(redirectRoute);
                        })
                    })
            }
        });
    },

    onClickRemoveOriginUrl(event) {
        const $formRowContainer = $(event.currentTarget).closest('.j-origin-urls-form-row');
        $formRowContainer.find('.j-room-origin-url-form-url-input').val('');
    }
});