import {ElView} from "../../shared/el-view";
import {Translator} from '../../shared/translation/translator';
import {Router} from '../../shared/routing/router';
import {LocaleManager} from '../../shared/locale/localeManager';


export const AuthPage = ElView.extend({
    el: '#j-auth-view',
    events: {
        'click #login_forgot': 'showLoginForgot',
        'click #login_forgot_cancel': 'showLoginSignIn',
        'click #login_signup_cancel': 'showLoginSignIn',
        'click #login_signup': 'showLoginSignUp',
        'click #acceptHabwinContact': 'contactCheckbox',
    },
    buttonSpinnerClasses: 'spinner spinner-right spinner-white pr-15',
    recaptchaKey: '',
    init: function () {
        this.handleFormSignin();

        this.handleFormForgot();
        this.handleFormSignup();
        const action = $(this.el).attr('data-action');

        this.recaptchaKey = $(this.el).attr('data-recaptcha');
        if (action === 'register')
            this.find('#login_signup').click();

        Particles.init({
            connectParticles: true,
            selector:
                '.background-particles'
        });

    },

    showLoginForgot: function () {
        this.showForm('forgot');
    },

    showLoginSignIn: function () {
        this.showForm('signin');
    },

    showLoginSignUp: function () {
        this.showForm('signup');
    },

    handleFormSignin: function () {
        const _this = this;
        const form = KTUtil.getById('login_signin_form');
        KTUtil.attr(form, 'action');
        if (!form)
            return;

        FormValidation.formValidation(form,
            {
                locale: LocaleManager.currentLocale(),
                localization: FormValidation.locales[LocaleManager.currentLocale()],
                fields: {
                    email: {
                        validators: {
                            notEmpty: {},
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {}
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    submitButton: new FormValidation.plugins.SubmitButton(),
                    bootstrap: new FormValidation.plugins.Bootstrap({}),
                    l10n: new FormValidation.plugins.L10n({
                        email: {
                            notEmpty: {
                                es_ES: 'El campo es obligatorio',
                                en_US: 'Field is required',
                            },
                        },
                        password: {
                            notEmpty: {
                                es_ES: 'El campo contraseña es obligatorio',
                                en_US: 'Password is required',
                            },
                        },
                    })
                }
            }
        )
            .on('core.form.invalid', function () {
                Swal.fire({
                    text: Translator.trans('errors.detected'),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: Translator.trans('ok.gotit'),
                    customClass: {
                        confirmButton: "btn font-weight-bold btn-light-primary"
                    }
                }).then(function () {
                    KTUtil.scrollTop();
                });
            })
            .on('core.form.valid', function () {
                $.ajax({
                    type: "POST",
                    url: _this.route('login.' + Translator.currentLanguage()),
                    data: $(form).serialize(),
                })
                    .done((response) => {
                        location = Router.generate('login-success');
                    })
                    .fail((response) => {
                        if (response.responseText === 'activate-account')
                            Swal.fire({
                                text: Translator.trans(response.responseText),
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: Translator.trans('resend-confirmation-email'),
                                cancelButtonText: Translator.trans('cancel'),
                                customClass: {
                                    confirmButton: "btn font-weight-bold btn-light-primary"
                                }
                            }).then(function () {
                                _this.resendConfirmationEmail(form);
                                KTUtil.scrollTop();
                            });
                        else
                            Swal.fire({
                                text: Translator.trans(response.responseText),
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: Translator.trans('ok.gotit'),
                                customClass: {
                                    confirmButton: "btn font-weight-bold btn-light-primary"
                                }
                            }).then(function () {
                                KTUtil.scrollTop();
                            });
                    });
            });
    },

    handleFormForgot: function () {
        const _this = this;
        const form = KTUtil.getById('login_forgot_form');
        KTUtil.attr(form, 'action');
        if (!form)
            return;

        FormValidation.formValidation(form,
            {
                locale: LocaleManager.currentLocale(),
                localization: FormValidation.locales[LocaleManager.currentLocale()],
                fields: {
                    email: {
                        validators: {
                            notEmpty: {},
                            emailAddress: {}
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    submitButton: new FormValidation.plugins.SubmitButton(),
                    bootstrap: new FormValidation.plugins.Bootstrap({}),
                    l10n: new FormValidation.plugins.L10n({
                        email: {
                            notEmpty: {
                                es_ES: 'El campo email es obligatorio',
                                en_US: 'Email is required',
                            },
                            emailAddress: {
                                es_ES: 'Debes introducir un email válido',
                                en_US: 'The value is not a valid email address',
                            },
                        },
                    })
                }
            }
        )
            .on('core.form.invalid', function () {
                Swal.fire({
                    text: Translator.trans('errors.detected'),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: Translator.trans('ok.gotit'),
                    customClass: {
                        confirmButton: "btn font-weight-bold btn-light-primary"
                    }
                }).then(function () {
                    KTUtil.scrollTop();
                });
            })
            .on('core.form.valid', function () {
                $.ajax({
                    type: "POST",
                    url: _this.route('login.' + Translator.currentLanguage()),
                    data: $(form).serialize(),
                })
                    .done(() => {
                        Swal.fire({
                            text: Translator.trans('email.sent'),
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: Translator.trans('ok.gotit'),
                            customClass: {
                                confirmButton: "btn font-weight-bold btn-light-primary"
                            }
                        }).then(function () {
                            KTUtil.scrollTop();
                        });
                    })
                    .fail(() => {
                        Swal.fire({
                            text: Translator.trans('errors.invalid.email'),
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: Translator.trans('ok.gotit'),
                            customClass: {
                                confirmButton: "btn font-weight-bold btn-light-primary"
                            }
                        }).then(function () {
                            KTUtil.scrollTop();
                        });
                    });
            });
    },

    handleFormSignup: function () {
        const _this = this;
        const form = KTUtil.getById('login_signup_form');


        if (!form)
            return;

        this.find('#dateOfBirth_day').select2({
            minimumResultsForSearch: -1,
        });
        this.find('#dateOfBirth_month').select2({
            minimumResultsForSearch: -1,
        });
        this.find('#dateOfBirth_year').select2({
            minimumResultsForSearch: -1,
        });

        const locale = Translator.currentLanguage();
        const params = new URLSearchParams(window.location.search);
        const roomSlug = params.get("room");

        let url = _this.route('auth-register');
        if (roomSlug)
            url += "?room_slug=" + roomSlug
        FormValidation.formValidation(form,
            {
                locale: locale,
                localization: FormValidation.locales[locale],
                fields: this.setSignUpFields(locale),
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    submitButton: new FormValidation.plugins.SubmitButton(),
                    bootstrap: new FormValidation.plugins.Bootstrap({}),
                    l10n: new FormValidation.plugins.L10n(this.setSignUpPlugins(locale))
                }
            })
            .on('core.form.invalid', function () {
                Swal.fire({
                    text: Translator.trans('errors.detected'),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: Translator.trans('ok.gotit'),
                    customClass: {
                        confirmButton: "btn font-weight-bold btn-light-primary"
                    }
                }).then(function () {
                    KTUtil.scrollTop();
                });
            })
            .on('core.form.valid', () => {

                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(this.recaptchaKey, {action: 'submit'}).then((token) => {
                        $('.j-recaptcha').val(token);

                        $.ajax({
                            type: "POST",
                            url: url,
                            data: $(form).serialize(),
                        })
                            .done(() => {
                                Swal.fire({
                                    text: Translator.trans('successful.register'),
                                    icon: "success",
                                    buttonsStyling: false,
                                    confirmButtonText: Translator.trans('ok.gotit'),
                                    customClass: {
                                        confirmButton: "btn font-weight-bold btn-light-primary"
                                    }
                                }).then(() => {
                                    window.location = _this.route('login-success');
                                });

                                try {
                                    if (dataLayer) {
                                        dataLayer.push({
                                            'event': 'RegistroEnHabwin',
                                            'nick': $("form input[name=username]").val()
                                        });
                                    }
                                } catch (e) {

                                }


                            })
                            .fail((data) => {
                                if (data.status === 403 && data.responseText === 'Email already exists') {
                                    Swal.fire({
                                        text: Translator.trans('errors.email.use'),
                                        icon: "error",
                                        buttonsStyling: false,
                                        confirmButtonText: Translator.trans('ok.gotit'),
                                        customClass: {
                                            confirmButton: "btn font-weight-bold btn-light-primary"
                                        }
                                    }).then(function () {
                                        KTUtil.scrollTop();
                                    });
                                    return;
                                }
                                if (data.status === 403 && data.responseText === 'User telephone contact data is not correct') {
                                    Swal.fire({
                                        text: Translator.trans('errors.invalid.contact'),
                                        icon: "error",
                                        buttonsStyling: false,
                                        confirmButtonText: Translator.trans('ok.gotit'),
                                        customClass: {
                                            confirmButton: "btn font-weight-bold btn-light-primary"
                                        }
                                    });
                                    return;
                                }
                                if (data.status === 404) {
                                    Swal.fire({
                                        text: Translator.trans('errors.user.use'),
                                        icon: "error",
                                        buttonsStyling: false,
                                        confirmButtonText: Translator.trans('ok.gotit'),
                                        customClass: {
                                            confirmButton: "btn font-weight-bold btn-light-primary"
                                        }
                                    }).then(function () {
                                        KTUtil.scrollTop();
                                    });
                                    return;
                                }
                                Swal.fire({
                                    text: Translator.trans('errors.generic'),
                                    icon: "error",
                                    buttonsStyling: false,
                                    confirmButtonText: Translator.trans('ok.gotit'),
                                    customClass: {
                                        confirmButton: "btn font-weight-bold btn-light-primary"
                                    }
                                }).then(function () {
                                    KTUtil.scrollTop();
                                });
                            });
                    });
                });


            });

    },

    setSignUpFields: function (locale) {
        let fields = {
            name: {
                validators: {
                    notEmpty: {}
                }
            },
            surname: {
                validators: {
                    notEmpty: {}
                }
            },
            email: {
                validators: {
                    notEmpty: {},
                    emailAddress: {}
                }
            },
            username: {
                validators: {
                    notEmpty: {},
                }
            },
            'plainPassword[first]': {
                validators: {
                    notEmpty: {}
                }
            },
            'plainPassword[second]': {
                validators: {
                    notEmpty: {},
                    identical: {
                        compare: function () {
                            return form.querySelector('[name="plainPassword[first]"]').value;
                        },
                    }
                }
            },
            acceptTerms: {
                validators: {
                    notEmpty: {}
                }
            },
        }
        if (locale === 'es_ES') {
            const _this = this;
            fields = {
                ...fields,
                dni: {
                    validators: {
                        notEmpty: {}
                    }
                },
                dateOfBirth: {
                    validators: {
                        callback:
                            {
                                callback: function () {

                                    const day = _this.find('#dateOfBirth_day').val();
                                    const month = _this.find('#dateOfBirth_month').val();
                                    const year = _this.find('#dateOfBirth_year').val();

                                    const dateOfBirth = new Date(year, month - 1, day);
                                    const today = new Date();
                                    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(),
                                        today.getDate());

                                    return eighteenYearsAgo >= dateOfBirth;
                                }
                            }
                    }
                },
            }
        }

        return fields;
    },

    setSignUpPlugins: function (locale) {
        let fields = {
            email: {
                notEmpty: {
                    es_ES: 'El campo email es obligatorio',
                    en_US: 'Email is required',
                },
                emailAddress: {
                    es_ES: 'Debes introducir un email válido',
                    en_US: 'The value is not a valid email address',
                },
            },
            username: {
                notEmpty: {
                    es_ES: 'El campo nombre de usuario es obligatorio',
                    en_US: 'Username is required',
                },
            },
            name: {
                notEmpty: {
                    es_ES: 'El campo nombre es obligatorio',
                    en_US: 'Name field is required',
                },
            },
            surname: {
                notEmpty: {
                    es_ES: 'El campo apellido es obligatorio',
                    en_US: 'Surname field is required',
                },
            },
            'plainPassword[first]': {
                notEmpty: {
                    es_ES: 'El campo contraseña es obligatorio',
                    en_US: 'Password is required',
                },
            },
            'plainPassword[second]': {
                notEmpty: {
                    es_ES: 'El campo contraseña es obligatorio',
                    en_US: 'Password is required',
                },
                identical: {
                    es_ES: 'Las contraseñas deben ser iguales',
                    en_US: 'Both passwords must be equal',
                }
            },
            acceptTerms: {
                notEmpty: {
                    es_ES: 'Debes aceptar los términos y condiciones de uso',
                    en_US: 'Terms and conditions must be accepted',
                },
            }

        }
        if (locale === 'es_ES') {
            fields = {
                ...fields,
                dni: {
                    notEmpty: {
                        es_ES: 'El campo dni es obligatorio',
                        en_US: '',
                    },
                },
                dateOfBirth: {
                    callback: {
                        es_ES: 'Debes ser mayor de edad para registrarte',
                        en_US: '',
                    },
                },
            }
        }

        return fields;
    },

    showForm: function (form) {
        const cls = 'login-' + form + '-on';
        form = 'login_' + form + '_form';

        this.$el.removeClass('login-forgot-on');
        this.$el.removeClass('login-signin-on');
        this.$el.removeClass('login-signup-on');

        this.$el.addClass(cls);

        KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
    },

    contactCheckbox: function (event) {

        if (event.currentTarget.checked) {
            $('#contact-form').fadeIn(() => {
                $("html, body").animate({scrollTop: $(document).height()}, 1000);
            });

        } else {
            $('#contact-form').fadeOut();

        }
    },

    async resendConfirmationEmail(form) {
        const email = $(form).find('.j-login-email').val();

        const route = this.route('send-account-confirmation-email', {email})

        try {
            await $.post(route);

        } catch (exception) {
            if (exception.responseText === 'email-not-found')
                this.confirmationError('Error', 'email-not-found')
        }
    }
});