import {ElView} from "../../shared/el-view";

export const AdminHeaderView = ElView.extend({
    el: '.j-admin-header',
    events: {
        'mouseenter .j-header-menu-option': 'onHoverMenuOption',
        'mouseleave': 'onMouseLeaveHeader'
    },
    init: function () {
        this.$currentMenuOption = this.find('.j-header-menu-option.inverted-corner');
    },

    showMenuOption: function ($menuOption) {
        let $menuOptions = this.find('.j-header-menu-option');

        $menuOptions.removeClass('inverted-corner');
        $menuOptions.find('a').removeClass('active');
        $menuOptions.find('a').removeClass('header__nav-link--selected');
        $menuOption.addClass('inverted-corner');
        $menuOption.find('a').addClass('active');
        $menuOption.find('a').addClass('header__nav-link--selected');

        const menuIndex = $menuOption.index();
        let $subheaderTabs = this.find('.j-subheader-tabs .tab-pane');
        $subheaderTabs.removeClass('active');
        $subheaderTabs.each((index, element) => {
            if (index === menuIndex) {
                    $(element).addClass('active');
            }
        })
    },

    onHoverMenuOption(event) {
        const $currentTarget = $(event.currentTarget);
        this.showMenuOption($currentTarget);
    },

    onMouseLeaveHeader() {
        this.showMenuOption(this.$currentMenuOption);
    }
});