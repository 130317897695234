import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {trans_en} from "./en";
import {trans_es} from "./es";

export class Translator {
    static currentLanguage() {
        return i18next.language;
    }

    static currentLanguageCode() {
        if (this.isCurrentLanguageEnglish())
            return 'en_US';
        if (this.isCurrentLanguageSpanish())
            return 'es_ES';
        return '';
    }

    static currentLanguageShortCode() {
        if (this.isCurrentLanguageEnglish())
            return 'en';
        if (this.isCurrentLanguageSpanish())
            return 'es';
        return '';
    }

    static isCurrentLanguageSpanish() {
        return this.currentLanguage().includes('es');
    }

    static isCurrentLanguageEnglish() {
        return this.currentLanguage().includes('en');
    }

    static trans(key) {
        return i18next.t(key);
    }

    init() {
        i18next.use(LanguageDetector).init({
            debug: false,
            detection: {
                order: ['htmlTag'],
            },
            caches: [],
            resources: {
                en: {
                    translation: trans_en
                },
                en_ES: {
                    translation: trans_en
                },
                en_GB: {
                    translation: trans_en
                },
                es: {
                    translation: trans_es
                },
                es_ES: {
                    translation: trans_es
                },
                es_GB: {
                    translation: trans_es
                },
            },
        });
    }
}
