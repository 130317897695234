import {ElView} from "../shared/el-view";
import {Router} from "../shared/routing/router";
import {Translator} from "../shared/translation/translator";

export const CorporateHabstorePage = ElView.extend({
    el: '.j-corporate-habstore',
    events: {
        'mouseup .j-parent-category-container': 'onClickParentCategory',
        'mouseup .j-subcategory-container': 'onClickSubcategory',
        'click .j-parent-category-container': 'onClickParentCategoryPreventDefault',
        'click .j-subcategory-container': 'onClickSubcategoryPreventDefault',
        'select2:select .j-select-sort-by': 'onSelectSortBy',
        'click .j-add-default-product-to-cart-button': 'onClickAddDefaultProductToCart',
        'click .j-category': 'onClickCategoryImage',
        'click .j-subcategory': 'onClickSubcategoryImage',
        'click .j-search-button': 'onSearchButtonPressed',
        'keydown #search-input': 'onKeyPressedInSearchInput'
    },

    falsifiedMaxPrice: 60000,
    minPrice: 0,
    maxPrice: -1,
    categories: [],
    page: 1,
    elementsPerPage: 12,
    allItemsLoadedForFilters: false,
    sortBy: 'createdAt',
    sortingMethod: 'desc',
    infinitePrice: -1,
    search: "",


    init() {
        this.maxPrice = this.falsifiedMaxPrice;
        $('.j-content-container').css('minHeight', 2000 + 'px');

        const _this = this;
        $('#j-price-slider').ionRangeSlider({
            type: "double",
            min: 0,
            max: _this.falsifiedMaxPrice,
            from: 0,
            to: _this.falsifiedMaxPrice,
            prefix: "Habcoins",
            prettify_enabled: true,
            skin: "round",
            onFinish: function (data) {
                _this.minPrice = data.from;
                _this.maxPrice = data.to;
                _this.onRetrieveFilteredProducts();

                _this.synchronizePriceFilterLabels();
            },
        });

        this.onRetrieveFilteredProducts = _.debounce(this.retrieveFilteredProducts, 300);
        this.onScrolledToBotton = _.debounce(this.onRetrieveMoreProducts, 300);

        this.onRetrieveFilteredProducts();

        this.onChangeFilters();

        this.synchronizePriceFilterLabels();

        $(window).scroll(() => {
            if ($(window).scrollTop() + $(window).height() >= $(document).height() * 0.90)
                _this.onScrolledToBotton();
        });
    },

    synchronizePriceFilterLabels() {
        const $minPriceLabel = this.find('.j-min-price');
        const $maxPriceLabel = this.find('.j-max-price');
        $minPriceLabel.text(this.minPrice + ' Habcoins');
        const $maxPriceLabelText = this.maxPrice < this.falsifiedMaxPrice ?
            this.maxPrice + ' Habcoins' : '+' + this.falsifiedMaxPrice + ' Habcoins'
        $maxPriceLabel.text($maxPriceLabelText);
    },

    onRetrieveFilteredProducts() {

    },

    onScrolledToBotton() {
    },

    onRetrieveMoreProducts() {
        if (this.allItemsLoadedForFilters)
            return;

        const _this = this;
        const route = Router.generate('product-filter');
        const $itemsPlacement = $(_this.el).find('.j-items');

        const loader = `<div class="col-md-12 mt-8 j-loader" style="text-align: center; display: none">
                            <div class="lds-ring">
                                <div></div><div></div><div></div><div></div>
                            </div>
                        </div>`;

        $itemsPlacement.append(loader);
        const $loader = $itemsPlacement.find('.j-loader');
        $loader.slideDown();

        this.page += 1;

        const $maxPriceSelected = this.maxPrice < this.falsifiedMaxPrice ? this.maxPrice : this.infinitePrice;
        setTimeout(() => {
            $.ajax({
                type: 'get',
                url: route,
                data: {
                    categoriesIds: _this.categories,
                    minPrice: _this.minPrice,
                    maxPrice: $maxPriceSelected,
                    page: _this.page,
                    elementsPerPage: _this.elementsPerPage,
                    sortBy: _this.sortBy,
                    sortingMethod: _this.sortingMethod,
                    search: _this.search,
                },
                success: (data) => {
                    $loader.slideUp();

                    if (data !== '') {
                        $itemsPlacement.append(data);
                        const $items = $itemsPlacement.find('.j-item-col');

                        $loader.remove();
                        $items.fadeIn();

                    } else {
                        _this.retrieveFilteredProducts = false;

                        $loader.remove();
                    }
                }
            })
        }, 500)
    },

    onChangeFilters() {
        const _this = this;
        this.categories = [];
        const $parents = this.find('.j-parent-category-checkbox');
        let allParentsAreUnchecked = true;
        let parentIds = [];
        let childIds = [];

        $parents.each((index, parent) => {
            const parentId = $(parent).attr('data-id');

            if ($(parent).is(':checked')) {

                allParentsAreUnchecked = false;
                const $childs = $(parent).closest('.j-category-container').find('.j-subcategory-checkbox');
                if ($childs.length === 0)
                    childIds.push(parentId);

                $childs.each((index, child) => {
                    if ($(child).is(':checked')) {
                        const childId = $(child).attr('data-id');
                        childIds.push(childId);
                    }
                })
            } else {
                _this.categories.push(parentId);
            }
            parentIds.push(parentId);
        })

        _this.categories = childIds;
        if (allParentsAreUnchecked)
            _this.categories = parentIds;

        this.onRetrieveFilteredProducts();
    },

    retrieveFilteredProducts() {
        const _this = this;
        const route = Router.generate('product-filter');
        const $itemsPlacement = $(_this.el).find('.j-items');

        const loader = `<div class="col-md-12 mt-12 j-loader" style="text-align: center; display: none">
                            <div class="lds-ring">
                                <div></div><div></div><div></div><div></div>
                            </div>
                        </div>`;

        $itemsPlacement.fadeOut();
        $itemsPlacement.empty();
        $itemsPlacement.append(loader);
        const $loader = $itemsPlacement.find('.j-loader');
        $loader.fadeIn();

        this.page = 1;
        this.allItemsLoadedForFilters = false;

        const $maxPriceSelected = this.maxPrice < this.falsifiedMaxPrice ? this.maxPrice : this.infinitePrice;
        setTimeout(() => {
            $.ajax({
                type: 'get',
                url: route,
                data: {
                    categoriesIds: _this.categories,
                    minPrice: _this.minPrice,
                    maxPrice: $maxPriceSelected,
                    page: _this.page,
                    elementsPerPage: _this.elementsPerPage,
                    sortBy: _this.sortBy,
                    sortingMethod: _this.sortingMethod,
                    search: _this.search,
                },
                success: function (data) {
                    $loader.fadeOut();

                    if (data !== '') {
                        $loader.fadeOut();

                        $itemsPlacement.append(data);
                    } else {
                        _this.allItemsLoadedForFilters = true;

                        const message = `<span class="j-item-col mt-8 text-muted" style="text-align: center; width: 100%; color: #B5B5C3; font-family: Poppins; font-size: 15px; font-style: normal; font-weight: 500; line-height: 17px; letter-spacing: 0em;"> 
                                            ${_this.translate('no-items-match-your-search')} 
                                         </span>`

                        $itemsPlacement.append(message);
                    }
                    const $items = $itemsPlacement.find('.j-item-col');

                    $itemsPlacement.fadeIn();
                    $loader.remove();
                    $items.fadeIn();
                }
            })
        }, 125);
    },

    onClickParentCategory(event) {
        const $target = $(event.currentTarget);
        const categoryId = $target.find('.j-parent-category-checkbox').attr('data-id');
        this.parentCategoryClicked(categoryId);
    },

    async onClickSubcategory(event) {
        const target = $(event.currentTarget);
        const subcategoryCheckbox = target.find('.j-subcategory-checkbox');
        const subcategoryIsChecked = subcategoryCheckbox.is(':checked');
        const categoryId = target.find('.j-subcategory-checkbox').attr('parent-id');
        if (subcategoryIsChecked) {
            target.find('.j-subcategory-checkbox').prop('checked', false);

            if (this.areAllSubcategoriesOfCategoryUnchecked(categoryId)) {
                this.uncheckAllSubcategoriesFromCategory(categoryId)
                this.uncheckParentCategory(categoryId);
                this.collapseCategoryById(categoryId);
            }
        } else {
            target.find('.j-subcategory-checkbox').prop('checked', true);
        }

        this.toggleCategoriesImages();
        this.toggleSubcategoriesImages();

        this.onChangeFilters();
    },

    onClickCategoryImage(event) {
        const categoryId = $(event.currentTarget).attr('data-id');
        this.parentCategoryClicked(categoryId);
    },

    onClickSubcategoryImage(event) {
        const categoryId = $(event.currentTarget).attr('data-parent-id');
        const subcategoryId = $(event.currentTarget).attr('data-id');
        this.uncheckAllSubcategoriesFromCategory(categoryId);
        this.checkSubcategoryById(subcategoryId);
        this.toggleCategoriesImages();
        this.toggleSubcategoriesImages();
        this.onChangeFilters();
    },

    parentCategoryClicked(categoryId) {
        const parentCheckbox = this.categoryCheckbox(categoryId);
        const parentIsChecked = parentCheckbox.is(':checked');
        if (parentIsChecked) {
            this.uncheckAllSubcategoriesFromCategory(categoryId);
            parentCheckbox.prop('checked', false);
            this.collapseCategoryById(categoryId);
        } else {
            this.checkAllSubcategories(categoryId);
            parentCheckbox.prop('checked', true);
            this.expandCategoryById(categoryId);
        }

        this.toggleCategoriesImages();
        this.toggleSubcategoriesImages();

        this.onChangeFilters();
    },

    uncheckParentCategory(categoryId) {
        const categoryCheckbox = this.categoryCheckbox(categoryId);
        categoryCheckbox.prop('checked', false);
    },

    checkAllSubcategories(categoryId) {
        const subcategoryCheckboxes = this.subcategoryCheckboxContainer(categoryId).find('.j-subcategory-checkbox');
        subcategoryCheckboxes.each((index, element) => {
            $(element).prop('checked', true);
        })
    },

    uncheckAllSubcategoriesFromCategory(categoryId) {
        const subcategoryCheckboxes = this.subcategoryCheckboxContainer(categoryId).find('.j-subcategory-checkbox');
        subcategoryCheckboxes.each((index, element) => {
            $(element).prop('checked', false);
        })
    },

    collapseCategoryById(categoryId) {
        this.subcategoryCheckboxContainer(categoryId).collapse('hide');
    },

    expandCategoryById(categoryId) {
        this.subcategoryCheckboxContainer(categoryId).collapse('show');
    },

    areAllCategoriesUnchecked() {
        const categoryCheckboxes = this.find('.j-parent-category-checkbox');
        let returnValue = true;
        categoryCheckboxes.each((index, checkbox) => {
            if ($(checkbox).is(':checked'))
                returnValue = false;
        });
        return returnValue;
    },

    areAllSubcategoriesOfCategoryUnchecked(categoryId) {
        const subcategoryCheckboxes = this.subcategoryCheckboxContainer(categoryId).find('.j-subcategory-checkbox');

        let returnValue = true;

        subcategoryCheckboxes.each((index, element) => {
            if ($(element).is(':checked'))
                returnValue = false;
        })

        return returnValue;
    },

    areOnlySubcategoriesOfCategoryChecked(categoryId) {
        const categoryCheckboxes = this.find('.j-parent-category-checkbox');
        let returnValue = $(this.categoryCheckboxContainer(categoryId).find('[type="checkbox"]')).is(':checked');

        categoryCheckboxes.each((index, checkbox) => {
            if ($(checkbox).is(':checked') && $(checkbox).attr('data-id') !== categoryId)
                returnValue = false;
        });
        return returnValue;
    },

    areAtLeastTwoSubcategoriesCheckedOfCategory(categoryId) {
        const subcategoryCheckboxes = this.subcategoryCheckboxContainer(categoryId).find('.j-subcategory-checkbox');
        let numSubcategoriesChecked = 0;

        subcategoryCheckboxes.each((index, element) => {
            if ($(element).is(':checked'))
                numSubcategoriesChecked++;
        });
        return numSubcategoriesChecked >= 2;
    },

    isSubcategoryChecked(subcategoryId) {
        return this.subcategoryCheckbox(subcategoryId).is(':checked');
    },

    onClickSubcategoryPreventDefault(event) {
        event.preventDefault();
    },

    onClickParentCategoryPreventDefault(event) {
        event.preventDefault();
    },

    onSelectSortBy(event) {
        const $select = $(event.currentTarget);
        const selected = $select.find(':selected').val().split('-');

        this.sortBy = selected[0];
        this.sortingMethod = selected[1];

        this.onChangeFilters();
    },

    async onClickAddDefaultProductToCart(event) {
        let route = Router.generate('add-cart-product-default');

        const data = {
            productId: $(event.currentTarget).attr('data-product-id'),
        };

        try {
            await $.post(route, data)

        } catch (exception) {
            toastr.error(Translator.trans('product-already-in-cart'));
            return;
        }

        if (this.isUserLoggedIn()) {
            toastr.success(Translator.trans('product-added-to-cart'));
        } else {
            toastr.error(Translator.trans('user-have-to-log-to-buy'));
        }

        this.reloadCartHeader();
        setTimeout(() => {
            $(document).find('.j-cart-header-dropdown').dropdown('toggle');
        }, 250);
    },

    reloadCartHeader() {
        $(document).find('.j-reload-cart-header').click();
    },
    showAllSubcategoriesFromCategory(categoryId) {
        this.showAllSubcategoriesContainer();
        $($(this.find('.j-subcategories')).find('[parent-id="' + categoryId + '"]')).show();
    },

    hideAllSubcategoriesFromCategory(categoryId) {
        $($(this.find('.j-subcategories')).find('[parent-id="' + categoryId + '"]')).hide();
    },

    showAllCategories() {
        this.find('.j-categories').show();
    },

    hideAllCategories() {
        this.find('.j-categories').hide();
    },

    showAllSubcategoriesContainer() {
        this.find('.j-subcategories').show();
    },

    hideAllSubcategoriesContainer() {
        this.find('.j-subcategories').hide();
    },

    showSubcategory(subcategoryId) {
        this.showAllSubcategoriesContainer();
        this.subcategoryImage(subcategoryId).show();
    },

    hideSubcategory(subcategoryId) {
        this.subcategoryImage(subcategoryId).hide();
    },

    checkSubcategoryById(subcategoryId) {
        this.subcategoryCheckbox(subcategoryId).prop('checked', true);
    },

    categoryCheckboxContainer(categoryId) {
        return this.find('#' + categoryId + '-category-checkbox-container');
    },

    categoryCheckbox(categoryId) {
        return this.find('#' + categoryId + '-category-checkbox-container').find('.j-parent-category-checkbox');
    },

    subcategoryCheckboxContainer(categoryId) {
        return this.find('#' + categoryId + '-subcategories-checkbox-container');
    },

    subcategoryCheckbox(subcategoryId) {
        return this.find('#' + subcategoryId);
    },

    subcategoryImage(subcategoryId) {
        return this.find('.j-subcategories').find('[data-id="' + subcategoryId + '"]');
    },

    getAllCategoriesIds() {
        const categories = $(this.find('.j-parent-category-checkbox'));
        let categoriesIds = [];
        categories.each((index, checkbox) => {
            categoriesIds.push($(checkbox).attr('data-id'));
        });
        return categoriesIds;
    },

    getAllSubcategoriesIds() {
        const subcategories = $(this.find('.j-subcategory-checkbox'));
        let subcategoriesIds = [];
        subcategories.each((index, checkbox) => {
            subcategoriesIds.push($(checkbox).attr('data-id'));
        });
        return subcategoriesIds;
    },

    getAllSubcategoriesIdsFromCategory(categoryId) {
        const subcategories = $(this.subcategoryCheckboxContainer(categoryId).find('.j-subcategory-checkbox'));
        let subcategoriesIds = [];
        subcategories.each((index, checkbox) => {
            subcategoriesIds.push($(checkbox).attr('data-id'));
        });
        return subcategoriesIds;
    },

    toggleCategoriesImages() {
        if (this.areAllCategoriesUnchecked()) {
            this.showAllCategories();
            this.hideAllSubcategoriesContainer();
        } else
            this.hideAllCategories();
    },

    toggleSubcategoriesImages() {
        const categoriesIds = this.getAllCategoriesIds();
        categoriesIds.forEach(categoryId => {
            const subcategoriesIds = this.getAllSubcategoriesIdsFromCategory(categoryId);
            subcategoriesIds.forEach(subcategoryId => {
                if (this.isSubcategoryChecked(subcategoryId) &&
                    this.areOnlySubcategoriesOfCategoryChecked(categoryId) &&
                    this.areAtLeastTwoSubcategoriesCheckedOfCategory(categoryId)) {
                    this.showSubcategory(subcategoryId);
                } else {
                    this.hideSubcategory(subcategoryId);
                }
            });
        });
    },

    onSearchButtonPressed(event) {
        this.makeSearch();
    },

    onKeyPressedInSearchInput(event) {
        if (event.key !== 'Enter')
            return;

        this.makeSearch();
    },

    makeSearch() {
        this.search = $('#search-input').val();

        this.page = 0;
        const productContainer = $('#product-container .j-item-col');
        console.log('aaaaaaaaaaa', productContainer);
        productContainer.remove();
        this.onRetrieveMoreProducts();
    }
})
