import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";
import {v4 as uuidv4} from 'uuid';


export const AdminWithdrawTypesDetailsPage = ElView.extend({
    el: '.j-admin-withdraw-type-details',
    events: {
        'click .j-save-withdrawType': 'onSaveWithdrawType',
        'click .j-remove-withdrawType': 'onDeleteWithdrawType',
        'click .j-create-clone': 'onCreateClone',
        'click .j-remove-new-withdrawType': 'onRemoveNewWithdrawType',
    },

    init() {

    },

    onRemoveNewWithdrawType(event) {
        const $button = $(event.currentTarget);
        const $container = $button.closest('.j-template-container');

        $container.remove();
    },

    onCreateClone() {
        const $container = this.find('.j-template');
        const $newRow = $container.clone();
        $newRow.removeClass("j-template d-none")
        $($newRow).appendTo(".j-create-withdraws-types-container");
        $('.j-admin-withdraw-type-details-container').animate({scrollTop: $('.j-admin-withdraw-type-details-container').height()}, "slow");
    },

    async onDeleteWithdrawType(event) {
        const withdrawTypeId = $(event.currentTarget).attr('data-withdraw-type-id');
        const route = this.route('core-api-remove-withdraws-types', {withdrawTypeId: withdrawTypeId});
        const $button = this.$(event.currentTarget);
        const $container = $button.closest('.j-withdraws-types-container');

        const result = await this.confirmation('¿Quiere eliminar el monedero?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Monedero eliminado');

                $container.remove();

            } catch (postFailed) {
                await this.confirmationError('Error', 'No ha sido eliminado');
            }
        }
    },

    async onSaveWithdrawType() {
        const $newTypes = this.find('.j-withdrawType-form');
        $newTypes.each(async (index, element) => {
            const $newType = $(element);

            if ($newType.val() !== '') {
                const route = Router.generate('core-api-update-withdraws-types');
                const data = {
                    newName: $newType.val(),
                    withdrawTypeId: uuidv4(),
                };

                try {
                    await $.post(route, data);
                } catch (postFailed) {

                }
            }
        });

        const $oldTypes = this.find('.j-withdraws-types-container');
        $oldTypes.each(async (index, element) => {
            const $oldTypeContainer = $(element);
            const newName = $oldTypeContainer.find('.j-newName').val();
            if (newName !== '') {
                const route = Router.generate('core-api-update-withdraws-types');
                const withdrawTypeId = $oldTypeContainer.find('.j-withdraw-type-id').attr('data-withdraw-type-id');
                const data = {
                    newName: newName,
                    withdrawTypeId: withdrawTypeId,
                };

                try {
                    await $.post(route, data);
                } catch (postFailed) {

                }
            }
        });

        location.reload();
    },

});