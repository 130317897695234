import {ElView} from "../../shared/el-view";
import {Router} from "../../shared/routing/router";

export const UserDashboardPage = ElView.extend({
    el: '.j-user-dashboard',
    events: {
        'click .j-product-favourite': 'onClickProductFavourite',
        'click .j-add-product-to-cart-dashboard-button': 'onClickAddProductToCart',
    },

    init() {
    },

    onClickProductFavourite: function (event) {
        const $clicked = $(event.currentTarget);
        const productId = $clicked.attr('data-product-id');

        window.location = Router.generate('tool-product-detail', {'productId': productId});
    },
})