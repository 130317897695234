import {ElView} from "../../el-view";


export const ToastComponent = ElView.extend({
    el: '#toast-messages',
    events: {},
    INFO_TYPE: 'info',
    SUCCESS_TYPE: 'success',
    ERROR_TYPE: 'error',
    WARNING_TYPE: 'warning',

    init: function () {
        toastr.options = {
            positionClass: 'toast-bottom-left',

            progressBar: true,
            preventDuplicates: false,
            closeButton: true,
        };
        this.render();
    },

    render: function () {
        const _this = this;

        this.find('.j-toast-message').each(function () {
            const $this = $(this);
            const msg = $this.attr('data-msg');
            const type = $this.attr('data-type');
            if (type === _this.SUCCESS_TYPE)
                toastr.success(msg);
            else if (type === _this.ERROR_TYPE)
                toastr.error(msg);
            else if (type === _this.WARNING_TYPE)
                toastr.warning(msg);
            else if(type === _this.INFO_TYPE)
                toastr.info(msg);
        });
    }
});
