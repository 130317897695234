import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";

export const ToolHands = ElView.extend({
    el: 'hands-downloader',
    events: {
        'click .j-update-tree': 'onUpdateTree',
        'click .j-download-file': 'onDownloadFile',
    },
    baseRoute: 'uploads/hands',
    treeData: null,
    selectedRoute: null,
    selectedFile: null,
    handsDropzone: null,

    async init() {
        this.selectedRoute = this.baseRoute;

        await this.getHandsTree();
        this.initTreeView(this.find('#tool-hands-treeview'));
    },

    async onUpdateTree() {
        await this.getHandsTree();
        this.jstree.jstree(true).settings.core.data = JSON.parse(this.treeData);
        this.jstree.jstree(true).refresh();
    },

    initTreeView($treeview) {
        if (this.jstree)
            this.jstree.jstree(true).destroy();

        this.jstree = $treeview.jstree({
            'plugins': ["types", "changed"],
            "core": {
                "themes": {
                    "responsive": false
                },
                'data': JSON.parse(this.treeData)
            },
            "types": {
                "default": {
                    "icon": "fa fa-folder text-warning"
                },
                "file": {
                    "icon": "fa fa-file  text-warning"
                }
            },
        });

        $treeview.on('select_node.jstree', (e, data) => {
            const path = data.instance.get_path(data.node, '/');
            this.selectedFile = path;
            this.selectedRoute = this.baseRoute + path;
        });
    },

    async getHandsTree() {
        const route = this.route('get-hands-tree');
        const data = await $.get(route)
        this.treeData = JSON.stringify(this.jstreeJsonBuilder(data));
    },

    jstreeJsonBuilder(data) {
        if (_.isEmpty(data))
            return;

        if (Array.isArray(data)) {
            let result = [];
            data.forEach((sibling) => {
                result.push(this.jstreeJsonBuilder(sibling));
            })
            return result;
        }

        if (data['type'] === 'dir') {
            if (data['relPath'] === '')
                return {
                    "text": data['relPath'], "icon": "fa fa-folder text-warning",
                    "state": {"opened": true},
                    "children": this.jstreeJsonBuilder(data['children'])
                    ,
                }
            return {
                "text": data['relPath'], "icon": "fa fa-folder text-warning",
                "children": this.jstreeJsonBuilder(data['children'])
                ,
            }
        }

        return {"text": data['relPath'], "icon": "fa fa-file text-info",}
    },

    onDownloadFile() {
        const _this = this;

        if (_.isNull(this.selectedFile) || !_this.selectedFile.includes('.')) {
            swal.fire({
                title: Translator.trans('no-file-selected-error'),
                icon: "error",
                confirmButtonText: "Cerrar",
                reverseButtons: true
            })

        } else {
            swal.fire({
                title: Translator.trans('download-file-confirmation'),
                text: _this.selectedFile,
                icon: "warning",
                confirmButtonText: "Confirmar",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                reverseButtons: true

            }).then(function (value) {
                if (value.value) {
                    const link = document.createElement('a');
                    link.href = window.location.origin + '/' + _this.selectedRoute;
                    link.download = _this.selectedFile;
                    link.target = "_blank";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    const route = _this.route('tool-api-update-user-hand-folder-levels');
                    const data = {
                        handFolder: _this.selectedRoute.split('/')[2],
                        folderLevel: _this.selectedRoute.split('/')[3],
                        userId: _this.find('.j-data').attr('data-user-id'),
                    };

                    $.post(route, data);

                    _this.onUpdateTree();
                }
            })
        }
    }
});