import {ElView} from "../shared/el-view";
import {DropzoneComponent} from "../shared/component/dropzone/dropzone-component";
import slugify from "@sindresorhus/slugify";

export const BlogRoomPostDetailsPage = ElView.extend({
    el: '.j-blog-room-post-details',
    events: {
        'keyup .j-create-post-form-title-input': 'onKeyPressed',
        'click .j-save-post': 'onSavePost',
        'click .j-remove-post': 'onDeletePost',
        'onChange .j-text-editor': 'onContentChange',
        'click .j-copy-es-form': 'onCopyESForm',
        'click .j-copy-en-form': 'onCopyENForm',
        'click .j-add-faq': 'addFaq',
        'click .j-change-design': 'changeDesign',
    },

    dropZoneUploader1: null,
    dropZoneUploader2: null,
    dropZoneUploader3: null,
    dropZoneUploader4: null,
    dropZoneUploader5: null,
    dropZoneUploader6: null,
    dropZoneUploader7: null,
    dropZoneUploader8: null,
    dropZoneUploader9: null,
    dropZoneUploader10: null,
    dropZoneUploader11: null,

    localeToTabClassMap: {
        'es_ES': 'j-esEsContent-tab-pane',
        'es_GB': 'j-ukEsContent-tab-pane',
        'en_GB': 'j-ukEnContent-tab-pane',
        'es': 'j-esContent-tab-pane',
        'en': 'j-enContent-tab-pane',
    },

    hidePostContentClassAndEvent: {
        intl: 'hide-intl',
        ES: 'hide-es',
        GB: 'hide-gb'
    },

    showPostContentClassAndEvent: {
        intl: 'show-intl',
        ES: 'show-es',
        GB: 'show-gb'
    },

    errorOnPostContentEvent: {
        es_ES: 'error-es-es',
        es_GB: 'error-es-gb',
        en_GB: 'error-en-gb',
        es: 'error-es',
        en: 'error-en',
    },

    allAvailableCountries: ['ES', 'GB', 'intl'],

    mainNavIds: ['info', 'instruccions', 'acordeon'],

    mainAccordionNavIds: ['acor1', 'acor2', 'acor3', 'acor4', 'acor5'],


    async init() {
        this.onShowAvailableCountriesWarning = _.debounce(this.showAvailableCountriesWarning, 100);

        this.initStatusSelect();
        this.initDropzones();

        this.onChangeAvailableCountries();
        await this.validateAvailableCountries();
        this.showErrorsOnMainNavIds(this.mainNavIds);
        this.showErrorsOnMainNavIds(this.mainAccordionNavIds);
    },

    showErrorsOnMainNavIds(navIds) {
        navIds.forEach((navId) => {
            const $navContent = this.find('#' + navId);
            const errorFoundInNavContent = $navContent.find('.j-content-editor-nav-error').length !== 0 || $navContent.find('.invalid-feedback ul').length !== 0;
            if (errorFoundInNavContent)
                this.showErrorOnNavId(navId);
        });
    },

    showErrorOnNavId(navId) {
        const $nav = this.find('[href="#' + navId + '"]')

        $nav.css('border-bottom', '3px solid red');
    },

    initDropzones() {
        this.dropZoneUploader1 = new DropzoneComponent({el: '#esContent .j-image-form'});
        this.dropZoneUploader2 = new DropzoneComponent({el: '#esEsContent .j-image-form'});
        this.dropZoneUploader3 = new DropzoneComponent({el: '#ukEsContent .j-image-form'});
        this.dropZoneUploader4 = new DropzoneComponent({el: '#ukEnContent .j-image-form'});
        this.dropZoneUploader5 = new DropzoneComponent({el: '#enContent .j-image-form'});
        this.dropZoneUploader6 = new DropzoneComponent({el: '#acor1 .j-image-form'});
        this.dropZoneUploader7 = new DropzoneComponent({el: '#acor2 .j-image-form'});
        this.dropZoneUploader8 = new DropzoneComponent({el: '#acor3 .j-image-form'});
        this.dropZoneUploader9 = new DropzoneComponent({el: '#acor4 .j-image-form'});
        this.dropZoneUploader10 = new DropzoneComponent({el: '#acor5 .j-image-form'});
        this.dropZoneUploader11 = new DropzoneComponent({el: '.j-blog-main-image-uploader'});

        this.dropZoneUploader1.onUpload((file) => {
            this.log(file)
            this.find('#esContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader2.onUpload((file) => {
            this.log(file)
            this.find('#esEsContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader3.onUpload((file) => {
            this.log(file)
            this.find('#ukEsContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader4.onUpload((file) => {
            this.log(file)
            this.find('#ukEnContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader5.onUpload((file) => {
            this.log(file)
            this.find('#enContent').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader6.onUpload((file) => {
            this.log(file)
            this.find('#acor1').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader7.onUpload((file) => {
            this.log(file)
            this.find('#acor2').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader8.onUpload((file) => {
            this.log(file)
            this.find('#acor3').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader9.onUpload((file) => {
            this.log(file)
            this.find('#acor4').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader10.onUpload((file) => {
            this.log(file)
            this.find('#acor5').find('.j-image-from-url-input').val(file.url);
        });

        this.dropZoneUploader11.onUpload((file) => {
            this.log(file)
            this.find('.j-image-url-input').val(file.url);
        });
    },

    async showAvailableCountriesWarning() {
        toastr.error('Rellene los contenidos de todos los países disponibles');
    },

    initStatusSelect() {
        this.find('.j-create-post-form-status-input').select2({
            minimumResultsForSearch: -1,
        });
    },

    onContentChange() {
        this.log('change')
    },

    onKeyPressed(event) {
        const $titleInput = this.$(event.currentTarget);
        const titleValue = $titleInput.val();

        if (!titleValue)
            return;

        const newSlug = slugify(titleValue);
        const $parentContainer = $titleInput.closest('.j-post-content-container');
        const $slugInput = $parentContainer.find('.j-create-post-slug');

        $slugInput.val(newSlug);
    },

    async changeDesign(event) {
        let displayStatus = this.find('.j-data').attr('data-room-post-display-status');
        let postId = $(event.currentTarget).attr('data-post-id');
        let result = '';
        let data = {
            displayStatus: displayStatus,
            postId: postId,
        };
        const route = this.route('change-display-status-room-post-extended');

        if (displayStatus === 'hidden')
            result = await this.confirmation('¿Deseas actualizar la vista de la sala al nuevo diseño?', 'Este paso se puede revertir', 'Aceptar', 'Cancelar');
        else
            result = await this.confirmation('¿Deseas actualizar la vista de la sala al antiguo diseño?', 'Este paso se puede revertir', 'Aceptar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route, data);

                await this.confirmationOk('Diseño cambiado');
                location.reload();

            } catch (postFailed) {
                await this.confirmationError('Error', 'El diseño no ha sido cambiado');
            }
        }
    },

    async onDeletePost(event) {
        const postId = $(event.currentTarget).attr('data-post-id');
        const route = this.route('remove-post', {postId});

        const result = await this.confirmation('¿Deseas borrar la sala?', 'Este paso no se puede deshacer', 'Borrar', 'Cancelar');

        if (result.isConfirmed) {
            try {
                await $.post(route);

                await this.confirmationOk('Sala eliminada');
                const redirectRoute = this.route('admin-blog-rooms-post');

                location.replace(redirectRoute);

            } catch (postFailed) {
                await this.confirmationError('Error', 'El post no ha sido eliminado');
            }
        }
    },

    async onSavePost() {
        this.clearErrorsOnPostContents();

        this.find('.j-room-post-form').submit();
    },

    async validateAvailableCountries() {
        const availableCountries = JSON.parse(this.find('.j-data').attr('data-available-countries'));

        if (availableCountries.includes('ES') && this.localizedContentIsEmpty('es_ES')) {
            this.showPostContentErrorOnLocaleAndContainer('es_ES', this.emptyContentSelectorsForLocale('es_ES'));
            await this.onShowAvailableCountriesWarning();
        }
        if (availableCountries.includes('GB')) {
            if (this.localizedContentIsEmpty('es_GB')) {
                this.showPostContentErrorOnLocaleAndContainer('es_GB', this.emptyContentSelectorsForLocale('es_GB'));
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en_GB')) {
                this.showPostContentErrorOnLocaleAndContainer('en_GB', this.emptyContentSelectorsForLocale('en_GB'));
                await this.onShowAvailableCountriesWarning();
            }
        }
        if (availableCountries.includes('intl')) {
            if (this.localizedContentIsEmpty('es')) {
                this.showPostContentErrorOnLocaleAndContainer('es', this.emptyContentSelectorsForLocale('es'));
                await this.onShowAvailableCountriesWarning();
            }
            if (this.localizedContentIsEmpty('en')) {
                this.showPostContentErrorOnLocaleAndContainer('en', this.emptyContentSelectorsForLocale('en'));
                await this.onShowAvailableCountriesWarning();
            }
        }
    },

    showPostContentErrorOnLocaleAndContainer(country, containerSelectors) {
        containerSelectors.forEach((selector) => {
            this.find(selector).addClass(this.errorOnPostContentEvent[country]).trigger(this.errorOnPostContentEvent[country]);
        });
    },

    localizedContentIsEmpty(locale) {
        return this.emptyContentSelectorsForLocale(locale).length !== 0;
    },

    emptyContentSelectorsForLocale(locale) {
        const genericInfo = this.genericInfoIsEmpty(locale) ? '.j-generic-info-container' : '';
        const creditInfo = this.creditIsEmpty(locale) ? '.j-credit-container' : '';
        const instructionsInfo = this.instructionsIsEmpty(locale) ? '.j-instructions-container' : '';
        const informationInfo = this.informationIsEmpty(locale) ? '.j-information-container' : '';

        const accordion1 = this.accordionIsEmpty(locale, '.j-accordion-1-container');
        const accordion2 = this.accordionIsEmpty(locale, '.j-accordion-2-container');
        const accordion3 = this.accordionIsEmpty(locale, '.j-accordion-3-container');
        const accordion4 = this.accordionIsEmpty(locale, '.j-accordion-4-container');
        const accordion5 = this.accordionIsEmpty(locale, '.j-accordion-5-container');

        let classSelectorsArray = (genericInfo + creditInfo + instructionsInfo + informationInfo + accordion1
            + accordion2 + accordion3 + accordion4 + accordion5).split('.');
        classSelectorsArray.shift();

        const selectorsArray = classSelectorsArray.map((classSelector) => '.' + classSelector);

        return classSelectorsArray[0] === '' ? [] : selectorsArray;
    },

    accordionIsEmpty(locale, accordionSelector) {
        const $genericInfoContainer = this.find(accordionSelector);
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $genericInfoContainer.find('.' + tabIdsFromLocale);

        return this.accordionContentIsEmpty($tabForLocale) ? accordionSelector : '';
    },

    genericInfoIsEmpty(locale) {
        const $genericInfoContainer = this.find('.j-generic-info-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $genericInfoContainer.find('.' + tabIdsFromLocale);

        return this.genericInfoContentIsEmpty($tabForLocale);
    },

    creditIsEmpty(locale) {
        const $creditContainer = this.find('.j-credit-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $creditContainer.find('.' + tabIdsFromLocale);

        return this.creditContentIsEmpty($tabForLocale);
    },
    instructionsIsEmpty(locale) {
        const $instructionsContainer = this.find('.j-instructions-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $instructionsContainer.find('.' + tabIdsFromLocale);

        return this.instructionsContentIsEmpty($tabForLocale);
    },
    informationIsEmpty(locale) {
        const $informationContainer = this.find('.j-information-container');
        const tabIdsFromLocale = this.localeToTabClassMap[locale];
        const $tabForLocale = $informationContainer.find('.' + tabIdsFromLocale);

        return this.informationContentIsEmpty($tabForLocale);
    },

    accordionContentIsEmpty($tabContent) {
        const contentTitle = $tabContent.find('.j-create-post-form-title-input').val();
        const contentContent = $tabContent.find('.j-create-post-form-content-input').val();

        return (this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentContent)) && (!this.inputIsEmpty(contentTitle) || !this.inputIsEmpty(contentContent));
    },

    genericInfoContentIsEmpty($tabContent) {
        const contentTitle = $tabContent.find('.j-create-post-form-title-input').val();
        const contentMetaTitle = $tabContent.find('.j-create-category-form-metaTitle-input').val();
        const contentSlug = $tabContent.find('.j-create-post-slug').val();
        const contentMetaDescription = $tabContent.find('.j-create-category-form-metaDescription-input').val();
        const contentLead = $tabContent.find("input[class*='j-create-room-post-lead-']").val();
        const contentClaim = $tabContent.find("input[class*='j-create-room-post-claim-']").val();

        return this.inputIsEmpty(contentTitle) || this.inputIsEmpty(contentMetaTitle) ||
            this.inputIsEmpty(contentSlug) || this.inputIsEmpty(contentMetaDescription) || this.inputIsEmpty(contentLead) || this.inputIsEmpty(contentClaim);
    },

    creditContentIsEmpty($tabContent) {
        const contentCredit = $tabContent.find('.j-create-room-post-credit').val();

        return this.inputIsEmpty(contentCredit);
    },

    instructionsContentIsEmpty($tabContent) {
        const contentContent = $tabContent.find('.j-create-post-form-content-input').val();

        return this.inputIsEmpty(contentContent);
    },

    informationContentIsEmpty($tabContent) {
        const contentContent = $tabContent.find('.j-create-post-form-content-input').val();

        return this.inputIsEmpty(contentContent);
    },

    inputIsEmpty(inputValue) {
        const inputCleared = this.removeHTMLTagsFromInput(inputValue);

        return _.isUndefined(inputCleared) || _.isNull(inputCleared) || inputCleared === '';
    },

    removeHTMLTagsFromInput(inputValue) {
        return inputValue.replaceAll(/<.+?>/g, '');
    },

    clearErrorsOnPostContents() {
        this.find('post-content-component').trigger('clear-errors');
    },

    onCopyESForm(event) {
        const $button = this.$(event.currentTarget);
        this.copyFromLang($button.closest('.j-post-content-container'), '#esContent')
    },

    onCopyENForm(event) {
        const $button = this.$(event.currentTarget);
        this.copyFromLang($button.closest('.j-post-content-container'), '#enContent')
    },

    copyFromLang($container, id) {
        const $titleValue = this.find(id).find('.j-create-post-form-title-input').val();
        const $slugInValue = this.find(id).find('.j-create-post-slug').val();
        const $descriptionValue = this.find(id).find('.j-create-post-form-shortDescription-input').val();
        const $contentValue = this.find(id).find('.j-create-post-form-content-input').val();
        const $metaTitleValue = this.find(id).find('.j-create-category-form-metaTitle-input').val();
        const $metaDescriptionValue = this.find(id).find('.j-create-category-form-metaDescription-input').val();

        const $parentContainer = $container;

        const $titleInput = $parentContainer.find('.j-create-post-form-title-input');
        const $slugInput = $parentContainer.find('.j-create-post-slug');
        const $descriptionInput = $parentContainer.find('.j-create-post-form-shortDescription-input');
        const $contentInput = $parentContainer.find('.j-create-post-form-content-input');
        const $metaTitleInput = $parentContainer.find('.j-create-category-form-metaTitle-input');
        const $metaDescriptionInput = $parentContainer.find('.j-create-category-form-metaDescription-input');

        $titleInput.val($titleValue);
        $slugInput.val($slugInValue);
        $descriptionInput.val($descriptionValue);
        $contentInput.summernote('code', $contentValue);
        $metaTitleInput.val($metaTitleValue);
        $metaDescriptionInput.val($metaDescriptionValue);
    },

    addFaq() {
        const $template = this.find('.j-faq-template');
        let $faqsContainer = this.find('.j-faq-content-container');
        const $faqRows = this.find('.j-faq-row');

        const newRowIndex = $faqRows.length;

        let $newRowForm = $template.clone();
        $newRowForm.removeClass('j-faq-row');
        $newRowForm = $($newRowForm.html().replaceAll(/__name__/g, newRowIndex));
        $newRowForm.addClass('j-faq-row');

        $faqsContainer.append($newRowForm);

        $newRowForm.fadeIn();

    },

    onChangeAvailableCountries() {
        const $postContent = this.find('post-content-component');

        const selectedCountries = JSON.parse(this.find('.j-data').attr('data-available-countries'));
        const notSelectedCountries = this.allAvailableCountries.filter((country) => !selectedCountries.includes(country));

        selectedCountries.forEach((country) => this.showPostContent(country, $postContent));
        notSelectedCountries.forEach((country) => this.hidePostContent(country, $postContent));
    },

    hidePostContent(country, $postContent) {
        $postContent.removeClass(this.showPostContentClassAndEvent[country])
        $postContent.addClass(this.hidePostContentClassAndEvent[country]).trigger(this.hidePostContentClassAndEvent[country]);
    },

    showPostContent(country, $postContent) {
        $postContent.removeClass(this.hidePostContentClassAndEvent[country])
        $postContent.addClass(this.showPostContentClassAndEvent[country]).trigger(this.showPostContentClassAndEvent[country]);
    }

});