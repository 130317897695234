import {ElView} from "../../../shared/el-view";

export const ToolMyOrders = ElView.extend({
    el: 'tool-my-orders',
    events: {},

    appSession: null,

    async init() {
        this.appSession = await $.get(this.route('get-session-app'));

        this.find('.j-my-orders-select-by-state').select2({
            minimumResultsForSearch: -1,
        })

        const route = this.route('my-orders-table-data')
        const response = await $.get(route);
        this.initOrdersTable(response);
    },

    initOrdersTable(data) {
        const _this = this;
        this.datatable = this.find('#tool-my-orders-table').KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#affiliates-search'),
                    key: 'generalSearch'
                },

                columns: [
                    {
                        field: 'createdAt',
                        title: this.translate('ordered-at'),
                        textAlign: 'center',
                        width: 175,
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.createdAt}</span>`;
                        }
                    },
                    {
                        field: 'billingData',
                        title: this.translate('billing-data'),
                        textAlign: 'center',
                        width: 225,
                        template: (row) => {
                            const billingData = row.billingData;
                            const shortened = (billingData.length > 10) ? (billingData.substr(0, 30) + '...') : billingData;

                            return `<span class="text-muted" data-toggle="tooltip" data-placement="top" title="${billingData}">${shortened}</span>`;
                        }
                    },
                    {
                        field: 'numberOfItems',
                        title: this.translate('number-of-items'),
                        textAlign: 'center',
                        width: 175,
                        template: (row) => {
                            return `<span class="text-muted">${row.numberOfItems}</span>`;
                        }
                    },
                    {
                        field: 'state',
                        title: this.translate('state'),
                        textAlign: 'center',
                        width: 150,
                        template: (row) => {
                            let backgroundColor = '';
                            let textColor = '';

                            if (row.state === 'pending') {
                                backgroundColor = '#FFF4DE';
                                textColor = 'warning';
                            }
                            if (row.state === 'paid') {
                                backgroundColor = '#C9F7F5';
                                textColor = 'primary';
                            }
                            if (row.state === 'processed') {
                                backgroundColor = '#eaffe2';
                                textColor = 'primary';
                            }
                            if (row.state === 'rejected') {
                                backgroundColor = '#FFE2E5';
                                textColor = 'danger';
                            }

                            return `<label class="font-weight-bold text-${textColor}"
                                            style="background-color: ${backgroundColor}; border-radius: 15px; padding: 0px 5px;">
                                                ${this.translate(row.state)}
                                        </label>`;
                        }
                    },
                    {
                        field: 'basePriceAmount',
                        title: this.translate('base-price-amount') + ' (' + this.appSession['coin'] + ')',
                        textAlign: 'center',
                        width: 175,
                        template: (row) => {
                            return `<span class="text-muted">${row.basePriceAmount}</span>`;
                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: this.translate('details'),
                        textAlign: 'center',
                        template: (row) => {
                            const route = _this.route('tool-order-details', {orderId: row.id})

                            return `<a href="${route}" class="btn btn-icon btn-light-info j-tool-order-details" data-toggle="tooltip" data-placement="top" title="Detalles"><span class="flaticon2-list-3" style="font-size: 1.5rem;"></span></a>`;
                        },
                    },
                ],
            }
        );

        $('.j-my-orders-select-by-state').on('select2:select', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'state');
        });
    },
})