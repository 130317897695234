import {ElView} from "../../el-view";
import {Chart} from 'chart.js/dist/chart';
import {Router} from "../../../shared/routing/router";
import _ from 'lodash/lodash';
import {
    convertDatesToYearMonthArray,
    dateFromString,
    formatYearMonthString,
    getCurrentDateDDMMYYYY
} from "../../helper/date-helper";
import {DownloadFile} from "../download-file";

export const RakeChartComponent = ElView.extend({
    el: '.j-rake-chart',
    events: {
        'change .j-start-date': 'onChangeDate',
        'change .j-end-date': 'onChangeDate',
        'select2:select .j-select-user': 'onChangeUser',
        'select2:select .j-select-room': 'onChangeRoom',
        'select2:unselect .j-select-room': 'onChangeRoom',
        'select2:select .j-select-affiliate': 'onChangeAffiliate',
        'click .j-reset': 'resetFields',
        'change .j-checkbox-type': 'onChangeCheckbox',
        'click .j-download-csv': 'onDownloadCSV',
    },

    rakeChart: null,
    startDate: null,
    endDate: null,
    userSelected: null,
    roomsSelected: [],
    affiliateSelected: null,
    affiliateUser: null,
    initialStartDate: null,
    initialEndDate: null,

    datesXAxisChart: [],
    userRakebackData: {},
    rakeData: {},
    parentData: {},
    rakeDates: {},
    subAffiliateDataset: null,

    affiliateTypes: [],

    shadesOfGreen: [
        '#255e4c',
        '#378755',
        '#67ae4d',
        '#aad337',
        '#fff200',
    ],

    shadesOfBlue: [
        '#022561',
        '#005798',
        '#0089b1',
        '#00baaa',
        '#53e78c',
    ],

    shadesOfRed: [
        '#a600ff',
        '#ff606c',
        '#ff954c',
        '#ffc93b',
        '#f8f77b',
    ],

    async init() {
        this.blockBody();
        this.initDatePickers();

        this.initRakeDates();

        const data = await this.getRakes();
        this.setRakeChartDatasets(Object.values(data['total']), 'total');

        this.renderChart();

        this.initUserSelect();
        this.initRoomSelect();
        this.initAffiliateSelect();

        this.renderMaxUser();
        this.renderMaxRoom();

        this.unblockBody();
    },

    renderChart() {
        this.generatedRakeChart = new Chart(this.find('#generated-rake-chart'), {
                type: 'bar',
                data: this.chartData(this.rakeData, this.shadesOfGreen),
                options: this.chartOptions('Rake generado (€)'),
            }
        );

        this.userRakebackChart = new Chart(this.find('#user-rakeback-chart'), {
                type: 'bar',
                data: this.chartData(this.userRakebackData, this.shadesOfBlue),
                options: this.chartOptions('Rakeback Usuario (€)'),
            }
        );

        this.affiliateRakebackChart = new Chart(this.find('#affiliate-rakeback-chart'), {
                type: 'bar',
                data: this.chartData(this.parentData, this.shadesOfRed),
                options: this.chartOptions('Rakeback Afiliado (€)'),
            }
        );
    },

    chartOptions(title) {
        let delayed;

        return {
            transitions: {
                hide: {
                    animation: {
                        duration: 0,
                    },
                }
            },
            animation: {
                easing: 'easeOutQuint',
                onComplete: () => {
                    delayed = true;
                },
                delay: (context) => {
                    let delay = 0;
                    if (context.type === 'data' && context.mode === 'default' && !delayed) {
                        delay = context.dataIndex * 50 + context.datasetIndex * 10;
                    }
                    return delay;
                },
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: title,
                    font: {
                        size: 30,
                    }
                },
                legend: {
                    fontColor: '#ac0000',
                    position: 'right',
                    boxHeight: 20,
                    boxWidth: 20,
                }
            }
        };
    },

    chartData(data, colors) {
        let dataset = [];

        Object.keys(data).forEach((roomId, index) => {
            const roomName = this.getRoomName(roomId);

            const newSet = [{
                data: data[roomId],
                label: roomName,
                borderColor: colors[index],
                backgroundColor: colors[index],
                boxWidth: 5,
                fill: colors[index],
            }];

            dataset = dataset.concat(newSet);
        });


        return {
            labels: this.datesXAxisChart,
            datasets: dataset,
        }
    },

    initDatePickers() {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.initialStartDate = this.startDate;
        this.initialEndDate = this.endDate;

        $('.j-start-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        $('.j-end-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });
    },

    async initUserSelect() {
        const $userSelect = this.find('.j-select-user');
        const route = this.route('get-user-usernames');
        const data = {
            roomIds: this.roomsSelected,
            affiliateId: this.affiliateSelected,
        };

        $userSelect.empty();
        $userSelect.select2({});
        await $.get(route, data)
            .done((users) => {
                users.forEach(user => {
                    const newOption = new Option(user.username, user.id, false, false);
                    this.find('.j-select-user').append(newOption).trigger('change');
                });
                this.find('.j-select-user').val(null).trigger('change');
            });

        $userSelect.val(this.userSelected).trigger('change');
    },

    async initRoomSelect() {
        const $roomSelect = this.find('.j-select-room');
        const route = this.route('get-room-roomnames-from-credentials');
        const data = {
            affiliateId: this.affiliateSelected,
            userId: this.userSelected,
        }

        $roomSelect.empty();
        $roomSelect.select2({});
        await $.get(route, data)
            .done((rooms) => {
                rooms.forEach(room => {
                    const newOption = new Option(room.name, room.id, false, false);
                    this.find('.j-select-room').append(newOption).trigger('change');
                });
                this.find('.j-select-room').val(null).trigger('change');
            });

        $roomSelect.val(this.roomsSelected).trigger('change');

        const selectOptions = this.find('.j-select-room').val();
        this.removeUnavailableRoomsFromSelection(selectOptions, this.roomsSelected);
    },

    async initAffiliateSelect() {
        const $affiliateSelect = this.find('.j-select-affiliate');
        const route = Router.generate('all-affiliate-name');
        const data = {
            roomIds: this.roomsSelected,
            types: this.affiliateTypes
        };

        $affiliateSelect.empty();
        $affiliateSelect.select2({});
        await $.get(route, data)
            .done((affiliates) => {
                affiliates.forEach(affiliate => {
                    const newOption = new Option(affiliate.name, affiliate.id, false, false);
                    this.find('.j-select-affiliate').append(newOption).trigger('change');
                });
                this.find('.j-select-affiliate').val(null).trigger('change');
            });

        $affiliateSelect.val(this.affiliateSelected).trigger('change');
    },

    async renderMaxUser() {
        const maxUser = await this.getData('max-rake-user', {
            start: this.startDate,
            end: this.endDate,
        });

        const route = this.route('admin-user-details', {userId: maxUser.userId})
        this.find('.max-user').html(`<a href="${route}">${maxUser.username}</a>`);
    },

    async renderMaxRoom() {
        const maxRoom = await this.getData('max-rake-room', {
            start: this.startDate,
            end: this.endDate,
        });

        const route = this.route('admin-room-details', {roomId: maxRoom.roomId})
        this.find('.max-room').html(`<a href="${route}">${maxRoom.name}</a>`);
    },

    async getData(routeName, parameters) {
        const route = Router.generate(routeName, parameters);
        return await $.get(route);
    },

    async getRakes() {
        const rakeData = {};

        if (this.roomsSelected.length === 0)
            rakeData['total'] = await this.getRakesData(this.roomsSelected);
        else
            await Promise.all(this.roomsSelected.map((roomId) => {
                rakeData[roomId] = this.getRakesData([roomId])
                return rakeData[roomId];
            }));

        for (const roomId in rakeData)
            rakeData[roomId] = await rakeData[roomId];

        return rakeData;
    },

    async getRakesData(roomIds) {
        const query = {
            startAt: this.startDate,
            endAt: this.endDate,
            userId: this.userSelected,
            roomIds: roomIds,
            affiliateId: this.affiliateSelected,
        };

        const route = Router.generate('report-rakes', query);

        return await $.get(route);
    },

    async onChangeDate() {
        this.startDate = this.find('.j-start-date').val();
        this.endDate = this.find('.j-end-date').val();

        this.initRakeDates();
        this.updateRakeData();
        this.renderMaxUser();
        this.renderMaxRoom();
    },

    async onChangeRoom() {
        this.roomsSelected = this.find('.j-select-room').val();

        this.initAffiliateSelect();
        this.initUserSelect();
        this.updateRakeData();
    },

    async onChangeUser() {
        this.userSelected = this.find('.j-select-user').val();

        const route = Router.generate('get-affiliate', {'userId': this.userSelected});
        this.affiliateId = await $.get(route);

        const affiliateSelector = this.find('.j-select-affiliate');

        affiliateSelector.val(this.affiliateId);
        affiliateSelector.select2().trigger('change');
        affiliateSelector.prop('disabled', true);

        await this.initRoomSelect();
        this.updateRakeData();
    },

    removeUnavailableRoomsFromSelection(options, selectedRooms) {
        const newSelection = [];

        selectedRooms.forEach((roomId) => {
            if (options.includes(roomId))
                newSelection.push(roomId);
        });

        this.roomsSelected = newSelection;
    },

    async onChangeAffiliate() {
        this.affiliateSelected = this.find('.j-select-affiliate').val();

        // const userSelector = this.find('.j-select-user');
        // userSelector.prop('disabled', true);
        //
        // const route = Router.generate('get-subaffiliates', {'affiliateId': this.affiliateSelected});
        // //TEST
        // console.log(await $.get(route));
        //
        this.initUserSelect();
        this.initRoomSelect();
        this.updateRakeData();
    },

    setRakeChartDatasets(data, roomId) {
        let userRakeback = {};
        let generatedRake = {};
        let parentRakeback = {};

        Object.values(data).forEach((rake) => {
            const date = formatYearMonthString(rake['yearMonth']);

            generatedRake[date] = rake['generatedRake'];
            userRakeback[date] = rake['userRakeback'] / 100;
            parentRakeback[date] = rake['parentRakeback'] / 100;
        });

        this.rakeData[roomId] = this.fillEmptyKeysWithZero(generatedRake, this.datesXAxisChart);
        this.userRakebackData[roomId] = this.fillEmptyKeysWithZero(userRakeback, this.datesXAxisChart);
        this.parentData[roomId] = this.fillEmptyKeysWithZero(parentRakeback, this.datesXAxisChart);
    },

    fillEmptyKeysWithZero(data, keys) {
        let filledData = {};
        keys.forEach((key) => {
            filledData[key] = data[key];

            if (!data[key])
                filledData[key] = 0.0;
        });

        return filledData;
    },

    async updateRakeData() {
        this.userRakebackData = {};
        this.rakeData = {};
        this.parentData = {};

        const result = await this.getRakes();

        Object.keys(result).forEach((roomId) => this.setRakeChartDatasets(Object.values(result[roomId]), roomId))

        this.updateChart();
    },

    updateChart() {
        this.generatedRakeChart.destroy();
        this.userRakebackChart.destroy();
        this.affiliateRakebackChart.destroy();

        this.renderChart();
    },

    async onChangeCheckbox(event) {
        const $currentTarget = $(event.currentTarget);
        const affiliateType = $currentTarget.val();

        this.affiliateTypes = _.xor(this.affiliateTypes, [affiliateType]);

        this.find('.j-select-affiliate').val(null).empty().select2('destroy');

        this.initAffiliateSelect();
    },

    async resetFields() {
        this.blockBody();

        this.startDate = this.initialStartDate;
        this.endDate = this.initialEndDate;
        this.roomsSelected = [];
        this.userSelected = null;
        this.affiliateSelected = null;
        this.affiliateUser = null;
        this.parentData = {};

        this.find('.j-start-date').val(this.startDate);
        this.find('.j-end-date').val(this.endDate);
        this.initUserSelect();
        this.initRoomSelect()
        this.initAffiliateSelect();
        this.find('.j-select-affiliate').prop('disabled', false);

        await this.updateRakeData();

        this.unblockBody()
    },

    getRoomName(roomId) {
        if (_.isEmpty(roomId) || roomId === 'total')
            return 'Total';

        const roomItem = this.find('.j-select-room').select2('data').find((item) => item.id === roomId);

        if (_.isUndefined(roomItem) || _.isNull(roomItem))
            return '';

        return roomItem.text;
    },

    initRakeDates() {
        const startDate = dateFromString(this.startDate, 'd/m/Y');
        const endDate = dateFromString(this.endDate, 'd/m/Y');

        this.datesXAxisChart = convertDatesToYearMonthArray(startDate, endDate);
    },

    onDownloadCSV() {
        let csvContent = 'Sala;YearMonth;Rake;RakebackUsuario;RakebackAfiliado\r\n';

        Object.entries(this.rakeData).forEach(([roomId, rakesByDates]) => {
            const roomName = this.getRoomName(roomId);
            Object.entries(rakesByDates).forEach(([yearMonth, rake]) => {
                let row = roomName + ';' + yearMonth + ';' + rake.toString().replace('.', ',') + ';' + this.userRakebackData[roomId][yearMonth].toString().replace('.', ',') + ';' + this.parentData[roomId][yearMonth].toString().replace('.', ',');
                csvContent += row + "\r\n";
            })
        });

        const today = getCurrentDateDDMMYYYY('-');

        const fileDownloader = new DownloadFile({
            el: this,
            type: 'text/csv;charset=utf-8;',
            content: csvContent,
            filename: 'rakes_data_' + today + '.csv'
        });
    }
});