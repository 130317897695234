import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";

export const AdminProductCategoriesPage = ElView.extend({
    el: '.j-admin-product-categories',
    events: {
        'datatable-on-layout-updated': 'initListeners',
    },
    datatable: null,

    init() {
        this.find('.j-select-2-category-status').select2({
            minimumResultsForSearch: -1,
        })

        this.undelegateEvents();
        this.delegateEvents();
        this.initCategoriesTable();
    },

    initListeners() {
        this.undelegateEvents();
        this.delegateEvents();
        this.find('.j-toggle-category-status').click(this.onToggleCategoryStatus.bind(this));
    },

    initCategoriesTable() {
        this.datatable = this.find('#admin-product-categories-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('product-categories-table-data'),
                    pageSize: 10,
                },
                success: () => {
                    this.find('.j-toggle-product-status').click(this.onToggleProductStatus.bind(this));
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#product-categories-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'title',
                        title: capitalizeFirstLetter(Translator.trans('title')),
                    },
                    {
                        field: 'isParentCategory',
                        title: capitalizeFirstLetter(Translator.trans('type')),
                        textAlign: 'center',
                        template: row => {
                            let type = '';
                            if (row.isParentCategory)
                                type = Translator.trans('category.category')
                            else
                                type = Translator.trans('category.subcategory')
                            return `<span class="label label-inline label-rounded font-weight-bold label-light-primary">${capitalizeFirstLetter(type)}</span>`;
                        }

                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter('status'),
                        textAlign: 'center',
                        template: (row) => {
                            const statusToCssClassMap = {
                                'enabled': 'primary',
                                'disabled': 'danger'
                            };

                            return `<button class="border-0 label label-inline label-rounded font-weight-bold label-light-${statusToCssClassMap[row.status]} j-toggle-category-status" data-category-id="${row.id}"">${capitalizeFirstLetter(
                                Translator.trans('product.status.' + row.status))}</button>`;
                        }
                    },
                    {
                        field: 'actions',
                        sortable: false,
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        textAlign: 'center',
                        width: 150,
                        template: function (row) {
                            const editRoute = Router.generate('admin-category-details', {'id': row.id})
                            return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>`
                        },

                    },

                ],
            }
        );
        $('#product-categories-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });
    },

    onToggleCategoryStatus(event) {
        // event.preventDefault();
        const $clickedEl = $(event.currentTarget);
        if ($clickedEl.length === 0)
            return;

        const route = this.route('toggle-category-status', {'categoryId': $clickedEl.attr('data-category-id')});
        $.post(route)
            .always(() => location.reload());
    }
});