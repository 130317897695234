import {ElView} from "../../el-view";
import {formatNumber} from "../../helper/number-helper";
import {getCurrentDateDDMMYYYY} from "../../helper/date-helper";
import {DownloadFile} from "../download-file";

export const UserAnalyticsComponent = ElView.extend({
    el: 'user-analytics-component',
    events: {
        'change .j-user-analytics-start-date': 'onChangeDate',
        'change .j-user-analytics-end-date': 'onChangeDate',
        'select2:select .j-select-user': 'onChangeUser',
        'select2:select .j-select-room': 'onChangeRoom',
        'select2:unselect .j-select-room': 'onChangeRoom',
        'click .j-row-expand-refereds': 'wrapperOnClickExpandUsers',
        'click .j-row-expand-subaffiliates': 'wrapperOnClickExpandUsers',
        'click .j-download-csv': 'onDownloadCSV',
    },

    startDate: null,
    endDate: null,

    userRakeHistories: null,
    referedRakeHistories: null,
    subaffiliatesRakeHistories: null,

    roomIdOptions: null,
    roomsSelected: null,

    expandedReferedAffiliateIds: [],
    expandedSubAffiliateIds: [],

    userId: null,

    isReferedUserView: false,

    shadesOfGreen: [
        '#fcf9bc',
        '#eafcba',
        '#cdfcbd',
        '#bbfcd3',
        '#c1fce9',
    ],

    shadesOfBlue: [
        '#022561',
        '#005798',
        '#0089b1',
        '#00baaa',
        '#53e78c',
    ],

    shadesOfRed: [
        '#c478ef',
        '#f37880',
        '#f6ab79',
        '#fad77b',
        '#fafa7f',
    ],

    async init() {
        this.initDatePickers();
        this.userId = this.find('.j-data').attr('data-user-id');

        if (this.userId !== '') {
            this.find('.j-select-user').val(this.userId).trigger('change');
            this.find('.j-select-user').trigger('select2:select');
        }

        this.isReferedUserView = $('.refered-user-view').length !== 0;
    },

    insertRowAfterElement(userRakeHistories, $element, type, expandedUserId, anidationLevel) {
        const $rowTemplate = this.find('.j-template-row');

        if (anidationLevel !== 0) {
            $element.addClass('row-expanded_common');
            $element.addClass('row-expanded_' + anidationLevel);
        }
        Object.values(userRakeHistories).forEach((rakeHistory) => {
            if (type === 'isRefered' || type === 'isSubaffiliate') {
                rakeHistory = this.isReferedUserView ? rakeHistory['earningsAsPlayer'] : rakeHistory['allUserEarnings'];
            }

            if (rakeHistory.rake === 0 && type !== 'isExpanded')
                return;

            const $newTableRow = $rowTemplate.clone();

            $newTableRow.removeClass('j-template-row');
            $newTableRow.addClass('j-table-row');

            $newTableRow.attr('data-user-id', rakeHistory.userId);
            $newTableRow.attr('data-expanded-user-id', expandedUserId);
            $newTableRow.attr('data-anidation-level', anidationLevel);
            if (type !== 'asPlayer' && type !== 'fromReferedsWithoutUser' && type !== 'asAffiliate' && type !== 'fromRefereds')
                $newTableRow.find('.j-row-username').text(rakeHistory.username);
            $newTableRow.find('.j-row-username').attr('href', this.route('admin-user-details', {userId: rakeHistory.userId}));
            $newTableRow.find('.j-row-rake').text(formatNumber(rakeHistory.rake, 2));
            $newTableRow.find('.j-row-user-earnings').text(formatNumber(rakeHistory.userEarnings / 100, 2));
            $newTableRow.find('.j-row-rakeback').text(formatNumber(rakeHistory.otherCombinedEarnings / 100, 2));

            if (type === 'isExpanded') {
                $newTableRow.find('.j-row-type').text('Total');
                $newTableRow.addClass('row-expanded-refered-parent');
                $newTableRow.find('.j-row-expand-refereds').remove();

                if (this.isReferedUserView) {
                    const total = this.calculateTotal();
                    $newTableRow.find('.j-row-rake').text(total);
                }
            }

            if (type === 'isRefered') {
                $newTableRow.find('.j-row-type').text('Referido');
                $newTableRow.addClass('row-expanded-refered-children_' + anidationLevel);
                $newTableRow.addClass('j-is-refered');
            }

            if (type === 'isSubaffiliate') {
                $newTableRow.addClass('j-is-subaffiliate');
                $newTableRow.find('.j-row-type').text('Subafiliado');
                $newTableRow.addClass('row-expanded-subaffiliate-children_' + anidationLevel);
            }

            if (type === 'asPlayer') {
                $newTableRow.addClass('j-is-as-player');
                $newTableRow.find('.j-row-type').text('Como jugador');
                $newTableRow.addClass('row-expanded-refered-parent--as-player');
                $newTableRow.find('.j-row-expand-refereds').remove();
            }

            if (type === 'fromReferedsWithoutUser') {
                $newTableRow.addClass('j-is-from-refereds-without-user');
                $newTableRow.find('.j-row-type').text('Externos');
                $newTableRow.addClass('row-expanded-refered-parent--from-credentials-without-user');
                $newTableRow.find('.j-row-expand-refereds').remove();
            }

            if (type === 'asAffiliate') {
                $newTableRow.addClass('j-is-as-affiliate');
                $newTableRow.find('.j-row-type').text('De afiliados');
                $newTableRow.addClass('row-expanded-refered-parent--as-affiliate');
                $newTableRow.find('.j-row-expand-refereds').remove();
            }

            if (type === 'fromRefereds') {
                $newTableRow.addClass('j-is-from-refereds');
                $newTableRow.find('.j-row-type').text('De referidos');
                $newTableRow.addClass('row-expanded-refered-parent--from-refereds');
                $newTableRow.find('.j-row-expand-refereds').remove();

                if (this.isReferedUserView) {
                    const fromReferedsRake = this.calculateFromRefereds();
                    $newTableRow.find('.j-row-rake').text(fromReferedsRake);
                }
            }

            $newTableRow.insertAfter($element);
            $newTableRow.fadeIn();
        })
    },

    async fetchUserData(userId) {
        const data = {
            userIdToLookRakes: userId,
            userIdToCalculateEarnings: this.userId,
            startAt: this.startDate,
            endAt: this.endDate,
            roomIds: this.roomsSelected,
        }

        const route = this.route('get-refereds-report', data);

        return await $.get(route);
    },

    async fetchSubaffiliatesData(affiliateId) {
        const data = {
            parentAffiliateId: affiliateId,
            startAt: this.startDate,
            endAt: this.endDate,
            roomIds: this.roomsSelected,
        }

        const route = this.route('get-subaffiliates-report', data);

        return {};
        return await $.get(route);
    },

    initDatePickers() {
        this.startDate = this.find('.j-user-analytics-start-date').val();
        this.endDate = this.find('.j-user-analytics-end-date').val();

        $('.j-user-analytics-start-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });

        $('.j-user-analytics-end-date').datepicker({
            format: 'd/m/yyyy',
            orientation: 'bottom',
        });
    },

    async onChangeDate() {
        this.startDate = this.find('.j-user-analytics-start-date').val();
        this.endDate = this.find('.j-user-analytics-end-date').val();

        await this.rebuildTable()

        this.initRoomSelector(this.userRakeHistories['allUserEarnings']['roomIds']);
    },

    async rebuildTable() {
        this.emptyTableData();

        const $rowTemplate = this.find('.j-template-row');

        this.userRakeHistories = await this.fetchUserData(this.userId);

        await this.insertRowAfterElement(this.userRakeHistories['referedEarnings'], $rowTemplate, 'isRefered', this.userId, 0);

        this.insertRowAfterElement(this.userRakeHistories['subAffiliateEarnings'], $rowTemplate, 'isSubaffiliate', this.userId, 0);

        this.insertRowAfterElement([this.userRakeHistories['earningsFromRefereds']], $rowTemplate, 'fromRefereds', this.userId, 0);

        this.insertRowAfterElement([this.userRakeHistories['earningsFromReferedsWithoutUser']], $rowTemplate, 'fromReferedsWithoutUser', this.userId, 0);

        this.insertRowAfterElement([this.userRakeHistories['earningsAsAffiliate']], $rowTemplate, 'asAffiliate', this.userId, 0);

        this.insertRowAfterElement([this.userRakeHistories['earningsAsPlayer']], $rowTemplate, 'asPlayer', this.userId, 0);

        this.insertRowAfterElement([this.userRakeHistories['allUserEarnings']], $rowTemplate, 'isExpanded', this.userId, 0);

        for (const referedAffiliateId of this.expandedReferedAffiliateIds) {
            let $referedRow = this.find("tr[data-user-id='" + referedAffiliateId + "']").find('.j-row-expand-refereds');
            if (!!$referedRow)
                await this.onClickExpandUser($referedRow);
        }
    },

    emptyTableData() {
        this.find('.j-table-row').remove();
    },

    async initRoomSelector(rakeHistories) {
        this.updateSelectorOptions(rakeHistories)

        const $roomSelect = this.find('.j-select-room');
        const route = this.route('get-room-roomnames');
        const data = {
            roomIds: this.roomIdOptions,
        }

        $roomSelect.empty();
        $roomSelect.select2({});
        await $.get(route, data)
            .done((rooms) => {
                rooms.forEach(room => {
                    const newOption = new Option(room.name, room.id, false, false);
                    this.find('.j-select-room').append(newOption).trigger('change');
                });
                this.find('.j-select-room').val(null).trigger('change');
            });
    },

    updateSelectorOptions(rakeHistories) {
        const firstElementIsNotUuid4 = !rakeHistories[0][0] || rakeHistories[0][0].length === 1;
        if (firstElementIsNotUuid4) {
            this.roomIdOptions = _.uniq(rakeHistories);
            return;
        }

        const allRoomIds = [];
        Object.values(rakeHistories).forEach((roomIdsArray) => allRoomIds.push(...roomIdsArray));

        this.roomIdOptions = _.uniq(allRoomIds);
    },

    async onChangeRoom() {
        this.roomsSelected = this.find('.j-select-room').val();

        this.rebuildTable()
    },

    async wrapperOnClickExpandUsers(event) {
        const $clicked = $(event.currentTarget);

        const type = $clicked.hasClass('j-row-expand-refereds') ? 'isRefered' : 'isSubaffiliate';

        await this.onClickExpandUser($clicked, type);
    },

    async onClickExpandUser($clicked) {
        const $tableRow = $clicked.closest('.j-table-row');

        const expandUserId = $tableRow.attr('data-user-id');

        const isExpanded = $clicked.attr('data-is-expanded') === 'isExpanded';
        if (!isExpanded)
            await this.expandUserRakeHistories(expandUserId, $tableRow, $clicked);
        else
            this.retractReferedRakeHistories(expandUserId, $tableRow, $clicked);
    },

    async expandUserRakeHistories(expandUserId, $tableRow, $clicked) {
        $clicked.attr('data-is-expanded', 'isExpanded');

        const $icon = $tableRow.find('.j-expand-icon');
        $icon.removeClass('fa-plus-square');
        $icon.addClass('fa-minus');

        const anidationLevel = parseFloat($tableRow.attr('data-anidation-level')) + 1;

        let rakeHistories = await this.fetchUserData(expandUserId);

        this.insertRowAfterElement(rakeHistories['referedEarnings'], $tableRow, 'isRefered', expandUserId, anidationLevel);
        if (!this.expandedReferedAffiliateIds.includes(expandUserId))
            this.expandedReferedAffiliateIds.push(expandUserId);

        this.insertRowAfterElement(rakeHistories['subAffiliateEarnings'], $tableRow, 'isSubaffiliate', expandUserId, anidationLevel);
        if (!this.expandedReferedAffiliateIds.includes(expandUserId))
            this.expandedReferedAffiliateIds.push(expandUserId);

    },

    retractReferedRakeHistories(expandUserId, $tableRow, $clicked, type) {
        this.retractChildren(expandUserId, type);

        $tableRow.removeClass('row-expanded_common');
        $clicked.attr('data-is-expanded', 'isNotExpanded');

        const $icon = $tableRow.find('.j-expand-icon');
        $icon.removeClass('fa-minus');
        $icon.addClass('fa-plus-square');

        this.expandedReferedAffiliateIds = this.expandedReferedAffiliateIds.filter((affiliateId) => affiliateId !== expandUserId);

        this.find("tr[data-expanded-user-id='" + expandUserId + "']").remove();
    },

    retractChildren(referedAffiliateId) {
        const $expandedChildren = this.find("tr[data-recipient-affiliate-id='" + referedAffiliateId + "']");

        for (const $expandedChild of $expandedChildren) {
            this.onClickExpandUser($($expandedChild).find('.j-row-expand-refereds'));
        }
    },

    async onChangeUser(event) {
        this.blockBody();

        this.userId = $(event.currentTarget).select2('data')[0].id;

        await this.rebuildTable()

        this.unblockBody();

        if (!this.roomsSelected)
            this.initRoomSelector(this.userRakeHistories['allUserEarnings']['roomIds']);
    },

    onDownloadCSV() {
        let csvContent = 'Nomber de usuario;Tipo;Rake generado;Comisiones del afiliado;Rakeback del resto de usuarios\r\n';

        this.find('.j-table-row').each((index, rowElement) => {
            const $rowElement = $(rowElement);
            const username = $rowElement.find('.j-row-username').text();
            const type = $rowElement.find('.j-row-type').text();
            const rake = $rowElement.find('.j-row-rake').text();
            const userEarnings = $rowElement.find('.j-row-user-earnings').text();
            const rakeback = $rowElement.find('.j-row-rakeback').text();

            let row = username + ';' + type + ';' + rake + ';' + userEarnings + ';' + rakeback;

            csvContent += row + '\r\n';
        })

        const today = getCurrentDateDDMMYYYY('-');

        const fileDownloader = new DownloadFile({
            el: this,
            type: 'text/csv;charset=utf-8;',
            content: csvContent,
            filename: 'affiliate_commissions_' + today + '.csv'
        });
    },

    calculateFromRefereds() {
        let totalRakeFromRefereds = 0;
        let referedsRows = $(".j-is-refered");

        for (const referedsRow of referedsRows) {
            let rakeDiv = referedsRow.querySelectorAll(".j-row-rake");

            let firstNode = rakeDiv[0];
            let rake = parseFloat(firstNode.innerHTML.replace(".", "").replace(",", "."));
            totalRakeFromRefereds += rake;
        }

        let numString = totalRakeFromRefereds.toFixed(2);
        let num = parseFloat(numString);
        numString = num.toFixed(2).toString();
        let parts = numString.split(".");
        let integerPart = parts[0];
        let decimalPart = parts[1];
        let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + decimalPart;

        return result;
    },

    calculateTotal() {
        let playerRow = $(".row-expanded-refered-parent--as-player")[0];
        let playerRakeDiv = playerRow.querySelectorAll(".j-row-rake");
        let playerRake = parseFloat(playerRakeDiv[0].innerHTML.replace(".", "").replace(",", "."));
        let totalReferedsRow = $(".row-expanded-refered-parent--from-refereds")[0];
        let totalReferedsRakeDiv = totalReferedsRow.querySelectorAll(".j-row-rake");
        let totalReferedsRake = parseFloat(totalReferedsRakeDiv[0].innerHTML.replace(".", "").replace(",", "."));
        let total = playerRake + totalReferedsRake;
        let numString = total.toFixed(2).toString();
        let parts = numString.split(".");
        let integerPart = parts[0];
        let decimalPart = parts[1];
        let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + decimalPart;

        return result;
    }
});