import {ElView} from "../el-view";
import {capitalizeFirstLetter} from "../helper/string-helper";
import {Translator} from "../translation/translator";

export const CopyToClipboardComponent = ElView.extend({
    el: '.j-copy-to-clipboard',
    events: {
        'click': 'onClickCopyToClipboard',
    },
    userId: null,

    init() {
    },

    onClickCopyToClipboard(event) {
        const $clicked = $(event.currentTarget);

        toastr.options = {
            positionClass: 'toast-bottom-left',

            progressBar: true,
            preventDuplicates: true,
            closeButton: true,
        };

        const copyTextarea = $clicked.attr('data-url');

        navigator.clipboard.writeText(copyTextarea).then(function () {
            toastr.success(capitalizeFirstLetter(Translator.trans('copied')));

        }, function (err) {
            toastr.warning(capitalizeFirstLetter(Translator.trans('error')));
        });
    }
    ,
})