import {ElView} from "../../../shared/el-view";
import {Translator} from "../../../shared/translation/translator";
import {formatNumber} from "../../../shared/helper/number-helper";


export const AdminRoomReportPreviewTablePage = ElView.extend({
    events: {
        'click .j-reset-preview-manual': 'onClickResetPreviewManual',
    },

    init(params) {
        this.initReportPreviewTable(params.data)
    },

    initReportPreviewTable(data) {
        this.datatable = this.$el.KTDatatable(
            {
                data: {
                    type: 'local',
                    source: data,
                    pageSize: 10,
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: $(this.el).find('#report-preview-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'username',
                        title: 'Nombre',
                        template: (row) => {
                            if (row.username === 'Sin asignar')
                                return `<label class="j-username-row-select-user-credentials font-weight-bold" 
                                            style="background-color: #1bc5bd45; border-radius: 15px; padding: 0px 5px;">${row.username}
                                        </label>`;
                            return `<span class="font-weight-bolder">${row.username}</span>`
                        }
                    },
                    {
                        field: 'nick',
                        title: 'Nick',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.nick}</span>`
                        }
                    },
                    {
                        field: 'login',
                        title: 'Login',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.login}</span>`
                        }
                    },
                    {
                        field: 'code',
                        title: 'ID Number',
                        template: (row) => {
                            return `<span class="font-weight-bold">${row.code}</span>`
                        }
                    },
                    {
                        field: 'room',
                        title: 'Sala',
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${row.room}</span>`
                        }
                    },
                    {
                        field: 'amount',
                        title: 'Rake',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${formatNumber(row.amount, 2)}</span>`
                        }
                    },
                    {
                        field: 'error_msg',
                        title: 'Estado',
                        template: (row) => {
                            if (row.error_msg === 'credentials_not_verified')
                                return `<label class="label label-lg label-inline label-light-danger font-weight-bold">No verificado
                                        </label>`;
                            return `<label class="label label-lg label-inline  font-weight-bold label-light-primary">Ok
                                        </label>`;
                        }
                    },
                    {
                        field: 'currency',
                        title: 'Divisa',
                        width: 150,
                        template: (row) => {
                            return `<span class="font-weight-bolder text-muted">${Translator.trans(row.currency)}</span>`
                        }
                    },
                ],
            }
        );

        $('#report-preview-username-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'username');
        });

        $('#report-preview-nick-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'nick');
        });

        $('#report-preview-login-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'login');
        });

        $('#report-preview-code-search').on('input', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'code');
        });
    },

    destroy() {
        this.datatable.destroy();
    }
});