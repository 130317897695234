const sumRakeHistoriesColumns = (mainField, fieldValue, roomId, data) => {
    return _.chain(data)
        .filter((row) => fieldValue === '*' || row[mainField] === fieldValue)
        .filter((row) => roomId === '*' || row.roomId === roomId)
        .reduce((rake, row) => {
            rake.generatedRake += row.rakeInEuros;
            rake.userRakeback += row.userRakeback;
            rake.affiliatesRakeback += parseFloat(row.parentRakeback) + parseFloat(row.subaffiliateRakeback);

            return rake;

        }, {fieldName: '*', roomName: '*', generatedRake: 0, userRakeback: 0, affiliatesRakeback: 0})
        .value();
}

const calculate = (userIds, roomIds, affiliateIds, nick, login, data) => {
    const rows = [];
    let mainFields = userIds;
    let mainField = 'userId';

    if (affiliateIds[0] !== '*') {
        mainField = 'affiliateId';
        mainFields = affiliateIds
    }

    if (nick[0] !== '*') {
        mainField = 'nick';
        mainFields = nick
    }

    if (login[0] !== '*') {
        mainField = 'login';
        mainFields = login
    }

    mainFields.forEach((fieldId) => {
        roomIds.forEach((roomId) => {
            rows.push({
                mainField: mainField,
                fieldId: fieldId,
                roomId: roomId,
                ...sumRakeHistoriesColumns(mainField, fieldId, roomId, data)
            });
        })
    });

    return rows;
};

export const rakeReportCalculator = () => {
    return {calculate}
}