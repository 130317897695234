import {ElView} from "../shared/el-view";
import {capitalizeFirstLetter} from "../shared/helper/string-helper";
import {Translator} from "../shared/translation/translator";

export const BlogSectionsPage = ElView.extend({
    el: '.j-blog-sections',
    events: {},
    datatable: null,

    init: function () {
        this.initSectionsTable();
    },

    initSectionsTable() {
        this.datatable = this.find('#blog-sections-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('blog-sections-table-data'),
                    pageSize: 10,
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: false,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#posts-search'),
                    key: 'generalSearch'
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },

                columns: [

                    {
                        field: 'title',
                        width: 300,
                        title: capitalizeFirstLetter('titulo'),
                        textAlign: 'center',
                        template: (row) => {
                            return `<span class="font-weight-bolder">${row.title}</span>`;
                        }
                    },
                    {
                        field: 'shortDescription',
                        width: 300,
                        title: capitalizeFirstLetter('descripción'),
                        textAlign: 'center',
                        template: (row) => {
                            return `${row.shortDescription}`;
                        }
                    },

                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter('Acciones'),
                        textAlign: 'center',
                        width: 300,
                        template: (row) => {

                            const detailsRoute = this.route('admin-blog-section-details', {sectionId: row.id});

                            return `
                                    <a href="${detailsRoute}" class="btn btn-sm btn-icon btn-light-warning" title="Edit details">
                                        <span class="fas fa-pencil-alt">
                                        </span>
                                    </a>
                            
                                  `
                        },
                    },
                ],
            }
        );
    },


});