import {Router} from './routing/router';
import * as Backbone from "backbone";
import {Translator} from "./translation/translator";

window.jQuery = window.$ = window.themeJquery;
Backbone.$ = window.themeJquery;
const localBackbone = Backbone.noConflict();

const BODY = 'body';

const HABWIN_ID = '020ff7ef-57bb-403b-92f3-93d6471d44f4';
const PARTYRAKEBACK_ID = 'ff65ab50-f8eb-4cc5-954d-03dc44900cba';

export const ElView = localBackbone.View.extend({
    initialize: function (params) {
        if (this.init && this.$el.length > 0) {
            this.$body = $('body');
            this.init(params);
        } else {
            this.undelegateEvents();

        }
    },

    find: function (selector) {
        return this.$el.find(selector);
    },

    findBody: function (selector) {
        return this.$body.find(selector);
    },

    route: function (url, params) {
        return Router.generate(url, params);
    },

    log(...data) {
        console.log(...data);
    },

    attr(attribute) {
        return this.$el.attr(attribute);
    },
    reload() {
        location.reload();
    },

    redirect(url) {
        window.location.href = url;
    },

    confirmation(title, text, confirmButtonText, cancelButtonText) {
        return Swal.fire({
            title: title,
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            reverseButtons: true
        })
    },

    confirmationOk(title, text) {
        return Swal.fire({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: this.translate('confirm'),
            reverseButtons: true
        });
    },

    confirmationError(title, text) {
        return Swal.fire(
            title,
            text,
            "error"
        );
    },

    block(el, text) {
        KTApp.block(el, {
            overlayColor: 'black',
            opacity: 0.4,
            state: 'primary',
            message: text
        });

        setTimeout(() => this.unblock(el), 5000);
    },

    unblock(el) {
        KTApp.unblock(el);
    },

    blockBody() {
        this.block(BODY);
    },

    isUserLoggedIn() {
        return this.findBody('.j-is-user-logged-in').attr('data-is-user-logged-in') === 'true';
    },

    unblockBody() {
        this.unblock(BODY);
    },

    translate(text) {
        return Translator.trans(text);
    },
    isMobile() {
        return this.$body.find('device-info').attr('data-device') === 'mobile';

    },

    isHabwin() {
        return this.find('.j-session-app').attr('data-id') === HABWIN_ID;
    },

    isPartyrakeback() {
        return this.find('.j-session-app').attr('data-id') === PARTYRAKEBACK_ID;
    },

    routeFromPartyToHabwin: (url, params) => 'https://www.habwin.com' + Router.generate(url, params),
});