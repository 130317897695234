import {ElView} from "../../shared/el-view";


export const AdminZoho = ElView.extend({
    el: 'zoho-uploader',
    events: {
        'click .j-update-tree': 'onUpdateTree',
        'click .j-remove-file': 'onRemoveFile',
    },

    baseRoute: 'uploads/zoho',
    treeData: null,
    selectedRoute: null,
    selectedFile: null,
    dropzone: null,

    async init() {
        this.selectedRoute = this.baseRoute;
        const _this = this;

        $('#admin-zoho-treeview').on('select_node.jstree', function (e, data) {
            const path = data.instance.get_path(data.node, '/');
            _this.selectedFile = path;
            _this.selectedRoute = _this.baseRoute + path;
        })

        await this.getZohoFiles();
        this.initTreeView(this.find('#admin-zoho-treeview'));
        this.initDropzone();
    },

    async onUpdateTree() {
        await this.getZohoFiles();
        this.jstree.jstree(true).settings.core.data = JSON.parse(this.treeData);
        this.jstree.jstree(true).refresh();
    },

    initTreeView($treeview) {
        this.jstree = $treeview.jstree({
            'plugins': ["types", "changed"],
            "core": {
                "themes": {
                    "responsive": false
                },
                'data': JSON.parse(this.treeData)
            },
            "types": {
                "default": {
                    "icon": "fa fa-folder text-warning"
                },
                "file": {
                    "icon": "fa fa-file  text-warning"
                }
            },
        });

        $treeview.on('select_node.jstree', function (e, data) {
            const link = $('#' + data.selected).find('a');
            if (link.attr("href") != "#" && link.attr("href") != "javascript:;" && link.attr("href") != "") {
                if (link.attr("target") == "_blank") {
                    link.attr("href").target = "_blank";
                }
                document.location.href = link.attr("href");
                return false;
            }
        });
    },

    initDropzone() {
        const _this = this;
        $('#j-zoho-dropzone').addClass("dropzone dropzone-default");

        this.dropzone = $('#j-zoho-dropzone').dropzone({
            url: _this.route('upload-files'),
            paramName: _this.baseRoute, // The name that will be used to transfer the file
            maxFilesize: 100, // MB
            addRemoveLinks: true,

            error: function (file, error, xhr) {
                if (xhr.status === 400)
                    swal.fire({
                        title: "El fichero ya existe",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                        reverseButtons: true
                    })

            },
            success: function (file, done) {
                _this.onUpdateTree();
            },
        });
    },

    async getZohoFiles() {
        const route = this.route('get-route-tree');
        const data = {route: this.baseRoute};

        await $.get(route, data)
            .done((data) => {
                this.treeData = JSON.stringify(this.jstreeJsonBuilder(data));
            })
    },

    jstreeJsonBuilder(data) {
        if (_.isEmpty(data))
            return;

        if (Array.isArray(data)) {
            let result = [];
            data.forEach((sibling) => {
                result.push(this.jstreeJsonBuilder(sibling));
            })
            return result;
        }

        if (data['type'] === 'dir') {
            if (data['relPath'] === '')
                return {
                    "text": data['relPath'], "icon": "fa fa-folder text-warning",
                    "state": {"opened": true},
                    "children": this.jstreeJsonBuilder(data['children'])
                    ,
                }
            return {
                "text": data['relPath'], "icon": "fa fa-folder text-warning",
                "children": this.jstreeJsonBuilder(data['children'])
                ,
            }
        }

        return {"text": data['relPath'], "icon": "fa fa-file text-info",}
    },

    onRemoveFile() {
        const _this = this;

        if (this.selectedRoute === this.baseRoute) {
            swal.fire({
                title: "Ningún fichero seleccionado",
                icon: "error",
                confirmButtonText: "Cerrar",
                reverseButtons: true
            })
        } else {
            swal.fire({
                title: "¿Seguro que deseas eliminar el fichero?",
                text: _this.selectedFile,
                icon: "warning",
                confirmButtonText: "Confirmar",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                reverseButtons: true

            }).then(async function (value) {
                if (value.value) {
                    const route = _this.route('remove-file-or-folder');
                    const data = {route: _this.selectedRoute};
                    await $.post(route, data);

                    await _this.onUpdateTree();
                    this.selectedRoute = null;
                    this.selectedFile = null;
                }
            })
        }
    },
});