import {ElView} from "../shared/el-view";

export const CorporateRoomsPage = ElView.extend({
    el: '.j-corporate-rooms',
    events: {
        'select2:select .j-roomgroups-rooms': 'onSelectRoomGroup',
        'select2:select .j-sw-rooms': 'onSelectSw',
        'click .j-room-affiliate-link': 'onClickRoomAffiliateLink',
    },
    sw: '',
    roomGroupId: '',
    webSectionName: '',
    init() {
        $(document).ready(function () {
            $('.j-sw-rooms').select2();
        });
        $(document).ready(function () {
            $('.j-roomgroups-rooms').select2();
        });

        const $table = $('table');
        $table.floatThead({
            top: 75
        });
    },

    onSelectRoomGroup(event) {
        const $select = $(event.currentTarget);
        this.roomGroupId = ($select.select2('data')[0].id);
        this.webSectionName = this.find('.j-data').attr('data-web-section');
        if (($select.select2('data')[0].text) === 'Todos') {
            this.roomGroupId = '';
        }

        this.renderNewRooms();
    },

    onSelectSw(event) {
        const $select = $(event.currentTarget);
        this.webSectionName = this.find('.j-data').attr('data-web-section');
        this.sw = ($select.select2('data')[0].id);
        if (($select.select2('data')[0].text) === 'Todos') {
            this.sw = '';
        }

        this.renderNewRooms();
    },

    async renderNewRooms() {
        let tableRoute = this.route('public-room-table-rows');
        const data = {
            roomGroupId: this.roomGroupId,
            webSectionName: this.webSectionName,
            sw: this.sw
        }
        let cardRoute = this.route('public-room-details-card');
        const tableHtml = await $.get(tableRoute, data);
        const cardHtml = await $.get(cardRoute, data);

        this.find('.j-table-body').html(tableHtml);
        this.find('.j-rooms-details').html(cardHtml);
    },

    onClickRoomAffiliateLink(event) {
        const url = this.find(event.currentTarget).attr('data-url');

        window.open(url, '_blank');
    },
});
