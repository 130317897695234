import {ElView} from "../shared/el-view";


export const HomePage = ElView.extend({
    el: '.j-home',
    events: {
        'click .home-advantages-arrow-down': 'onClickSlideDownDescription',
        'click .cancelBtnModal': 'onClickCancelBtnModal',
    },

    init() {
        console.time('initRewardsCarousel');
        this.initRewardsCarousel();
        console.timeEnd('initRewardsCarousel');
        console.time('initRoomsCarousel');
        this.initRoomsCarousel();
        console.timeEnd('initRoomsCarousel');
        _.defer(() => {
            console.time('initParticles 2');
            this.initParticles();
            console.timeEnd('initParticles 2');
        });
        console.time('initSwiper');
        this.initSwiper();
        console.timeEnd('initSwiper');
    },

    initSwiper() {
        if (this.find('.j-home-promotions-container').length === 0)
            return;

        new Swiper('.j-home-promotions-container', {
            speed: 400,
            direction: 'horizontal',
            slidesPerView: 1,
            autoHeight: false,
            centeredSlides: false,
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    loop: true,
                },
                1000: {
                    slidesPerView: 2,
                    loop: true,
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
        })
    },
    initParticles() {
        if (this.find('.background-particles').length === 0)
            return;

        Particles.init({
            connectParticles: true,
            selector:
                '.background-particles',
            maxParticles: 125,
            color: '#a2bbbb',
            responsive: [
                {
                    breakpoint: 768,
                    options: {
                        maxParticles: 70,
                        // color:'#48F2E3',
                        connectParticles: true
                    }
                }, {
                    breakpoint: 375,
                    options: {
                        maxParticles: 50,
                        // color:'#48F2E3',
                        connectParticles: true
                    }
                },
            ]
        });
    },

    initRewardsCarousel: function () {
        $('.j-rewards-carousel').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            draggable: true,
            centerMode: true,
            variableWidth: true,
            useTransform: false
        })
        /*$('.j-rewards-carousel').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            $('.j-reward-carousel-item').removeClass('active');
            $(`.j-reward-carousel-item.slick-slide[data-slick-index="${nextSlide}"]`).addClass('active');
        });*/
    },

    initRoomsCarousel: function () {
        this.$roomsCarousel = $('.j-rooms-carousel').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: true,
            centerMode: true,
            variableWidth: true,
        })

        $('.j-rooms-carousel').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            $('.j-room-carousel-item').removeClass('active');
            $(`.j-room-carousel-item.slick-slide[data-slick-index="${nextSlide}"]`).addClass('active');
        })
        ;
    },

    onClickSlideDownDescription(event) {
        const toggle = this.$(event.currentTarget);
        this.descriptionSlideDown(toggle.closest('.j-advantage-container'));
        this.changeArrow(toggle.closest('.j-advantage-container'));

    },

    onClickCancelBtnModal() {
        $('.j-age-check-modal .modal-content').hide();
    },

    descriptionSlideDown(container) {
        const child = container.find('.home-advantage-description');
        $(child).slideToggle("fast");
        $(child).show();
    },

    changeArrow(container) {
        const child = container.find('.home-advantages-arrow-down');
        if ($(child).hasClass('fa-chevron-down')) {
            $(child).removeClass('fa-chevron-down');
            $(child).addClass('fa-chevron-up');
        } else {
            $(child).removeClass('fa-chevron-up');
            $(child).addClass('fa-chevron-down');
        }

    },


});
