import {ElView} from "../shared/el-view";

export const LanguageGreetingPage = ElView.extend({
    el: '.j-language-greeting',
    events: {},

    init() {
        this.fillLanguageCard();
    },

    async fillLanguageCard() {
        const country = $(this.el).attr('data-country');

        const route = this.route('language-greeting');
        const data = {
            country: country,
            localeFromRoute: $(this.el).attr('data-route-locale'),
            requestRoute: $(this.el).attr('data-request-route'),
            requestParameters: $(this.el).attr('data-request-parameters'),
            websection: $(this.el).attr('data-websection'),
        }

        const response = await $.get(route, data);

        if (response === 'no-modal')
            this.find('#language-greeting-modal').modal('hide');
        else {
            this.find('#language-greeting-modal').modal('show');
            this.find('.j-language-greeting_modal-body').append(response);
        }
    },
})